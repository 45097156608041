import { Task } from '@app/api/models/Tasks';
import TasksApi from '@app/api/public/TasksApi';
import { L } from '@app/shared/lib/i18n';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getTodosQueryKeys } from './useGetTaskList';

type Variables = {
    id: string;
    setTodoState: React.Dispatch<React.SetStateAction<'completed' | 'todo' | 'expired' | undefined>>;
};
const unCompleteTask = async (task: Pick<Task, 'id'>) => {
    return TasksApi.markAsNonCompleted(task.id);
};

const useUncompleteTask = () => {
    const queryClient = useQueryClient();
    const { propertyId } = useParams<{ propertyId: string }>();
    return useMutation((task: Variables) => unCompleteTask(task), {
        onMutate: (variables) => {
            queryClient.cancelQueries(getTodosQueryKeys.list(propertyId));
            variables.setTodoState('todo');
        },
        onError: (_error, variables) => {
            toast(`❌  ${L('error')}`);
            variables.setTodoState('completed');
        },
        onSettled: () => {
            queryClient.invalidateQueries(getTodosQueryKeys.all);
        },
    });
};

export default useUncompleteTask;
