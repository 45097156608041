import { NotificationType } from '@app/api/models/Notifications';
import styled from 'lib/styled';
import React, { FC, RefAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import useNotificationsList from '../Notice/hooks/useNotificationList';
import NotificationMapper from '../Notice/notificationHandler/NotificationMapper';
import H1 from '../Texts/H1';

const StyledLink = styled(Link)({});

const Titel = styled(H1)(({ theme }) => {
    return {
        textAlign: 'left',
        fontSize: '0.8rem',
        fontWeight: 500,
        color: theme?.colors?.black90,
        padding: '0.25rem 0px 0rem 0.75rem',
        margin: '0px',
    };
});

const ContentData = styled.div({
    padding: '1.5rem 0.75rem 0rem',
});

type Props = {
    notification: NotificationType;
};
const NotificationBannerContent: FC<Props & RefAttributes<HTMLAnchorElement>> = ({ notification, ...rest }) => {
    if (!notification) return null;
    return (
        <StyledLink {...rest} to={`/`}>
            <NotificationMapper style={{ margin: '0px' }} notification={notification} isCollapsed={true} />
        </StyledLink>
    );
};

export default NotificationBannerContent;
