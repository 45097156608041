import TicketApi, { CreateTicket, Ticket } from '@app/api/public/TicketsApi';
import { useParams } from 'react-router-dom';
import { analyticsExeptionEvent } from '../../../lib/analytics/analyticsEvent';
import { useMutation } from 'react-query';

export const useSendTicket = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const mutation = useMutation(
        ({ ticket, attachments }: { ticket: CreateTicket; attachments: File[] }) => {
            return saveTicket({ propertyId, ticket, attachments });
        },
        {
            onSuccess: (data) => {
                if (!data) return;
            },
        }
    );

    return mutation;
};

const saveTicket = async ({
    ticket,
    propertyId,
    attachments,
}: {
    ticket: CreateTicket;
    propertyId: string | number;
    attachments: File[];
}) => {
    try {
        if (
            isInvalidTicket({
                description: ticket.description,
                title: ticket.title,
            })
        )
            return Promise.reject('ticket validation failed');

        return TicketApi.saveTicket(+propertyId, ticket, attachments);
    } catch (error) {
        analyticsExeptionEvent(`Save ticket Error: ${JSON.stringify(error)}`);

        return Promise.reject('Server error');
    }
};

const isInvalidTicket = ({ title, description }: Pick<Ticket, 'description' | 'title'>) => {
    if (!title || !description) return 'Fältet får ej vara tomt';
    return false;
};
