import React from 'react';
import styled from 'lib/styled';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { desktop } from '../MediaQuery/breakpoints';
import HeroNav from '../../components/Navbars/HeroNav/HeroNav';
import H1 from '../Texts/H1';
import { colors, Colors } from '../Shared/Style/colors';
import { ThemeType } from '@app/api/models/Theme';

const StyledCarouselWrapper = styled.div({
    marginBottom: '1rem',
    gridArea: 'headerCarousel',
});

const StyledHeader = styled.div({
    gap: '2rem',
    display: 'flex',
    justifyContent: 'end',
    marginRight: '1rem',
    zIndex: 2,
    marginTop: '1rem',
    color: 'white',
    height: 'fit-content',
    gridArea: 'headerCarousel',
});

const HeroBox = styled.div({
    gridTemplateAreas: `
        "headerCarousel"
    `,
    position: 'relative',
    width: '100%',
    display: 'grid',
    marginBottom: '0.5rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const StyledImg = styled.img((props: { url?: string | undefined | null; gradient?: boolean; theme: ThemeType }) => ({
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: props?.theme?.colors?.black90 || colors?.black90,
    backgroundImage: props.url
        ? props.gradient
            ? `linear-gradient(0deg, rgba(0,0,0,0.48783263305322133) 0%, rgba(0,0,0,0.0984768907563025) 37%, rgba(255,255,255,0) 55%, rgba(255,255,255,0) 64%, rgba(0,0,0,0.1741071428571429) 81%, rgba(0,0,0,0.41220238095238093) 100%), url(${props.url})`
            : `url(${props.url})`
        : ``,
    aspectRatio: '4/3',
}));

const StyledNav = styled(HeroNav)({
    color: 'white',
    display: 'flex',
    marginLeft: '1rem',
});

const H1StyledAsH2 = styled(H1)({
    alignSelf: 'end',
    margin: '0px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '100%',
    marginBottom: '2.75rem',
    letterSpacing: '0.41px',
    gridArea: 'headerCarousel',
    zIndex: 2,
    padding: '1rem',
    paddingBottom: '0px',
});

export type Props = {
    urls: (string | undefined)[];
    to: string;
    gradient?: boolean;
    className?: string;
    title?: string;
    color?: Colors;
};
const CarouselHero: React.FC<Props> = ({ to, urls, children, color = 'white', title, gradient = true }) => {
    return (
        <HeroBox>
            <StyledHeader>
                <StyledNav to={to} />
                {children}
            </StyledHeader>

            <StyledCarouselWrapper className="carousel-wrapper">
                <Carousel showThumbs={false} showStatus={false} showArrows={false}>
                    {urls &&
                        urls.map((url) => {
                            return (
                                <div key={url}>
                                    <StyledImg gradient={gradient} url={url} />
                                </div>
                            );
                        })}
                </Carousel>
            </StyledCarouselWrapper>
            <H1StyledAsH2 color={color}>{title}</H1StyledAsH2>
        </HeroBox>
    );
};

export default CarouselHero;
