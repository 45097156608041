import React from 'react';
import styled from 'lib/styled';
import { FileTypeIcon } from '../../Icon/Icon';
import { colors, Colors } from '../../Shared/Style/colors';
import { ThemeType } from '@app/api/models/Theme';
import useGetDocumentUrl from '../../../areas/documents/useGetDocumentUrl';
import { DocumentDetail } from '@app/api/models/Documents';

type props = {
    color?: Colors;
    document?: DocumentDetail;
};

const P = styled.p(({ color, theme }: { color?: Colors; theme: ThemeType }) => {
    return {
        fontSize: '0.8rem',
        padding: '0px',
        color: color ? theme?.colors?.[color] || colors[color] : theme?.colors?.textColor2 || colors.textColor2,
        textDecoration: 'none',
        maxWidth: '100%',
        overflowWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&:hover': {
            cursor: 'pointer',
        },
    };
});

const StyledA = styled.a({
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr min-content min-content',
    gap: '1rem',
    position: 'relative',
});

const DocumentItem: React.FC<props & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>> = ({
    color = 'textColor2',
    document,
    children,
    ...rest
}) => {
    const { getSignedUrlDocument } = useGetDocumentUrl();
    const { data: url } = getSignedUrlDocument(document);

    const fileType = document?.document_data?.original?.metadata?.mime_type?.split('/').pop();

    return (
        <StyledA href={url} target="_blank" {...rest}>
            <div style={{ alignSelf: 'center' }}>
                <FileTypeIcon fileType={fileType} />
            </div>
            <P style={{ width: '100%' }} color={color}>
                {document?.name || document?.document_data?.original?.metadata?.filename}
            </P>
            {children}
        </StyledA>
    );
};

export default DocumentItem;
