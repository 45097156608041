import styled from 'lib/styled';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HeaderOnlyBackArrow from '../../components/Header/HeaderOnlyBackArrow';
import HeaderStart from '../../components/Header/HeaderStart';
import { BorderDesktop } from '../../components/Layout/BorderDesktop';
import { PageBackground } from '../../components/Layout/PageBackground';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { FixedBar } from '../../components/StickyBar/FixedBar';
import StickyBarContent from '../../components/StickyBar/StickyBarContent';
import ContactAdd from './ContactAdd';
import ContactsBoardDetails from './ContactsBoardDetails';
import ContactsDetails from './ContactsDetails';
import ContactsList from './ContactsList';

const StyledHeaderStart = styled(HeaderStart)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});
const StyledFixedBar = styled(FixedBar)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});
const StyledPageBackground = styled(PageBackground)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'grid',
        gridTemplateColumns: 'auto auto 1fr',
        maxHeight: '100vh',
    },
});

const MobileOnly = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const Aside = styled.aside({
    display: 'none',
    paddingRight: '2rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        alignSelf: 'start',
        display: 'block',
        maxHeight: 'calc(100vh - 140px)',
        maxWidth: '340px',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'visible',
    },
});
const Main = styled.main({
    width: '100%',
    [`@media screen and (min-width: ${desktop}px)`]: {
        paddingLeft: '1rem',
        alignSelf: 'start',
    },
});

const StyledBorderDesktop = styled(BorderDesktop)({
    display: 'none',
    height: '100%',
    maxHeight: '100%',
    margin: '0px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

export const ContactsPage = () => {
    return (
        <StyledPageBackground>
            <Aside>
                <div>
                    <ContactsList />
                </div>
            </Aside>
            <StyledBorderDesktop />
            <Main>
                <Switch>
                    <Route path="/contacts/add">
                        <HeaderOnlyBackArrow />
                        <ContactAdd />
                    </Route>
                    <Route path="/contacts/board/:id">
                        <ContactsBoardDetails />
                    </Route>
                    <Route path="/contacts/:id">
                        <ContactsDetails />
                    </Route>
                    <Route path="/contacts">
                        <MobileOnly>
                            <StyledHeaderStart />
                            <ContactsList />
                            <StyledFixedBar>
                                <StickyBarContent />
                            </StyledFixedBar>
                        </MobileOnly>
                    </Route>
                </Switch>
            </Main>
        </StyledPageBackground>
    );
};
