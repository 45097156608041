import React, { FC, useState } from 'react';
import styled from 'lib/styled';

const Image = styled.img({
    width: '100%',
    maxWidth: '100%',
    maxHeight: '750px',
    overflow: 'hidden',
    objectFit: 'contain',
    border: 'none',
});

type Props = {
    url: string;
};

const FeedImage: FC<Props> = ({ url, ...rest }) => {
    const [isError, setIsError] = useState(false);

    const handleOnError = () => {
        setIsError(true);
    };

    return isError ? null : <Image src={url} onError={handleOnError} {...rest} />;
};

export default FeedImage;
