import { observable } from 'mobx';
import { useObserver } from 'mobx-react';
import { useMemo } from 'react';
import { desktop as desktopBreakpoint, tablet as tabletBreakpoint } from './breakpoints';

const active = observable({ view: 'mobile' });
const desktop = window.matchMedia(`(min-width: ${desktopBreakpoint}px)`);
const tablet = window.matchMedia(`(min-width: ${tabletBreakpoint}px)`);

function setActiveView() {
    if (desktop.matches) active.view = 'desktop';
    else if (tablet.matches) active.view = 'tablet';
    else active.view = 'mobile';
}

try {
    // Chrome & Firefox
    desktop.addEventListener('change', setActiveView);
    tablet.addEventListener('change', setActiveView);
} catch (e1) {
    try {
        // Safari
        desktop.addListener((e) => {
            setActiveView;
        });
        tablet.addListener((e) => {
            setActiveView;
        });
    } catch (e2) {
        console.error(e2);
    }
}

// desktop.addListener('change', setActiveView);
// tablet.addListener('change', setActiveView);

setActiveView();

export function MediaQuery(props: { on?: string; off?: string; children: any }) {
    const on = useMemo(() => (props.on ? props.on.split('|') : null), [props.on]);
    const off = useMemo(() => (props.off ? props.off.split('|') : null), [props.off]);

    return useObserver(() => {
        if (off && off.indexOf(active.view) > -1) return null;
        if (off && !on) return props.children || null;
        if (on && on.indexOf(active.view) > -1) return props.children || null;
        return null;
    });
}

// example
// function TicketDetailsView() {
//     return (<>
//       <MediaQuery on="desktop"><DesktopView /></MediaQuery>
//       <MediaQuery off="desktop"><MobileView /></MediaQuery>
//     </>);
//   }

// application code
// import React from 'react';
// import { render } from 'react-dom';
// const ShowView = () => useObserver(() => <span>{active.view}</span>);

// function App() {
//     return <div>
//         <h1>Test (<ShowView />)</h1>
//         <div>
//             <MediaQuery on="mobile|desktop">Hi!</MediaQuery>
//             <MediaQuery on="tablet">Hey tablet user!</MediaQuery>
//             <MediaQuery off="mobile">
//                 <div>This information isn't really needed on mobile.</div>
//             </MediaQuery>
//         </div>
//     </div>
// }

// render(<App />, document.getElementById('app'));
