export const fontSize = {
    giga: 72 as const,
    mega: 54 as const,
    kilo: 36 as const,
    alpha: 32 as const,
    beta: 28 as const,
    gamma: 24 as const,
    delta: 18 as const,
    epsilon: 16 as const,
    seta: 14 as const,
    centi: 13 as const,
    milli: 12 as const,
    micro: 10 as const,
    mini: 8 as const,
};

export const fontWeight = {
    thin: 300 as const,
    normal: 400 as const,
    medium: 600 as const,
    bold: 700 as const,
};

export const lineHeight = {
    normal: 1.5 as const,
    tight: 1.3 as const,
};

export const acordionTitle = {
    fontSize: '1rem' as const,
    fontWeight: 'normal' as const,
    margin: '0px' as const,
};
