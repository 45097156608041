import React, { useState } from 'react';
import H1 from '../../components/Texts/H1/H1';
import styled from 'lib/styled';
import Search from '../../components/Inputs/Search/Search';
import { L } from '@app/shared/lib/i18n';
import ContactListHandler from './ContactListHandler';
import CreateNewContactLink from './components/CreateNewContactLink';
import { desktop } from '../../components/MediaQuery/breakpoints';

const Main = styled.div({
    padding: '16px',
    width: '100%',
    marginBottom: '60px',

    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
        marginBottom: '0px',
    },
});

const StyledH1 = styled(H1)({
    fontSize: '2rem',
    margin: '0px',
    alignSelf: ' self-start',
    gridArea: 'heading',
    [`@media screen and (min-width: ${desktop}px)`]: {
        fontSize: '1.5rem',
        padding: '0px',
    },
});

const StyledSearch = styled(Search)({
    margin: '0px',
    gridArea: 'search',
});

const SearchAddContainer = styled.div({
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'auto min-content',
    gridTemplateAreas: `
        "heading add"
        "search search"
    `,
});

const StyledCreateNewContactLink = styled(CreateNewContactLink)({
    alignSelf: 'center',
    justifySelf: 'end',
    gridArea: 'add',
});

const ContactsList = ({ ...rest }: React.HTMLAttributes<HTMLElement>) => {
    const [value, setValue] = useState('');

    return (
        <Main {...rest}>
            <SearchAddContainer>
                <StyledH1>{L('contacts')}</StyledH1>
                <StyledSearch setValue={setValue} />
                <StyledCreateNewContactLink />
            </SearchAddContainer>
            <ContactListHandler searchValue={value} />
        </Main>
    );
};

export default ContactsList;
