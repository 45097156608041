import React, { FC } from 'react';
import styled from 'lib/styled';
import { ReactComponent as ArrowLeft } from '../../Icon/arrow-left.svg';
import { Colors, colors } from '../../Shared/Style/colors';
import { ThemeType } from '@app/api/models/Theme';
import { roundCornerSize } from '../../Shared/Style/box';

export type Props = {
    color?: Colors;
    height?: string;
    width?: string;
    className?: string;
    background_arrow?: string;
};

const StyledArrowLeft = styled(ArrowLeft)(
    ({
        color,
        height = '24px',
        width = '24px',
        theme,
        background_arrow,
    }: Pick<Props, 'color' | 'height' | 'width' | 'background_arrow'> & { theme: ThemeType }) => {
        return {
            display: 'inline-block',
            width,
            height,
            backgroundColor: background_arrow ? 'rgba(0, 0, 0, 0.2)' : 'auto',
            borderRadius: background_arrow ? roundCornerSize.medium : '0px',
            padding: background_arrow ? '3px' : '0px',
            '& g': {
                height: '24px',
                stroke: color ? theme?.colors?.[color] || colors[color] : theme?.colors?.black || colors.black,
            },
            '&:hover': {
                cursor: 'pointer',
            },

            '& span svg': {},
        };
    }
);

const BackArrow: FC<Props> = ({ ...rest }) => {
    return <StyledArrowLeft {...rest} />;
};

export default BackArrow;
