import React from 'react';
import styled from 'lib/styled';
import StickyBarContent from '../../components/StickyBar/StickyBarContent';
import H1 from '../../components/Texts/H1';
import BackArrow from '../../components/Breadcrumb/BackArrow';
import { isCordovaBuild } from '../../lib/cordovaHelpers';
import { PageBackground } from '../../components/Layout/PageBackground';
import { margin } from '../../components/Shared/Style/margin';
import { FixedBar } from '../../components/StickyBar/FixedBar';
import PushNotificationsSettings from './Components/PushNotificationsSettings';
import { L } from '@app/shared/lib/i18n';
import { desktop } from '../../components/MediaQuery/breakpoints';

const StyledPage = styled(PageBackground)({
    margin: `${margin.m}px ${margin.m}px 0px ${margin.m}px`,
    minHeight: isCordovaBuild ? '-webkit-fill-available' : '100vh',
    width: 'auto',
    height: 'auto',
    display: 'block',
});

const StyledH1 = styled(H1)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        margin: '0px',
    },
});

const StyledBackArrow = styled(BackArrow)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});
const StyledFixedBar = styled(FixedBar)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});
export const Notifications = () => {
    return (
        <StyledPage>
            <StyledBackArrow style={{ placeSelf: 'start' }} />
            <StyledH1>{L('notifications')}</StyledH1>
            <PushNotificationsSettings />
            <StyledFixedBar>
                <StickyBarContent />
            </StyledFixedBar>
        </StyledPage>
    );
};
