import styled from 'lib/styled';
import React, { useState } from 'react';
import TodoItem, { Props } from '../../../components/Todos/TodoItem/TodoItem';
import { useTransition, animated } from 'react-spring';
import useUncompleteTask from '../hooks/useUncompleteTask';
import { Link, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { getTodosQueryKeys } from '../hooks/useGetTaskList';

const StyledTodoItem = styled(TodoItem)(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.divider}`,
}));

const AnimatedTodo = animated(styled.div({}));

const CompletedTodo = (todo: Props & { todoId: string }) => {
    const queryClient = useQueryClient();

    const { mutate: unCompletetask } = useUncompleteTask();
    const [todoState, setTodoState] = useState<Props['state']>('completed');
    const [show, setShow] = useState(true);
    const { propertyId } = useParams<{ propertyId: string }>();

    const transitions = useTransition(show, {
        enter: { opacity: 1, transform: 'translateX(0px)' },
        leave: { opacity: 0, transform: 'translateX(-100%)' },
        onRest: () => queryClient.invalidateQueries(getTodosQueryKeys.list(propertyId)),
    });

    const handleCheckClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        if (!todo.todoId) return;
        unCompletetask(
            { id: todo.todoId, setTodoState },
            {
                onSuccess: () => setShow(false),
            }
        );
    };

    return transitions(
        (styles, item) =>
            item && (
                <AnimatedTodo style={styles}>
                    <Link to={`/properties/${propertyId}/todo/${todo.todoId}`}>
                        <StyledTodoItem {...todo} onCheckClick={handleCheckClick} state={todoState} />
                    </Link>
                </AnimatedTodo>
            )
    );
};

export default CompletedTodo;
