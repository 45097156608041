import styled from 'lib/styled';
import React, { FC } from 'react';
import { HTMLAttributes } from 'react';
import { desktop } from '../../MediaQuery/breakpoints';
import { style } from '../../Shared/Style';

const StyledH3 = styled.h3(({ theme }) => ({
    fontStyle: 'normal',
    fontSize: '1rem',
    lineHeight: '19px',
    width: '100%',
    textAlign: 'left',
    paddingTop: '0px',
    margin: '0px',
    color: theme.colors.black90,
    fontWeight: style.fontWeight.thin,
}));

const PropertyName: FC<HTMLAttributes<HTMLHeadingElement>> = ({ children, ...rest }) => {
    return <StyledH3 {...rest}>{children}</StyledH3>;
};

export default PropertyName;
