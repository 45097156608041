import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropertiesLayout from '../../components/Layouts/PropertiesLayout';
import AddNewTodo from './components/AddNewTodo';
import EditTodo from './components/EditTodo';
import Todos from './Todos';

export const TodoPage = () => {
    return (
        <PropertiesLayout>
            <Switch>
                <Route path={`/properties/:propertyId/todo/new`}>
                    <AddNewTodo />
                </Route>
                <Route path={`/properties/:propertyId/todo/:todoId`}>
                    <EditTodo />
                </Route>
                <Route path={`/properties/:propertyId/todo`}>
                    <Todos />
                </Route>
            </Switch>
        </PropertiesLayout>
    );
};
