import React, { ChangeEvent, FC } from 'react';
import styled from 'lib/styled';
import AttachmentInput from './AttachmentInput';
import CameraInput from './CameraInput';

const StyledCameraInput = styled(CameraInput)({
    marginLeft: '2rem',
    cursor: 'pointer',
});
const AttachmentContainer = styled.div({
    display: 'flex',
});

export type Props = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
};

const AttachmentInputs: FC<Props> = ({ onChange, className }) => {
    return (
        <AttachmentContainer className={className}>
            <AttachmentInput onChange={onChange} className={className} />
            <StyledCameraInput onChange={onChange} className={className} />
        </AttachmentContainer>
    );
};

export default AttachmentInputs;
