import styled from 'lib/styled';
import React, { FC } from 'react';
import { HTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const StyledList = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    margin: '0px',
    padding: '0px',
    color: theme?.colors?.textColor1,
    gap: '1ch',
}));

type Props = {
    children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;
const Breadcrumbs = ({ children, ...rest }: Props) => {
    return (
        <StyledList {...rest}>
            {React.Children.map(children, (child, index) => {
                const amount = React.Children.count(children);
                if (index + 1 === amount) return child;
                return (
                    <>
                        {child} {child && <span>/</span>}
                    </>
                );
            })}
        </StyledList>
    );
};

const StyledLink = styled(Link)(({ theme }) => ({
    fontSize: '1rem',
    color: theme?.colors?.textColor1,
    fontWeight: 500,
    textTransform: 'capitalize',
    textDecoration: 'none',

    '&:hover': {
        color: theme.colors.tabActive,
        cursor: 'pointer',
    },
}));

type BreadCrumbProps = LinkProps;

const Item = ({ to, children, ...rest }: BreadCrumbProps) => {
    return (
        <StyledLink to={to} {...rest}>
            {children}
        </StyledLink>
    );
};

Breadcrumbs.Item = Item;

const ActiveItem = styled.div(({ theme }) => ({
    fontSize: '1rem',
    color: theme?.colors?.tabActive,
    fontWeight: 500,
    textDecoration: 'none',
    ':first-letter': {
        textTransform: 'capitalize',
    },

    '&:hover': {
        color: theme.colors.tabActive,
        cursor: 'pointer',
    },
}));

const ItemActive: FC<HTMLAttributes<HTMLElement>> = ({ children, ...rest }) => {
    return <ActiveItem {...rest}>{children}</ActiveItem>;
};
Breadcrumbs.ItemActive = ItemActive;

export default Breadcrumbs;
