import { useQuery } from 'react-query';
import BoardMembersApi from '@app/api/public/BoardMembersApi';
import { useParams } from 'react-router-dom';

const boardMemberKeys = {
    all: ['boardMember'],
    list: (projectName?: string) => [...boardMemberKeys.all, 'list', projectName],
    contact: (id?: string) => [...boardMemberKeys.all, 'contact', id],
};

const getBoardMemberContact = async (id?: string) => {
    if (!id) return;
    return await BoardMembersApi.getBoardMemberById(id);
};

const useGetBoardMemberContact = () => {
    const { id } = useParams<{ id: string }>();
    return useQuery(boardMemberKeys.contact(id), () => getBoardMemberContact(id), {
        enabled: !!id,
    });
};

const uselistBoardMembers = (projectName: string) =>
    useQuery(boardMemberKeys.list(projectName), () => BoardMembersApi.listBoardMembers(projectName), {
        enabled: !!projectName,
    });

export { uselistBoardMembers, useGetBoardMemberContact };
