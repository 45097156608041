import React, { FC } from 'react';
import styled from 'lib/styled';
import { style } from '../../Shared/Style';
import { L } from '@app/shared/lib/i18n';

type Props = {
    type?: string;
    className?: string;
    fontSize?: string;
};

const Label = styled.div({
    gridArea: 'label',
    display: 'grid',
    placeSelf: 'center',
    backgroundColor: '#F2994A',
    color: '#FFFCFC',
    fontSize: style.fontSize.mini,
    padding: '6px 16px',
});

const Text = styled.span({
    placeSelf: 'center',
    width: 'max-content',
});

const NoticeLabel: FC<Props> = ({ type, ...rest }) => {
    return (
        <Label {...rest}>
            <Text>{L(type || '')}</Text>
        </Label>
    );
};

export default NoticeLabel;
