import React from 'react';
import styled from 'lib/styled';
import { L } from '@app/shared/lib/i18n';
import P from '../../../components/Texts/P';
import { RawHtml } from '../../../components/Layout/RawHtml';

const DescriptionParagraph = styled(P)({
    fontSize: '1rem',
    margin: '16px 0px 16px 0px',
});

const TicketOLInfo = ({ responseText }: { responseText?: string }) => {
    return (
        <DescriptionParagraph>
            {responseText ? <RawHtml content={responseText} /> : L('ticket_ol_response_info')}
        </DescriptionParagraph>
    );
};

export default TicketOLInfo;
