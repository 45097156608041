import ContactsApi from '@app/api/public/ContactsApi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

export const getContactQueryKeys = {
    all: ['contact'],
    list: () => [...getContactQueryKeys.all, 'list'],
    id: (id: number) => [...getContactQueryKeys.all, 'id', id],
};

const getContactById = (id: number) => {
    return ContactsApi.getContactById(id);
};

const useGetContactById = () => {
    const { id } = useParams<{ id: string }>();
    return useQuery(getContactQueryKeys.id(+id), () => getContactById(+id));
};

const useGetContactList = () => useQuery(getContactQueryKeys.list(), () => ContactsApi.listContactsWithBoardMembers());

export { useGetContactList, useGetContactById };
