import styled from 'lib/styled';
import React from 'react';
import { SocketStore } from '../../lib/socketStore';
import Close from '../Close';
import { tablet } from '../MediaQuery/breakpoints';
import { useObserver } from 'mobx-react-lite';
import NotificationBannerContent from './NotificationBannerContent';

const NotifyCard = styled.div(({ theme }) => {
    return {
        display: 'grid',
        position: 'fixed',
        top: 0,
        right: 0,
        width: '100%',
        maxWidth: '330px',
        zIndex: 200,
        color: theme.colors.textColor2,
        padding: `0px 0px 15px 0px`,
        // borderRadius: style.margin.s,
        // boxShadow: '0px 8px 6px rgb(0 0 0 / 40%)',
        [`@media screen and (min-width: ${tablet}px)`]: {
            right: '54px',
        },
    };
});

const StyledClose = styled(Close)(({ theme }) => {
    return {
        position: 'static',
        justifySelf: 'end',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: theme.colors.black,
    };
});

const NewNotificationBanner = () => {
    const removeNewNotificationFromSocketStore = () => {
        SocketStore.newNotification = null;
    };

    return useObserver(() => {
        if (!SocketStore.newNotification) return null;
        return (
            <NotifyCard onClick={removeNewNotificationFromSocketStore}>
                <StyledClose onClose={removeNewNotificationFromSocketStore} />
                <NotificationBannerContent notification={SocketStore.newNotification} />
            </NotifyCard>
        );
    });
};

export default NewNotificationBanner;
