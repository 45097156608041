import React, { FC } from 'react';
import styled from 'lib/styled';
import { RawNotificationHtml } from '../../Layout/RawNotificationHtml';

type Props = {
    description: string;
    isCollapsed?: boolean;
    className?: string;
};

const Description = styled.div({
    fontSize: '13px',
    fontWeight: 400,
    gridArea: 'description',
    placeSelf: 'start',
    margin: '0px',
});

const maxTextLength = 178;

const NoticeDescription: FC<Props> = ({ description, ...rest }) => {
    const partialText =
        description?.length > maxTextLength ? `${description.substr(0, maxTextLength)}... ` : description;
    return (
        <Description>
            <RawNotificationHtml content={partialText} />
        </Description>
    );
};

export default NoticeDescription;
