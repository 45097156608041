import styled from 'lib/styled';
import React from 'react';
import { L } from '@app/shared/lib/i18n';
import { style } from '../Shared/Style';
import { Colors } from '../Shared/Style/colors';
import P from '../Texts/P';

interface Props {
    text: string;
    onClick: () => void;
    isCollapsed: boolean;
    textAlign?: string;
    color?: Colors | string;
    width?: string;
    padding?: string;
    margin?: string;
}

const StyledP = styled(P)(
    (p: { padding?: string; margin?: string; width?: string; color?: Colors | string; textAlign?: any }) => ({
        padding: p.padding || `0px ${style.margin.m}px`,
        margin: p.margin || '0px',
        width: p.width || '100%',
        color: p.color || 'auto',
        textAlign: p.textAlign || 'left',
        cursor: 'pointer',
    })
);

const StyledSpan = styled.span({
    display: 'inline-block',
    paddingTop: style.margin.s,
    color: style.colors.primary,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    fontWeight: 'bold',
});

const StyledPre = styled.pre({
    whiteSpace: 'pre-wrap',
});

export const MoreText = (p: Props) => {
    const maxTextLegnth = 300;
    const descriptionText = p.text || '';
    const partialText =
        descriptionText.length > maxTextLegnth ? `${descriptionText.substr(0, maxTextLegnth)}... ` : descriptionText;

    if (descriptionText.length < maxTextLegnth) {
        return <StyledPre>{descriptionText}</StyledPre>;
    }

    return (
        <>
            {p.isCollapsed ? (
                <>
                    <StyledPre onClick={p.onClick}>
                        {partialText}
                        <br />
                        <StyledSpan>{L('show_more')}</StyledSpan>{' '}
                    </StyledPre>
                </>
            ) : (
                <>
                    <StyledPre onClick={p.onClick}>
                        {descriptionText}
                        <br />
                        <StyledSpan>{L('show_less')}</StyledSpan>{' '}
                    </StyledPre>
                </>
            )}
        </>
    );
};
