import styled from 'lib/styled';
import React, { FC } from 'react';
import { L } from '@app/shared/lib/i18n';
import { colors } from '../../Shared/Style/colors';
import { TicketStatusType } from '@app/api/models/tickets/Tickets';

type StatusType = {
    status?: TicketStatusType;
};
const StatusBox = styled.div(({ status }: StatusType) => {
    return {
        minWidth: '58px',
        maxWidth: '58px',
        height: '21px',
        display: 'grid',
        placeItems: 'center',
        backgroundColor: status?.color_code ?? colors.primary,
        color: colors.white,
    };
});

const StatusText = styled.span({
    fontSize: '8px',
    lineHeight: '16px',
});

const Status: FC<StatusType> = ({ status, ...rest }) => {
    return (
        <StatusBox status={status} {...rest}>
            <StatusText>{status?.name ?? L('new')}</StatusText>
        </StatusBox>
    );
};

export default Status;
