import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import * as BindersModel from '../models/Binders';
import { ProductDetail, PropertyDetail, SpaceDetail, Entity } from '../models/Binders';
import { Property } from '../models/Property';
import { getLoginContext } from '../models/Users';

export { Entity, ProductDetail, PropertyDetail, SpaceDetail };

class BindersApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async listSpaces(propertyId: Property['id']) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await BindersModel.listSpaces(+context.property_id);
        }

        return null;
    }
    @rpc({ httpMethod: 'GET' })
    async listComponent(propertyId: Property['id']) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await BindersModel.listComponentOrSecurity(+context.property_id, 2);
        }

        return null;
    }
    @rpc({ httpMethod: 'GET' })
    async listSecurity(propertyId: Property['id']) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await BindersModel.listComponentOrSecurity(+context.property_id, 3);
        }

        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async listProducts(propertyId: Property['id'], spaceId: SpaceDetail['id']) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await BindersModel.listProducts(spaceId, +context.property_id);
        }

        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async getProductDetail(propertyId: Property['id'], product_id: ProductDetail['id']) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await BindersModel.getProductDetail(product_id, context.property_id);
        }

        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async getSpaceDetail(propertyId: Property['id'], space_id: number) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await BindersModel.getSapceDetail(space_id, context.property_id);
        }

        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async getPropertyDetail(propertyId: Property['id']) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await BindersModel.getPropertyDetail(context.property_id);
        }

        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async getPropertyDetailById(propertyId: Property['id']) {
        return await BindersModel.getPropertyDetail(propertyId);
    }

    async createEntity(entity: Partial<Entity>, propertyId: Property['id']) {
        // Return Entity
        try {
            const context = await getLoginContext(this.user.id, propertyId);
            const isReadonly = await BindersModel.isBinderReadonly(propertyId);
            if (isReadonly) throw new Error('permission is not enabled');
            if (context?.property_id) return await BindersModel.createEntity(entity, propertyId);
        } catch (err) {
            console.log(err);
            this.httpContext.status = 500;
            this.httpContext.body = 'Clould not create entity';
            return;
        }
    }

    @rpc({ httpMethod: 'GET' })
    async createSpace(entity: Partial<Entity>, propertyId: Property['id']) {
        entity.entity_type = 0;
        return await this.createEntity(entity, propertyId);
    }

    @rpc({ httpMethod: 'GET' })
    async createProduct(entity: Partial<Entity>, propertyId: Property['id']) {
        entity.entity_type = 1;
        return await this.createEntity(entity, propertyId);
    }

    @rpc({ httpMethod: 'GET' })
    async createComponent(entity: Partial<Entity>, propertyId: Property['id']) {
        entity.entity_type = 2;
        return await this.createEntity(entity, propertyId);
    }

    @rpc({ httpMethod: 'GET' })
    async createSecurity(entity: Partial<Entity>, propertyId: Property['id']) {
        entity.entity_type = 3;
        return await this.createEntity(entity, propertyId);
    }

    async saveEntity(
        entity: BindersModel.SaveSpaceDetail | ProductDetail | BindersModel.SaveProductDetail,
        propertyId: Property['id']
    ) {
        try {
            const context = await getLoginContext(this.user.id, propertyId);
            const isReadOnly = await BindersModel.isBinderReadonly(propertyId);
            if (isReadOnly) throw new Error('permission is not enabled');
            if (context?.property_id) return await BindersModel.saveEntity(entity, propertyId);
        } catch (err) {
            console.log(err);
            this.httpContext.status = 500;
            this.httpContext.body = 'Could not save Entity';
        }
    }

    @rpc()
    async saveSpace(space: BindersModel.SaveSpaceDetail, propertyId: Property['id']) {
        space.entity_type = 0;
        return await this.saveEntity(space, propertyId);
    }

    @rpc()
    async saveProduct(product: BindersModel.SaveProductDetail, propertyId: Property['id']) {
        product.entity_type = 1;
        return await this.saveEntity(product, propertyId);
    }

    @rpc()
    async saveComponent(component: BindersModel.SaveProductDetail, propertyId: Property['id']) {
        component.entity_type = 2;
        return await this.saveEntity(component, propertyId);
    }

    @rpc()
    async saveSecurity(security: BindersModel.SaveProductDetail, propertyId: Property['id']) {
        security.entity_type = 3;
        return await this.saveEntity(security, propertyId);
    }

    @rpc()
    async hasSecurity(propertyId: Property['id']) {
        const r = await this.listSecurity(propertyId);
        return r && r?.length > 0 ? true : false;
    }

    @rpc()
    async hasConstructionParts(propertyId: Property['id']) {
        const r = await this.listComponent(propertyId);
        return r && r?.length > 0 ? true : false;
    }

    @rpc()
    async isBinderReadonly(propertyId: Property['id']) {
        try {
            return await BindersModel.isBinderReadonly(propertyId);
        } catch {
            return false;
        }
    }
}

export default new BindersApi();
