import React from 'react';
import styled from 'lib/styled';
import { margin } from '../Shared/Style/margin';

const Border = styled.div({
    borderRight: 'solid 1px rgba(226, 226, 226, 1)',
    maxHeight: '80%',
    marginTop: margin.l,
});

export const BorderDesktop = ({ ...rest }) => {
    return <Border {...rest} />;
};
