import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import TicketsApi from '@app/api/public/TicketsApi';

const propertyTicketQueryKeys = {
    all: ['ticket_type'],
    id: (id: number) => ['ticket_type', id],
};

const useGetTicketType = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return useQuery(propertyTicketQueryKeys.id(+propertyId), () => TicketsApi.getTicketType(+propertyId));
};

export default useGetTicketType;
