import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DocumentItems from './DocumentItems';
import DocumentsList from './DocumentsList';

export const DocumentsPage = () => {
    return (
        <Switch>
            <Route exact path="/properties/:propertyId/document" component={DocumentsList} />
            <Route exact path="/properties/:propertyId/document/:documentType" component={DocumentItems} />
        </Switch>
    );
};
