import React, { FC } from 'react';
import styled from 'lib/styled';
import { colors, Colors } from '../../Shared/Style/colors';
import { ThemeType } from '@app/api/models/Theme';

const StyledH1 = styled.h1((props: { color?: Colors; theme: ThemeType }) => {
    /* Header 1 */
    return {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '2rem',
        lineHeight: '100%',
        marginTop: '0px',

        letterSpacing: '0.41px',

        /* Text color 2 */
        color: props.color ? colors[props.color] : props.theme?.colors?.textColor2 || colors.textColor2,
    };
});

type Props = {
    className?: string;
    color?: Colors;
};
const H1: FC<Props> = ({ children, ...rest }) => {
    return <StyledH1 {...rest}>{children}</StyledH1>;
};

export default H1;
