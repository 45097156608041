import { useEffect } from 'react';

export function useScrollToTop() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return useEffect(() => {
        scrollToTop();
    }, []);
}
