import BindersApi from '@app/api/public/BindersApi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const getEditBinderIsReadonly = async (propertyId: string) => {
    return BindersApi.isBinderReadonly(+propertyId);
};

const useEditBinderIsReadonly = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const { data: isReadonly } = useQuery(['canEditBinder', propertyId], () => getEditBinderIsReadonly(propertyId));

    return isReadonly;
};

export default useEditBinderIsReadonly;
