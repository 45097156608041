import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.tz.guess();

const formatChatDate = (date: dayjs.ConfigType) => {
    const timezone = dayjs(date).tz();
    if (timezone.isSame(dayjs(), 'day')) {
        return timezone.format('HH:mm');
    }
    if (timezone.isSame(dayjs(), 'year')) {
        return timezone.format('MM-DD HH:mm');
    }
    return timezone.format('YYYY-MM-DD HH:mm');
};

export default formatChatDate;
