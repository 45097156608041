import { useQuery } from 'react-query';
import NotificationsApi from '../../../../api/public/NotificationsApi';

const notificationQueryKeys = {
    all: ['notification'],
    list: () => [...notificationQueryKeys.all, 'list'],
};
const useNotificationsList = () => useQuery(notificationQueryKeys.list(), () => NotificationsApi.list());

export default useNotificationsList;
