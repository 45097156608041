import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import BoardContentInformationApi from '@app/api/public/BoardContentInformationApi';

export const useBoardInformationList = () => {
    const { projectId } = useParams<{ projectId: string }>();
    return useQuery(['informationBoardList', projectId], () => BoardContentInformationApi.list(+projectId));
};

export const useDocumentList = (id?: string) => {
    const { boardId } = useParams<{ boardId: string }>();
    const { projectId } = useParams<{ projectId: string }>();
    return useQuery(['boardDocumentGroupList', id || boardId], () =>
        BoardContentInformationApi.listDocuments(id || boardId, +projectId)
    );
};
