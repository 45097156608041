import React from 'react';
import styled from 'lib/styled';
import StickyBarContent from '../../components/StickyBar/StickyBarContent';
import { L } from '@app/shared/lib/i18n';
import { FixedBar } from '../../components/StickyBar/FixedBar';
import { style } from '../../components/Shared/Style';
import HeaderClean from '../../components/Header/HeaderOnlyBackArrow';
import { desktop } from '../../components/MediaQuery/breakpoints';
import useGetTaskList from './hooks/useGetTaskList';
import Acordion from './components/Acordion';
import ColorCircleWithPlus from '../../components/Icon/ThemedIcons/ColorCircleWithPlus';
import { Link, useLocation, useParams } from 'react-router-dom';
import ExpiredTodo from './components/ExpiredTodo';
import Todo from './components/Todo';
import CompletedTodo from './components/CompletedTodo';
import H2 from '../../components/Texts/H2/H2';
import PropertyName from '../../components/Texts/PropertyName/PropertyName';
import { useGetPropertyDetail } from '../binder/hooks';

const Container = styled.div({
    padding: `0px ${style.margin.m}px 0px ${style.margin.m}px`,
    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
    },
});

const StyledHeaderClean = styled(HeaderClean)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const StyledHeaderFixedBar = styled(FixedBar)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const StyledAcordion = styled(Acordion)({
    padding: '1.5rem 1rem 0px 1rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '1.5rem 0px 0px 0px',
    },
});

const AddTodo = styled(ColorCircleWithPlus)({});

const Wrapper = styled.div({
    position: 'relative',
    overflow: 'hidden',
});

const StyledH2 = styled(H2)({
    marginBottom: '0px',
});

const InvisibleLink = styled(Link)({
    alignSelf: 'center',
});

const StyledPropertyName = styled(PropertyName)(({ theme }) => ({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
}));

const Todos: React.FC = () => {
    const location = useLocation();
    const { propertyId } = useParams<{ propertyId: string }>();
    const { data } = useGetTaskList(propertyId);
    const { data: completed } = useGetTaskList(propertyId, { completed: true });
    const residenceDetail = useGetPropertyDetail();

    const Todos = data
        ?.filter((todo) => !todo.completed_at && +todo.overdue_days === 0)
        .map((todo) => {
            return <Todo key={todo.id} todoId={todo.id} date={todo.due_at} name={todo.name} repeat={todo.recurrency} />;
        });

    const ExpiredTodos = data
        ?.filter((todo) => !todo.completed_at && +todo.overdue_days > 0)
        .map((todo) => {
            return (
                <ExpiredTodo
                    key={todo.id}
                    todoId={todo.id}
                    date={todo.due_at}
                    name={todo.name}
                    repeat={todo.recurrency}
                />
            );
        });

    const CompletedTodos = completed
        ?.filter((todo) => todo.completed_at)
        .map((todo) => {
            return (
                <CompletedTodo
                    key={todo.id}
                    todoId={todo.id}
                    date={todo.due_at}
                    name={todo.name}
                    repeat={todo.recurrency}
                    completed_at={todo.completed_at}
                />
            );
        });

    return (
        <Wrapper>
            <StyledHeaderClean to={`/properties/`} />
            <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <StyledH2>{L('to_do')}</StyledH2>
                    <StyledPropertyName>{residenceDetail.data?.name}</StyledPropertyName>
                </div>
                <InvisibleLink to={`/properties/${propertyId}/todo/new`}>
                    <AddTodo />
                </InvisibleLink>
            </Container>
            <Container>
                {ExpiredTodos}
                {Todos}
            </Container>
            {CompletedTodos && CompletedTodos?.length > 0 && (
                <StyledAcordion
                    localStorageKey={location.pathname + 'todo_completed_title'}
                    Title={`${L('todo_completed_title')} ${CompletedTodos?.length}`}
                >
                    <Container>{CompletedTodos}</Container>
                </StyledAcordion>
            )}
            <Container style={{ paddingBottom: '100px' }} />
            <StyledHeaderFixedBar>
                <StickyBarContent />
            </StyledHeaderFixedBar>
        </Wrapper>
    );
};

export default Todos;
