import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import * as React from 'react';
import { FC, HTMLAttributes } from 'react';
import { desktop } from '../MediaQuery/breakpoints';

const ContentContainer = styled.div((p: { theme: ThemeType }) => {
    return {
        padding: '0px 1rem',
        width: '100%',

        [`@media screen and (min-width: ${desktop}px)`]: {
            padding: '0px',
        },
    };
});

export const Content: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
    return <ContentContainer {...rest}>{children}</ContentContainer>;
};
