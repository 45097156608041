import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { ReactComponent as Icon } from '../e-remove-outline-12.svg';

const ThemedCloseX = styled(Icon)(({ theme }) => {
    return {
        height: '24px',
        width: '24px',

        '& line': {
            stroke: theme.colors.icons,
        },
    };
});
const CloseX: FC = ({ ...rest }) => {
    return <ThemedCloseX viewBox="0 0 12 12" {...rest} />;
};

export default CloseX;
