import { isCordovaBuild } from '../lib/cordovaHelpers';

declare let AppIconChanger: any;

export const setHomeScreenIcon = (iconName: string) => {
    if (isCordovaBuild) {
        AppIconChanger.changeIcon(
            {
                iconName: iconName,
                suppressUserNotification: true,
            },
            function () {
                console.log('icon set with name', iconName);
            },
            function (err) {
                console.log(`error with icon name: ${iconName}`, err);
            }
        );
    }
};
