const getInboundsPosition = (e: React.MouseEvent, ref: React.RefObject<any>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    let x = e.clientX - rect.left; //x position within the element.
    const body = document.querySelector('body');
    const y = e.clientY - rect.top; //y position within the element.
    const contex = ref.current;
    if (!contex || !body) return { x, y };

    const contextWidth = contex.offsetWidth;

    if (contextWidth + e.clientX > body.clientWidth) {
        const overflow = contextWidth + e.clientX - body.clientWidth + 10;
        x = x - overflow;
    }

    return { x, y };
};

export default getInboundsPosition;
