import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../pin-filled.svg';

const ThemedPinFilled = styled(Icon)(({ theme }) => {
    return {
        height: '24px',
        width: '24px',
        '& path': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& line': {
            stroke: theme?.colors?.icons || colors.icons,
        },
    };
});
const PinFilled: FC = ({ ...rest }) => {
    return <ThemedPinFilled {...rest} />;
};

export default PinFilled;
