import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQuery } from 'react-query';

const useGetDocumentsById = (ids: string[]) => {
    const query = useQuery(['documents', ids], async () => {
        try {
            const docApiMap = ids.map((id) => DocumentsApi.getbyid(id));
            const promisAllData = await Promise.all(docApiMap);
            return promisAllData;
        } catch (err) {
            console.log('useGetDocumentsById ERR', err);
        }
    });

    return query;
};

export default useGetDocumentsById;
