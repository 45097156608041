import { observable, autorun } from 'mobx';
import UsersApi, { IUser } from '@app/api/public/UsersApi';
import { getTokenForIos } from '../../cordovaIos/getIosToken';
import { isCordovaBuild } from '../../lib/cordovaHelpers';
import { setIosTheme } from '../../cordovaIos/IosTheme';
import ThemeApi from '../../../api/public/ThemeApi';
import { setQueryThemeCache } from '../../components/Shared/hooks/useGetTheme';
import { analyticsExeptionEvent, analyticsLoginEventEmail } from '../../lib/analytics/analyticsEvent';
import dayjs from 'dayjs';
import { QueryClient } from 'react-query';
import { Preferences } from '@capacitor/preferences';

class LoginStoreClass {
    @observable state: '' | 'error' | 'projects' | 'loading' | 'failed' | 'logged-in' = '';
    @observable user?: IUser;
    @observable projects: { id: string; name: string }[];
    @observable selectedProjectId: string;
    @observable isImpersonated: boolean;
    @observable isBoardMember: boolean;

    async verifyLogin() {
        this.state = 'loading';
        const user = await UsersApi.verifyLogin();
        console.log('verify login', user);
        if (user) {
            this.user = user;
            this.state = 'logged-in';
            this.isImpersonated = this.user.isImpersonated;
        } else {
            this.state = '';
        }
    }

    async checkBoardMember() {
        this.isBoardMember = await UsersApi.isMemberInAnyBoard();
    }

    multiLogin = (project: string) => {
        this.selectedProjectId = project;
        UsersApi.setCookie(project);
        this.state = 'logged-in';
    };

    async login(username: string, password: string, queryClient: QueryClient) {
        this.state = 'loading';
        this.user = undefined;
        try {
            const user = await UsersApi.login(username, password);

            if (user !== null) {
                await UsersApi.updateSignInAt(user.id);
                analyticsLoginEventEmail();
                if (isCordovaBuild) {
                    await Preferences.set({
                        key: 'TOKEN',
                        value: user.cookieString,
                    });
                    const theme = await ThemeApi.getUserAccoutTheme();
                    if (theme) {
                        setIosTheme(theme);
                        setQueryThemeCache(queryClient, theme.data);
                    }
                }
                this.user = user;
                this.state = 'logged-in';
            } else if (username || password) {
                this.state = 'failed';
            } else {
                this.state = '';
            }
        } catch (ex) {
            if (ex.message) {
                analyticsExeptionEvent('LoginStore.ts ' + ex.message);
            }
            console.error(ex);
            this.state = 'error';
        }
    }

    logoutImpersonate = async () => {
        await UsersApi.logoutImpersonatePropertyUser();
        this.verifyLogin();
    };
}

export const LoginStore = new LoginStoreClass();

autorun(() => {
    if (!LoginStore?.user?.locale) return;
    require(`dayjs/locale/${LoginStore.user.locale}.js`);
    dayjs.locale(`${LoginStore.user.locale}`);
});

//cordova ios push token
autorun((reaction) => {
    console.log('---- autoRun: window.cordova ----', isCordovaBuild);
    if (!LoginStore.user) return;
    if (isCordovaBuild) {
        getTokenForIos(LoginStore.user);
    }
});
//cordova ios push token
autorun(() => {
    console.log('---- autoRun: Loginstore.user ----', LoginStore.user);
});
