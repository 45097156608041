import React from 'react';
import { keyframes } from '@emotion/core';
import styled from 'lib/styled';
import { style } from '../Shared/Style';
import { FC } from 'react';
import { ThemeType } from '@app/api/models/Theme';

const spinner = keyframes({
    '0%': {
        opacity: 1,
        transform: 'scale(0)',
    },
    '100%': {
        opacity: 0,
        transform: 'scale(1)',
    },
});

type Props = {
    size: number | string;
};

const LoadDiv = styled.div<Props & { theme: ThemeType }>((p) => ({
    display: 'inline-block',
    width: p.size,
    height: p.size,
    backgroundColor: p.theme?.colors?.primary || style.colors.primary,
    borderRadius: '50%',
    animation: `${spinner} 1s infinite ease-in-out 200ms backwards`,
}));

const Load: FC<Props> = ({ children, ...rest }) => {
    return <LoadDiv {...rest}>{children}</LoadDiv>;
};

export default Load;
