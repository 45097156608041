import styled from 'lib/styled';
import React, { FC } from 'react';
import { NotificationType } from '../../../../api/models/Notifications';
import AddonSigningIsReadyNotice from '../AddonSigningIsReadyNotice/AddonSigningIsReadyNotice';
import AlertNotice from '../AlertNotice/AlertNotice';
import BookingNotice from '../BookingNotice/BookingNotice';
import CommunityCalendarNotice from '../CommunityCalendarNotice/CommunityCalendarNotice';
import CommunityNewsNotice from '../CommunityNewsNotice/CommunityNewsNotice';
import InformationNotice from '../InformationNotice/InformationNotice';
import TicketNotice from '../TicketNotice/TicketNotice';
import TodoNotice from '../TodoNotice/TodoNotice';
import WarrantyNotice from '../WarrantyNotice/WarrantyNotice';

const StyledNotificationList = styled.ul({});

type Props = {
    notifications: NotificationType[];
    isCollapsed: boolean;
};

// lägg till Rätt Notification components här
const componentMapper = {
    alert: AlertNotice,
    booking: BookingNotice,
    new_ticket: TicketNotice,
    todo: TodoNotice,
    warranty: WarrantyNotice,
    content_news: CommunityNewsNotice,
    content_calendar: CommunityCalendarNotice,
    content_information: InformationNotice,
    addon_signing_is_ready: AddonSigningIsReadyNotice,
};

const NotificationList: FC<Props> = ({ notifications, isCollapsed, ...rest }) => {
    return (
        <StyledNotificationList {...rest}>
            {notifications.map((notification) => {
                const NotificationComponent = componentMapper[notification.notifiable_type];
                if (!NotificationComponent) {
                    console.log('no notification component for ->', notification);
                    return null;
                }
                return (
                    <li key={notification.id}>
                        <NotificationComponent {...notification} isCollapsed={isCollapsed} />
                    </li>
                );
            })}
        </StyledNotificationList>
    );
};

export default NotificationList;
