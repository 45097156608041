import * as React from 'react';
import { useObserver } from 'mobx-react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'lib/styled';
import { Button } from '../../components/Buttons/Button';
import { L } from '@app/shared/lib/i18n';
import { style } from '../../components/Shared/Style';
import { Dropdown } from '../../components/Dropdown/Dropdown';
import { usePromise } from '../../lib/react';
import { Loading } from '../../components/Loading/Loading';
import { LoginStore } from './LoginStore';
import { ThemeType } from '@app/api/models/Theme';
import { Footer } from '../../components/Footer/Footer';
import Logo from '../../components/Logo/Logo';
import { isCordovaBuild } from '../../lib/cordovaHelpers';
import Input from '../../components/Forms/Input';
import Label from '../../components/Forms/Label';
import toast from 'react-hot-toast';
import PushPermission from '../../components/PushPermission/PushPermission';
import firebase, { getToken } from '../../firebase/index';
import { useQueryClient } from 'react-query';
import useGetHelpListItem from '../help/hooks/useGetHelpListItem';

const Header = styled.label({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    paddingBottom: style.margin.xs,
});

const Background = styled.div(({ theme }: { theme: ThemeType }) => {
    return {
        minHeight: isCordovaBuild ? '-webkit-fill-available' : '100vh',
        display: 'grid',
        backgroundColor: theme?.colors?.pageBackground,
    };
});

const LoginCard = styled.div({
    alignSelf: 'end',
    position: 'relative',
    justifySelf: 'center',
    gridArea: 'Form',
});

const StyledNavLink = styled(NavLink)(({ theme }: { theme: ThemeType }) => {
    return {
        textDecoration: 'none',
        color: theme?.colors?.primary || style.colors.primary,
        lineHeight: '32px',
        fontSize: style.fontSize.seta,
        fontWeight: style.fontWeight.bold,
    };
});

const StyledLogo = styled(Logo)({
    maxWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    placeSelf: 'center',
    backgroundColor: 'inherit',
});

const Error = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.m,
    paddingTop: style.margin.m,
    minHeight: '54px',
});
const ErrorMessage = styled.strong({
    color: 'red',
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    paddingTop: style.margin.ml,
});

const CenterGridDiv = styled.div({
    placeSelf: 'start center',
    width: '100%',
    [`@media screen and (min-width: 378px)`]: {
        width: '378px',
    },
    [`@media screen and (min-width: 500px)`]: {
        placeSelf: 'center',
    },
});

const Card = styled.div<{ theme: ThemeType }>(({ theme }) => {
    return {
        display: 'grid',
        padding: '40px 16px 40px 16px',
        boxSizing: 'border-box',
        borderRadius: style.roundCornerSize.medium,
        flexDirection: 'column',
        width: '100%',
        minHeight: '461px',

        [`@media screen and (min-width: 500px)`]: {
            backgroundColor: theme?.colors?.background,
            boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
        },
    };
});

export const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [project, setProject] = useState('');
    const queryClient = useQueryClient();

    const [ready] = usePromise(async () => {
        await LoginStore.verifyLogin();
    }, []);

    const login = (evt) => {
        evt.preventDefault();
        LoginStore.login(username, password, queryClient);
    };

    const multiLogin = (evt) => {
        evt.preventDefault();
        LoginStore.multiLogin(project);
    };

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        return (
            <Background>
                <CenterGridDiv>
                    <Card>
                        <StyledLogo />
                        <form style={{ alignSelf: 'end' }} onSubmit={login}>
                            <LoginCard>
                                {LoginStore.state !== 'projects' ? (
                                    <>
                                        <Error>
                                            {LoginStore.state === 'error' && (
                                                <ErrorMessage>{L('login_error')}</ErrorMessage>
                                            )}
                                            {LoginStore.state === 'failed' && (
                                                <ErrorMessage>{L('login_failed')}</ErrorMessage>
                                            )}
                                        </Error>

                                        <Label title={L('email')}>
                                            <Input
                                                autoFocus
                                                type="email"
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </Label>

                                        <Label title={L('password')}>
                                            <Input type="password" onChange={(e) => setPassword(e.target.value)} />
                                        </Label>

                                        <ButtonMenu>
                                            <Button
                                                type={'submit'}
                                                variant="selectedMenu"
                                                onClick={login}
                                                style={{ width: '100%' }}
                                            >
                                                {L('login')}
                                            </Button>
                                            <StyledNavLink to="/forgottenpassword">
                                                {L('forgot_password')}?
                                            </StyledNavLink>
                                            <StyledNavLink to="/loginproblem">{L('login_problems')}?</StyledNavLink>
                                        </ButtonMenu>
                                    </>
                                ) : (
                                    <>
                                        <Header htmlFor="projects">{L('projects')}</Header>
                                        <Dropdown
                                            options={LoginStore.projects.map((p) => ({ label: p.name, value: p.id }))}
                                            onChange={(value) => setProject(value)}
                                            isSearchable={false}
                                            value={project}
                                            id="projects"
                                        />

                                        <ButtonMenu>
                                            <Button
                                                style={{ marginTop: style.margin.l, width: '100%' }}
                                                variant="selectedMenu"
                                                onClick={multiLogin}
                                            >
                                                {L('continue')}
                                            </Button>
                                        </ButtonMenu>
                                    </>
                                )}
                            </LoginCard>
                        </form>
                    </Card>
                    {/* <Footer name={'Powered by'} /> */}
                </CenterGridDiv>
            </Background>
        );
    });
};

export const LoginPage = (props: { children: any }) => {
    return useObserver(() => {
        useEffect(() => {
            if ('serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window) {
                if (Notification.permission === 'default') {
                    if (LoginStore.state === 'logged-in') {
                        if (!firebase.messaging.isSupported()) return;
                        toast((toast) => <PushPermission toastId={toast.id} />, {
                            duration: Infinity,
                        });
                    }
                }
                if (Notification.permission === 'granted') {
                    if (!firebase.messaging.isSupported()) return;
                    getToken();
                }
            }
        }, [LoginStore.state]);
        if (LoginStore.state === 'logged-in') {
            return <React.Fragment>{props.children}</React.Fragment>;
        }
        return <LoginForm />;
    });
};
