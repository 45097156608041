import styled from 'lib/styled';
import React, { useState } from 'react';
import TodoItem, { Props } from '../../../components/Todos/TodoItem/TodoItem';
import useCompleteTask from '../hooks/useCompleteTask';
import { useTransition, animated } from 'react-spring';
import { Link, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { getTodosQueryKeys } from '../hooks/useGetTaskList';
import triggerDirtyFieldsToast from '../../../components/Forms/DirtyFieldsToast';
import { L } from '@app/shared/lib/i18n';

const StyledTodoItem = styled(TodoItem)(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.divider}`,
}));

const AnimatedTodo = animated(styled.div({}));

const Todo = (todo: Props & { todoId: number }) => {
    const queryClient = useQueryClient();
    const { mutate: completetask } = useCompleteTask();
    const [todoState, setTodoState] = useState<Props['state']>('todo');
    const [show, setShow] = useState(true);
    const { propertyId } = useParams<{ propertyId: string }>();

    const transitions = useTransition(show, {
        enter: { opacity: 1, transform: 'translateX(0px)' },
        leave: { opacity: 0, transform: 'translateX(-100%)' },
        from: { opacity: 1, transform: 'translateX(100%)' },
        trail: 200,
        onRest: () => queryClient.invalidateQueries(getTodosQueryKeys.all),
    });

    const handleCheckClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        if (!todo.todoId) return;
        return triggerDirtyFieldsToast({
            title: `${L('complete_todo')} "${todo.name}"`,
            continueButtonText: L('yes'),
            continueFn: () => {
                completetask(
                    { id: todo.todoId, name: todo.name, setTodoState },
                    {
                        onSuccess: () => {
                            setShow(false);
                        },
                    }
                );
            },
        });
    };

    return transitions(
        (styles, item) =>
            item && (
                <AnimatedTodo style={styles}>
                    <Link to={`/properties/${propertyId}/todo/${todo.todoId}`}>
                        <StyledTodoItem {...todo} onCheckClick={handleCheckClick} state={todoState} />
                    </Link>
                </AnimatedTodo>
            )
    );
};

export default Todo;
