import * as React from 'react';
import styled from 'lib/styled';
import Avatar from '../../../components/thread/avatar/Avatar';
import Title from '../../../components/thread/title/Title';
import { colors } from '../../../components/Shared/Style/colors';
import Divider from '../../../components/Divider/Divider';
import { Contact } from '@app/api/models/Contacts';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import { medium } from 'lib/imageSize';

export interface ItemProps {
    id: number;
    name: string;
    phone_number: string;
    email: string;
    profile_image_data?: Contact['profile_image_data'];
}

const ContactContainer = styled.div({
    display: 'grid',
    gridTemplateAreas: `
    "avatar title arrow"
    "avatar company arrow"
    "divider divider divider"
    `,
    gridTemplateColumns: 'min-content auto min-content',
    columnGap: '1rem',
});

const GridAvatar = styled(Avatar)({
    gridArea: 'avatar',
    justifySelf: 'start',
    alignSelf: 'start',
});

const GridName = styled(Title)({
    gridArea: 'title',
    placeSelf: 'stretch',
});

const GridEmail = styled.span({
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.black80,
    gridArea: 'company',
    placeSelf: 'stretch',
});

const StyledDivider = styled(Divider)({
    gridArea: 'divider',
    marginTop: '0.5rem',
});

const SharedWithItem: React.FC<ItemProps> = ({ id, name, email, profile_image_data }) => {
    const { data: url } = useGetSignedUrl(profile_image_data, medium);

    return (
        <li>
            <ContactContainer key={id}>
                {url ? <GridAvatar url={url} /> : <GridAvatar name={name} />}
                <GridName>{name}</GridName>
                <GridEmail>{email}</GridEmail>
                <StyledDivider width={100} />
            </ContactContainer>
        </li>
    );
};

export default SharedWithItem;
