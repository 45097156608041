import React from 'react';
import styled from 'lib/styled';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import TicketStatus from './TicketStatus';
import H1 from '../../../components/Texts/H1';
import H2 from '../../../components/Texts/H2';
import { useParams } from 'react-router-dom';
import { useGetTicketById } from '../hooks/useGetTicketById';
import { Loading } from '../../../components/Loading/Loading';
import { useGetTicketAttachments } from '../hooks/UseGetTicketAttachments';
import DocumentItem from '../../../components/Document/DocumentItem';
import P from '../../../components/Texts/P';

const DescriptionTitle = styled(H2)({
    textTransform: 'capitalize',
    fontSize: '1rem',
    fontWeight: 'bolder',
    padding: '2rem 0rem 0rem 0rem',
    margin: '0px',
});

const FlexSameRow = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

const Content = styled.main({
    padding: '1rem',

    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
    },
});

const DescriptionParagraph = styled(P)({
    fontSize: '1rem',
    marginBottom: '1.5rem',
    marginTop: '16px',
    padding: '0 1rem',
});

const Title = styled(H1)({
    textTransform: 'capitalize',
    fontSize: '1.2rem',
    maxWidth: '100%',
    wordBreak: 'break-word',
    fontWeight: 'bolder',
});

const TicketStatusContent = () => {
    const { ticketId } = useParams<{ ticketId: string }>();
    const { data } = useGetTicketById(+ticketId);
    const { data: documentDetails } = useGetTicketAttachments(+ticketId);
    const documents = documentDetails?.flatMap((doc) => (doc ? [doc] : [])) || [];

    if (!data) {
        return <Loading />;
    }

    return (
        <Content>
            <FlexSameRow>
                <Title>{data.title}</Title>
                <TicketStatus {...data} />
            </FlexSameRow>
            {data.space_name && (
                <>
                    <DescriptionTitle>Rum</DescriptionTitle>
                    <DescriptionParagraph>{data.space_name}</DescriptionParagraph>
                </>
            )}

            {data.product_name && (
                <>
                    <DescriptionTitle>Produkt</DescriptionTitle>
                    <DescriptionParagraph>{data.product_name}</DescriptionParagraph>
                </>
            )}

            <DescriptionTitle>Beskrivning</DescriptionTitle>
            <DescriptionParagraph>{data.description}</DescriptionParagraph>

            {documents?.map((doc) => (
                <DocumentItem key={doc.id} document={doc} />
            ))}
        </Content>
    );
};

export default TicketStatusContent;
