import * as React from 'react';
import styled from 'lib/styled';
import { style } from '../Shared/Style';
import { isCordovaBuild } from '../../lib/cordovaHelpers';
import { ThemeType } from '@app/api/models/Theme';
import { HTMLAttributes } from 'react';

type Props = {
    children: any;
    padding?: string;
    marginTop?: string;
    className?: string;
} & HTMLAttributes<HTMLDivElement>;

const CardContainer = styled.div((props: { marginTop?: string; padding?: string; theme: ThemeType }) => {
    return {
        display: 'flex',
        minWidth: '320px',
        width: '100%',
        marginTop: props.marginTop ? props.marginTop : 'auto',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: props.theme.colors?.background || style.colors.background,
        padding: props.padding ? props.padding : `${style.margin.m}px 0px ${style.margin.xl}px 0px`,
        borderRadius: style.margin.s,
        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1)',
        flexDirection: 'column',
        position: 'relative',
    };
});

export const Card = (p: Props) => {
    return (
        <CardContainer {...p} className={p.className} padding={p.padding} marginTop={p.marginTop}>
            {p.children}
        </CardContainer>
    );
};

export default Card;
