import React, { FC, HTMLAttributes } from 'react';
import styled from 'lib/styled';
import { Colors, colors } from '../../Shared/Style/colors';

const StyledH3 = styled.h3<{ color?: Colors }>((props) => {
    return {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '19px',
        color: props.color ? colors[props.color] : props?.theme?.colors?.textColor2 || colors.textColor2,
    };
});

type Props = {
    color?: Colors;
};
const H3: FC<Props & HTMLAttributes<HTMLHeadingElement>> = ({ children, color, ...rest }) => {
    return (
        <StyledH3 color={color} {...rest}>
            {children}
        </StyledH3>
    );
};

export default H3;
