import React, { useCallback } from 'react';
import { IPageOf } from '@app/api/public/DocumentsApi';
import { useRef } from 'react';
import { QueryFunction, QueryKey, UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { Stylable } from '../types';
import { useOnUIEvent } from '../lib/hooks';
import Load from './Loading/Load';
import styled from 'lib/styled';
import { ErrorText } from './Texts/ErrorText';
import { margin } from './Shared/Style/margin';
import H2 from './Texts/H2';
import { L } from '@app/shared/lib/i18n';

export const erre = () => Error('Ett fel inträffade vid hämtning. Vänligen försök igen senare.');

const Centered = styled.div({
    flexShrink: 0,
    textAlign: 'center',
    marginBottom: margin.l,
});

const StyledError = styled(ErrorText)({
    flexShrink: 0,
});

const StyledH2 = styled(H2)({
    flexShrink: 0,
    textAlign: 'center',
    fontSize: '1.2rem',
    marginTop: '2rem',
});

type Props<T> = {
    queryOptions?: UseInfiniteQueryOptions<IPageOf<T>>;
    threshold: number;
    cacheKey: QueryKey;
    reverse?: boolean;
    partial?: boolean;
    emptyResponse?: string;
    asyncFn: QueryFunction<IPageOf<T> | undefined, QueryKey>;
    as?: string;
    feedFilter?: (item: T) => boolean;
    children: (data: T, index: number, array: T[]) => React.ReactNode;
};

type ReturnType = React.ReactElement | null;

const lookAhead = (em: HTMLElement, partial: boolean, reverse: boolean) =>
    partial
        ? em.scrollHeight - em.clientHeight + (reverse ? 1 : -1) * em.scrollTop
        : em.clientHeight - window.innerHeight + (reverse ? 1 : -1) * window.pageYOffset;

const Feed = <T extends any>({
    queryOptions,
    children,
    asyncFn,
    threshold,
    cacheKey,
    emptyResponse,
    reverse = false,
    partial = false,
    as = 'div',
    className,
    feedFilter = () => true,
}: Props<T> & Stylable): ReturnType => {
    const ref = useRef<HTMLElement>(null);
    const getFetchMore = (lastPage: IPageOf<T>) => {
        return lastPage && lastPage.currentPage < lastPage.pages ? lastPage.currentPage + 1 : null;
    };

    const { fetchNextPage, data, error, hasNextPage, isFetching, isFetchingNextPage, isError } = useInfiniteQuery({
        queryFn: asyncFn,
        queryKey: cacheKey,
        getNextPageParam: getFetchMore,
        ...queryOptions,
    });

    const update = useCallback(() => {
        const em = ref.current;
        if (!em) return;
        const y = lookAhead(em, partial, reverse);
        if (y < threshold && hasNextPage && !isFetchingNextPage) fetchNextPage();
    }, [ref, reverse, partial, threshold, fetchNextPage, hasNextPage, isFetchingNextPage]);

    useOnUIEvent(partial ? ref : window, 'scroll', () => update(), true, [partial, update]);

    const items = data?.pages
        ?.flatMap((page) => page?.items)
        .filter(feedFilter)
        .map(children);

    if (isFetching)
        items?.push(
            <Centered key="load">
                <Load size={40} />
            </Centered>
        );

    if (isError) items?.push(<StyledError key="error" error={L('server_error')} />);

    if (items?.length === 0) items?.push(<StyledH2 key="error">{L(emptyResponse || 'feed_no_item')}</StyledH2>);

    return React.createElement(
        as,
        {
            ref,
            className,
            style: {
                overflowY: 'auto',
                overlfowX: 'hidden',
                display: 'flex',
                flexDirection: reverse ? 'column-reverse' : 'column',
            },
        },
        items
    );
};

export default Feed;
