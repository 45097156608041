import * as TicketModel from '../models/tickets/Tickets';
import { CreateTicket, Ticket } from '../models/tickets/Tickets';

import * as MessageModel from '../models/Messages';
import * as DocumentModel from '../models/Documents';
import * as ResidenceModel from '../models/Residence';
import * as BinderModel from '../models/Binders';

import * as Mail from '../lib/mail/mail';

import { rpc, File, read } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import { User, getLoginContext } from '../models/Users';
import { Property } from '../models/Property';
import { CreateMessage } from '../models/Messages';
import { getProjectId, getProjectTransferredAt, id } from '../models/Projects';
import * as MailTemplates from '../models/MailTemplates';
import NodeMailer from 'nodemailer/lib/mailer';
import ProfilesApi from './ProfilesApi';
import dayjs from 'dayjs';
import * as ProprtymanagementNotification from '../models/tickets/PropertymanagementTicketsNotification';
import * as CommunityNotification from '../models/tickets/CommunityTicketsNotification';
import { getAccountFromProjectId } from '../models/Accounts';
import * as AWSS3 from '../lib/aws/aws-s3';
import * as mime from 'mime-types';

export { MailMessage } from '../lib/mail/mail';
export { CreateTicket, Ticket };

const addAttachmentsToTicket = async ({
    ticket_id,
    files,
    userId,
}: {
    ticket_id: number;
    files?: File[];
    userId: number;
}) => {
    if (!files) return null;

    const uploadToAws = files.map(async (file) => {
        const fileId = await DocumentModel.uploadToAwsStore(file);
        const documentDetail = DocumentModel.fileToDocumentDetail(file, fileId, 'store', {
            documentable_type: 'Ticket',
            document_type: 6,
            name: file.name,
            documentable_id: ticket_id,
        });
        return await DocumentModel.save(documentDetail);
    });

    const savedFiles = await Promise.all(uploadToAws);
    const ticket = await TicketModel.id(userId, ticket_id);
    if (!ticket) return;

    const attachment_ids = [...ticket.attachment_ids, ...savedFiles.map((f) => f.id.toString())];
    ticket.attachment_ids = attachment_ids;
    return await TicketModel.saveAttatchments(ticket.id, attachment_ids);
};

type SaveTicket = {
    propertyId: number;
    projectId: number;
    ticket: TicketModel.CreateTicket;
    user: User;
    attachments?: File[];
};
const saveTicket = async ({ projectId, propertyId, ticket, user, attachments }: SaveTicket) => {
    const savedTicket = await TicketModel.save(user.id, propertyId, ticket);
    if (!savedTicket) return null;

    if (savedTicket?.id && attachments) {
        await addAttachmentsToTicket({
            ticket_id: savedTicket.id,
            userId: user.id,
            files: attachments,
        });
    }

    if (!ticket.id) {
        await MessageModel.saveMessageThread(user, {
            ticket: {
                id: savedTicket?.id,
            },
        });
    }

    return await TicketModel.id(user.id, savedTicket?.id);
};

const sendTicketNotification = async ({ projectId, ticketId }: { projectId: number; ticketId: number }) => {
    const { transferred_at } = await getProjectTransferredAt(projectId);
    if (transferred_at) await CommunityNotification.sendNewTicketNotification(ticketId);
    else await ProprtymanagementNotification.sendPropertyManagementNewTicketNotification(ticketId);
};

type SendTicketMail = {
    propertyId: number;
    ticket: TicketModel.CreateTicket;
    attachments?: NodeMailer.Attachment[];
    projectId: number;
    user: User;
};
const sendTicketMail = async ({ projectId, propertyId, ticket, attachments, user }: SendTicketMail) => {
    const serviceEmail = await TicketModel.getTicketEmailInfo(propertyId.toString());
    if (!serviceEmail.service_email) return;
    const project = await id(projectId);
    const space = await BinderModel.getSpace(propertyId, ticket.space_id);
    const template = await MailTemplates.getMailTemplate(propertyId, projectId, 'ticket_mail', 'html');
    const property = await ResidenceModel.getById(propertyId);

    let product: BinderModel.ProductDetail | null = null;
    if (ticket?.product_id) product = await BinderModel.getProductDetail(ticket?.product_id, property.id);

    const profile = await ProfilesApi.byId(user.id);
    // send mail
    const message = Mail.parseMailTemplate(
        {
            username: profile?.name,
            ticket_date: dayjs(ticket?.created_at).format('YYYY-MM-DD HH:mm'),
            phone_number: profile?.phone_number,
            postal_address: property.attr.street,
            postal_code: property.attr.postalcode,
            postal_city: property.attr.city,
            apartment_number: property.attr.apartment_number,
            space: space?.name,
            product: ticket?.product_name,
            title: ticket?.title,
            description: ticket?.description,
            reply_to: profile?.email,
            ...(product?.attr.brand && { brand: product?.attr.brand }),
            ...(product?.attr.e_no && { e_no: product.attr.e_no }),
            ...(product?.attr.rsk_no && { rsk_no: product.attr.rsk_no }),
            ...(product?.attr.gtin_ean && { gtin_ean: product.attr.gtin_ean }),
            ...(product?.attr.article_no && { article_no: product.attr.article_no }),
            ...(product?.attr.model && { model: product.attr.model }),
            ...(product?.attr.info && { info: product.attr.info }),
            ...(product?.attr.extra_information && { extra_information: product.attr.extra_information }),
            ...(product?.attr.color && { color: product.attr.color }),
            ...(product?.attr.pnc_number && { pnc_number: product.attr.pnc_number }),
            ...(product?.attr.order_no && { order_no: product.attr.order_no }),
            ...(product?.attr.serial_number && { serial_number: product.attr.serial_number }),
        },
        template.data,
        'html'
    );

    const subject = `Felanmälan - ${project.name}`;

    const mail: Mail.MailMessage = {
        recipients: [serviceEmail.service_email],
        html: message,
        subject: subject,
    };

    try {
        return await Mail.send(mail, attachments);
    } catch (e) {
        console.log('Error sending mail', e);
    }
};

class TicketsApi extends BaseApi {
    @rpc()
    async getbyid(id: number) {
        return await TicketModel.id(this.user.id, id);
    }

    @rpc()
    async getTicketAttachmentById(ticketId: number) {
        return await TicketModel.getTicketAttachmentById(ticketId);
    }

    @rpc()
    async saveTicket(propertyId: number, ticket: TicketModel.CreateTicket, attachments?: File[]) {
        try {
            const context = await getLoginContext(this.user.id, propertyId);

            if (context?.property_id) {
                const account = await getAccountFromProjectId(context.project_id);
                // boklok och hsb fix
                if ([2, 15].includes(account.id)) {
                    return;
                }

                const savedTicket = await saveTicket({
                    projectId: context.project_id,
                    propertyId: context.property_id,
                    ticket,
                    user: this.user,
                    attachments,
                });

                await sendTicketNotification({
                    projectId: context.project_id,
                    ticketId: savedTicket.id,
                });

                // Resize images
                const resizedAttachments: NodeMailer.Attachment[] = [];
                const documents = (await DocumentModel.list(savedTicket.attachment_ids)) || [];
                await Promise.all(
                    documents?.map(async (document) => {
                        if (mime.lookup(document.document_data.original.id).includes('image')) {
                            const signedUrl = await AWSS3.getSignedUrlStore(
                                `800x800/${document.document_data.original.id}`
                            );

                            const resizedAttachment = await Mail.getAttachment(signedUrl, true);

                            if (resizedAttachment) resizedAttachments.push(resizedAttachment);
                        }
                    })
                );

                await sendTicketMail({
                    projectId: context.project_id,
                    propertyId: context.property_id,
                    ticket,
                    user: this.user,
                    attachments: resizedAttachments,
                });

                return savedTicket;
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    @rpc()
    async listAttachments(id: number) {
        return await DocumentModel.listByDocumentable(id, 'Ticket');
    }

    @rpc({ httpMethod: 'GET' })
    async list(propertyId: Property['id'], page: number, pageSize?: number, filter?: TicketModel.TicketFilter) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await TicketModel.list(this.user, propertyId, page, pageSize, filter);
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async listTicketMessages(id: number, page: number, pageSize?: number) {
        // get thread id for ticket
        try {
            const thread = await TicketModel.getTicketMessageThread(id);

            // get messages for thread
            return await MessageModel.listMessages(this.user, thread.message_thread_id, page, pageSize);
        } catch {
            return null;
        }
    }
    @rpc()
    async saveMessage({
        id,
        message,
        attachments,
    }: {
        id: number;
        message: Omit<CreateMessage, 'thread_id'>;
        attachments?: File[];
    }) {
        // get thread id for ticket
        let message_thread_id: number;
        try {
            message_thread_id = (await TicketModel.getTicketMessageThread(id)).message_thread_id;
        } catch {
            const response = await TicketModel.createTicketMessageThread(this.user.id, id);

            message_thread_id = response.message_thread_id;
        }

        if (message_thread_id) {
            const ticketMessage: CreateMessage = {
                ...message,
                thread_id: message_thread_id,
            };
            const m = await MessageModel.saveMessage(this.user, ticketMessage);

            if (m && attachments) {
                const attachment_ids = (
                    await DocumentModel.uploadFilesToStore(attachments, this.user, {
                        document_type: 0,
                        documentable_id: m.id,
                        documentable_type: 'Message',
                    })
                ).map((a) => a.toString());
                return await MessageModel.saveMessage(this.user, {
                    ...m,
                    attachment_ids: attachment_ids ?? [],
                });
            }

            return m;
        }
    }

    @rpc({ httpMethod: 'GET' })
    async getTicketUrlInfo(propertyId: string) {
        return await TicketModel.getTicketUrlInfo(propertyId);
    }

    @rpc({ httpMethod: 'GET' })
    async getTicketEmailInfo(propertyId: string) {
        return await TicketModel.getTicketEmailInfo(propertyId);
    }

    @rpc({ httpMethod: 'GET' })
    async getTicketType(propertyId: number) {
        const { project_id } = await ResidenceModel.getById(propertyId);
        return await TicketModel.getTicketType(project_id);
    }
}

export default new TicketsApi();
