import { keyframes } from '@emotion/core';

const clickFlashCss = keyframes`
  50% {
    opacity:0.25
  }
`;

export const clickFlash = {
    animation: `${clickFlashCss} 0.2s linear`,
};
