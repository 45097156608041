import { UserCompanyTheme } from '@app/api/models/Theme';
import { isCordovaBuild } from '../lib/cordovaHelpers';
import { setHomeScreenIcon } from './setHomeScreenIcon';
import { Preferences } from '@capacitor/preferences';

export const getIosTheme = async (callBack: (companyTheme: UserCompanyTheme) => void) => {
    if (isCordovaBuild) {
        console.log(' get native storage');
        const ret = await Preferences.get({ key: 'theme' });
        if (!ret.value) return;
        callBack(JSON.parse(ret.value));
        console.log('get native storage item', ret.value);
    }
};

export const setIosTheme = async (value: UserCompanyTheme) => {
    if (isCordovaBuild) {
        if (value?.urlname) {
            setHomeScreenIcon(value.urlname);
        }
        if (!value?.urlname || 'ourliving') {
            setHomeScreenIcon('original');
        }

        await Preferences.set({
            key: 'theme',
            value: JSON.stringify(value?.data),
        });

        console.log('set native storage return', value?.data);
    }
};
