export const recurrencyArr = [
    'weekly',
    'two_weeks',
    'four_weeks',
    'monthly',
    'quarterly',
    'biannually',
    'annually',
    'two_years',
];
