import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'lib/styled';
import { style } from '../../Shared/Style';

const Trigger = styled(DropdownMenu.Trigger)({
    backgroundColor: 'inherit',
    border: 'none',
    padding: '0px',
    margin: '0px',
    height: 'fit-content',
    display: 'flex',
    ':focus-visible': {
        outline: 'none',
    },
});

const Content = styled(DropdownMenu.Content)(({ theme }) => ({
    padding: '0.5rem',
    backgroundColor: theme.colors.white,
    color: theme.colors.textColor2,
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
}));

const ContextItem = styled.li(() => ({
    padding: '1rem 2rem 1rem 1rem ',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '1rem',
    cursor: 'pointer',
}));

const Arrow = styled(DropdownMenu.Arrow)(({ theme }) => ({
    fill: theme.colors.white,
}));

export { Trigger, Content, ContextItem, Arrow };
