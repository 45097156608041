import React, { useRef } from 'react';
import styled from 'lib/styled';
import store from './store';
import Pane from './Pane';
import Close from '@app/web/components/Close';
import { observer } from 'mobx-react';
import { animate, sumOffsets } from './lib';

type HeroProps = {
    height: number;
    hide: boolean;
};

const Hero = styled.header<HeroProps>(({ height, hide }) => ({
    height: height,
    visibility: hide ? 'hidden' : 'visible',
    cursor: 'pointer',
}));

const Modal: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null);
    const { headBounds, modalHeadBounds, state, styles, index, context, headEms, bodyEms, modalSide } = store;
    const headEm = headEms.get(index);
    const bodyEm = bodyEms.get(index);

    const close = () => {
        const animation = animate(headBounds, {
            ...modalHeadBounds,
            top: modalHeadBounds.top - Math.min(modalHeadBounds.height + sumOffsets(context).top, window.pageYOffset),
        });
        requestAnimationFrame(animation);
        store.close();
    };

    return (
        <div>
            <Hero ref={ref} hide={!!styles} height={modalHeadBounds?.height} onClick={close}>
                {typeof headEm === 'function' ? headEm(state) : headEm}
            </Hero>
            {bodyEm}
            {modalSide && (
                <Pane>
                    <Close onClose={close} top={40} right={40} />
                </Pane>
            )}
        </div>
    );
};

export default observer(Modal);
