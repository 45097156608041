import React from 'react';
import styled from 'lib/styled';
import { Stylable } from '../../../types';
import { useParams } from 'react-router-dom';
import Flex from '../../../components/Layout/Flex';
import TicketSummary from './TicketSummary';
import { ThemeType } from '@app/api/models/Theme';
import { margin } from '../../../components/Shared/Style/margin';
import { useFetchTicketInfo } from '../hooks/useFetchTicketInfo';
import SendMessageArea from '../../../components/thread/SendMessageArea/SendMessageArea';
import TicketMessageFeed from './TicketMessageFeed';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import Header from '@app/web/components/Header/HeaderOnlyBackArrow';

const RigidArea = styled.div({
    flexShrink: 0,
});

const StyledTicketMessageFeed = styled(TicketMessageFeed)({
    flexGrow: 1,
    flexBasis: '100%',
    padding: `0px ${margin.m}px`,

    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
    },
});

const StyledFlex = styled(Flex)(({ theme }: { theme: ThemeType }) => {
    return {
        backgroundColor: theme?.colors?.pageBackground,
        display: 'grid',
        gridTemplateRows: 'min-content 1fr min-content',
        height: '100vh',

        [`@media screen and (min-width: ${desktop}px)`]: {
            height: 'calc(100vh - 140px)',
        },
    };
});
const OnlyBacknav = styled(Header)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'flex',
        padding: '0px 0px 1rem 0px',
    },
});
const TicketChat: React.FC<Stylable> = ({ className }) => {
    const { propertyId } = useParams<{ propertyId: string }>();

    const { data: ticketInfo } = useFetchTicketInfo();

    if (!ticketInfo) return null;

    return (
        <StyledFlex className={className}>
            <RigidArea>
                <OnlyBacknav to={`/properties/${propertyId}/tickets`} />
                <TicketSummary {...ticketInfo} />
            </RigidArea>
            <StyledTicketMessageFeed />
            <SendMessageArea style={{ alignSelf: 'end' }} />
        </StyledFlex>
    );
};

export default TicketChat;
