import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import BoardPosts from './BoardPosts';
import H2 from '../../components/Texts/H2/H2';
import BoardPagePost from './BoardPagePost';
import { Content } from '../../components/Layout/Content';
import { useBoardInformationList } from './hooks';
import styled from 'lib/styled';
import Hero from '../../components/Hero/WithTitle';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { useGetProjectDetail } from 'hooks/projectQueries';
import { useGetSignedUrls } from 'hooks/useGetSignedUrl';
import CarouselHero from '../../components/Hero/CarouselHero';
import ProjectsLayout from '../../components/Layouts/ProjectsLayout';

const StyledHero = styled(Hero)({
    margin: '0px',
    marginBottom: '1.5rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const StyledH2 = styled(H2)({
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});
const StyledCarouselHero = styled(CarouselHero)({
    'div:first-of-type': {
        gap: '2rem',
    },
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const BoardPage = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const { data: projectDetail } = useGetProjectDetail(projectId);
    const { data } = useBoardInformationList();
    const { data: signedUrls } = useGetSignedUrls(projectDetail?.documents);

    if (!data) return null;

    return (
        <ProjectsLayout>
            <Switch>
                <Route exact path={`/projects/:projectId/board/`}>
                    {signedUrls && signedUrls.length > 1 ? (
                        <StyledCarouselHero
                            urls={signedUrls && signedUrls}
                            title={projectDetail?.name}
                            to="/properties"
                        />
                    ) : (
                        <StyledHero
                            url={signedUrls && signedUrls[0]}
                            title={projectDetail?.name}
                            nav={true}
                            to={'/properties'}
                        />
                    )}
                </Route>
                <Route exact path={`/projects/:projectId/board/:boardId`}>
                    {signedUrls && signedUrls.length > 1 ? (
                        <StyledCarouselHero
                            urls={signedUrls && signedUrls}
                            title={projectDetail?.name}
                            to={`/projects/${projectId}/board`}
                        />
                    ) : (
                        <StyledHero
                            url={signedUrls && signedUrls[0]}
                            title={projectDetail?.name}
                            nav={true}
                            to={`/projects/${projectId}/board`}
                        />
                    )}
                </Route>
            </Switch>
            <Content>
                <StyledH2>{projectDetail?.name}</StyledH2>
                <Switch>
                    <Route exact path={`/projects/:projectId/board/`}>
                        <BoardPosts list={data} />
                    </Route>
                    <Route exact path={`/projects/:projectId/board/:boardId`}>
                        <BoardPagePost />
                    </Route>
                </Switch>
            </Content>
        </ProjectsLayout>
    );
};

export default BoardPage;
