import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../wash-2.svg';

const ThemedWash = styled(Icon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',

        '& g': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& path': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& rect': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& line': {
            stroke: theme?.colors?.icons || colors.icons,
        },
    };
});
const Wash: FC = ({ ...rest }) => {
    return <ThemedWash {...rest} />;
};

export default Wash;
