import React, { ChangeEvent, useRef } from 'react';
import SelectOptions from './SelectOptions';
import Select from '../../../components/Forms/Select/Select';
import { useListProducts } from '../../binder/hooks';
import { TicketAction } from './CreateTicket';
import Label from '../../../components/Forms/Label';
import { CreateTicket } from '@app/api/models/Tickets';

type Props = {
    state: CreateTicket;
    dispatch: React.Dispatch<TicketAction>;
};

const ProductSelect = ({ state, dispatch }: Props) => {
    const productListQuery = useListProducts(state.space_id);

    const selectRef = useRef<HTMLSelectElement>(null);

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        if (!selectRef.current) return;
        dispatch({ type: 'product', value: +selectRef.current.value });
    };

    return (
        <>
            <Label title={'Välj vad som är fel'} name={'what'} />
            <Select
                ref={selectRef}
                onChange={handleSelectChange}
                name={'spaces'}
                id={'what'}
                value={state.product_id?.toString() || ''}
            >
                <option value="" disabled>
                    Välj produkt
                </option>
                {productListQuery.data &&
                    productListQuery.data.map((option) => (
                        <SelectOptions key={option.id} name={option.name} value={option.id.toString()} />
                    ))}
            </Select>
        </>
    );
};

export default ProductSelect;
