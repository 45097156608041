import * as HelpModel from '../models/Help';
import { BaseApi } from '../lib/BaseApi';
import { rpc } from 'koa-rpc-call';
import { HelpItem } from '../models/Help';
export { IPageOf } from '../lib/db';
export { HelpItem } from '../models/Help';

class HelpApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async list() {
        return await HelpModel.list();
    }

    @rpc({ httpMethod: 'GET', anonymous: true })
    async get(page_name: string) {
        return await HelpModel.get(page_name);
    }

    @rpc({ httpMethod: 'GET' })
    async getById(id: HelpItem['id']) {
        return await HelpModel.getById(id);
    }
}

export default new HelpApi();
