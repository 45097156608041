import React from 'react';
import styled from 'lib/styled';

import { L } from '@app/shared/lib/i18n';
import { colors } from '../../../components/Shared/Style/colors';
import { RawHtml } from '../../../components/Layout/RawHtml';

const DescriptionParagraph = styled.p({
    fontSize: '1rem',
    margin: ' 0rem 0px 1.5rem 0px',
    color: colors.textColor1,
    whiteSpace: 'pre-line',
});

const TicketEmailInfo = ({ serviceEmail, responseText }: { serviceEmail: string; responseText?: string }) => {
    return (
        <>
            <DescriptionParagraph>
                {responseText ? <RawHtml content={responseText} /> : L('ticket_email_description')}
                {serviceEmail}
            </DescriptionParagraph>
            <DescriptionParagraph>{L('ticket_email_response_info')}</DescriptionParagraph>
        </>
    );
};

export default TicketEmailInfo;
