import lazyRetry from 'lib/lazyRetry';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const BookingPage = lazy(() => lazyRetry(() => import('./BookingPage')));

export const routes = [
    <Route key="1" path="/projects/:projectId/bookings">
        <BookingPage />
    </Route>,
];
