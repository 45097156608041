import React, { FC } from 'react';
import { HTMLAttributes } from 'react';
import { Link, useParams } from 'react-router-dom';
import ColorCircleWithPlus from '../../../components/Icon/ThemedIcons/ColorCircleWithPlus';
import useIsOLTicketEnabledForPropery from '../hooks/useIsOLTicketEnabledForPropery';

const CreateNewTicketButton: FC<HTMLAttributes<HTMLAnchorElement>> = ({ ...rest }) => {
    const isTicketEnabled = useIsOLTicketEnabledForPropery;
    const { propertyId } = useParams<{ propertyId: string }>();

    if (!isTicketEnabled) {
        return null;
    }
    return (
        // check if ticket feature
        <Link {...rest} to={`/properties/${propertyId}/tickets/new`}>
            <ColorCircleWithPlus />
        </Link>
    );
};

export default CreateNewTicketButton;
