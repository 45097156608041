import React, { FC } from 'react';
import css from './Icon.module.css';

export type IIconProps = {
    className?: string;
    state?: 'success' | 'failed' | 'spin';
    size?: 'small' | 'large' | 'medium' | 'Msmall' | 'Xsmall' | 'Mmedium' | 'MmediumMaxHeight';
    light?: boolean;
    onClick?: (e: any) => void;
    fill?: string;
    stroke?: string;
};

export const SvgIcon = (props: IIconProps & { iconId: string }) => {
    const { fill, iconId, light, state, size, className, ...rest } = props;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const SvgCodeIn = require('!!raw-loader!./' + iconId + '.svg');
    const SvgCode = (SvgCodeIn && SvgCodeIn.default) || SvgCodeIn;
    const classNames = [css.svg];
    if (fill) {
        classNames.push(css.fill);
    }
    if (fill) {
        classNames.push(css.fill);
    }
    if (state) {
        classNames.push(css['state-' + state]);
    }
    if (light) {
        classNames.push(css.light);
    }
    if (className) {
        classNames.push(className);
    }
    if (size) {
        classNames.push(css['size-' + size]);
    }

    if ('onClick' in props) {
        classNames.push(css.clickable);
    }
    const resultProps = {
        ...rest,
        className: classNames.join(' '),
        dangerouslySetInnerHTML: { __html: SvgCode },
    };

    if ('onClick' in props) {
        return <button role="img" tabIndex={0} {...resultProps} onClick={props.onClick} />;
    } else {
        return <span role="img" {...resultProps} />;
    }
};

export const Icon = {
    Binder(props: IIconProps) {
        return <SvgIcon {...props} iconId="books" />;
    },
    Dashboard(props: IIconProps) {
        return <SvgIcon {...props} iconId="house-pricing" />;
    },
    Settings(props: IIconProps) {
        return <SvgIcon {...props} iconId="privacy-settings" />;
    },
    Tickets(props: IIconProps) {
        return <SvgIcon {...props} iconId="property-agreement" />;
    },
    MoreVertical(props: IIconProps) {
        return <SvgIcon {...props} iconId="ic_more_vert_18px" />;
    },
    Attachment(props: IIconProps) {
        return <SvgIcon {...props} iconId="attachment" />;
    },
    SortUp(props: IIconProps) {
        return <SvgIcon {...props} iconId="small-triangle-up" />;
    },
    SortDown(props: IIconProps) {
        return <SvgIcon {...props} iconId="small-triangle-down" />;
    },
    SignOut(props: IIconProps) {
        return <SvgIcon {...props} iconId="signout" />;
    },
    Messages(props: IIconProps) {
        return <SvgIcon {...props} iconId="email" />;
    },
    ContentCopy(props: IIconProps) {
        return <SvgIcon {...props} iconId="content-copy" />;
    },
    Image(props: IIconProps) {
        return <SvgIcon {...props} iconId="image" />;
    },
    Document(props: IIconProps) {
        return <SvgIcon {...props} iconId="single-folded-content" />;
    },
    TrashCan(props: IIconProps) {
        return <SvgIcon {...props} iconId="trash-simple" />;
    },
    Link(props: IIconProps) {
        return <SvgIcon {...props} iconId="link-72" />;
    },
    Upload(props: IIconProps) {
        return <SvgIcon {...props} iconId="square-upload" />;
    },
    Member(props: IIconProps) {
        return <SvgIcon {...props} iconId="single-02" />;
    },
    BoardMember(props: IIconProps) {
        return <SvgIcon {...props} iconId="boardmember" />;
    },
    Contact(props: IIconProps) {
        return <SvgIcon {...props} iconId="user-frame-32" />;
    },
    News(props: IIconProps) {
        return <SvgIcon {...props} iconId="paper" />;
    },
    Calendar(props: IIconProps) {
        return <SvgIcon {...props} iconId="calendar-date-2" />;
    },
    Information(props: IIconProps) {
        return <SvgIcon {...props} iconId="info" />;
    },
    Property(props: IIconProps) {
        return <SvgIcon {...props} iconId="fav-property" />;
    },
    OptionChecked(props: IIconProps) {
        return <SvgIcon {...props} iconId="c-check" />;
    },
    Avatar(props: IIconProps) {
        return <SvgIcon {...props} iconId="circle-08" />;
    },
    ArrowLeft(props: IIconProps) {
        return <SvgIcon {...props} iconId="arrow-left" />;
    },
    List(props: IIconProps) {
        return <SvgIcon {...props} iconId="list" />;
    },
    Sofa(props: IIconProps) {
        return <SvgIcon {...props} iconId="sofa" />;
    },
    OptionNotChecked(props: IIconProps) {
        return <SvgIcon {...props} iconId="button-record" />;
    },
    AddToCart(props: IIconProps) {
        return <SvgIcon {...props} iconId="add-to-cart" />;
    },
    RightArrow(props: IIconProps) {
        return <SvgIcon {...props} iconId="right-arrow" />;
    },
    ArrowRight(props: IIconProps) {
        return <SvgIcon {...props} iconId="arrow-right" />;
    },
    CalendarCreateEvent(props: IIconProps) {
        return <SvgIcon {...props} iconId="calendar-event-create" />;
    },
    CalendarConfirmedEvent(props: IIconProps) {
        return <SvgIcon {...props} iconId="calendar-event" />;
    },
    BellNotification(props: IIconProps) {
        return <SvgIcon {...props} iconId="bell" />;
    },
    LocationPin(props: IIconProps) {
        return <SvgIcon {...props} iconId="pin-3" />;
    },
    CameraIcon(props: IIconProps) {
        return <SvgIcon {...props} iconId="camera" />;
    },
    CirleRightArrow(props: IIconProps) {
        return <SvgIcon {...props} iconId="circle-simple-right" />;
    },
    CWarning(props: IIconProps) {
        return <SvgIcon {...props} iconId="c-warning" />;
    },
    TWarning(props: IIconProps) {
        return <SvgIcon {...props} iconId="t-warning" />;
    },
    Impersonate(props: IIconProps) {
        return <SvgIcon {...props} iconId="no-contact" />;
    },
    RealEstate(props: IIconProps) {
        return <SvgIcon {...props} iconId="real-estate" />;
    },
    DeleteForEver(props: IIconProps) {
        return <SvgIcon {...props} iconId="delete-forever" />;
    },
    TableTennis(props: IIconProps) {
        return <SvgIcon {...props} iconId="table-tennis-bat" />;
    },
    LaundryRoom(props: IIconProps) {
        return <SvgIcon {...props} iconId="wash-2" />;
    },
    RightArrowBlack(props: IIconProps) {
        return <SvgIcon {...props} iconId="arrow-right-black" />;
    },
    SmallRight(props: IIconProps) {
        return <SvgIcon {...props} iconId="small-right" />;
    },
    SmallLeft(props: IIconProps) {
        return <SvgIcon {...props} iconId="small-left" />;
    },
    ParkingLot(props: IIconProps) {
        return <SvgIcon {...props} iconId="parking" />;
    },
    HourGlass(props: IIconProps) {
        return <SvgIcon {...props} iconId="hourglass" />;
    },
    FileDownload(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-download" />;
    },
    WebHyperlink(props: IIconProps) {
        return <SvgIcon {...props} iconId="web-hyperlink" />;
    },
    Add(props: IIconProps) {
        return <SvgIcon {...props} iconId="c-add" />;
    },
    ShapeOval(props: IIconProps) {
        return <SvgIcon {...props} iconId="shape-oval" />;
    },
    Check(props: IIconProps) {
        return <SvgIcon {...props} iconId="check" />;
    },
    StorageSpace(props: IIconProps) {
        return <SvgIcon {...props} iconId="storage-unit" />;
    },
    DownLoadDocument(props: IIconProps) {
        return <SvgIcon {...props} iconId="data-download-outline-64" />;
    },
    CommentBubbleLines(props: IIconProps) {
        return <SvgIcon {...props} iconId="f-comment" />;
    },
    CommentBubble(props: IIconProps) {
        return <SvgIcon {...props} iconId="comment" />;
    },
    Info(props: IIconProps) {
        return <SvgIcon {...props} iconId="c-info" />;
    },
    Search(props: IIconProps) {
        return <SvgIcon {...props} iconId="search" />;
    },
    MoreHorizontal(props: IIconProps) {
        return <SvgIcon {...props} iconId="menu-5" />;
    },
    Dropdown(props: IIconProps) {
        return <SvgIcon {...props} iconId="ctrl-down" />;
    },
    File(props: IIconProps) {
        return <SvgIcon {...props} iconId="file" />;
    },
    FileCsv(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-csv" />;
    },
    FileHtml(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-html" />;
    },
    FileDocx(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-docx" />;
    },
    FileGif(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-gif" />;
    },
    FilePng(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-png" />;
    },
    FileJpg(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-jpg" />;
    },
    FilePdf(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-pdf" />;
    },
    FileSvg(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-svg" />;
    },
    FileTxt(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-txt" />;
    },
    FileXlsx(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-xlsx" />;
    },
    fileImg(props: IIconProps) {
        return <SvgIcon {...props} iconId="polaroid-photo" />;
    },
    remove(props: IIconProps) {
        return <SvgIcon {...props} iconId="c-remove" />;
    },
    Printer(props: IIconProps) {
        return <SvgIcon {...props} iconId="printer-p" />;
    },
    Pin(props: IIconProps) {
        return <SvgIcon {...props} iconId="pin" />;
    },
    Pin3(props: IIconProps) {
        return <SvgIcon {...props} iconId="pin-3" />;
    },
};

export type Icons = keyof typeof Icon;

export const iconsKeyArray = Object.keys(Icon).map((key) => key) as Icons[];

export const IconSelect = {
    control: {
        type: 'select',
        options: iconsKeyArray,
    },
};

type Props = {
    fileType?: string;
};

export const FileTypeIcon: React.FC<Props> = ({ fileType }) => {
    switch (fileType) {
        case 'doc' || 'docx':
            return <Icon.FileDocx size="MmediumMaxHeight" />;
        case 'xls' || 'xlsx':
            return <Icon.FileXlsx size="MmediumMaxHeight" />;
        case 'pdf':
            return <Icon.FilePdf size="MmediumMaxHeight" />;
        case 'htm' || 'html':
            return <Icon.FileHtml size="MmediumMaxHeight" />;
        case 'txt':
            return <Icon.FileTxt size="MmediumMaxHeight" />;
        case 'svg':
            return <Icon.FileSvg size="MmediumMaxHeight" />;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'PNG':
            return <Icon.fileImg size="MmediumMaxHeight" />;
        case 'webp':
            return <Icon.fileImg size="MmediumMaxHeight" />;
        case 'application/doc' || 'application/docx':
            return <Icon.FileDocx size="MmediumMaxHeight" />;
        case 'application/xls' || 'application/xlsx':
            return <Icon.FileXlsx size="MmediumMaxHeight" />;
        case 'application/pdf':
            return <Icon.FilePdf size="MmediumMaxHeight" />;
        case 'application/htm' || 'application/html':
            return <Icon.FileHtml size="MmediumMaxHeight" />;
        case 'application/txt':
            return <Icon.FileTxt size="MmediumMaxHeight" />;
        case 'image/svg':
            return <Icon.FileSvg size="MmediumMaxHeight" />;
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/png':
        case 'image/PNG':
            return <Icon.fileImg size="MmediumMaxHeight" />;
        case 'image/webp':
            return <Icon.fileImg size="MmediumMaxHeight" />;
        default:
            return <Icon.File size="MmediumMaxHeight" />;
    }
};
