import React from 'react';
import styled from 'lib/styled';
import SendButton from '../../Forms/SendButton';
import { useAttachments } from '../../Forms/AttachedFiles/useAttachments';
import AttachedFiles from '../../Forms/AttachedFiles';
import AttachmentInput from '../../Forms/AttachmentInputs/AttachmentInput';
import AutoGrowingTextarea from '../../Forms/AutoGrowingTextArea/AutoGrowingTextarea';
import { style } from '../../Shared/Style';
import { HTMLAttributes } from 'react';
import { desktop } from '../../MediaQuery/breakpoints';
import DocumentClick from '../../Document/DocumentClick/DocumentClick';
import { useParams } from 'react-router-dom';
import { useSendTicketMessage } from '../../../areas/tickets/hooks/useSendTicketMessage';

const Submit = styled(SendButton)({
    width: '30px',
    height: '30px',
    placeSelf: 'center',
    gridArea: 'send',
});

const StyledTextInput = styled(AutoGrowingTextarea)({
    border: 'none',
    gridArea: 'textarea',
    alignSelf: 'center',
    padding: '5px',
    fontSize: '0.8rem',
});
const StyledForm = styled.form({
    padding: '16px',

    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '1rem 0px',
    },
});
const StyledAttachedFiles = styled(AttachedFiles)({
    paddingTop: '1rem',
});

const Wrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gap: '0.5rem',
    alignItems: 'center',
});

const TextAreaContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
    gridTemplateAreas: `
    "textarea ...."
    "textarea send"
    `,
    padding: '0px 0px 0px 10px',
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    borderRadius: style.roundCornerSize.medium,
});

const StyledAttachmetInputs = styled(AttachmentInput)({
    height: '24px',
    width: '24px',
});
export type Payload = {
    text: string;
    attachments: File[];
};

type Props = HTMLAttributes<HTMLFormElement>;

const SendMessageArea = ({ ...rest }: Props) => {
    const { attachments, handleInputAddAttachment, removeAttachment, clearAllAttachments } = useAttachments();

    const { ticketId } = useParams<{ ticketId: string }>();
    const { mutate, status } = useSendTicketMessage();

    const submit: React.FormEventHandler = async (evt) => {
        evt.preventDefault();
        const form = evt.target as HTMLFormElement;
        const area = form.elements.namedItem('text') as HTMLFormElement;
        const text = area.value.trim();
        if (!text) return;

        try {
            mutate(
                { ticketId: +ticketId, text, attachments },
                {
                    onSuccess: () => {
                        clearAllAttachments();
                        area.value = '';
                        area.rows = 1;
                    },
                }
            );
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <StyledForm {...rest} onSubmit={submit}>
            <Wrapper>
                <StyledAttachmetInputs onChange={handleInputAddAttachment} accept={''} />
                <TextAreaContainer>
                    <StyledTextInput name="text" defaultHeight={45} placeholder="Skriv ett meddelande..." />

                    <Submit type="submit" disabled={status === 'loading'} />
                </TextAreaContainer>
            </Wrapper>
            {attachments.map((attachment) => {
                return (
                    <DocumentClick
                        name={attachment.name}
                        fileType={attachment.type}
                        deleteDocument={() => removeAttachment(attachment)}
                        key={attachment.name}
                    />
                );
            })}
        </StyledForm>
    );
};

export default SendMessageArea;
