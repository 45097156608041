import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../binder.svg';

const ThemedBinder = styled(Icon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: theme?.colors?.icons || colors.icons,
            strokeWidth: '3px',
        },
        '& path': {
            strokeWidth: '3px',
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& polyline': {
            strokeWidth: '1px',
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& line': {
            strokeWidth: '1px',
            stroke: theme?.colors?.icons || colors.icons,
        },
    };
});
const Binder: FC = ({ ...rest }) => {
    return <ThemedBinder {...rest} />;
};

export default Binder;
