import React from 'react';
import { Stylable } from '@app/web/types';
import styled from 'lib/styled';
import { space } from 'styled-system';
import Photo from '../photo/Photo';
import { margin } from '../../../components/Shared/Style/margin';
import { Message as MessageType } from '@app/api/models/Messages';
import Title from '../title/Title';
import Timestamp from '../timestamp/TimeStamp';
import { colors } from '../../Shared/Style/colors';
import Avatar from '../avatar/Avatar';
import useGetDocumentsById from '../../../areas/binder/hooks/useGetDocumentsById';
import { useGetSignedFromDocumentId, useGetSignedUrlIds } from 'hooks/useGetSignedUrlId';
import DocumentItem from '../../Document/DocumentItem';

const Article = styled.article({
    display: 'grid',
    gridTemplateColumns: 'min-content auto 16px',
    columnGap: '1rem',
    gridTemplateAreas: `
    "avatar title ."
    "avatar time ."
    "avatar pre ."
    "avatar documents ."
    `,
});

const GridAvatar = styled(Avatar)({
    gridArea: 'avatar',
    justifySelf: 'start',
    alignSelf: 'start',
});

const GridTitle = styled(Title)({
    gridArea: 'title',
    placeSelf: 'stretch',
});
const GridTimestamp = styled(Timestamp)({
    gridArea: 'time',
    placeSelf: 'stretch',
});
const GridDocuments = styled.div({
    gridArea: 'documents',
    placeSelf: 'stretch',
});

const GridPre = styled.p({
    gridArea: 'pre',
    placeSelf: 'stretch',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    color: colors.textColor2,
    fontSize: '14px',
    lineHeight: '20px',
});

export const Message: React.FC<MessageType & Stylable> = ({
    className,
    text,
    created_name,
    updated_at,
    avatar_url,
    attachment_ids,
}) => {
    const { data } = useGetDocumentsById(attachment_ids);
    const documents = data?.flatMap((doc) => (doc ? [doc] : [])) || [];
    return (
        <Article className={className}>
            {avatar_url ? <GridAvatar url={avatar_url} /> : <GridAvatar name={created_name} />}
            <GridTitle>{created_name}</GridTitle>
            <GridTimestamp date={updated_at} />
            <GridPre>{text}</GridPre>
            <GridDocuments>
                {documents?.map((doc) => (
                    <DocumentItem key={doc.id} document={doc} />
                ))}
            </GridDocuments>
        </Article>
    );
};

export default styled(Message)(space);
