import { L } from '@app/shared/lib/i18n';
import styled from 'lib/styled';
import React from 'react';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import TextArea from '../../../components/Forms/TextArea';
import NotificationBellIcon from '../../../components/Icon/ThemedIcons/NotificationBellIcon';
import { ReactComponent as cycle } from '../../../components/Icon/cycle-outline.svg';
import { Dropdown } from '../../../components/Dropdown/DropDownDefault';
import { recurrencyArr } from '../lib/recurrency';
import { useState } from 'react';
import { Task } from '@app/api/models/Tasks';
import { Icon } from '../../../components/Icon/Icon';
import { useGetTaskListTemplate } from '../hooks/useGetListTemplates';
import useSaveTask from '../hooks/useSaveTask';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import toast from 'react-hot-toast';
import { addTimezone } from '@app/shared/lib/formatting';
import HeaderClean from '../../../components/Header/HeaderBackArrowAndHelp';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import z from 'zod';

const StyledWrapper = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.pageBackground,
    paddingBottom: '100px',
}));
const Container = styled.form(({ theme }) => ({
    margin: '0px 1rem',
}));

const StyledBellIcon = styled(NotificationBellIcon)(({ theme }) => ({
    height: '0.7rem',
    width: '0.7rem',
}));
const StyledCycle = styled(cycle)(({ theme }) => ({
    height: '0.7rem',
    width: '0.7rem',
}));
const StyledRemindMeAt = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
});

const OnlyBacknav = styled(HeaderClean)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'flex',
        padding: '0px 0px 1rem 0px',
    },
});

const SendReportButton = styled.button(() => {
    return {
        background: 'transparent',
        border: 'none',
        padding: '0.15rem',
        margin: '20px auto',
        justifySelf: 'center',
        display: 'grid',
        placeItems: 'center',

        '&:hover': {
            cursor: 'pointer',
        },
    };
});

const FlexContainer = styled.div({
    width: '100%',
    display: 'flex',
});

export const SendReportLable = styled.p(({ theme }) => {
    return {
        color: theme?.colors?.secondary,
        margin: 0,
        marginTop: '0.5rem',
    };
});

export const SendReportIcon = styled(Icon.CirleRightArrow)(({ theme }) => {
    return {
        display: 'block',
        height: '50px',
        width: '50px',

        '& svg': {
            width: '100%',
        },

        '& .nc-icon-wrapper': {
            'path:first-of-type': {
                // Circle
                fill: theme?.colors?.tabActive,
            },

            'path:last-of-type': {
                // Arrow
                fill: theme?.colors?.white,
            },
        },
        'button:disabled &': {
            'path:first-of-type': {
                // Circle
                fill: theme.colors.primary,
            },

            'path:last-of-type': {
                // Arrow
                fill: theme.colors.white,
            },
        },
    };
});

const RemindMeAtTitle = (
    <StyledRemindMeAt>
        {L('todo_notify_at')}
        <StyledBellIcon />
    </StyledRemindMeAt>
);
const RepeatTitle = (
    <StyledRemindMeAt>
        {L('repeat')}
        <StyledCycle />
    </StyledRemindMeAt>
);

const validateInputs = (todo: Partial<Task>) => {
    return z
        .object({
            name: z.string().min(1),
            due_at: z.string().min(1),
        })
        .safeParse(todo);
};

const AddNewTodo = () => {
    const [todo, setTodo] = useState<Partial<Task>>({});
    const { data: templates } = useGetTaskListTemplate();
    const { mutate: saveTask, status } = useSaveTask();
    const history = useHistory();
    const { propertyId } = useParams<{ propertyId: string }>();
    const [errors, setErrors] = useState<Partial<Record<keyof Task, string>>>({});

    const handleSave = (e: React.FormEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (status === 'loading') return;
        const validatedInput = validateInputs(todo);

        if (!validatedInput.success) {
            return setErrors({
                name: !todo.name && L('error_required'),
                due_at: !todo.due_at && L('error_required'),
            });
        }
        saveTask(
            {
                ...todo,
                notify_at: todo.notify_at ? addTimezone(todo.notify_at) : undefined,
                due_at: todo.due_at && addTimezone(todo.due_at),
            },
            {
                onSuccess: () => {
                    toast.success(`${todo.name} ${L('saved')}`);
                    history.push(`/properties/${propertyId}/todo`);
                },
            }
        );
    };
    return (
        //
        <StyledWrapper onSubmit={handleSave}>
            <OnlyBacknav />
            <Container>
                <Label TitleArea={L('templates')}>
                    <Dropdown
                        placeholder={L('templates_select')}
                        value={todo}
                        onChange={(value) => {
                            setTodo(value);
                        }}
                        options={
                            templates?.map((task) => ({
                                label: task.name,
                                value: { ...task, id: undefined, due_at: '', notify_at: '' },
                            })) || []
                        }
                    />
                </Label>
                <Label title={L('name')} error={errors.name}>
                    <Input
                        value={todo.name || ''}
                        onChange={(e) => {
                            setTodo((prev) => ({ ...prev, name: e.target.value }));
                        }}
                    />
                </Label>
                <Label title={L('description')}>
                    <TextArea
                        value={todo.description || ''}
                        onChange={(e) => {
                            setTodo((prev) => ({ ...prev, description: e.target.value }));
                        }}
                    />
                </Label>
                <Label title={L('todo_expires')} error={errors.due_at}>
                    <Input
                        value={todo.due_at || ''}
                        type={'datetime-local'}
                        onChange={(e) => {
                            setTodo((prev) => ({ ...prev, due_at: e.target.value }));
                        }}
                    />
                </Label>
                <Label TitleArea={RemindMeAtTitle}>
                    <Input
                        value={todo.notify_at || ''}
                        type={'datetime-local'}
                        onChange={(e) => {
                            setTodo((prev) => ({ ...prev, notify_at: e.target.value }));
                        }}
                    />
                </Label>
                <Label TitleArea={RepeatTitle}>
                    <Dropdown
                        placeholder={L('select')}
                        value={todo.recurrency}
                        onChange={(value) => {
                            setTodo((prev) => ({ ...prev, recurrency: value }));
                        }}
                        options={[
                            { label: L('no_recurrency'), value: undefined },
                            ...recurrencyArr.map((recurrency) => ({ label: L(recurrency), value: recurrency })),
                        ]}
                    />
                </Label>

                {todo.tips && (
                    <>
                        <Label title={L('tips')} />
                        <div>{todo.tips}</div>
                    </>
                )}

                <FlexContainer>
                    <SendReportButton disabled={status === 'loading'} type={'submit'}>
                        {status == 'loading' ? <Spinner /> : <SendReportIcon />}
                        {status == 'idle' && <SendReportLable>{L('create_todo')}</SendReportLable>}
                        {status == 'loading' && <SendReportLable>{L('saving')}</SendReportLable>}
                        {status == 'error' && <SendReportLable>{L('error')}</SendReportLable>}
                        {status == 'success' && <SendReportLable>{L('saved')}</SendReportLable>}
                    </SendReportButton>
                </FlexContainer>
            </Container>
        </StyledWrapper>
        //
    );
};

export default AddNewTodo;
