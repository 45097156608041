import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoginPage } from './areas/login/LoginPage';
import { Loading } from './components/Loading/Loading';
import PrivateRoutes from './PrivateRoutes';
import { routes as emailSettings } from './areas/emailSettings/navigation';
import { routes as login } from './areas/login/navigation';
export const Routes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                {emailSettings}
                {login}
                <LoginPage>
                    <PrivateRoutes />
                </LoginPage>
            </Switch>
        </Suspense>
    );
};
