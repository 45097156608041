import React, { useEffect, useMemo } from 'react';
import styled from 'lib/styled';
import SearchIcon from '@app/web/components/Icon/ThemedIcons/Search';
import { colors } from '../../Shared/Style/colors';
import { ThemeType } from '@app/api/models/Theme';
import { useTheme } from 'emotion-theming';
import { L } from '@app/shared/lib/i18n';
import { debounce } from 'lodash';

export interface SearchInputProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Form = styled.form({
    marginTop: '2rem',
});

const Input = styled.input(() => {
    return {
        border: 'none',
        gridArea: 'input',
        fontSize: '16px',
        color: colors.textColor2,
        '&:focus': {
            outline: 'none',
        },
        '::placeholder': {
            fontSize: '16px',

            fontStyle: 'italic',
        },
    };
});

const SearchBar = styled.div((p: { theme: ThemeType }) => {
    return {
        display: 'grid',
        gridTemplateAreas: `
        "icon input"
        `,
        gridTemplateColumns: 'min-content auto',

        columnGap: '0.7rem',
        padding: '0.7rem',
        borderRadius: '8px',
        backgroundColor: p.theme?.colors?.white || colors.white,
        '&:focus': {
            outline: 'solid',
        },
    };
});

const Label = styled.label({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
});

const StyledSearchIcon = styled(SearchIcon)({
    alignSelf: 'center',
    gridArea: 'icon',
    height: '22px',
    width: '22px',
});

const Search: React.FC<SearchInputProps> = ({ setValue, ...rest }) => {
    const theme = useTheme<ThemeType>();

    const changeHandler = (event) => {
        setValue(event.target.value);
    };

    const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), []);

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    return (
        <Form {...rest}>
            <Label aria-label={L('search')} htmlFor="search" />
            <SearchBar theme={theme}>
                <StyledSearchIcon />
                <Input id="search" type="text" placeholder={L('search')} onChange={debouncedChangeHandler} />
            </SearchBar>
        </Form>
    );
};

export default Search;
