import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useInformationList } from '../residence/hooks';
import HousingPosts from './HousingPosts';
import H2 from '../../components/Texts/H2/H2';
import HousingPagePost from './HousingPagePost';
import { Content } from '../../components/Layout/Content';
import { useGetProjectDetail } from 'hooks/projectQueries';
import styled from 'lib/styled';
import { desktop } from '../../components/MediaQuery/breakpoints';
import ProjectsLayout from '../../components/Layouts/ProjectsLayout';
import { useGetSignedUrls } from 'hooks/useGetSignedUrl';
import CarouselHero from '../../components/Hero/CarouselHero';
import Hero from '../../components/Hero/WithTitle';

const DesktopH2 = styled(H2)({
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

const StyledCarouselHero = styled(CarouselHero)({
    'div:first-of-type': {
        gap: '2rem',
    },
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const StyledHero = styled(Hero)({
    margin: '0px',
    marginBottom: '1.5rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const HousingPage = () => {
    const { projectId } = useParams<{
        projectId: string;
    }>();

    const { data: project } = useGetProjectDetail(projectId);
    const { data: info } = useInformationList(projectId);
    const { data: signedUrls } = useGetSignedUrls(project?.documents);

    if (!info) return null;

    return (
        <ProjectsLayout>
            <Switch>
                <Route exact path={`/projects/:projectid/housing/`}>
                    {signedUrls && signedUrls.length > 1 ? (
                        <StyledCarouselHero urls={signedUrls && signedUrls} title={project?.name} to="/properties" />
                    ) : (
                        <StyledHero
                            url={signedUrls && signedUrls[0]}
                            title={project?.name}
                            nav={true}
                            to={'/properties'}
                        />
                    )}
                </Route>
                <Route exact path={`/projects/:projectId/housing/:housingId`}>
                    {signedUrls && signedUrls.length > 1 ? (
                        <StyledCarouselHero
                            urls={signedUrls && signedUrls}
                            title={project?.name}
                            to={`/projects/${projectId}/housing`}
                        />
                    ) : (
                        <StyledHero
                            url={signedUrls && signedUrls[0]}
                            title={project?.name}
                            nav={true}
                            to={`/projects/${projectId}/housing`}
                        />
                    )}
                </Route>
            </Switch>
            <Content>
                <DesktopH2>{project?.name}</DesktopH2>

                <Switch>
                    <Route exact path={`/projects/:projectId/housing/`}>
                        <HousingPosts list={info} />
                    </Route>
                    <Route exact path={`/projects/:projectId/housing/:housingId`}>
                        <HousingPagePost />
                    </Route>
                </Switch>
            </Content>
        </ProjectsLayout>
    );
};
export default HousingPage;
