import TicketsApi from '@app/api/public/TicketsApi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import useGetTicketType from './useGetPropertyticketFeature';

const useGetEmailFeatureInfo = () => {
    const { propertyId } = useParams<{ propertyId: string }>();

    const { data } = useGetTicketType();

    return useQuery(['getTicketUrlInfo', propertyId], () => TicketsApi.getTicketUrlInfo(propertyId), {
        enabled: data?.ticket_type === 'ticket_email',
    });
    //
};

export default useGetEmailFeatureInfo;
