import React from 'react';
import { useGetDocumentsByPropertyId } from './hooks/useDocumentsList';
import { L } from '@app/shared/lib/i18n';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import PropertiesLayout from '../../components/Layouts/PropertiesLayout';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { Content } from '../../components/Layout/Content';
import { Icon } from '../../components/Icon/Icon';
import { useGetHasConstructionParts, useGetHasSecurity, useGetPropertyDetail } from '../binder/hooks';
import styled from 'lib/styled';
import { useGetSignedUrls } from 'hooks/useGetSignedUrl';
import CarouselHero from '../../components/Hero/CarouselHero';
import Hero from '../../components/Hero/WithTitle';
import RadixDropdownMenu from '../../components/Dropdown/RadixDropdownMenu';
import BinderTabs from '../binder/BinderTabs';
import H2 from '../../components/Texts/H2';
import CreateNewTicketLink from '../tickets/components/CreateNewTicketLink';

export interface DocumentsListProps {
    value: string;
}

const StyledListItem = styled.li(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.listBorderColor}`,
    padding: '20px 10px 20px 0px',
    '&:first-of-type': {
        borderTop: `1px solid ${theme.colors.listBorderColor}`,
    },
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    color: theme.colors.black,
}));

const StyledList = styled.ul({
    padding: '0rem 0rem 2rem 0rem',
});

const StyledArrowContainer = styled.div({
    textAlign: 'right',
});

const StyledHero = styled(Hero)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});
const StyledCarouselHero = styled(CarouselHero)({
    'div:first-of-type': {
        gap: '2rem',
    },
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const StyledH2 = styled(H2)({
    width: '100%',
    textAlign: 'left',
    padding: '0px',
    margin: '0px',
});
const FlexContainer = styled.div({
    justifyContent: 'space-between',
    padding: '0px',
    paddingBottom: '16px',
    display: 'none',
    gap: '2rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'flex',
    },
});

const DocumentsList = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const { data: documentsList } = useGetDocumentsByPropertyId();
    const { data: residenceDetail } = useGetPropertyDetail(propertyId);
    const { data: signedUrls } = useGetSignedUrls(residenceDetail?.documents);
    const { data: hasSecurity } = useGetHasSecurity();
    const { data: hasConstructionParts } = useGetHasConstructionParts();
    const mySet = new Set<string>();
    documentsList?.forEach((doc) => {
        if (doc.document_type !== 'image') mySet.add(doc.document_type);
    });
    return (
        <PropertiesLayout>
            {signedUrls && signedUrls.length > 1 ? (
                <StyledCarouselHero
                    urls={signedUrls && signedUrls}
                    title={residenceDetail?.name}
                    to={`/properties/${propertyId}/binder`}
                >
                    <RadixDropdownMenu />
                </StyledCarouselHero>
            ) : (
                <StyledHero
                    url={signedUrls && signedUrls[0]}
                    title={residenceDetail?.name}
                    nav={true}
                    to={`/properties/${propertyId}/binder`}
                >
                    <RadixDropdownMenu />
                </StyledHero>
            )}
            <Content>
                <FlexContainer>
                    <StyledH2>{residenceDetail?.name}</StyledH2>
                    <CreateNewTicketLink />
                </FlexContainer>
                <BinderTabs hasConstructionParts={hasConstructionParts || false} hasSecurity={hasSecurity || false} />
                <StyledList>
                    {[...mySet].map((docType) => {
                        return (
                            <StyledListItem key={docType}>
                                <StyledNavLink to={`/properties/${propertyId}/document/${docType}`}>
                                    {L(`${docType}`)}

                                    <StyledArrowContainer>
                                        <Icon.RightArrowBlack />
                                    </StyledArrowContainer>
                                </StyledNavLink>
                            </StyledListItem>
                        );
                    })}
                </StyledList>
            </Content>
        </PropertiesLayout>
    );
};

export default DocumentsList;
