import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ContentInformationApi from '@app/api/public/ContentInformationApi';

export const useDocumentList = (id?: string) => {
    const { housingId } = useParams<{ housingId: string }>();
    return useQuery(['housingDocumentGroupList', id || housingId], () =>
        ContentInformationApi.listDocuments(id || housingId)
    );
};
