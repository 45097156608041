import { ProjectType } from '@app/api/public/ProjectsApi';

export const housingSociety = {
    project_type: 'Förening',
    project_about: 'Om föreningen',
    board: 'Styrelse',
    board_members: 'Styrelsemedlemmar',
    member: 'Medlem',
    members: 'Medlemslista',
    member_menu_section: 'Medlemmar',
    properties: 'Bostäder',
    project_description: 'Här kan du få en överblick över din förening',
    all_board_members_description: 'Här kan du hantera och få en överblick över styrelsemedlemmar',
    all_members_description: 'Här kan du hantera och få en överblick över medlemmarna',
    all_boardmembers: 'Styrelsemedlemmar',
    contact_email: 'E-post styrelse',
    board_member_invite_notice: 'Du har blivit inbjuden till att vara medlem i styrelsen för',
    housing: 'Förening',
    housing_about: 'Förening',
    about: 'Om bostaden',
    binder_edit_room: 'Redigera rum',
    binder_add_room_description: 'En kort beskrivning av rummet',
};

export const apartmentBlock = {
    project_type: 'Fastighet',
    projecet_about: 'Om fastigheten',
    property: 'Lägenhet',
    properties: 'Lägenheter',
    property_info: 'Lägenhetsinformation',
    board: 'Fastighet',
    board_members: 'Administratörer',
    member: 'Hyresgäst',
    members: 'Hyresgäster',
    members_news_title: 'Hyresgäser - Nyheter',
    members_information_title: 'Hyresgäser - Information',
    members_event_title: 'Hyresgäser - Event',

    member_menu_section: 'Hyresgäster',
    project_description: 'Här kan du få en överblick över din fastighet',
    all_board_members_description: 'Här kan du hantera och få en överblick över dina administratörer och personal',
    all_members_description: 'Här kan du hantera och få en överblick över hyrestagare',
    all_boardmembers: 'Administratörer',
    board_member_content: 'Administratör',
    board_member: 'Personal',
    boardmembers: 'Administratörer',
    boardmember: 'Personal',
    boardmember_role: 'Administratörfunktion',
    boardmember_area: 'Administratörrum',
    board_member_function: 'Administratörfunktion',
    invite_board_member: 'Inbjudan som administratör i ',
    all_properties: 'Alla lägenheter',
    all_members: 'Alla hyregäster',
    export_members: 'Hyresgästlista',
    total_members: 'Hyresgäster',
    board_information_title: 'Administratör - Information',
    board_news_title: 'Administratör - Nyheter',
    board_event_title: 'Administratör - Event',
    board_content_news: 'Nyheter',
    board_content_calendar: 'Event',
    board_content_information: 'Information',
    add_member: 'Lägg till hyresgäst',
    save_member_and_invite: 'Lägg till hyresgäst och bjud in',
    members_tab: 'Hyresgäster',
    all_property_members_description: 'Här kan du hantera och få en överblick över bostadens hyresgäster',
    add_more_members: 'Lägg till fler hyresgäster',
    new_onwer_transfer: 'Ny hyregäst',
    change_property_owner: 'Omflyttning',
    ongoing_property_transfer: 'Omflyttning',
    cancel_property_transfer: 'Avbryt omflyttning',
    cancel_property_transfer_success: 'Omflyttning är nu avbrutet',
    cancel_property_transfer_error: 'Omflyttning gick inte att avbryta',
    transfer_date: 'Datum för omflyttning',
    board_member_invite_notice: 'Du har blivit inbjuden som administratör i ',
    housing: 'Fastighet',
    housing_about: 'Om fastigheten',
    residence_name: 'Lägenhetsnamn',
    about: 'Om lägenheten',
    binder_edit_room: 'Redigera rum',
    binder_add_room_description: 'En kort beskrivning av rummet',
};

export const office = {
    project_type: 'Kontoret',
    project_about: 'Om kontoret',
    board: 'Kontoret',
    board_members: 'Administratörer',
    member: 'Medlemmar',
    members: 'Medlemmar',
    member_menu_section: 'Medlemmar',
    project_description: 'Här kan du få en överblick över ditt kontor',
    all_board_members_description: 'Här kan du hantera och få en överblick över dina administratörer',
    all_members_description: 'Här kan du hantera och få en överblick över hyrestagare',

    board_member_content: 'Administratör',
    board_member: 'Administratör',
    boardmembers: 'Administratörer',
    boardmember: 'Administratör',
    boardmember_role: 'Administratörsfunktion',
    boardmember_area: 'Administratörsrum',
    board_member_function: 'Administratörsfunktion',
    invite_board_member: 'Inbjudan till administratörs i',
    all_boardmembers: 'Alla administratörer',
    contact_email: 'E-post förvaltare',
    housing: 'Kontoret',
    housing_about: 'Om kontoret',
    about: 'om kontoret',
    binder_edit_room: 'Redigera rum',
    binder_add_room_description: 'En kort beskrivning av rummet',
};

export const smallHouse = {
    project_type: 'Huset',
    project_about: 'Om huset',
    board: 'Styrelse',
    board_members: 'Syrelsemedlemmar',
    member: 'Medlemmar',
    members: 'Medlemmar',
    member_menu_section: 'Medlemmar',
    project_description: 'Här kan du få en överblick över dina hus',
    all_board_members_description: 'Här kan du hantera och få en överblick över dina administratörer',
    all_members_description: 'Här kan du hantera och få en överblick över medlemmar',
    contact_email: 'E-post',
    housing: 'Huset',
    housing_about: 'Om huset',
    about: 'Om huset',
    binder_edit_room: 'Redigera rum',
    binder_add_room_description: 'En kort beskrivning av rummet',
};

export const solar = {
    room: 'Anläggningsdelar',
    binder: 'Solanläggning',
    binder_list_building: 'Alla anläggningsdelar',
    housing_about: 'Om Solceller',
    residence_shared_with: 'Anläggningen delas med',
    about: 'Om anläggningen',
    my_property: 'Min solanläggning',
    binder_add_new_room: 'Lägg till en ny anläggningsdel',
    binder_add_space: 'Lägg till en ny anläggningsdel',
    binder_add_room_name: 'Fyll i anläggningsdelens namn',
    binder_add_room: 'Lägg till anläggningsdel',
    binder_add_product: 'Lägg till produkt',
    binder_edit_room: 'Redigera anläggningsdel',
    binder_add_room_description: 'En kort beskrivning av anläggninsdelen',
    housing: 'Solceller',
    binder_description_tile: 'Digital projektpärm med bruksanvisningar',
    binder_add_inventory_material: 'Lägg till ny produkt',
};

export function getI18ProjectTypeStrings(projectType: ProjectType) {
    switch (projectType) {
        case 'housing_society':
            return housingSociety;
        case 'apartment_block':
            return apartmentBlock;
        case 'office':
            return office;
        case 'small_house':
            return smallHouse;
        case 'solar':
            return solar;
        default:
            return housingSociety;
    }
}
