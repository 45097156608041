import styled from 'lib/styled';
import React, { useEffect, useRef, useState } from 'react';
import { Extend } from '@app/web/types';
import { space } from 'styled-system';
import { style } from '../../../components/Shared/Style';

const Textarea = styled.textarea({
    lineHeight: 'normal',
    width: '100%',
    height: 'auto',
    overflowY: 'scroll',
    outline: 'none',
    resize: 'none',
    wordWrap: 'break-word',
    overflow: 'hidden',
    boxSizing: 'border-box',
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    borderRadius: style.roundCornerSize.small,
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
    appearance: 'none',
});
const AutoGrowingTextarea: React.FC<Extend<HTMLTextAreaElement>> = ({ onChange, ...props }) => {
    const [row, setRow] = useState(1);

    const input = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (evt.key === 'Enter' && !evt.shiftKey) {
            evt.preventDefault();
            const em = evt.target as HTMLTextAreaElement;
            em.form!.dispatchEvent(new Event('submit'));
        }
    };

    const handleChange = (target: HTMLTextAreaElement) => {
        const textArea = target;
        const textareaLineHeight = 17;
        const minRows = 1;
        const maxRows = 7;
        const previousRows = textArea.rows;
        textArea.rows = minRows; // reset number of rows in textarea
        const currentRows = ~~((textArea.scrollHeight - 8) / textareaLineHeight);

        if (currentRows === previousRows) {
            textArea.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            textArea.rows = maxRows;
            textArea.scrollTop = textArea.scrollHeight;
        }

        setRow(currentRows < maxRows ? currentRows : maxRows);
    };

    const ref = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        if (ref.current) {
            handleChange(ref.current);
        }
    }, [ref.current]);

    return (
        <Textarea
            ref={ref}
            rows={row}
            onChange={(e) => {
                handleChange(e.target);
                if (onChange) {
                    onChange(e);
                }
            }}
            onKeyPress={input}
            {...props}
        />
    );
};

export default styled(AutoGrowingTextarea)(space);
