export const colors = {
    ourliving: '#469253',
    alert_low: '#29CC97',
    alert_medium: '#FEC400',
    alert_high: '#EC6666',
    body: '#F8F8F8F7',
    white: '#FFFFFF',
    black: '#252733',
    black90: '#3E3E3E',
    black80: '#AFAFAF',
    black50: '#C5C7CD',
    black30: '#F0F1F7',
    black10: '#FCFDFE',
    divider: '#DFE0EB',
    grey: '#F5F5F5',
    background: '#FFFFFF',
    sticky: '#FFFFFF',
    icons: '#000000',
    fixedBarIcons: 'white',
    impersonateBackground: '#DB7463',
    deleteButton: '#dc3545',
    deleteButtonHover: '#ad2a36',
    primary: '#469253',
    primary50: '#C2D6A1',
    primary25: '#E4EDD6',
    primary90: '#5aaf68',
    secondary: '#9FA2B4',
    lightGrayText: '#9FA2B4',
    notification: '#F12B2C',
    newcount: '#2EB67D',
    pageBackground: '#FAFAFA',
    errors: '#FF0000',
    inputBackground: '#FFFFFF',
    inputHoverBackground: '#E3F0FE',
    inputBorder: '#F0F1F7',
    statusDone: '#29CC97',
    timeSlotBorder: '#e5e5e5',
    statusWarning: '#FEC400',
    statusError: '#F12B2C',
    textColor1: '#9DA3B4',
    textColor2: '#444444',
    tabActive: '#469253',
    notificationCount: '#d84f53',
    tabInactive: '#A9D6B0',
    menuTabActive: '#272726',
    menuTabInactive: '#9A9898',
    listBorderColor: '#979797',
    none: '',
    resourceBackground: '#1947ff',
    resourceTextColor: '#808080',
    iconContrastBackground: '#E6E6FA',
    radialCompleted: '#86ae43',
    radialHalf: '#FFA500',
    orange: '#FFA500',
    removeButton: '#F12B2C',
    lightOrange: '#FFA500',
    avatarBackground: '#89d79b',
    formPlaceholder: '#4B506D',
    alertBackground: '#E0E0E0',
    alertPrimary: '#cfe2ff',
    alertSecondary: '#e2e3e',
    alertSuccess: '#d1e7dd',
    alertDanger: '#f8d7da',
    alertWarning: '#fff3cd',
    alertInfo: '#cff4f',
    alertLight: '#fefefe',
    alertDark: '#d3d3d',
    mobileStyckyNav: '#F5F5F5',
    inputBackgroundDisabled: '#F5F5F5',
};

export type Colors = keyof typeof colors;

export const colorsKeyArray = Object.keys(colors).map((key) => key);

export const colorSelect = {
    control: {
        type: 'select',
        options: colorsKeyArray,
    },
};
