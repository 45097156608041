import React from 'react';
import styled from 'lib/styled';
import CreateNewTicketLink from '../../tickets/components/CreateNewTicketLink';
import { useIsTicketEnabledForProperty } from '../../tickets/hooks/useIsTicketEnabledForProperty';
import useGetDocumentUrl from '../useGetDocumentUrl';
import { useGetPropertyDetail } from '../../binder/hooks';
import { medium } from 'lib/imageSize';
import WithTitle from '../../../components/Hero/WithTitle';

const StyledCreateNewTicketLink = styled(CreateNewTicketLink)({
    alignSelf: 'start',
});

const Header = ({ id, ...rest }) => {
    const { data } = useGetPropertyDetail(id);
    const { getSignedUrlDocument } = useGetDocumentUrl();
    const { data: signedUrl } = getSignedUrlDocument(data?.documents?.[0], medium);
    const { data: ticketEnabled } = useIsTicketEnabledForProperty();
    return (
        <WithTitle
            to={`/properties/${id}/document`}
            url={signedUrl}
            color={'white'}
            gradient={true}
            title={data?.name}
            nav={true}
            {...rest}
        >
            {ticketEnabled && <StyledCreateNewTicketLink />}
        </WithTitle>
    );
};

export default Header;
