import styled from 'lib/styled';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../../Loading/Loading';
import useNotificationsList from '../hooks/useNotificationList';
import NotificationList from '../notificationList/NotificationList';

type Props = {
    filter?: string;
};

const StyledNotificationList = styled(NotificationList)({
    '& li': { marginBottom: '21px' },
});

const NotificationHandler = ({ filter }) => {
    const { data: notifications, isLoading } = useNotificationsList();
    const { type } = useParams<{ type: string }>();

    const tabFilterdnotifications = notifications?.filter((notification) => {
        if (!type) {
            return notification;
        }
        return notification.notifiable_type === type;
    });

    const filteredTabNotifications = tabFilterdnotifications?.filter((notification) => {
        if (!filter) return notification;

        // filter by value match
        const regexp = new RegExp(filter, 'i');
        const notificationKeyValueMatch = Object.values(notification).find((value) => {
            if (typeof value === 'string') return value.match(regexp);
        });

        let descriptionMatch: RegExpMatchArray | null = null;
        let titleMatch: RegExpMatchArray | null = null;

        // filter alerts
        if (notification.notifiable_type === 'alert') {
            descriptionMatch = notification?.data?.description.plain_text.match(regexp);
            titleMatch = notification?.data?.title.match(regexp);
        }

        if (notificationKeyValueMatch?.length || descriptionMatch?.length || titleMatch?.length) {
            return notification;
        }
    });

    if (isLoading) return <Loading />;

    return <StyledNotificationList isCollapsed={false} notifications={filteredTabNotifications || []} />;
};

export default NotificationHandler;
