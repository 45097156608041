import { Dimension } from '@app/api/lib/resize';
import { DocumentDetail } from '@app/api/models/Documents';
import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQueries, useQuery } from 'react-query';

export const getSignedUrlQueryKey = {
    all: ['getSignedUrl'],
    id: (id?: string, demension?: string) => [...getSignedUrlQueryKey.all, { id, demension }],
};

const getSignedUrlFromDocumentId = async (id?: number, demension?: Dimension) => {
    if (!id) return;
    const doc = await DocumentsApi.getbyid(id);
    return await DocumentsApi.getSignedUrlStore(`${demension ? demension + '/' : ''}${doc?.document_data.original.id}`);
};

const useGetSignedFromDocumentId = (id?: number, demension?: Dimension) => {
    return useQuery(
        [getSignedUrlQueryKey, `${demension ? demension + '/' : ''}${id}`],
        () => {
            return getSignedUrlFromDocumentId(id, demension);
        },
        {
            enabled: !!id,
        }
    );
};

const getSignedUrl = async (id?: DocumentDetail['document_data']['original']['id'], demension?: Dimension) => {
    if (!id) return;
    return await DocumentsApi.getSignedUrlStore(`${demension ? demension + '/' : ''}${id}`);
};

const useGetSignedUrlId = (id?: DocumentDetail['document_data']['original']['id'], demension?: Dimension) => {
    return useQuery(
        getSignedUrlQueryKey.id(id, demension),
        () => {
            return getSignedUrl(id, demension);
        },
        {
            enabled: !!id,
            staleTime: Infinity,
        }
    );
};

const useGetSignedUrlIds = (ids?: DocumentDetail['document_data']['original']['id'][], demension?: Dimension) => {
    return useQueries(
        (ids || []).map((id) => {
            return {
                queryKey: getSignedUrlQueryKey.id(id, demension),
                queryFn: () => getSignedUrl(id, demension),
            };
        })
    );
};

export { useGetSignedUrlId as default, useGetSignedUrlIds, useGetSignedFromDocumentId };
