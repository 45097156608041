import React from 'react';
import styled from 'lib/styled';
import store from './store';
import { Rect } from './types';

const Div = styled.div<Pick<Rect, 'left' | 'width'>>(({ left, width }) => ({
    position: 'fixed',
    top: 0,
    left,
    width,
    bottom: 0,
    pointerEvents: 'none',
}));

const Pane: React.FC = (props) => {
    const { modalHeadBounds } = store;
    if (!modalHeadBounds) return null;
    const { left, width } = modalHeadBounds;

    return <Div {...props} left={left} width={width} />;
};

export default Pane;
