import { L } from '@app/shared/lib/i18n';
import * as EmailValidator from 'email-validator';
import Personnummer from 'personnummer';

export function required(): ValidationRule {
    return {
        test(value: any) {
            if (!!value) return;
            return L('error_required');
        },
    };
}
export function requiredCheck(): ValidationRule {
    return {
        test(value: any) {
            if (!!value) return;
            return L('error_required_checkbox');
        },
    };
}
export function requiredEmail(): ValidationRule {
    return {
        test(value: any) {
            if (EmailValidator.validate(value)) return;
            return L('error_required');
        },
    };
}

export function requiredNationalId(): ValidationRule {
    return {
        test(value: any) {
            if (Personnummer.valid(value)) return;
            return L('error_id_required');
        },
    };
}

export function requireOneOf(keys: string[]): ValidationRule {
    return {
        test(value: any, model: any) {
            if (keys.filter((k) => !!model[k]).length >= 1) return;
            return L('error_oneisrequired');
        },
    };
}

class ModelValidator<T> {
    rules: ValidationRules;
    constructor(rules: ValidationRules) {
        this.rules = rules;
    }
    test(model: { [key: string]: any }): ValidationResult<T> {
        const results: ValidationResult<T> = { ok: true, errors: {} };
        for (const rules of Object.entries(this.rules)) {
            const key = rules[0];
            const value = model[key];

            for (const rule of rules[1]) {
                const errorText = rule.test(value, model);
                if (errorText) {
                    results.errors[key] = errorText;
                    results.ok = false;
                    break;
                }
            }
        }
        return results;
    }
}

export type ValidationRules = Record<string, ValidationRule[]>;

export interface ValidationResult<T> {
    ok: boolean;
    errors: { [key in keyof T]?: string };
}
export interface ValidationRule {
    test(value: any, model: any): string | undefined;
}

export function createValidator<T extends ValidationRules>(rules: T): ModelValidator<T> {
    return new ModelValidator(rules);
}
