import React from 'react';
import styled from 'lib/styled';
import { style } from '../Shared/Style';
import { useTheme } from 'emotion-theming';
import { ThemeType } from '@app/api/models/Theme';
interface Props {
    title?: string;
    backgroundColor?: string;
    padding?: string;
    color?: string;
    textAlign?: string;
    fontSize?: number | string;
    minWidth?: string;
}

const Title = styled.div(
    (props: {
        backgroundColor?: string;
        padding?: string;
        color?: string;
        textAlign?: string;
        fontSize?: number | string;
        minWidth?: string;
        theme: ThemeType;
    }) => ({
        width: '100%',
        padding: props.padding || '0px 10%',
        color: props.color || props.theme?.colors?.black90 || style.colors.black90,
        fontSize: props.fontSize || style.fontSize.delta,
        margin: '0 auto',
        lineHeight: style.lineHeight.normal,
        textAlign: (props.textAlign as '') || 'left',
        backgroundColor: props.backgroundColor,
        minWidth: props.minWidth || 'auto',
    })
);

export const DetailHeader = (p: Props) => {
    return (
        <Title
            textAlign={p.textAlign}
            color={p.color || ''}
            padding={p.padding || ''}
            backgroundColor={p.backgroundColor || ''}
            fontSize={p.fontSize || ''}
            minWidth={p.minWidth}
        >
            {p.title}
        </Title>
    );
};
