import styled from '@emotion/styled';
import { space } from 'styled-system';

export const Ul = styled.ul(
    {
        padding: 0,
        margin: 0,
    },
    space
);

export const Li = styled.li(
    {
        listStyleType: 'none',
    },
    space
);
