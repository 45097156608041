import TicketsApi from '@app/api/public/TicketsApi';
import { useQuery } from 'react-query';

const getTicketAttachment = (ticketId?: number) => {
    if (!ticketId) return;
    return TicketsApi.getTicketAttachmentById(+ticketId);
};

export const useGetTicketAttachments = (ticketId?: number) => {
    return useQuery(['ticket', 'attachments', ticketId], () => getTicketAttachment(ticketId), {
        enabled: !!ticketId,
    });
};
