import React, { FC } from 'react';
import styled from 'lib/styled';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import sv from 'dayjs/locale/sv';
import NoticeIcon from '../components/NoticeIcon';
import NoticeTitle from '../components/NoticeTitle';
import NoticeDate from '../components/NoticeDate';
import NoticeLabel from '../components/NoticeLabel';
import { BookingNotification } from '../../../../api/models/Notifications';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
const Border = styled.div(({ theme }) => {
    return {
        backgroundColor: theme.colors.alertBackground,
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        display: 'grid',
        gridTemplateColumns: 'min-content auto min-content',
        gridTemplateAreas: `
    "icon title label"
    "icon date label"
    `,
        columnGap: '1rem',
    };
});

const BookingNotice: FC<BookingNotification & { isCollapsed: boolean }> = ({
    notifiable_type,
    data,
    body,
    isCollapsed,
    ...rest
}) => {
    if (!data) return null;
    const { time } = data;
    const start = time && dayjs(time.start).format('HH:mm');
    const end = time && dayjs(time.end).format('HH:mm');
    const when = time && dayjs(time.start).locale(sv).fromNow();
    return (
        <Link to={`/${notifiable_type}`}>
            <Border {...rest}>
                <NoticeIcon />
                <NoticeTitle isCollapsed={isCollapsed} description={body} />
                <NoticeDate start={start} end={end} when={when} />
                <NoticeLabel type={'Booking'} />
            </Border>
        </Link>
    );
};

export default BookingNotice;
