import React from 'react';
import { FC } from 'react';
import styled from 'lib/styled';
import { desktop } from '../../../../components/MediaQuery/breakpoints';

const ContainerStyle = styled.main({
    padding: '0 1rem',
    width: '100%',

    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
    },
});

const ContentArea: FC = ({ children, ...rest }) => {
    return <ContainerStyle {...rest}>{children}</ContainerStyle>;
};

export default ContentArea;
