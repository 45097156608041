import { autorun, observable, toJS } from 'mobx';
import io from 'socket.io-client';
import { LoginStore } from '../areas/login/LoginStore';
import { NewMessage } from '@app/api/lib/socket/new_message/new_message';
import { NotificationBellType, NotificationType } from '../../api/models/Notifications';
import invalidateQueriesByNotificationType from '../components/NewNotification/functions/invalidateQueriesByNotificationType';

class SocketStoreClass {
    constructor() {
        this.socket = io(SOCKET_URL_PREFIX ? `${SOCKET_URL_PREFIX}/socket.io` : '/socket.io', {
            reconnectionDelayMax: 10000,
            // transports: ['websocket'],
            query: {
                token: localStorage.getItem('TOKEN') || '',
            },
        });

        this.socket.on('new_message', (payload: NewMessage) => {
            this.newMessage = payload;
            console.log('resived payload from new_message', payload);
        });
        this.socket.on('new_notification', (payload: NotificationType) => {
            console.log('resived payload from new_notification', payload);
            // invalidateQueriesByNotificationType(payload);
            this.newNotification = payload;
        });
        this.socket.on('notification_bell', (payload: NotificationBellType) => {
            // queryCache.setQueryData('notificationBellCount', payload);
            // queryCache.invalidateQueries('notificationBellCount');
        });
        autorun(() => console.log('New message: ', toJS(this.newMessage)));
        autorun(() => console.log('New notification: ', toJS(this.newNotification)));
    }

    @observable newMessage: NewMessage | null = null;
    @observable newNotification: NotificationType | null = null;
    @observable socket: SocketIOClient.Socket;
}
export const SocketStore = new SocketStoreClass();

autorun(() => {
    console.log('socket io adress', `${window.location.origin}`);
    if (LoginStore.user) {
        !SocketStore.socket.connected ? SocketStore.socket.connect() : '';
    }
    if (!LoginStore.user) {
        !SocketStore.socket.connected ? SocketStore.socket.disconnect() : '';
    }
});
