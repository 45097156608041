import lazyRetry from 'lib/lazyRetry';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const FeedPage = lazy(() =>
    lazyRetry(() => import('./FeedPage/FeedPage').then((module) => ({ default: module.FeedPage })))
);

export const routes = [
    <Route key={1} exact path="/">
        <FeedPage />
    </Route>,
    <Route key={2} path={'/blogItem'}>
        <FeedPage />
    </Route>,
    <Route key={3} path={'/communityNews'}>
        <FeedPage />
    </Route>,
    <Route key={4} path={'/board'}>
        <FeedPage />
    </Route>,
    <Route key={5} path={'/communityEvent'}>
        <FeedPage />
    </Route>,
];
