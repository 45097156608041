import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../c-add.svg';

const ThemedColorCircleWithPlus = styled(Icon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',
        cursor: 'pointer',
        '& circle': {
            strokeWidth: '1px',
            stroke: theme?.colors?.tabActive || colors.tabActive,
            fill: theme?.colors?.tabActive || colors.tabActive,
        },
        '& path': {
            stroke: theme?.colors?.white || colors.white,
        },
    };
});
const ColorCircleWithPlus: FC = ({ ...rest }) => {
    return <ThemedColorCircleWithPlus viewBox="0 0 16 16" {...rest} />;
};

export default ColorCircleWithPlus;
