import React from 'react';
import styled from 'lib/styled';
import { isCordovaBuild } from 'lib/cordovaHelpers';
import { ThemeType } from '@app/api/models/Theme';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { useListEmailSettings, useSwitchSetting } from './hooks';
import { Loading } from '../../components/Loading/Loading';
import BackArrow from '../../components/Breadcrumb/BackArrow';
import { L } from '@app/shared/lib/i18n';
import H1 from '../../components/Texts/H1/H1';
import StyledSwitch from '../../components/StyledSwitch/StyledSwitch';
import StickyBarContent from '../../components/StickyBar/StickyBarContent';
import { FixedBar } from '../../components/StickyBar/FixedBar';
import { PageBackground } from '../../components/Layout/PageBackground';
import { margin } from '../../components/Shared/Style/margin';
import useGetProfile from '../../hooks/useGetProfile';

const StyledPage = styled(PageBackground)({
    margin: `${margin.m}px ${margin.m}px 0px ${margin.m}px`,
    minHeight: isCordovaBuild ? '-webkit-fill-available' : '100vh',
    width: 'auto',
    height: 'auto',
    display: 'block',
});

const Nav = styled.nav({
    minHeight: isCordovaBuild ? '-webkit-fill-available' : '100vh',
    alignSelf: 'center',
});

const P = styled.p({
    color: '#80808082',
    textTransform: 'capitalize',
    paddingBottom: '0.5rem',
    marginBottom: '0',
});

const Ul = styled.ul({
    padding: 0,
    margin: 0,
});

const Li = styled.li((p: { theme: ThemeType }) => {
    return {
        listStyleType: 'none',
        padding: '0',
        cursor: 'pointer',
        borderBottom: `1px solid ${p.theme?.colors?.listBorderColor}`,
        '&:first-of-type': {
            borderTop: `1px solid ${p.theme?.colors?.listBorderColor}`,
        },
    };
});
const Wrapper = styled.div({
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const RecurseStyled = styled.p({
    margin: '1rem 0',
    fontSize: '1.3rem',
});

const IconDivWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const WrapperDiv = styled.div({
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media screen and (min-width: ${desktop}px)`]: {
        width: '15%',
    },
});

const StyledH1 = styled(H1)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        margin: '0px',
    },
});

const StyledBackArrow = styled(BackArrow)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});
const StyledFixedBar = styled(FixedBar)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const EmailSettingsPage = () => {
    //switch the notification on/off
    const { data: emailList } = useListEmailSettings();
    const { data: profile } = useGetProfile();

    const { mutate } = useSwitchSetting();

    //switch button
    const onSwitch = (id: any, value: any) => {
        mutate({ id, value, key: 'email' });
    };
    if (!profile || !emailList) {
        return <Loading />;
    }
    return (
        <StyledPage>
            <StyledBackArrow style={{ placeSelf: 'start' }} />
            <StyledH1>{L('email_settings')}</StyledH1>
            <Nav>
                <P>{profile.name}</P>
                <Ul>
                    {emailList?.map((setting, i) => (
                        <Li key={i}>
                            <Wrapper>
                                <IconDivWrapper>
                                    <RecurseStyled>{L(setting.type)}</RecurseStyled>
                                </IconDivWrapper>
                                <WrapperDiv>
                                    <StyledSwitch
                                        isActive={setting.value}
                                        onClick={() => onSwitch(setting.setting_id, setting.value)}
                                        checked={setting.value}
                                    />
                                </WrapperDiv>
                            </Wrapper>
                        </Li>
                    ))}
                </Ul>
            </Nav>
            <StyledFixedBar>
                <StickyBarContent />
            </StyledFixedBar>
        </StyledPage>
    );
};

export default EmailSettingsPage;
