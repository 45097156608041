import React, { useState } from 'react';
import { useGetDocumentsByPropertyId } from './hooks/useDocumentsList';
import DocumentItemContext from '../../components/Document/DocumentItem/DocumentItemContext';
import { useParams } from 'react-router';
import PropertiesLayout from '../../components/Layouts/PropertiesLayout';
import styled from '@emotion/styled';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { Content } from '../../components/Layout/Content';
import Header from './Header/Header';
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs/Breadcrumbs';
import { L } from '@app/shared/lib/i18n';
import H2 from '../../components/Texts/H2';
import CreateNewTicketLink from '../tickets/components/CreateNewTicketLink';
import { useGetPropertyDetail } from '../binder/hooks';
import { ProductDocumentDetail } from '@app/api/public/DocumentsApi';
import { DetailHeader } from '../../components/Texts/DetailHeader';
import { style } from '../../components/Shared/Style';
import RadixDialog from '../../components/RadixDialog/RadixDialog';
import H3 from '../../components/Texts/H3';
import DocumentImageItem from './DocumentImageItem';
import { fontSize } from '../../components/Shared/Style/typography';

export interface DocumentsListProps {
    value: string;
}

const StyledHeader = styled(Header)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const FlexContainer = styled.div({
    justifyContent: 'space-between',
    padding: '0px',
    paddingBottom: '16px',
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'flex',
    },
});

const StyledH2 = styled(H2)({
    width: '100%',
    textAlign: 'left',
    padding: '0px',
    margin: '0px',
    paddingBottom: '1rem',
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

const Grid = styled.div({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill,minmax(100px,1fr))`,
    gap: 10,
});

const StyledImage = styled.img({
    placeSelf: 'center',
    maxWidth: '100%',
    maxHeight: 'inherit',
});

const StyledBreadcrumbs = styled(Breadcrumbs)({
    paddingBottom: '1rem',
});

type DocumentGroupsProps = {
    documents: ProductDocumentDetail[];
    handleImageClick?: (url: string, doc: ProductDocumentDetail) => void;
};
const DocumentGroups = ({ documents, handleImageClick }: DocumentGroupsProps) => {
    const { documentType } = useParams<{ documentType: string }>();
    const groups = [
        ...new Set(documents.flatMap((document) => (document.document_subtype ? [document.document_subtype] : []))),
    ];
    const documentsWithoutGroup = documents
        .filter((document) => !document.document_subtype)
        .map((document) => {
            if (documentType === 'document_image') {
                return <DocumentImageItem key={document.id} doc={document} handleImageClick={handleImageClick} />;
            }
            return <DocumentItemContext key={document.id} doc={document} />;
        });

    const groupedDocuments = groups.map((group) => {
        return (
            <React.Fragment key={group}>
                <DetailHeader color={style.colors.black90} padding={'0px'} title={L(group)} />

                {documents
                    .filter((document) => document.document_subtype === group)
                    .map((document) => {
                        if (documentType === 'document_image') {
                            return (
                                <DocumentImageItem
                                    handleImageClick={handleImageClick}
                                    key={document.id}
                                    doc={document}
                                />
                            );
                        }
                        return <DocumentItemContext key={document.id} doc={document} />;
                    })}
            </React.Fragment>
        );
    });

    return (
        <>
            <H3
                style={{
                    textAlign: 'left',
                    minWidth: 'auto',
                }}
            >
                {L('document')}
            </H3>
            {documentType === 'document_image' ? <Grid>{groupedDocuments}</Grid> : groupedDocuments}
            {groupedDocuments.length && documentsWithoutGroup.length ? (
                <DetailHeader color={style.colors.black90} padding={'0px'} title={L('other')} />
            ) : null}
            {documentType === 'document_image' ? <Grid>{documentsWithoutGroup}</Grid> : documentsWithoutGroup}
        </>
    );
};

const DocumentItems = () => {
    const [modalDoc, setModalDoc] = useState<{ url: string; doc: ProductDocumentDetail } | null>(null);
    const { propertyId, documentType } = useParams<{ propertyId: string; documentType: string }>();
    const { data: documentsList } = useGetDocumentsByPropertyId();
    const residenceDetail = useGetPropertyDetail();
    const documents = documentsList
        ?.filter((doc) => doc.document_type === documentType)
        .flatMap((doc) => (doc ? [doc] : []));

    return (
        <>
            <RadixDialog
                open={!!modalDoc}
                onOpenChange={() => {
                    setModalDoc(null);
                }}
            >
                <div style={{ padding: '0.5rem' }}>
                    <H2 style={{ color: '#FFF', fontSize: fontSize.delta }}>{modalDoc?.doc.name}</H2>
                    <StyledImage onClick={() => setModalDoc(null)} src={modalDoc?.url ?? ''} />
                </div>
            </RadixDialog>
            <PropertiesLayout>
                <StyledHeader id={propertyId} />
                <FlexContainer>
                    <StyledH2>{residenceDetail.data?.project_name}</StyledH2>
                    <CreateNewTicketLink />
                </FlexContainer>
                <Content>
                    <StyledBreadcrumbs>
                        <Breadcrumbs.Item to={`/properties/${propertyId}/document`}>{L('document')}</Breadcrumbs.Item>
                        <Breadcrumbs.ItemActive>{L(`${documentType}`)}</Breadcrumbs.ItemActive>
                    </StyledBreadcrumbs>
                    <DocumentGroups
                        documents={documents || []}
                        handleImageClick={(url: string, doc: ProductDocumentDetail) => setModalDoc({ url, doc })}
                    />
                </Content>
            </PropertiesLayout>
        </>
    );
};

export default DocumentItems;
