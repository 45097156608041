import * as Model from '../models/BoardContentInformation';
import { BaseApi } from '../lib/BaseApi';
import { rpc } from 'koa-rpc-call';
import { getBoardProjectLoginContext } from '../models/Users';
import { Property } from '../models/Property';
import { ContentInformationItem } from '../models/ContentInformation';
import { Project } from '../models/Projects';
export { IPageOf } from '../lib/db';

class BoardContentInformationApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async list(projectId: Project['id']) {
        const context = await getBoardProjectLoginContext(this.user.id, projectId);
        if (context?.project_id) {
            return await Model.list(context.project_id);
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async get(projectId: Project['id'], id: ContentInformationItem['id']) {
        const context = await getBoardProjectLoginContext(this.user.id, projectId);
        if (context?.project_id) {
            return await Model.get(context.project_id, id);
        }
        return null;
    }

    @rpc()
    async listDocuments(id: string, projectId: Project['id']) {
        const context = await getBoardProjectLoginContext(this.user.id, projectId);
        if (context?.project_id) {
            return await Model.listDocuments(+id, context.project_id);
        }
        return null;
    }

    @rpc()
    async listDocumentGroups(projectId: Project['id']) {
        const context = await getBoardProjectLoginContext(this.user.id, projectId);
        if (context?.project_id) {
            return await Model.listDocumentGroups(context.project_id);
        }
        return null;
    }
}

export default new BoardContentInformationApi();
