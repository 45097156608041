import { useQuery } from 'react-query';
import DocumentsApi, { DocumentList } from '@app/api/public/DocumentsApi';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Index } from 'flexsearch';
import { L } from '@app/shared/lib/i18n';

export const useGetDocumentsByPropertyId = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return useQuery(['documentList', propertyId], () => DocumentsApi.getlistByPropertyId(+propertyId));
};

export const useAllEffects = ({
    activeList,
    value,
    documentsList,
    setBigTabState,
    isLoadingDocList,
    setFilterList,
    bigTabState,
    setActiveList,
    setSmallTabState,
    smallTabState,
}) => {
    //Filter from searchbar indexed with FlexSearch tokenize option to match more than just full words.

    const [index, setIndex] = useState(new Index({ tokenize: 'full' }));

    const filterSearchbar = useEffect(() => {
        if (!activeList) return;
        // Limit to 20 results
        const ids = index.search(value, 20);
        setFilterList(activeList.filter((document) => ids.includes(document.document_id)));
    }, [value, activeList, bigTabState]);

    // Initial load
    const initialLoad = useEffect(() => {
        if (!documentsList) return;
        setActiveList(documentsList);
    }, [isLoadingDocList]);

    // Reset smallTabs when bigtab changes
    const resetSmalltab = useEffect(() => {
        setSmallTabState('');
    }, [bigTabState]);

    // Remove highlight when using searchbar
    const removeHighlight = useEffect(() => {
        value.length > 0 ? setBigTabState('') : setBigTabState('binder');
    }, [value]);

    // Filter list with active tabs
    const filterActiveTabs = useEffect(() => {
        if (bigTabState) setFilterList(activeList.filter((doc) => doc.header1 === bigTabState));
        if (smallTabState)
            setFilterList(activeList.filter((doc) => doc.header2 === smallTabState && doc.header1 === bigTabState));
    }, [smallTabState, bigTabState, isLoadingDocList]);

    // Make first load filter on bigstate and index documentList
    const loadWithTab = useEffect(() => {
        setFilterList(activeList.filter((doc) => doc.header1 === bigTabState));

        activeList.forEach((doc: DocumentList) => {
            setIndex(index.add(doc.document_id, [doc.document_name, L(doc.header1), L(doc.header2), doc.path]));
        });
    }, [activeList]);

    return { loadWithTab, filterActiveTabs, resetSmalltab, initialLoad, filterSearchbar, removeHighlight };
};
