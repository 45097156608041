import ProjectsApi from '@app/api/public/ProjectsApi';
import { useQuery } from 'react-query';

const getProjectDetailByPropertyId = (propertyId?: string) => {
    if (!propertyId) return;
    return ProjectsApi.getProjectDetailByPropertyId(+propertyId);
};

const useGetProjectDetailByPropertyId = (propertyId?: string) => {
    return useQuery(['projectDetail', propertyId], () => getProjectDetailByPropertyId(propertyId), {
        enabled: !!propertyId,
    });
};

export default useGetProjectDetailByPropertyId;
