import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import fetch from 'node-fetch';

class FileApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async downloadFile(url: string) {
        return fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                return blob;
            });
    }
}
export default new FileApi();
