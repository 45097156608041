import React from 'react';
import { DocumentDetail } from '@app/api/public/DocumentsApi';
import styled from 'lib/styled';
import useGetSignedUrl from 'hooks/useGetSignedUrl';

const ThumbNail = styled.img(() => ({
    aspectRatio: '1/1',
    objectFit: 'cover',
    objectPosition: 'center',
    maxWidth: '100%',
    cursor: 'pointer',
}));

type Props<T extends DocumentDetail> = {
    doc: T;
    handleImageClick?: (url: string, doc: T) => void;
} & React.HTMLAttributes<HTMLImageElement>;

const DocumentImageItem = <T extends DocumentDetail>({ doc, handleImageClick, ...rest }: Props<T>) => {
    const { data: url } = useGetSignedUrl(doc.document_data, '800x0');

    return (
        <ThumbNail
            src={url}
            onClick={() => {
                console.log('running');
                if (!handleImageClick || !url) return;
                handleImageClick(url, doc);
            }}
            title={doc.document_data.original.metadata.filename}
            {...rest}
        />
    );
};

export default DocumentImageItem;
