import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import CloseX from '../../Icon/ThemedIcons/CloseX';
import styled from 'lib/styled';

const StyledClose = styled(CloseX)({});
const StyledButton = styled(Link)({});

type CloseWithLink = {
    to: LinkProps['to'];
};

type Props = CloseWithLink;
const ClosePage: FC<Props> = ({ to, ...rest }) => {
    return (
        <StyledButton to={to} {...rest}>
            <StyledClose />
        </StyledButton>
    );
};

export default ClosePage;
