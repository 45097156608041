import { Preferences } from '@capacitor/preferences';
import { configure, RpcError, RpcRequestInit } from 'koa-rpc-call/browser';
import { isCordovaBuild } from './cordovaHelpers';

const getAppVersion = () => {
    if (HEROKU_RELEASE_VERSION) return HEROKU_RELEASE_VERSION;
    if (GIT_SHA) return GIT_SHA;
    if (isCordovaBuild) return 'ios';
    return 'develop';
};
const appVersion = getAppVersion();

console.log('appVersion', appVersion);
async function onRequest(info: RpcRequestInit, finish: (i: RpcRequestInit) => Promise<any>) {
    if (!info.headers) info.headers = {};
    const refreshed = window.sessionStorage.getItem(`retry-${appVersion}-refreshed`);
    const token = await Preferences.get({ key: 'TOKEN' });
    info.headers['Authorization'] = token?.value || '';
    info.headers['x-property-id'] = localStorage['property-id'] ? localStorage['property-id'] : '';
    info.headers['x-app-version'] = appVersion;
    refreshed && (info.headers['x-app-version-retry'] = refreshed);
    // parse properyId?
    try {
        const fin = await finish(info);
        return fin;
    } catch (ex) {
        if ((ex as RpcError) && ex?.response?.status === 401) {
            delete localStorage.TOKEN;
            console.log('restart 401');
            location.reload();
        }

        const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-app-version-refreshed') || 'false');
        if (ex?.response?.status === 418 && !hasRefreshed) {
            window.sessionStorage.setItem(`retry-${appVersion}-refreshed`, 'true'); // we are now going to refresh
            console.log('restart 418, appVersion: ', appVersion);
            if (Boolean(refreshed)) {
                location.reload();
            }
        }
    }
}

configure({
    onRequest,
    urlPrefix: typeof RPC_URL_PREFIX !== 'undefined' ? RPC_URL_PREFIX : '/rpc',
});
