import styled from '@emotion/styled';
import React from 'react';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import PropertyDetailFields from '../components/PropertyDetailFields';

const Main = styled.div({
    padding: '0px 16px',
    width: '100%',
    marginBottom: '60px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
    },
});

const ResidenceAboutPage: React.FC = () => {
    return (
        <Main>
            <PropertyDetailFields />
        </Main>
    );
};

export default ResidenceAboutPage;
