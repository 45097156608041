import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as CheckIcon } from '../check.svg';

const ThemedCheck = styled(CheckIcon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: theme?.colors?.primary || colors.primary,
        },
        '& path': {
            stroke: theme?.colors?.primary || colors.primary,
        },
        '& polyline': {
            stroke: theme?.colors?.primary || colors.primary,
        },
        '& line': {
            stroke: theme?.colors?.primary || colors.primary,
        },
    };
});
const Check: FC = ({ ...rest }) => {
    return <ThemedCheck {...rest} />;
};

export default Check;
