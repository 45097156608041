import React, { useCallback, useEffect } from 'react';
import store from './store';
import { observer } from 'mobx-react';
import Mask from './Mask';
import Modal from './Modal';
import Transition from './Transition';
import Cull from './Cull';

const onScroll = (evt: Event) => {
    evt.preventDefault();
    evt.returnValue = false;
    return false;
};

const scrollTo = (top: number) => {
    window.scrollTo({ top });
};

const disableScroll = () => {
    window.addEventListener('scroll', onScroll, { passive: false });
};

const enableScroll = () => {
    window.removeEventListener('scroll', onScroll);
};

const Context: React.FC = ({ children }) => {
    const { styles, state, scroll, start, end, modalSide, isAnimate } = store;

    const ref = useCallback((em: HTMLDivElement) => {
        if (!em) return;
        store.context = em;
    }, []);

    useEffect(() => {
        if (state === 'opening') scrollTo(0);
        if (state === 'closing') scrollTo(scroll);
        if (start) disableScroll();
        if (end) enableScroll();

        return () => void enableScroll();
    }, [styles, state]);

    return (
        <div ref={ref}>
            <Mask>
                <Cull active={!modalSide}>
                    <Modal />
                </Cull>
                <Cull active={modalSide}>{children}</Cull>
            </Mask>
            {isAnimate && <Transition />}
        </div>
    );
};

export default observer(Context);
