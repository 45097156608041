import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export function redirect(url: string) {
    history.push(url);
}

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, []);

    return null;
}

export default withRouter(ScrollToTop);
