import * as React from 'react';
import styled from 'lib/styled';
import { Link, useHistory } from 'react-router-dom';
import NotificationBell from '../../Layout/NotificationBell';
import { style } from '../../Shared/Style';
import { small } from '../../../lib/imageSize';
import { AvatarMenu } from '../../Menus/AvatarMenu';
import { ProfilePage } from '../../../areas/profile/ProfilePage';
import useGetSignedUrlId from '../../../hooks/useGetSignedUrlId';
import useGetProfile from '../../../hooks/useGetProfile';
import ProfileImageAvatars from '../../../areas/profile/Components/ProfileImageAvatars';
import { AvatarImpersonate } from '../../../areas/messages/shared/AvatarImpersonate';
import { LoginStore } from '../../../areas/login/LoginStore';
import BackArrow from '../../Buttons/BackArrow/BackArrow';

const Header = styled.div(({ to }: { to?: string }) => ({
    display: 'grid',
    width: '100%',
    padding: style.margin.m,
    gridTemplateColumns: to ? 'auto 1fr' : '1fr',
}));

const Items = styled.nav({
    display: 'flex',
    placeSelf: 'end',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100px',
});

export const HeaderStart: React.FC<{ to?: string }> = ({ to, ...rest }) => {
    const history = useHistory();

    const profileQuery = useGetProfile();

    const { data: signedUrl } = useGetSignedUrlId(profileQuery?.data?.profile_image_data?.id, small);

    return (
        <Header to={to} {...rest}>
            {to && (
                <Link style={{ placeSelf: 'start center' }} to={to}>
                    <BackArrow />
                </Link>
            )}
            <Items>
                <NotificationBell
                    color={'primary'}
                    to={{ pathname: '/notificationsCenter', state: history.location.pathname }}
                />

                {LoginStore.isImpersonated && (
                    <AvatarImpersonate signOut={LoginStore.logoutImpersonate} user={LoginStore.user} />
                )}
                <AvatarMenu
                    avatar={
                        <ProfileImageAvatars
                            signedUrl={signedUrl && signedUrl}
                            name={profileQuery.data?.name || profileQuery.data?.email}
                        />
                    }
                >
                    <ProfilePage />
                </AvatarMenu>
            </Items>
        </Header>
    );
};
export default HeaderStart;
