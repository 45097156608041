import { useState } from 'react';
export const useAttachments = () => {
    const [attachments, setAttachments] = useState<File[]>([]);

    const addAttachment = (attachment: FileList | null) => {
        if (!attachment) return;
        setAttachments((prev) => [...prev, attachment[0]]);
    };

    const handleInputAddAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        addAttachment(event.currentTarget.files);
    };

    const removeAttachment = (attachmentToFilter: File) => {
        const filteredAttachments = attachments.filter((attachment) => attachment !== attachmentToFilter);
        setAttachments(filteredAttachments);
    };

    const clearAllAttachments = () => {
        setAttachments([]);
    };

    return { attachments, addAttachment, removeAttachment, clearAllAttachments, handleInputAddAttachment };
};
