import styled from 'lib/styled';
import React, { ChangeEvent, FC } from 'react';
import AttachmentSvg from '@app/web/components/Icon/attachment.svg';
import { Colors } from '../../../Shared/Style/colors';

const Input = styled.input({
    display: 'none',
});

const InputLabel = styled.label({
    display: 'block',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundImage: `url(${AttachmentSvg})`,
    backgroundRepeat: 'no-repeat',
    width: '35px',
    height: '35px',
    cursor: 'pointer',
});

type Props = {
    className?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    accept?: string;
};

const AttachmentInput: FC<Props> = ({ className, onChange, accept }) => {
    return (
        <>
            <InputLabel className={className} htmlFor={'Attatchment'} />
            <Input id={'Attatchment'} onChange={onChange} type="file" accept={accept ?? 'image/jpeg, image/png'} />
        </>
    );
};

export default AttachmentInput;
