import Document from './Document';
import Avatar from './Avatar';
import Binder from './Binder';
import Calendar from './Calendar';
import CloseX from './CloseX';
import ColorCircleWithPlus from './ColorCircleWithPlus';
import CommentBubbleLines from './CommentBubbleLines';
import Contact from './Contact';
import Info from './Info';
import List from './List';
import Messages from './Messages';
import MoreVertical from './MoreVertical';
import NotificationBellIcon from './NotificationBellIcon';
import OptionChecked from './OptionsChecked';
import Check from './Check';
import OptionNotChecked from './OptionsNotChecked';
import Pong from './Pong';
import Property from './Property';
import RightArrow from './RightArrow';
import Search from './Search';
import Sofa from './Sofa';
import Pin from './Pin';
import PinFilled from './PinFilled';
import WarningSign from './WarningSign';
import Wash from './Wash';
import Boardmember from './BoardMember';
import LocationPin from './LocationPin';
import Camera from './Camera';
import TrashCan from './TrashCan';
import Download from './Download';
import Copy from './Copy';
import Chat from './Chat';
import Home from './Home';
import Tile from './Tile';
import User from './User';
import MegaPhone from './MegaPhone';
import ArrowRight from './ArrowRight';
import Warranty from './Warranty';
import Todo from './Todo';
import Ticket from './Ticket';
import Booking from './Booking';
import About from './About';
import Addons from './Addons';
import Contacts from './Contacts';
import SolarProduction from './SolarProduction';

const ThemeIcons = {
    Document,
    Avatar,
    Binder,
    Calendar,
    Chat,
    CloseX,
    ColorCircleWithPlus,
    CommentBubbleLines,
    Contact,
    Home,
    Info,
    List,
    PinFilled,
    Messages,
    MoreVertical,
    NotificationBellIcon,
    OptionChecked,
    OptionNotChecked,
    Pong,
    Property,
    RightArrow,
    Check,
    Search,
    Sofa,
    Tile,
    User,
    WarningSign,
    Wash,
    Boardmember,
    Pin,
    LocationPin,
    Camera,
    TrashCan,
    Download,
    Copy,
    MegaPhone,
    ArrowRight,
    Warranty,
    Todo,
    Ticket,
    Booking,
    About,
    Addons,
    Contacts,
    SolarProduction,
};
export default ThemeIcons;
