import lazyRetry from 'lib/lazyRetry';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const ThemeInterfacePage = lazy(() => lazyRetry(() => import('./ThemeInterfacePage')));

export const routes = [
    <Route key={'1'} exact path="/theme">
        <ThemeInterfacePage />
    </Route>,
];

export const navigation = [{ name: 'Feed', link: '/Feed' }];
