import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import ColorCircleWithPlus from '../../../components/Icon/ThemedIcons/ColorCircleWithPlus';

const CreateNewContactLink: FC = ({ ...rest }) => {
    return (
        <Link {...rest} to={`/contacts/add`}>
            <ColorCircleWithPlus />
        </Link>
    );
};

export default CreateNewContactLink;
