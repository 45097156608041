import UsersApi from '@app/api/public/UsersApi';
import { createValidator, required } from 'lib/validation';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

const forcedRules = createValidator({
    token: [required()],
    newPassword: [required()],
});

type Variables = { token: string; newPassword: string };

const confirmOrChangePassword = async (passwords: Variables, location: string) => {
    const validation = forcedRules.test(passwords);

    const errors = validation.errors;
    if (!validation.ok) return Promise.reject(errors);

    if (location === '/resetpassword') return await UsersApi.resetPassword(passwords.newPassword, passwords.token);

    return await UsersApi.setPasswordConfirm(passwords.newPassword, passwords.token);
};

const useConfirmOrChangePassword = () => {
    const location = useLocation();
    return useMutation((passwords: Variables) => confirmOrChangePassword(passwords, location.pathname));
};

export default useConfirmOrChangePassword;
