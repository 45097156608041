import styled from '@emotion/styled';
import { L } from '@app/shared/lib/i18n';
import React from 'react';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { MoreText } from '../../../components/MoreText/MoreText';
import { style } from '../../../components/Shared/Style';
import useGetResidenceDetails from '../hooks/useGetResidenceDetails';

const GridContainer = styled.div({
    display: 'grid',
    gap: '0 2rem',
    '@media screen and (min-width: 1100px)': {
        gridTemplateColumns: '1fr 1fr',
    },
});

const StyledMoreText = styled(MoreText)({
    padding: '1rem 0px',
});

const PropertyDetailFields = () => {
    const { data: property } = useGetResidenceDetails();
    const [isCollapsed, setIsCollapsed] = React.useState(true);

    return (
        <GridContainer>
            {property?.property_type && (
                <Label title={L('property_type')}>
                    <Input disabled value={L(property?.property_type) || ''} name="property_type" />
                </Label>
            )}
            {property?.name && (
                <Label title={L('property_name')}>
                    <Input disabled defaultValue={property?.name || ''} name="name" />
                </Label>
            )}

            {property?.attr.description && (
                <Label style={{ gridRow: 'span 2' }} title={L('property_description')}>
                    <StyledMoreText
                        color={style.colors.black}
                        textAlign={'left'}
                        onClick={() => setIsCollapsed((p) => !p)}
                        isCollapsed={isCollapsed}
                        text={property?.attr.description || ''}
                    />
                </Label>
            )}
            {property?.attr.apartment_number && (
                <Label title={L('apartment_number')}>
                    <Input disabled defaultValue={property?.attr.apartment_number || ''} name="id" />
                </Label>
            )}

            {property?.attr.apartment_number && (
                <Label style={{ alignSelf: 'end' }} title={L('property_number')}>
                    <Input disabled defaultValue={property?.attr.apartment_number || ''} name="attr.apartment_number" />
                </Label>
            )}
            {property?.attr.street && (
                <Label title={L('property_street')}>
                    <Input disabled defaultValue={property?.attr.street || ''} name="attr.street" />
                </Label>
            )}
            {property?.attr.postalcode && (
                <Label title={L('property_postal_code')}>
                    <Input disabled defaultValue={property?.attr.postalcode || ''} name="attr.postalcode" />
                </Label>
            )}
            {property?.attr.city && (
                <Label title={L('property_postal_city')}>
                    <Input disabled defaultValue={property?.attr.city || ''} name="attr.city" />
                </Label>
            )}
            {property?.attr.country && (
                <Label title={L('country')}>
                    <Input disabled defaultValue={property?.attr.country || ''} name="attr.country" />
                </Label>
            )}
            {property?.attr.commune && (
                <Label title={L('commune')}>
                    <Input disabled defaultValue={property?.attr.commune || ''} name="attr.commune" />
                </Label>
            )}
            {property?.attr.parish && (
                <Label title={L('property_parish')}>
                    <Input disabled defaultValue={property?.attr.parish || ''} name="attr.parish" />
                </Label>
            )}
            {property?.attr.property_term && (
                <Label title={L('property_designation')}>
                    <Input disabled defaultValue={property?.attr.property_term || ''} name="attr.property_term" />
                </Label>
            )}
            {property?.attr.property_area && (
                <Label title={L('property_area')}>
                    <Input disabled defaultValue={property?.attr.property_area || ''} name="attr.property_area" />
                </Label>
            )}
            {property?.attr.plot_area && (
                <Label title={L('property_plot_area')}>
                    <Input disabled defaultValue={property?.attr.plot_area || ''} name="attr.plot_area" />
                </Label>
            )}
            {property?.attr.built_year && (
                <Label title={L('property_built_year')}>
                    <Input disabled defaultValue={property?.attr.built_year || ''} name="attr.built_year" />
                </Label>
            )}
            {property?.attr?.rooms && (
                <Label title={L('property_room')}>
                    <Input disabled defaultValue={property?.attr.rooms || ''} name="attr.rooms" />
                </Label>
            )}
            {property?.attr?.floors && (
                <Label title={L('property_floors')}>
                    <Input disabled defaultValue={property?.attr.floors || ''} name="attr.floors" />
                </Label>
            )}
            {property?.attr?.bathrooms && (
                <Label title={L('property_wc')}>
                    <Input disabled defaultValue={property?.attr.bathrooms || ''} name="attr.bathrooms" />
                </Label>
            )}
            {property?.attr?.assess_value && (
                <Label title={L('property_assessed_value')}>
                    <Input disabled defaultValue={property?.attr.assess_value || ''} name="attr.assess_value" />
                </Label>
            )}
            {property?.attr?.heating && (
                <Label title={L('property_heating')}>
                    <Input disabled defaultValue={property?.attr.heating || ''} name="attr.heating" />
                </Label>
            )}

            {property?.attr?.stairwell && (
                <Label title={L('property_stairwell')}>
                    <Input disabled defaultValue={property?.attr.stairwell || ''} name="attr.stairwell" />
                </Label>
            )}
            {property?.attr?.house && (
                <Label title={L('property_house')}>
                    <Input disabled defaultValue={property?.attr.house || ''} name="attr.house" />
                </Label>
            )}
            {property?.attr?.floor && (
                <Label title={L('property_floor')}>
                    <Input disabled defaultValue={property?.attr.floor || ''} name="attr.floor" />
                </Label>
            )}
            {property?.attr.percentage_of_community && (
                <Label title={L('property_proportion')}>
                    <Input
                        disabled
                        defaultValue={property?.attr.percentage_of_community || ''}
                        name="attr.percentage_of_community"
                    />
                </Label>
            )}
            {property?.attr?.monthly_fee && (
                <Label title={L('property_monthly')}>
                    <Input disabled defaultValue={property?.attr.monthly_fee || ''} name="attr.monthly_fee" />
                </Label>
            )}
            {property?.attr?.access_date && (
                <Label title={L('property_access_date')}>
                    <Input
                        disabled
                        placeholder="YYYY-MM-DD"
                        defaultValue={property?.attr.access_date || ''}
                        name="attr.access_date"
                    />
                </Label>
            )}
        </GridContainer>
    );
};

export default PropertyDetailFields;
