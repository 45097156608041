import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import HeaderDesktopNew from './components/Header/Desktop/Header/HeaderDesktopNew';
import { MediaQuery } from './components/MediaQuery/MediaQuery';
import NewNotificationBanner from './components/NewNotification/NewNotificationBanner';
import { routes as Bookingv2 } from './areas/booking/v2booking/navigation';
import { routes as Feed } from './areas/feed/navigation';
import { routes as Housing } from './areas/housing/navigation';
import { routes as Board } from './areas/board/navigation';
import { routes as NotificationsCenter } from './areas/notificationsCenter/navigation';
import { routes as Contacts } from './areas/contacts/navigation';
import { routes as Profile } from './areas/profile/navigation';
import { routes as Documents } from './areas/documents/navigation';
import { routes as Residence } from './areas/residence/navigation';
import { routes as Addons } from './areas/addons/navigation';
import { routes as Binder } from './areas/binder/navigation';
import { routes as Tickets } from './areas/tickets/navigation';
import { routes as Todo } from './areas/todo/navigation';
import { routes as ThemeInterface } from './areas/themeInterface/navigation';
import { routes as SharedWith } from './areas/about/navigation';
import { routes as Solar } from './areas/solar/navigation';

const PrivateRoutes = () => {
    return (
        <>
            <NewNotificationBanner />
            <MediaQuery on="desktop">
                <Switch>
                    <Route path={'/projects/:projectId/solar'}>{null}</Route>
                    <Route path={'/'}>
                        <HeaderDesktopNew />
                    </Route>
                </Switch>
            </MediaQuery>

            <Switch>
                {Bookingv2}
                {Feed}
                {Housing}
                {Board}
                {Profile}
                {NotificationsCenter}
                {Contacts}
                {Documents}
                {Residence}
                {Addons}
                {Binder}
                {Tickets}
                {Todo}
                {ThemeInterface}
                {SharedWith}
                {Solar}
                <Route>
                    <Redirect to="/" />
                </Route>
            </Switch>
        </>
    );
};

export default PrivateRoutes;
