import React, { FC, ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';
import { useHeight } from 'hooks/useHeight';
import { ReactComponent as ArrowDown } from '../../../components/Icon/arrow-down-no-tail.svg';
import styled from 'lib/styled';
import { acordionTitle } from '../../../components/Shared/Style/typography';
import { useLocalStorage } from 'react-use';

const GroupingHeader = styled.div(({ theme }) => ({
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.colors.pageBackground,
    paddingBottom: '1.5rem',
    fontsize: '1rem',
    fontWeight: 600,
    '&:hover': {
        cursor: 'pointer',
    },
}));
const GroupingWrapper = styled.div(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.colors.pageBackground,
    overflow: 'hidden',

    marginBottom: '1rem',
    // boxShadow: `0px 4px 10px -10px rgba(0, 0, 0, 1)`,
}));

const HeaderWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    ...acordionTitle,
});

const AnimatedArrowDown = animated(ArrowDown);

const RowContainer = animated(styled.div({}));
type Props = {
    Title: ReactNode;
    intitalExpanded?: boolean;
    localStorageKey: string;
};

const Acordion: FC<Props> = ({ children, Title, intitalExpanded, localStorageKey, ...rest }) => {
    const [expanded, setExpanded] = useLocalStorage(localStorageKey, !!intitalExpanded);

    const [heightRef, height] = useHeight();

    const rowProps = useSpring({
        opacity: expanded ? 1 : 0,
        height: expanded ? height : 0,
    });

    const arrowProps = useSpring({
        transform: expanded ? 'rotateZ(0deg)' : 'rotateZ(-90deg)',
    });
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <GroupingWrapper {...rest}>
            <GroupingHeader onClick={toggleExpanded}>
                <HeaderWrapper>
                    {Title}
                    <AnimatedArrowDown style={arrowProps} />
                </HeaderWrapper>
            </GroupingHeader>
            <animated.div style={rowProps}>
                <RowContainer ref={heightRef} aria-expanded={expanded}>
                    {children}
                </RowContainer>
            </animated.div>
        </GroupingWrapper>
    );
};

export default Acordion;
