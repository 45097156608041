import React, { FormEvent, useReducer, useState } from 'react';
import styled from 'lib/styled';
import AttachedFiles from '../../../components/Forms/AttachedFiles';
import SendButton from '../../../components/Forms/SendButton';
import { RawHtml } from '../../../components/Layout/RawHtml';
import { toJS } from 'mobx';
import { useLocation, useParams } from 'react-router-dom';
import { L } from '@app/shared/lib/i18n';
import RenderSentTicket from '../../tickets/components/RenderSentTicket';
import { Content } from '../../../components/Layout/Content';
import PropertyName from '../../../components/Texts/PropertyName/PropertyName';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import H2 from '../../../components/Texts/H2';
import { useGetPropertyDetail } from '../../binder/hooks';
import { useSendTicket } from '../hooks/useSendTicket';
import { Params } from './CreateNewTicketLink';
import TicketForm from './TicketForm';
import { Loading } from '../../../components/Loading/Loading';
import useGetTicketType from '../hooks/useGetPropertyticketFeature';
import HeaderClean from '../../../components/Header/HeaderBackArrowAndHelp';
import useGetProjectDetailByPropertyId from 'hooks/useGetProjectDetailByPropertyId';
import { CreateTicket } from '@app/api/public/TicketsApi';
import EmptyState from '../../../components/EmptyState/EmptyState';

const StyledH2 = styled(H2)({
    textAlign: 'start',
    margin: '0px',
});

const OnlyBacknav = styled(HeaderClean)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'flex',
        padding: '0px 0px 1rem 0px',
    },
});

const SendReportContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '3rem 0 1rem 0',
});

const StyledPropertyName = styled(PropertyName)(() => ({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
}));

const StyledHTML = styled.div({
    marginTop: '1rem',
});

type Location = Params;

export type TicketAction =
    | {
          type: 'product' | 'space';
          value: number;
      }
    | {
          type: 'title' | 'description';
          value: string;
      };
const ticketReducer = (state: CreateTicket, action: TicketAction) => {
    switch (action.type) {
        case 'product':
            return { ...state, product_id: action.value };
        case 'space':
            return { ...state, space_id: action.value };
        case 'title':
            return { ...state, title: action.value };
        case 'description':
            return { ...state, description: action.value };
        default:
            return state;
    }
};

const CreateNewTicket = () => {
    const location = useLocation();
    const locationState = location.state as Location | undefined;
    const { propertyId } = useParams<{ propertyId: string }>();
    const [state, dispatch] = useReducer(ticketReducer, {
        title: '',
        description: '',
        product_id: locationState?.productId ? +locationState.productId : null,
        space_id: locationState?.spaceId ? +locationState.spaceId : null,
    });
    const residenceDetail = useGetPropertyDetail();
    const { data: projectDetail } = useGetProjectDetailByPropertyId(propertyId);
    const [attachments, setAttachments] = useState<File[]>([]);
    const { mutate: sendTicket, data, status: sendTicketStatus } = useSendTicket();

    const { data: ticketType, status: ticketTypeStatus } = useGetTicketType();

    if (ticketTypeStatus === 'loading') return <Loading />;

    if (ticketType === 'disabled')
        return (
            <>
                <OnlyBacknav />
                <Content>
                    <EmptyState>
                        <EmptyState.Title>{L('tickets_not_activated_title')}</EmptyState.Title>
                        <EmptyState.Description>
                            <p>{L('tickets_not_activated')}</p>
                        </EmptyState.Description>
                    </EmptyState>
                </Content>
            </>
        );

    if (ticketType === 'email' || ticketType === 'system') {
        const handleMalfunctionReportSubmit = (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            sendTicket({
                attachments,
                ticket: state,
            });
        };
        if (sendTicketStatus === 'success' && data) {
            return <RenderSentTicket ticket={data} />;
        }

        return (
            <>
                <OnlyBacknav />
                <Content>
                    <StyledH2>{L('ticket')}</StyledH2>
                    <StyledPropertyName>{residenceDetail.data?.name}</StyledPropertyName>

                    {projectDetail?.service_info && (
                        <StyledHTML>
                            {' '}
                            <RawHtml content={projectDetail.service_info} />
                        </StyledHTML>
                    )}
                    {
                        // boklok och hsb fix
                        ![2, 15].includes(projectDetail?.account_id || 0) && (
                            <form onSubmit={handleMalfunctionReportSubmit}>
                                <TicketForm
                                    state={state}
                                    dispatch={dispatch}
                                    setAttachments={setAttachments}
                                    attachments={attachments}
                                />
                                <AttachedFiles
                                    attachments={toJS(attachments)}
                                    onClose={(file) => {
                                        const files = attachments.filter((attachment) => attachment !== file);
                                        setAttachments([...files]);
                                    }}
                                />

                                <SendReportContainer>
                                    {sendTicketStatus === 'loading' ? (
                                        <SendButton disabled={true} label={L('loading')} />
                                    ) : (
                                        <SendButton label={L('send')} />
                                    )}
                                </SendReportContainer>
                            </form>
                        )
                    }
                </Content>
            </>
        );
    }

    return null;
};

export default CreateNewTicket;
