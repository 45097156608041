import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { colors } from '../Shared/Style/colors';
import { Button } from '../Buttons/Button';
import { ComponentProps } from 'react';
import { tablet } from '../MediaQuery/breakpoints';

const StyledNoProject = styled.div({
    padding: '50px',
    textAlign: 'center',
    minHeight: '436px',
    display: 'grid',
    alignContent: 'center',
});

const NoProjectTitle = styled.span({
    marginBottom: '2rem',
    fontSize: '1.25rem',
    [`@media screen and (min-width: ${tablet}px)`]: {
        fontSize: style.fontSize.gamma,
    },
});

const NoProjectDescription = styled.span({
    marginBottom: '1rem',
    fontSize: style.fontSize.epsilon,
    color: colors.secondary,
});

const EmptyState = (props: ComponentProps<'div'>) => {
    return <StyledNoProject {...props} />;
};

const Title: React.FC = ({ children, ...rest }) => {
    return <NoProjectTitle {...rest}>{children}</NoProjectTitle>;
};
const Description: React.FC = ({ children, ...rest }) => {
    return <NoProjectDescription {...rest}>{children}</NoProjectDescription>;
};
const EmptyStateButton: React.FC<React.ComponentProps<typeof Button>> = ({ children, ...rest }) => {
    return <Button {...rest}>{children}</Button>;
};

EmptyState.Title = Title;
EmptyState.Description = Description;
EmptyState.Button = EmptyStateButton;

export default EmptyState;
