import { ThemeType } from '@app/api/models/Theme';
import styled from '@emotion/styled';
import { L } from '@app/shared/lib/i18n';
import React from 'react';
import { useParams } from 'react-router-dom';
import { colors } from '../../components/Shared/Style/colors';
import SmallTab from '../../components/Tabs/SmallTab';
import TabList from '../../components/Tabs/TabList';
import { useGetPropertyDetail } from './hooks';

const StyledTablist = styled(TabList)({
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    flexWrap: 'nowrap',
    padding: `0px`,
    margin: `0px 0px 2rem 0px`,
});

const StyledSmallTabListItem = styled(SmallTab)(({ theme }: { theme: ThemeType }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '19px',
        color: theme?.colors?.tabInactive || colors.tabInactive,
        boxSizing: 'border-box',
        display: 'inline-block',
        margin: '0px',
        textDecoration: 'none',
        padding: '0px 0px 8px 0px',

        '&:hover': {
            color: theme?.colors?.tabActive || colors.tabActive,
        },
        '&.active': {
            color: theme?.colors?.tabActive || colors.tabActive,

            borderBottom: `1px solid ${theme?.colors?.tabActive || colors.tabActive}`,
        },
    };
});

interface Props {
    hasConstructionParts: boolean;
    hasSecurity: boolean;
}

const BinderTabs = (p: Props) => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const { data: property } = useGetPropertyDetail(propertyId);

    return (
        <StyledTablist>
            <StyledSmallTabListItem
                isActive={(match, location) => location.pathname === `/properties/${propertyId}/binder`}
                to={`/properties/${propertyId}/binder`}
            >
                {L('room', property?.project_type)}
            </StyledSmallTabListItem>
            <StyledSmallTabListItem
                isActive={(match, location) => location.pathname.includes('/document')}
                to={`/properties/${propertyId}/binder/document`}
            >
                {L('document', property?.project_type)}
            </StyledSmallTabListItem>
            {p.hasConstructionParts && (
                <StyledSmallTabListItem
                    isActive={(match, location) => location.search === '?area=component'}
                    to={`/properties/${propertyId}/binder?area=component`}
                >
                    {L('construction_parts', property?.project_type)}
                </StyledSmallTabListItem>
            )}
            {p.hasSecurity && (
                <StyledSmallTabListItem
                    isActive={(match, location) => location.search === '?area=security'}
                    to={`/properties/${propertyId}/binder?area=security`}
                >
                    {L('security', property?.project_type)}
                </StyledSmallTabListItem>
            )}
            <StyledSmallTabListItem
                isActive={(match, location) => location.pathname.includes('/about')}
                to={`/properties/${propertyId}/binder/about`}
            >
                {L('about', property?.project_type)}
            </StyledSmallTabListItem>
            <StyledSmallTabListItem
                isActive={(match, location) => location.pathname.includes('/shared-with')}
                to={`/properties/${propertyId}/binder/shared-with`}
            >
                {L('residence_shared_with', property?.project_type)}
            </StyledSmallTabListItem>
        </StyledTablist>
    );
};

export default BinderTabs;
