import { useQuery, useMutation, useQueryClient } from 'react-query';

/**
 * Profile useQuery Hooks
 */
import PushNotificationApi, { PushNotificationSetting } from '@app/api/public/PushNotificationApi';
import { L } from '@app/shared/lib/i18n';

const notificationsSettingsKeys = {
    all: ['notificationSettings'] as const,
    email: () => [...notificationsSettingsKeys.all, 'email'] as const,
    notification: () => [...notificationsSettingsKeys.all, 'notification'] as const,
};

export const useListEmailSettings = () =>
    useQuery([...notificationsSettingsKeys.email()], PushNotificationApi.listEmailSettings);

const listSettings = async () => {
    const settings = await PushNotificationApi.listSettings();
    settings?.sort((a, b) => {
        const aType = L(`push_notification_settings_${a.type}`);
        const bType = L(`push_notification_settings_${b.type}`);
        if (aType > bType) return 1;
        if (aType < bType) return -1;
        return 0;
    });
    return settings;
};
export const useListSettings = () => useQuery([...notificationsSettingsKeys.notification()], listSettings);

//switch the notification on/off
type SettinsKey = 'email' | 'notification';
const switchSiting = ({ id, value, key }: { id: string; value: boolean; key: SettinsKey }) => {
    if (key === 'email') {
        return PushNotificationApi.saveEmailSetting(id, !value);
    }
    return PushNotificationApi.saveSetting(id, !value);
};

export const useSwitchSetting = () => {
    const queryClient = useQueryClient();
    return useMutation(switchSiting, {
        onMutate: (variables) => {
            queryClient.cancelQueries([...notificationsSettingsKeys[variables.key]()]);
            const data = queryClient.getQueryData<PushNotificationSetting[]>([
                ...notificationsSettingsKeys[variables.key](),
            ]);
            if (!data) return;

            const optemisticData = [...data];
            const setting = optemisticData.find((setting) => setting.setting_id === variables.id);
            if (!setting) return;
            setting.value = !setting.value;
            queryClient.setQueryData([...notificationsSettingsKeys[variables.key]()], optemisticData);
        },
        //fetch the data again after switching the notification
        onSettled: (_data, _errors, variables) => {
            queryClient.refetchQueries([...notificationsSettingsKeys[variables.key]()]);
        },
    });
};
