import React from 'react';
import StyledSwitch from '../../../components/StyledSwitch/StyledSwitch';
import { useSwitchSetting } from '../hooks';

const PushNotificationSwitch = ({ id, value }: { id: string; value: boolean }) => {
    const { mutate, isLoading } = useSwitchSetting();

    //switch button
    const onSwitch = (id: string, value: boolean) => {
        if (isLoading) return;
        mutate({ id, value, key: 'notification' });
    };
    return <StyledSwitch disabled={isLoading} onClick={() => onSwitch(id, value)} checked={value} />;
};

export default PushNotificationSwitch;
