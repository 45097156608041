import React, { FC } from 'react';
import styled from 'lib/styled';
import Close from '@app/web/components/Close';
import { style } from '../../Shared/Style';

const StyledAttachemtPreview = styled.div(({ src }: { src: string }) => {
    return {
        backgroundImage: `url("${src}")`,
        backgroundPosition: 'center',
        height: '100%',
        width: '100%',
    };
});

const StyledImgContainer = styled.div({
    position: 'relative',
});

const ImageGrid = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 60px)',
    gridAutoRows: 60,
    gap: style.margin.l,
    marginTop: style.margin.m,
});

type props = {
    attachments: File[];
    onClose: (File: File) => void;
};
const AttachedFiles: FC<props> = ({ attachments, onClose, ...rest }) => {
    return (
        <ImageGrid {...rest}>
            {attachments?.map((attachment, index) => {
                const objectUrl = URL.createObjectURL(attachment);
                return (
                    <StyledImgContainer key={index}>
                        <StyledAttachemtPreview role="img" src={objectUrl} />
                        <Close
                            top={'-15px'}
                            right={'-15px'}
                            onClose={() => {
                                onClose(attachment);
                                URL.revokeObjectURL(objectUrl);
                            }}
                        />
                    </StyledImgContainer>
                );
            })}
        </ImageGrid>
    );
};

export default AttachedFiles;
