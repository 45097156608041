import React, { Fragment } from 'react';
import Feed from '../../../components/Feed';
import { Stylable } from '../../../types';
import { ITEMS_PER_PAGE, THRESHOLD } from '../lib/constants';
import { useOnMessage } from '@app/web/lib/hooks';
import { useQueryClient } from 'react-query';
import TicketThread from '../../../components/thread/ticketThread/TicketThread';
import Divider from '../../../components/Divider/Divider';
import styled from 'lib/styled';
import { ListTicket } from '@app/api/models/Tickets';
import { useParams } from 'react-router-dom';
import MessagesApi from '@app/api/public/MessagesApi';

const StyledDivider = styled(Divider)({
    marginBottom: '1rem',
    marginTop: '1rem',
});

const TicketsFeed: React.FC<Stylable> = ({ ...rest }) => {
    const queryClient = useQueryClient();
    useOnMessage(() => void queryClient.invalidateQueries('tickets'), []);
    const { propertyId } = useParams<{ propertyId: string }>();

    return (
        <nav {...rest}>
            <Feed
                threshold={THRESHOLD}
                cacheKey={['tickets', propertyId]}
                asyncFn={async ({ pageParam }) =>
                    await MessagesApi.listTicketThreads(+propertyId, pageParam, ITEMS_PER_PAGE)
                }
                emptyResponse={'no_tickets'}
            >
                {(item: ListTicket) => {
                    return (
                        <Fragment key={item.id}>
                            <TicketThread key={item.id} {...item} />
                            <StyledDivider width={100} />
                        </Fragment>
                    );
                }}
            </Feed>
        </nav>
    );
};

export default TicketsFeed;
