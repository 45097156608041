import React, { FC } from 'react';
import { Colors } from '../../Shared/Style/colors';
import H1 from '../../Texts/H1';
import Hero from '../Hero';
import styled from 'lib/styled';
import HeroNav from '../../Navbars/HeroNav/HeroNav';

export type Props = {
    url: string | undefined;
    title?: string;
    color?: Colors;
    className?: string;
    nav?: boolean;
    gradient?: boolean;
    to?: string;
};

const H1StyledAsH2 = styled(H1)({
    alignSelf: 'end',
    margin: '0px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '100%',
    marginBottom: '0.75rem',
    letterSpacing: '0.41px',
});

const StyledNav = styled(HeroNav)({});

const TopContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'start',
    alignItems: 'flex-start',
});

const Container = styled.div({
    width: '100%',
    display: 'grid',
});

const WithTitle: FC<Props> = ({ to, children, title, color = 'white', nav, gradient = true, ...rest }) => {
    return (
        <Hero gradient={gradient} {...rest}>
            <Container>
                <TopContainer>
                    {nav && <StyledNav to={to} />}
                    {children}
                </TopContainer>
                <H1StyledAsH2 color={color}>{title}</H1StyledAsH2>
            </Container>
        </Hero>
    );
};

export default WithTitle;
