import React, { FC } from 'react';
import styled from 'lib/styled';
import { Link } from 'react-router-dom';
import NoticeIcon from '../components/NoticeIcon';
import NoticeTitle from '../components/NoticeTitle';
import NoticeDate from '../components/NoticeDate';
import NoticeLabel from '../components/NoticeLabel';
import { AlertNotification, TodoNotification } from '../../../../api/models/Notifications';
import { daysJsDateFromNow } from '../../../lib/time';
import NoticeDescription from '../components/NoticeDescription';
import WarningSign from '../../Icon/ThemedIcons/WarningSign';

const Border = styled.div(({ theme }) => {
    return {
        backgroundColor: theme.colors.alertBackground,
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        display: 'grid',
        whiteSpace: 'pre-line',
        gridTemplateColumns: 'min-content auto min-content',
        gridTemplateAreas: `
    "icon title label"
    "icon description label"
    `,
        columnGap: '1rem',
        rowGap: '0.5rem',
    };
});

const TodoNotice: FC<AlertNotification & { isCollapsed: boolean }> = ({ title, body, isCollapsed, link, ...rest }) => {
    return (
        <Link to={link ?? `/`}>
            <Border {...rest}>
                <NoticeIcon>
                    <WarningSign />
                </NoticeIcon>
                <NoticeTitle isCollapsed={isCollapsed} description={title} />
                <NoticeDescription description={body} isCollapsed={isCollapsed} />
                <NoticeLabel type={'Alert'} />
            </Border>
        </Link>
    );
};

export default TodoNotice;
