import React, { FC } from 'react';
import { Ul } from '../../../components/List';
import useGetResidenceSharedWith from '../hooks/useGetResidenceSharedWith';
import SharedWithItem from './SharedWithItem';

type Props = {
    searchValue: string;
};

const ResidenceSharedWithListHandler: FC<Props> = ({ searchValue, ...rest }) => {
    const contactsList = useGetResidenceSharedWith();
    return (
        <Ul {...rest}>
            {contactsList &&
                contactsList.data?.map((contactDetail) => {
                    return (
                        <SharedWithItem
                            key={contactDetail.id}
                            id={contactDetail.id}
                            profile_image_data={contactDetail.profile_image_data}
                            name={contactDetail.name}
                            email={contactDetail.email}
                            phone_number={contactDetail.phone_number}
                        />
                    );
                })}
        </Ul>
    );
};

export default ResidenceSharedWithListHandler;
