import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.tz.guess();

export function capitalize(value: string) {
    return value.toLowerCase().replace(/(^| )[a-z]/g, (v) => v.toUpperCase());
}

const datetimeFormat = new Intl.DateTimeFormat('sv-SE', {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
});

export function formatDatetime(date: string | Date) {
    let value = 0;
    if (typeof date === 'string') {
        value = Date.parse(date);
    } else {
        value = +date;
    }
    return datetimeFormat.format(value);
}

export function formatCurrency(amount: number) {
    return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK',
        minimumFractionDigits: 0,
    }).format(+amount);
}

export function formatDateIsoDayjs(date?: Date | string | undefined) {
    if (!dayjs(date).isValid()) return undefined;
    return dayjs(date || undefined)
        .utc()
        .tz()
        .format('YYYY-MM-DD');
}

export function formatInputDateTimeDayjs(date?: Date | string | undefined | dayjs.Dayjs) {
    if (!dayjs(date).isValid()) return '';
    return dayjs(date).tz().format('YYYY-MM-DDTHH:mm');
}

export function formatDateLocaleIsoDayjs(date?: Date | string | undefined) {
    if (!dayjs(date).isValid()) return undefined;
    return dayjs(date).utc().tz().format('YYYY-MM-DDTHH:mm');
}

export function formatDateToTimeOnly(date?: Date | string | undefined) {
    if (!dayjs(date).isValid()) return undefined;
    return dayjs(date).utc().tz().format('HH:mm');
}

export function addTimezone(date?: Date | string | undefined) {
    if (!dayjs(date).isValid()) return undefined;
    return dayjs(date).utc().tz().format();
}

export function fromNow(date?: Date | string | undefined) {
    if (!dayjs(date).isValid()) return undefined;
    return dayjs(date).utc().tz().fromNow();
}
