import React, { ChangeEvent } from 'react';
import styled from 'lib/styled';
import { style } from '@app/web/components/Shared/Style';
import { TicketAction } from './CreateTicket';
import { CreateTicket } from '@app/api/models/Tickets';
import { Loading } from '../../../components/Loading/Loading';
import { useListSpaces } from '../../binder/hooks';
import SpaceSelect from './SpaceSelect';
import ProductSelect from './ProductSelect';
import TextArea from '../../../components/Forms/TextArea';
import Label from '../../../components/Forms/Label';
import Input from '../../../components/Forms/Input';
import AttachmentInputs from '../../../components/Forms/AttachmentInputs';

const Title = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.m,
    marginTop: style.margin.l,
    display: 'flex',
    justifyContent: 'space-between',
});

type Props = {
    setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
    attachments: File[];
    state: CreateTicket;
    dispatch: React.Dispatch<TicketAction>;
};

const ReportForm = ({ setAttachments, attachments, state, dispatch }: Props) => {
    const spacesQueary = useListSpaces();

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (!files || !files.length) return;
        setAttachments([...attachments, files[0]]);
    };

    const spaceArray = spacesQueary.data;

    if (!spaceArray) {
        return <Loading />;
    }

    return (
        <>
            <Label title={'Titel'} name={'shortDescription'} />
            <Input
                name={'shortDescription'}
                value={state.title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch({ type: 'title', value: e.target.value })}
                required={true}
            />

            <SpaceSelect state={state} dispatch={dispatch} />
            {state.space_id && <ProductSelect state={state} dispatch={dispatch} />}

            <Label title={'Beskriv felet'} name={'descriptionOfMalfunction'} />
            <TextArea
                name={'descriptionOfMalfunction'}
                value={state.description}
                onChange={(e) => dispatch({ type: 'description', value: e.target.value })}
                required={true}
            />

            <Title>Bifoga filer / bilder</Title>

            <AttachmentInputs onChange={handleFileInputChange} />
        </>
    );
};

export default ReportForm;
