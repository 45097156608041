import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../c-check.svg';

const ThemedOptionsChecked = styled(Icon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',
        // '& g': {
        //     fill: theme?.colors?.primary || colors.primary,
        // },
        '& path': {
            fill: theme?.colors?.primary || colors.primary,
        },
        '& path:last-of-type': {
            fill: theme?.colors?.white || colors.white,
        },
    };
});
const OptionsChecked: FC = ({ ...rest }) => {
    return <ThemedOptionsChecked {...rest} />;
};

export default OptionsChecked;
