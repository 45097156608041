import React, { FC } from 'react';
import styled from 'lib/styled';
import { clickFlash } from '@app/shared/lib/cssAnimations';
import { HTMLAttributes } from 'react';

const IconWrapperButton = styled.div({
    height: '32px',
    width: '32xp',
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    '&:active': clickFlash,
});

const IconWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
    return (
        <IconWrapperButton role="button" {...rest}>
            {children}
        </IconWrapperButton>
    );
};

export default IconWrapper;
