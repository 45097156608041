import * as DocumentModel from '../models/Documents';
import * as AWSS3 from '../lib/aws/aws-s3';
import { rpc, File } from 'koa-rpc-call';
export { IPageOf } from '../lib/db';
export {
    DocumentList,
    DocumentDetail,
    ProductDocumentDetail,
    DocumentTypeFilter,
    DocumentsFilter,
} from '../models/Documents';
import { BaseApi } from '../lib/BaseApi';
import { getLoginContext } from '../models/Users';
import * as BindersModel from '../models/Binders';
import db from '../lib/db';
import SQL from 'sql-template-strings';
import { z } from 'zod';
import loglevel from 'loglevel';
import { getLoglevelFormEnv } from '../utils/getLogLevelFromEnv';
import { getEntityDocument, getEntityDocuments } from '../models/Documents';

loglevel.setLevel(getLoglevelFormEnv());

const hasPermissionToEditEntity = async ({ userId, entityId }: { userId: number; entityId: number }) => {
    try {
        const getPropertyFromEntity = await db.first<{ property_id: number }>(SQL`
        select property_id from entities where id = ${entityId}
        `);
        await getLoginContext(userId, getPropertyFromEntity.property_id);
        return true;
    } catch (err) {
        loglevel.error('uploadFileToEntity permission error', err);
        return false;
    }
};

const uploadEntitySchema = z.object({
    file: z.instanceof(File),
    entityId: z.number(),
    name: z.string(),
    documentType: z.union([z.literal(1), z.literal(2)]),
});

const getEntitySchema = z.object({
    entityId: z.number(),
    documentType: z.union([z.literal(1), z.literal(2)]),
});

const removeEntityDocumentSchema = z.object({
    entityId: z.number(),
    documentType: z.union([z.literal(1), z.literal(2)]),
});
class DocumentsApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async getlist(ids: string[], page?: number) {
        const documents = await DocumentModel.list(ids, page);
        if (documents && documents.length > 0) {
            for (let i = 0; i < documents.length; i++) {
                const m = documents[i].document_data.medium;
                if (m) documents[i].signed_url = await AWSS3.getUrl(m.id);
                if (documents[i].document_data.original)
                    documents[i].signed_url = await AWSS3.getUrl(documents[i].document_data.original.id);
            }
        }
        return documents;
    }

    @rpc({ httpMethod: 'GET' })
    async getByPropertyId(propertyId: number, filter?: DocumentModel.DocumentTypeFilter, page?: number) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            const documents = await DocumentModel.listByProperty(+context.property_id, filter, page);
            if (documents && documents.length > 0) {
                for (let i = 0; i < documents.length; i++) {
                    const m = documents[i].document_data.medium;
                    if (m) documents[i].signed_url = await AWSS3.getUrl(m.id);
                    if (documents[i].document_data.original)
                        documents[i].signed_url = await AWSS3.getUrl(documents[i].document_data.original.id);
                }
            }
            return documents;
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async getlistByProductId(propertyId: number, product_id: number, page?: number) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            const docs = await DocumentModel.listByProductId(+context.property_id, product_id, page);
            return docs;
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async getlistByPropertyId(propertyId: number, document_type?: number, page?: number) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            const docs = await DocumentModel.listByPropertyId(+context.property_id, document_type, page);
            return docs;
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async getListByEntityType(propertyId: number, entityType: number, spaceId?: number, page?: number) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            const docs = await DocumentModel.listByEntityType(+context.property_id, entityType, spaceId, page);
            return docs;
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async getbyid(id: number) {
        return await DocumentModel.id(id.toString());
    }

    @rpc({ httpMethod: 'GET' })
    async getBinderDocumentsById(id: number) {
        return await DocumentModel.getBinderProductDocumentsById(id);
    }

    @rpc()
    async get(id: number) {
        const document = await DocumentModel.id(id.toString());

        if (document) {
            if (document.document_data.medium)
                document.signed_url = await AWSS3.getUrl(document.document_data.medium.id);
            if (document.document_data.original)
                document.signed_url = await AWSS3.getUrl(document.document_data.original.id);
        }
        return document;
    }

    @rpc()
    async uploadToAwsStore(file: File) {
        return await DocumentModel.uploadToAwsStore(file);
    }

    @rpc()
    async uploadToPublic(files: File[]) {
        return await DocumentModel.uploadToPublic(files, this.property.name);
    }

    @rpc()
    async uploadFilesToStore(files: File[], documentInfo: DocumentModel.DocumentInfo) {
        const ids = await DocumentModel.uploadFilesToStore(files, this.user, documentInfo);
        return ids;
    }

    @rpc()
    async uploadToProject(files: File[], folder: string) {
        return await DocumentModel.uploadToProject(files, folder);
    }

    @rpc()
    async getMjmlTemplate(id: string) {
        return await DocumentModel.getMjmlTemplate(id);
    }

    @rpc()
    async getSignedUrlStore(fileName: string) {
        return AWSS3.getSignedUrlStore(fileName);
    }

    @rpc({ httpMethod: 'GET' })
    async getSignedUrl(fileName: string, attachment?: boolean) {
        const r = await AWSS3.getUrl(fileName, attachment);
        return r;
    }

    @rpc({ httpMethod: 'GET' })
    async getListPublicFiles() {
        return AWSS3.listPublicObjects(this.property.name);
    }

    @rpc()
    async getEntityFileByDocumentType(params: z.infer<typeof getEntitySchema>) {
        const parsedParams = getEntitySchema.safeParse(params);
        if (!parsedParams.success) {
            loglevel.error('getEntityFileByDocumentType invalid params', JSON.stringify(params, null, 4));
            return null;
        }
        const { entityId, documentType } = parsedParams.data;
        if (!(await hasPermissionToEditEntity({ userId: this.user.id, entityId }))) return null;
        const entityDoc = await getEntityDocument({ documentType, entityId });
        if (!entityDoc) {
            loglevel.error('getEntityFileByDocumentType no entityDoc', entityDoc);
            return null;
        }
        const urls = {
            thumbnail: await AWSS3.getSignedUrlStore(`200x200/${entityDoc.document_data.original.id}`),
            original: await AWSS3.getSignedUrlStore(entityDoc.document_data.original.id),
        };
        return urls;
    }

    @rpc()
    async uploadFileToEntity(params: z.infer<typeof uploadEntitySchema>) {
        const parsedParams = uploadEntitySchema.safeParse(params);
        if (!parsedParams.success) {
            loglevel.error('uploadFileToEntity invalid params', JSON.stringify(params, null, 4));
            return null;
        }
        const { file, entityId, name, documentType } = parsedParams.data;
        if (!(await hasPermissionToEditEntity({ userId: this.user.id, entityId }))) return null;

        const id = await DocumentModel.uploadToAwsStore(file);
        const doc: DocumentModel.DocumentDetail = DocumentModel.fileToDocumentDetail(file, id, 'store', {
            documentable_type: 'Entity',
            documentable_id: entityId,
            document_type: documentType,
            name,
        });
        const savedDoc = await DocumentModel.save(doc);

        const docs = await getEntityDocuments({ documentType, entityId });

        const oldDocs = docs.filter((doc) => doc.id !== savedDoc.id);
        const deleteOldDocs = oldDocs.map(async (oldNamePlate) => {
            return await DocumentModel.deleteFromAWS(oldNamePlate.id);
        });
        await Promise.all(deleteOldDocs);

        return savedDoc;
    }

    @rpc()
    async removeDocumentFromEntity(params: z.infer<typeof removeEntityDocumentSchema>) {
        const parsedParams = removeEntityDocumentSchema.safeParse(params);
        if (!parsedParams.success) {
            loglevel.error('removeDocumentFromEntity invalid params', JSON.stringify(params, null, 4));
            return null;
        }
        const { entityId, documentType } = parsedParams.data;
        if (!(await hasPermissionToEditEntity({ userId: this.user.id, entityId }))) return null;
        const documents = await getEntityDocuments({ documentType, entityId });
        const deletDocuments = documents.map(async (document) => {
            return await DocumentModel.deleteFromAWS(document.id);
        });
        return await Promise.all(deletDocuments);
    }

    @rpc()
    async uploadHero(file: File, propertyId: number, entityId: number) {
        return DocumentModel.uploadFilesToStore([file], this.user, {
            documentable_type: 'Entity',
            documentable_id: entityId,
            document_type: 0,
        });
    }

    @rpc()
    async uploadDocument(file: File, propertyId: number, entityId: number) {
        return DocumentModel.uploadFilesToStore([file], this.user, {
            documentable_type: 'Entity',
            documentable_id: entityId,
            document_type: 6,
        });
    }

    @rpc()
    async deleteDocument(id: number) {
        return await DocumentModel.deleteFromAWS(id);
    }
    @rpc()
    async deleteDocumentFromProduct(id: string, propertyId: number, productId: string) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            const productDetail = await BindersModel.getProductDetail(+productId, context.property_id);
            const doc = productDetail?.documents?.find((doc) => +doc.id === +id);
            if (doc) {
                return DocumentModel.deleteFromAWS(+doc.id);
            }
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async listContentDocuments(propertyId: number, type?: DocumentModel.ContentDocumentType) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return DocumentModel.listContentDocuments(propertyId, type);
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async listDocs(propertyId: number) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return DocumentModel.listDocs(propertyId);
        }
        return null;
    }
}

export default new DocumentsApi();
