import React, { ComponentProps, ReactNode } from 'react';
import styled from '@emotion/styled';
import * as Dialog from '@radix-ui/react-dialog';

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
    '&:focus': {
        outline: 'none',
    },
});

type Props = {
    children?: ReactNode;
};

export default function RadixDialog({ children, ...rest }: Props & ComponentProps<typeof Dialog.Root>) {
    return (
        <Dialog.Root {...rest}>
            <Dialog.Portal>
                <Overlay>
                    <Content>{children}</Content>
                </Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    );
}
