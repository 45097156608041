import * as React from 'react';
import styled from 'lib/styled';
import { User } from '@app/api/models/Users';
import { style } from '../../../components/Shared/Style';
import { Icon } from '../../../components/Icon/Icon';
import { Button } from '../../../components/Buttons/Button';
import { L } from '@app/shared/lib/i18n';

const Container = styled.div({
    flexDirection: 'column',
    position: 'fixed',
    right: '20px',
    top: '20px',
    backgroundColor: 'transparent',
    borderRadius: style.roundCornerSize.small,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 99,
});

const Text = styled.div({
    fontWeight: style.fontWeight.thin,
    color: style.colors.black,
    width: '100%',
    textAlign: 'center',
});

const Name = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
});

interface Props {
    user: User;
    signOut: () => void;
}

export const AvatarImpersonate = (props: Props) => {
    return (
        <Container>
            <Name>
                <Text>{props.user.name || props.user.email}</Text>
                <Icon.Impersonate onClick={props.signOut} />
            </Name>
            <Button onClick={props.signOut} variant={'impersonate'}>
                {L('stop_impersonate')}
            </Button>
        </Container>
    );
};
