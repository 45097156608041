import PushNotificationApi from '@app/api/public/PushNotificationApi';
import UsersApi from '@app/api/public/UsersApi';
import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';
import getFirebaseConfig from './getConfigJson';

const FbConfig = getFirebaseConfig();

const firebaseConfig = {
    apiKey: FbConfig ? FbConfig.apiKey : 'AIzaSyBIQ1OYaqx3yMuN9YxlxWv3a7eyqSQwN_Y',
    authDomain: FbConfig ? FbConfig.authDomain : 'ourliving-develop.firebaseapp.com',
    projectId: FbConfig ? FbConfig.projectId : 'ourliving-develop',
    storageBucket: FbConfig ? FbConfig.storageBucket : 'ourliving-develop.appspot.com',
    messagingSenderId: FbConfig ? FbConfig.messagingSenderId : '945120490009',
    appId: FbConfig ? FbConfig.appId : '1:945120490009:web:834395c1e0b75a7938f088',
    measurementId: FbConfig ? FbConfig.measurementId : 'G-GVB64YDF1Z',
};

// init firebase
firebase.initializeApp(firebaseConfig);

let messaging: firebase.messaging.Messaging;
const analytics = firebase.analytics();

if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    console.log('messaging.onMessage');
    messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        // ...
    });
}

const getToken = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window && firebase.messaging.isSupported()) {
        try {
            const currentToken = await messaging?.getToken({
                vapidKey:
                    FbConfig?.vapidKey ||
                    'BKbYBN7OyaDaXzJVwbCW5isA1iBWD0cpv6A3dXsEo2s4SYCzu21Ijha9Bhv8pxPldR9mu8hX-EGyl4DGg4PUShU',
            });
            if (!currentToken) throw new Error('No token');
            const loggedIn = await UsersApi.verifyLogin();
            if (!loggedIn) return;
            await PushNotificationApi.saveToken(currentToken, 'browser');
        } catch (err) {
            console.log('An error occurred while retrieving token. ', err);
        }
    }
};

export const requestPermission = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        const permisson = await Notification.requestPermission();
        if (permisson === 'granted') {
            console.log('Permission granted');
            await getToken();
        }
    }
};
export { firebase as default, getToken, analytics };
