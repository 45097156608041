import styled from 'lib/styled';
import React from 'react';
import Header from '@app/web/components/Header/HeaderOnlyBackArrow';
import TicketStatusContent from './TicketStatusContent';
import StickyBarContent from '../../../components/StickyBar/StickyBarContent';
import { FixedBar } from '../../../components/StickyBar/FixedBar';
import { PageBackground } from '../../../components/Layout/PageBackground';
import { useParams } from 'react-router-dom';
import { desktop } from '../../../components/MediaQuery/breakpoints';

const StyledPageBackground = styled(PageBackground)({
    display: 'block',
});

const MobileOnly = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});
const OnlyBacknav = styled(Header)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'flex',
        padding: '0px 0px 1rem 0px',
    },
});

const TicketDetail = () => {
    const { propertyId, ticketId } = useParams<{ propertyId: string; ticketId: string }>();

    return (
        <StyledPageBackground>
            <OnlyBacknav to={`/properties/${propertyId}/tickets/${ticketId}`} />
            <TicketStatusContent />
            <MobileOnly>
                <FixedBar>
                    <StickyBarContent />
                </FixedBar>
            </MobileOnly>
        </StyledPageBackground>
    );
};

export default TicketDetail;
