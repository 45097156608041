import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../ic_more_vert_18px.svg';

const ThemedMoreVertical = styled(Icon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',
        '& path': {
            stroke: theme?.colors?.icons || colors.icons,
            fill: theme?.colors?.icons || colors.icons,
        },
    };
});
const MoreVertical: FC = ({ ...rest }) => {
    return <ThemedMoreVertical {...rest} />;
};

export default MoreVertical;
