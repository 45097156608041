import * as Theme from '../models/Theme';
import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import { CompanyTheme } from '../models/Theme';

class ThemeApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async getTheme() {
        return await Theme.id(this.user?.id);
    }

    @rpc({ httpMethod: 'GET' })
    async getThemeById(id: number) {
        return await Theme.byCompanyid(id);
    }

    @rpc({ anonymous: true })
    async getThemeBySuburl() {
        const suburl = Theme.getSubUrl(this.httpContext);
        if (suburl === 'residence') {
            this.httpContext.status = 204;
            return null;
        }
        const allData = await Theme.byCompanyName(suburl);
        return allData?.data;
    }

    @rpc({ httpMethod: 'GET' })
    async getUserAccoutTheme() {
        return await Theme.byAccountTheme(this.user.id);
    }

    @rpc({ httpMethod: 'GET' })
    async getCompanies() {
        return await Theme.getCompanies();
    }

    @rpc({ httpMethod: 'GET' })
    async saveTheme(theme: Omit<CompanyTheme, 'uppdated_at'>) {
        return await Theme.saveTheme(theme);
    }
}

export default new ThemeApi();
