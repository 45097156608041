import React, { FC } from 'react';
import styled from 'lib/styled';

type Props = {
    description: string;
    isCollapsed?: boolean;
    className?: string;
};

const Title = styled.p({
    fontSize: '13px',
    fontWeight: 600,
    gridArea: 'title',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    placeSelf: 'start',
    whiteSpace: 'nowrap',
    margin: '0px',
    textDecoration: 'none',
});

const maxTextLength = 75;

const NoticeTitle: FC<Props> = ({ description, ...rest }) => {
    const partialText =
        description?.length > maxTextLength ? `${description.substr(0, maxTextLength)}... ` : description;
    return <Title>{partialText}</Title>;
};

export default NoticeTitle;
