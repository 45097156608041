import { Dimension } from '@app/api/lib/resize';
import { ContentInformationDocumentDetail, DocumentData } from '@app/api/public/ContentInformationApi';
import DocumentsApi, { DocumentDetail } from '@app/api/public/DocumentsApi';
import { useQuery } from 'react-query';
import { ContentDocumentDetail } from '../../../api/models/Documents';

const useGetDocumentUrl = () => {
    const getSignedUrl = (document: DocumentDetail | undefined, demension?: Dimension) => {
        if (!document) {
            return useQuery(['url'], () => {
                return;
            });
        }
        return useQuery(['url', `${demension ? demension + '/' : ''}${document?.document_data.original?.id}`], () => {
            return DocumentsApi.getSignedUrlStore(
                `${demension ? demension + '/' : ''}${document?.document_data.original?.id}`
            );
        });
    };

    const getProfileImage = (document: DocumentDetail['document_data'], demension?: Dimension) => {
        if (!document?.original?.id) {
            return useQuery(['profileImage'], () => {
                return undefined;
            });
        }
        return useQuery(['profileImage', `${demension ? demension + '/' : ''}${document?.original?.id}`], () => {
            return DocumentsApi.getSignedUrlStore(`${demension ? demension + '/' : ''}${document?.original?.id}`);
        });
    };

    const getSignedUrlData = (
        document: { original: DocumentData; medium: DocumentData } | undefined,
        demension?: Dimension
    ) => {
        if (!document) {
            return useQuery(['url'], () => {
                return;
            });
        }
        return useQuery(['url', `${demension ? demension + '/' : ''}${document?.original?.id}`], () => {
            return DocumentsApi.getSignedUrlStore(`${demension ? demension + '/' : ''}${document?.original?.id}`);
        });
    };

    const getSignedUrlFromContentDocument = (document: ContentDocumentDetail, demension?: Dimension) => {
        return useQuery(['document', `${demension ? demension + '/' : ''}${document?.original?.id}`], () => {
            return DocumentsApi.getSignedUrlStore(`${demension ? demension + '/' : ''}${document?.original?.id}`);
        });
    };

    const getSignedUrlDocument = (document: DocumentDetail | undefined, dimension?: Dimension) => {
        return useQuery(
            ['document', `${dimension ? dimension + '/' : ''}${document?.document_data.original?.id}`],
            () => {
                return DocumentsApi.getSignedUrlStore(
                    `${dimension ? dimension + '/' : ''}${document?.document_data.original?.id}`
                );
            },
            { enabled: !!document }
        );
    };

    const getSignedUrls = async (document: DocumentDetail[], demension?: Dimension) => {
        const signedUrls: string[] = [];
        await Promise.all(
            document.map(async (r) => {
                const url = await DocumentsApi.getSignedUrlStore(
                    `${demension ? demension + '/' : ''}${r.document_data?.original?.id}`
                );
                if (url) signedUrls.push(url);
            })
        );
        return signedUrls;
    };

    const useGetSignedUrls = (documents: DocumentDetail[], dimension?: Dimension) => {
        return useQuery(['documents', [documents], dimension], () => getSignedUrls(documents, dimension));
    };

    const getSignedUrlsById = async (documentIds: string[], demension?: Dimension) => {
        const signedUrls: string[] = [];
        await Promise.all(
            documentIds?.map(async (id: string) => {
                const url = await DocumentsApi.getSignedUrlStore(`${demension ? demension + '/' : ''}${id}`);
                if (url) signedUrls.push(url);
            }) || []
        );
        return signedUrls;
    };

    const useGetSignedUrlsById = (documentIds: string[], demension?: Dimension) => {
        return useQuery(['documents', [documentIds], demension], () => getSignedUrlsById(documentIds, demension));
    };

    const getSignedDocuments = async (document: ContentInformationDocumentDetail[], demension?: Dimension) => {
        const signedDocuments: ContentInformationDocumentDetail[] = [];
        await Promise.all(
            document.map(async (r) => {
                const url = await DocumentsApi.getSignedUrlStore(
                    `${demension ? demension + '/' : ''}${r.document_data?.original?.id}`
                );
                r.signed_url = url;
                signedDocuments.push(r);
            })
        );
        return signedDocuments;
    };

    return {
        getSignedUrl,
        getSignedUrls,
        getProfileImage,
        getSignedUrlFromContentDocument,
        getSignedUrlDocument,
        getSignedUrlData,
        useGetSignedUrlsById,
        useGetSignedUrls,
        getSignedDocuments,
    };
};

export default useGetDocumentUrl;
