import React, { FC } from 'react';
import styled from 'lib/styled';
import ThemedIcons from '@app/web/components/Icon/ThemedIcons';

type Props = {
    className?: string;
};

const IconDiv = styled.div(({ theme }) => {
    return {
        cursor: 'pointer',
        gridArea: 'icon',
        placeSelf: 'center',
        '& span': {
            color: theme.colors.textColor2,
        },
        svg: {
            height: '24px',
            width: '24px',
        },
    };
});

const NoticeIcon: FC<Props> = ({ children }) => {
    return <IconDiv>{children}</IconDiv>;
};

export default NoticeIcon;
