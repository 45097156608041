import React from 'react';
import styled from 'lib/styled';
import { style } from '../../../Shared/Style';
import { L } from '@app/shared/lib/i18n';
import { ThemeType } from '@app/api/models/Theme';
import HeaderMenuResidence from './HeaderMenuResidence';
import SmallTab from '../../../Tabs/SmallTab';

const NavItem = styled(SmallTab)(({ theme }: { theme: ThemeType }) => {
    return {
        margin: '0px',
        padding: '8px 0px',
        color: theme.colors.menuTabInactive,
        fontSize: style.fontSize.epsilon,
    };
});

function HeaderMenuList() {
    return (
        <>
            <NavItem exact to="/">
                {L('start')}
            </NavItem>
            <NavItem exact to="/properties">
                {L('residence')}
            </NavItem>
            <NavItem to="/contacts">{L('contacts')}</NavItem>
        </>
    );
}

export default HeaderMenuList;
