import { ProjectType } from '@app/api/public/ProjectsApi';
import { solar, smallHouse, office, housingSociety, apartmentBlock } from './i18nProjectTypes';

export const stringData = {
    yes: 'Ja',
    no: 'Nej',
    no_messages: 'Det finns inga meddelanden',
    password_not_matched: 'Lösenordet matchar inte',
    ticket_email_description: 'Din felanmälan är skickad till',
    ticket_email_response_info: 'Svar på din felanmälan kommer skickas till din registrerade mail innom 48 timmar',
    ticket_ol_response_info: 'Vi kommer svara på din felanmälan så snart som möjligt!',
    ticket_email_attachments: 'Bifogade bilder / filer',
    ticket_sent_title: 'Din felanmälan är skickad',
    binder_list_room: 'Alla material och produkter',
    binder_add_room_description: 'En kort beskrivning av rummet',
    binder_add_room: 'Lägg till rum',
    binder_add_space: 'Lägg till ett nytt rum i din bopärm',
    binder_add_inventory_material: 'Lägg till ny produkt i din bopärm',
    binder_add_component: 'Lägg till ny byggnadsdel i din bopärm',
    binder_add_security: 'Lägg till ny säkerhet & teknik i din bopärm',
    binder_upload_img: 'Ladda upp bild',
    binder_edit_room: 'Redigera rum',
    binder_add_new_room: 'Lägg till ett nytt rum i din bopärm',
    binder_add_room_name: 'Fyll i rummets namn',
    binder_add_product_description: 'En kort beskrivning av produkten',
    binder_edit_product: 'Redigera produkt',
    binder_edit: 'Ändra',
    binder_add_product: 'Lägg till en ny produkt i din bopärm',
    add_document: 'Lägg till dokument',
    today: 'I dag',
    comment: 'Kommentar',
    event_sign_success: 'Du är nu anmäld till',
    no_tickets: 'Det finns inga pågående felanmälningar, skapa en ny felanmälan med plusknappen',
    create_new_ticket: 'Gör en ny felanmälan',
    todo_expired: 'förfallit,',
    todo_expired_title: 'Förfallit',
    todo_completed_title: 'Slutfört',
    todo_notify_at: 'Påminn mig',
    todo_expires: 'förfaller',
    todo_expired_yesterday: 'förfallit, i går',
    todo_expires_tomorrow: 'förfaller i morgon',
    binder_add_new_product: 'Lägg till en ny produkt',
    binder_add_product_name: 'Fyll i produktens namn',
    binder_list_building: 'Alla utrymmen',
    binder_list_security: 'All säkerhet & teknik',
    binder_list_construction_parts: 'Alla byggnadsdelar',
    binder_product_edit_name: 'Fyll i produktens visnings namn',
    binder_product_edit_product: 'Fyll i produktens hela namn',
    binder_product_edit_description: 'Fyll i en beskrivning av produkten',
    binder_product_edit_product_color: 'Fyll i produktens färgkod',
    binder_product_edit_brand: 'Fyll i produktens märke',
    binder_product_edit_article_no: 'Fyll i produktens artikelnummer',
    binder_product_edit_rsk_no: 'Fyll i produktens RSK-nummer',
    binder_product_edit_e_no: 'Fyll i produktens E-nummer',
    binder_product_edit_serial_number: 'Fyll i produktens serienummer',
    binder_product_edit_gtin_ean: 'Fyll i produktens GTIN/EAN',
    binder_product_edit_product_url: 'Fyll i en länk till produkten',
    binder_product_edit_extra_information: 'Fyll i extra enformation om produkten',
    binder_product_edit_service_advice: 'Fyll i skötselråd för produkten',
    binder_product_edit_purchase_store: 'Fyll i vart produkten är köpt',
    binder_product_edit_purchase_price: 'Fyll i produktens inköpspris',
    warranty_end_date: 'Slutdatum för garanti',
    extra_information: 'Extra information',
    purchase_price: 'inköpspris',
    product: 'produkt',
    alert: 'Viktigt meddelande',
    status_new: 'Ny',
    status_done: 'Klar',
    status_inprogress: 'Pågående',
    status_todo: 'Att göra',
    search: 'Sök',
    todo: 'Att göra',
    calendar: 'Event',
    document: 'Dokument',
    message: 'Meddelande',
    profile_image: 'Profilbild',
    upload_image: 'Ladda upp',
    remove_profile_image_success: 'Profilbild borttagen',
    remove_profile_image_error: 'Det gick inte att ta bort profilbild',
    repeat: 'Upprepa',
    upload: 'Ladda upp',
    ticket_new: 'Ny',
    ticket_priority_0: 'Critical',
    ticket_priority_1: 'High',
    ticket_priority_2: 'Medium',
    ticket_priority_3: 'Low',
    ticket_status_0: 'In Progress',
    ticket_status_1: 'Closed',
    addons_price: 'Summa inredningsval:',
    addons_total_price: 'Totalt inredningsval:',
    addons_how_to: 'Så här gör du Inredningsval',
    optional: 'Tillval',
    standard: 'Standard',
    name: 'Namn',
    laundry_room: 'Tvättstuga',
    price: 'Pris',
    addon_type: 'Inredningsvalstyp',
    addon_confirm_addons: 'Ladda ner orderbekräftelse (PDF)',
    download: 'Ladda ner',
    slot_blocked: 'Max antal bokningar uppnådda',
    picture: 'Bild',
    binder: 'Bopärm',
    booking_closed: 'Bokning stängd',
    booking: 'Bokning',
    my_account: 'Mitt konto',
    change_password: 'Byt lösenord',
    change_password_success: 'Lösenordet är bytt',
    change_password_error: 'Kunde inte byta lösenord försök igen',
    my_contacts: 'Mina kontakter',
    all_contacts: 'Övriga kontakter',
    contact_number: 'Tel',
    contact_email: 'Email',
    help_support: 'Hjälp & Support',
    my_profile: 'Min profil',
    profile: 'Profil',
    notifications: 'Notiser',
    my_notifications: 'Mina Notiser',
    no_notifications: 'Du har inga notiser',
    show_all_notifications: 'Visa alla notiser',
    event_sign_error: 'Eventet är stängt för amälan',
    log_out: 'Logga ut',
    property_type: 'Typ av bostad',
    property_number: 'Lgh nr',
    property_street: 'Gatuadress',
    property_id: 'Bostadsid',
    property_postal_city: 'Postort',
    property_postal_code: 'Postnr',
    property_parish: 'Församling',
    property_designation: 'Fastighetsbeteckning',
    property_area: 'Boarea',
    property_plot_area: 'Tomtarea',
    property_built_year: 'Byggnadsår',
    property_room: 'Antal rum',
    property_floors: 'Antal våningar',
    property_wc: 'Antal WC/Badrum',
    property_assessed_value: 'Taxeringsvärde',
    property_heating: 'Uppvärmning',
    property_stairwell: 'Trapphus',
    property_house: 'Hus',
    property_floor: 'Våningsplan',
    property_name: 'Namn på bostad',
    property_proportion: 'Andelstal',
    property_monthly: 'Månadsavgift',
    property_description: 'Beskrivning',
    property_access_date: 'Tillträdesdatum',
    property_member_count: 'Antal medlemmar',
    property_member_name: 'Namn på medlemmar',
    message_new_message_description: 'Mailnotis vid nya meddelanden',
    ticket_new_ticket_description: 'Mailnotis vid ny felanmälan',
    ticket_status_change_description: 'Mailnotis vid ny statusändring i felanmälan',
    notification_new_description: 'Mailnotis vid nytt generella händelser så som nyhetsblad och informationssidor',
    offer_new_description: 'Mailnotis vid offert',
    event_new_description: 'Mailnotis vid nytt event',
    no_rooms: 'Antal Rum',
    board: 'Styrelse',
    living_area: 'Boarea',
    monthly_price: 'Månadskostnad',
    send_request: 'Skicka ansökan',
    send: 'Skicka',
    loading: 'Laddar',
    description: 'Beskrivning',
    color: 'Färg',
    confirm: 'Bekräfta',
    sublist_finnish: 'Färdigställ val',
    addons_confirm: 'Sammanställning',
    addons_profile: 'Kunduppgifter',
    addons_order_confirmed: 'Bekräftelse',
    addons_checkout: 'Signera',
    confirmed_addons:
        'Dina val är nu bekräftade. Nedan ser du dina gjorda val och kan även ladda ner din orderbekräftelse.',
    confirmed_addons_no_link: 'Dina val är nu bekräftade. Nedan ser du dina gjorda val',
    select: 'Välj',
    templates: 'mallar',
    templates_select: 'Välj en färdig mall',
    create_todo: 'Spara att göra',
    unselect: 'Ta bort',
    remove: 'Ta bort',
    summarize: 'Summering',
    sublist: 'Flera val',
    days_left_addons: 'Dagar kvar för inredningsval',
    end_date_addons: 'Slutdatum',
    total: 'Totalt',
    start: 'Start',
    residence: 'Mitt OurLiving',
    construction_parts: 'Byggnadsdelar',
    construction: 'Byggande',
    security: 'Säkerhet & Teknik',
    messages: 'Inkorg',
    to_do: 'Att göra',
    residences: 'Boenden',
    my_ourliving: 'Mitt OurLiving',
    my_residences: 'Mina bostäder',
    my_residence: 'Mitt OurLiving',
    confirm_null: 'Du har inte gjort några val',
    residence_documents: 'SE DOKUMENT',
    documents: 'Dokument',
    residence_project: 'Projekt',
    residence_info: 'Visa projektinformation',
    residence_type: 'Bostadstyp',
    residence_living_space: 'Boarea',
    residence_no_rooms: 'Antal rum',
    room: 'Utrymme',
    residence_ready_date: 'Tillträdesdatum',
    apartment: 'Lägenhet',
    townhouse: 'Småhus',
    house: 'Hus',
    housing: 'Förening',
    housing_about: 'Om föreningen',
    solar_about: 'Om Solceller',
    about_property: 'Om bostaden',
    other: 'Övrigt',
    addons: 'Inredningsval',
    password: 'Lösenord',
    enter_password: 'Ange lösenord',
    forgot_password: 'Glömt lösenord',
    login_problems: 'Problem att logga in',
    reset_password_request: 'Återställning av lösenord',
    reset_password: 'Återställ lösenord',
    new_password: 'Nytt lösenord',
    create_password: 'Lösenord',
    confirm_account: 'Skapa konto',
    news: 'Nyheter',
    information: 'Information',
    confirm_password: 'Bekräfta lösenord',
    reset_password_not_matched: 'Lösenord matchar ej',
    reset_password_empty: 'Får ej vara tomt',
    reset_password_changed: 'Lösenordet är bytt. Vänligen logga in på nytt.',
    reset_password_changed_logged_in: 'Lösenordet är bytt.',
    reset_password_too_weak: 'Lösenordet är för svagt eller för kort. Minst 8 bokstäver och ej för enkelt',
    login: 'Logga in',
    logout: 'Logga ut',
    national_identification_number: 'Personnummer',
    addressline1: 'Adress 1',
    addressline2: 'Adress 2',
    address: 'Adress',
    profile_city: 'Postort',
    email: 'E-postadress',
    email_settings: 'Inställningar e-post',
    phone_number: 'Telefonnummer',
    no_recurrency: 'Ej återkommande',
    postal_code: 'Postnummer',
    save: 'Spara',
    saving: 'Sparar',
    saved: 'Sparad',
    cancel: 'Avbryt',
    country: 'Land',
    content_more_info: 'Mer info...',
    content_information: 'Information',
    content_news: 'Nyheter',
    no_end_date: 'Slutdatum passerat',
    approve_conditions: 'Godkänn villkor',
    approve_conditions_description: 'För slutföra inredningsval behöver du godkänna GDPR samt villkor',
    conditions: 'Villkor',
    accept: 'Godkänn',
    error_required: 'Måste anges',
    error_id_required: 'Ogiltigt personnummer',
    error_required_checkbox: 'Du måste godkänna villkoren',
    error_name: 'Vänligen fyll i fullständigt namn',
    error_phone_number: 'Vänligen fyll i telefonnummer',
    error_national_identification_number: 'Vänligen kontrollera personnummer',
    error_addressline1: 'Vänligen fyll i address',
    error_city: 'Vänligen fyll i postort',
    error_postal_code: 'Vänligen fyll i postkod',
    error_country: 'Vänligen fyll i Land',
    free: 'Fritt val',
    space: 'Utrymme',
    done: 'Klar',
    image: 'Bild',
    to_confirm_page: 'Sammanställning',
    addons_checkout_title: 'Slutför inredningsval',
    addons_checkout_title_done: 'Inredningsval signerat och klart!',
    addons_checkout_title_ongoing: 'Din signering är klar. Det är flera som måste signera',
    to_sign_page: 'Till signering',
    stop_impersonate: 'Sluta imitera',
    receipt: 'Kvitto',
    receipt_nameplate: 'Kvitton och typskyltar',
    ncs_color: 'NCS färgkod',
    wrong_colorcode: 'Fel färgkod',
    new_ticket: 'Felanmälan',
    nameplate: 'Typskylt',
    images: 'Bilder',
    navigate_to_bookings: 'Gå till bokningar',
    binder_description_tile: 'Digital bopärm med bruksanvisningar',
    more_info_link: 'Mer information kan du hitta ',
    more_info_link_action: 'här',
    contract: 'Avtal & Kontrakt',
    plan: 'Ritning',
    economy: 'Ekonomi & Försäkringar',
    protocols: 'Protokoll & Besiktningar',
    government: 'Myndighet',
    token_no_exists: 'Ogiltigt länk',
    token_no_valid_token: 'Ogiltigt länk',
    token_expired: 'Din länk har gått ut. Kontakta din BRF för en ny.',
    token_error: 'Något gick fel med din länk. Kontakta din BRF för en ny.',
    manual: 'Drift & Skötsel',
    my_property: 'Min bostad',
    my_solar: 'Min solanläggning',
    solar_props: 'Anläggningsdelar',
    my_personal: 'Mina kontakter',
    service: 'Service',
    warranty: 'Garanti',
    property_info: 'Bostadsinformation',
    residence_name: 'Bostadsnamn',
    certificate: 'Intyg',
    sublist_optional: 'Tillvalsvalspaket',
    back: 'Tillbaka',
    update: 'Uppdatera',
    bankid_rfa1: 'Starta BankID-appen',
    baknid_fra2: 'Du har inte BankID-appen installerad. Kontakta din internetbank.',
    bankid_rfa3: 'Åtgärden avbruten. Försök igen.',
    bankid_rfa4: 'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.',
    bankid_rfa5: 'Internt tekniskt fel. Försök igen.',
    bankid_rfa6: 'Åtgärden avbruten. ',
    bankid_rfa8:
        'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din Bank. Försök sedan igen.',
    bankid_rfa9: 'Skriv in din säkerhetskod i BankIDappen och välj Legitimera eller Skriv under.',
    bankid_rfa13: 'Vänligen starta din BankID-app.',
    bankid_rfa14:
        'Söker efter BankID, det kan ta en liten stund… Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du hämta ett hos din internetbank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där.',
    bankid_rfa15:
        'Söker efter BankID, det kan ta en liten stund… Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankIDkort, sätt in det i kortläsaren. Om du inte har något BankID kan du hämta ett hos din internetbank.',
    bankid_rfa16:
        'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.',
    bankid_rfa17:
        'BankID-appen verkar inte finnas i din dator eller telefon. Installera den och hämta ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',
    bankid_rfa18: 'Starta BankID-appen',
    bankid_rfa19:
        'Vill du identifiera dig eller skriva under med BankID på den här datorn eller med ett Mobilt BankID?',
    bankid_rfa20:
        'Vill du identifiera dig eller skriva under med ett BankID på den här enheten eller med ett BankID på en annan enhet?',
    bankid_rfa21: 'Identifiering eller underskrift pågår.',
    bankid_rfa22: 'Okänt fel. Försök igen. ',
    bankid_ALREADY_IN_PROGRESS: 'Identifiering eller underskrift pågår.',

    addons_progress: 'Gjorda val',
    next_addon: 'Nästa val',
    sign_bank_id: 'Signera',
    sign_bank_id_done: 'BankID-Signering klar.',
    sign_bank_id_waiting: 'Köpare återstår att signera',
    reset_mail_send: 'Mail skickat. Kolla din inkorg',
    reset_error: 'Något gick fel. Vänligen försök igen',
    reset_not_mail: 'Kontrollera mailadress',
    reset_expired: 'Länken har gått ut. Vänligen börja om',
    reset_no_valid_interval: 'Vänligen vänta och försök igen om en stund.',
    reset_no_exists: 'Ogiltig länk',
    reset_password_reset: 'Du kommer inom kort att få ett mejl med instruktioner om hur du återställar ditt lösenord.',
    to_login_page: 'Logga in här',
    password_reset_account_active: 'Ditt lösenord är nu ändrat och ditt konto aktiverat. Du kan nu logga in.',
    login_error: 'Inloggning misslyckades',
    login_failed: 'Fel lösenord eller mailadress',
    password_required_length: 'Ange minst 8 tecken och inte för enkelt',
    password_not_ok: 'Svagt',
    password_ok: 'Godkänt',
    password_strong: 'Bra',
    password_great: 'Starkt',
    password_short: 'Längre',
    key_open_door: 'Dörr öppen',
    key: 'Nycklar',
    message_new_message: 'Nytt meddelande',
    sign: 'Anmäl',
    unsign: 'Avanmäl',
    brand: 'Varumärke',
    ticket: 'Felanmälan',
    tickets: 'Gå till Felanmälan',
    ticket_new_ticket: 'Ny felanmälan',
    ticket_status_change: 'Ändring av ärendestatus',
    issues: 'Ärende',
    notification_new: 'Notifikationer',
    offer_new: 'Erbjudande',
    event_new: 'Event',
    order_locked_sign_start: 'Du kan inte längre göra ändringar när du går vidare',
    weekly: 'Varje vecka',
    two_weeks: 'Varannan vecka',
    four_weeks: 'Var fjärde vecka',
    monthly: 'Varje månad',
    quarterly: 'kvartals',
    biannually: 'Halvår',
    annually: 'Årligen',
    two_years: 'Två år',
    mandatory_option: 'Obligatoriskt val',
    addons_not_completed: 'Färdigställ val',
    addons_checkout_closed: 'Utchecking ännu ej öppen.',
    addons_checkout_closed_mobile: 'Ej öppen.',
    show_summary: 'Visa sammanställning',
    addons_summary: 'Sammanställning',
    summary: 'Sammanfattning',
    show_more: 'Visa mer',
    show_less: 'Visa mindre',
    locale: 'sv',
    old_password_mismatch: 'Lösenorden är inte samma.',
    reset_old_password_mismatch: 'Lösenorden är inte samma.',
    wrong_password: 'Fel lösenord.',
    reset_wrong_password: 'Fel lösenord.',
    old_password: 'Nuvarande lösenord',
    property: 'Bostad',
    all: 'Alla',
    contacts: 'Kontakter',
    createNewContact: 'Skapa ny kontakt',
    company: 'Företag',
    title: 'Titel',
    empty_response: 'Här var det tomt...',
    street: 'Gatuadress',
    info: 'Information',
    info_items: 'Information',
    news_items: 'Nyheter',
    inventory: 'Inventarier',
    input_placeholder_name: 'skriv namn',
    input_placeholder_title: 'skriv titel',
    input_placeholder_company: 'skriv företag',
    input_placeholder_phone: 'skriv telefonnummer',
    input_placeholder_email: 'skriv email',
    input_placeholder_city: 'skriv postort',
    input_placeholder_code: 'skriv postnummer',
    input_placeholder_street: 'skriv gatuadress',
    input_placeholder_info: 'skriv övrig information',
    contact_form_status_loading: 'Sparar kontakt',
    contact_form_status_error: 'Kunde ej spara kontakt',
    contact_form_status_success: 'Sparad',
    binder_success: 'Sparad',
    binder_idle: 'Spara',
    binder_error: 'Något gick fel',
    binder_loading: 'Sparar...',
    add_contact: 'Lägg till',
    server_error: 'Något gick fel försök igen',
    copy: 'Kopiera',
    copied: 'Kopierad',
    copied_to_clipboard: 'kopierad till urklipp',
    could_not_download_document: 'Något gick fel, kunde inte ladda ner dokument',
    ticket_view_more: 'Visa hela felanmälan',
    conversations: 'Meddelanden',
    addons_choices: 'Gör dina val',
    project_information: 'Projektinformation',
    product_information: 'Produktinformation',
    feed_no_item: 'Här var det tomt!',
    purchase_store: 'Inköpsställe',
    purchase_date: 'Inköpsdatum',
    service_advice: 'Skötselråd',
    product_url: 'Produktlänk',
    serial_number: 'Serienummer',
    gtin_ean: 'GTIN/EAN',
    e_no: 'E-nummer',
    rsk_no: 'RSK-nummer',
    article_no: 'Artikelnummer',
    max_file_size: 'Max filstorlek',
    product_color: 'Färg/Färgkod',
    booking_exists: 'Tiden är redan bokad',
    bookings: 'Bokningar',
    book: 'Boka',
    cancel_booking: 'Avboka',
    communityCalendar: 'Event',
    Information: 'Information',
    communityNews: 'Nyheter',
    no_tips: 'Det finns inga tips för',
    no_documents: 'Det finns inga dokument',
    push_notification_settings_alert: 'Viktiga meddelanden',
    push_notification_settings_content_news: 'Nyheter',
    push_notification_settings_content_calendar: 'Event',
    push_notification_settings_content_information: 'Information',
    push_notification_settings_new_ticket: 'Felanmälningar',
    push_notification_settings_addon: 'Inredningsval',
    content_calendar: 'Event',
    residence_shared_with: 'Bostaden delas med',
    project: 'Brf',
    brf_admin: 'Föreningsadministratör',
    board_member: 'Styrelseledamot',
    board_field: 'Ansvarsområde',
    role: 'Roll',
    project_admin: 'Projektadministratör',
    remove_booking_error: 'Kunde inte ta bort bokning',
    more_information: 'Mer information',
    my_bookings: 'Mina bokningar',
    booking_exceeded: 'Max antal bokningar uppnått',
    booking_window_out_of_scope: 'Bokning utanför bokningsperiod',
    sign_feed_event: 'bekräfta',
    our_brand: 'OurLiving',
    content_latest: 'Senaste',
    no_feed_items: 'Det finns inga senaste inlägg',
    no_feed_items_communityNews: 'Det finns inga nyheter',
    no_feed_items_communityEvent: 'Det finns inga event',
    no_feed_items_blogItem: 'Det finns inga OurLiving nyheter',
    no_feed_items_board: 'Det finns inga styrelse nyheter',
    notification_for: 'Få notiser för',
    allow: 'Tillåt',
    deny: 'Neka',
    allow_notificationMessage: 'Missa inga händelser för din bostad',
    addon: 'Inredningsval',
    rowhouse: 'Radhus',
    twinhouse: 'Parhus',
    addon_invite_members: 'Bjud in',
    addon_order_confirmation: 'Orderbekräftelse inredningsval',
    invite_addon_members_success: 'Användare inbjuden',
    invite_addon_members_error: 'Uppstod ett fel vid inbjudan',
    inviting_addon_members: 'Bjud in användare',
    invite_board_member: 'Styrelseinbjudan',
    cost: 'Kostnad',
    customer_cost: 'Debitera kund',
    surname: 'efternamn',
    first_name_surname: 'För- och efternamn',
    members: 'Medlemmar',
    boardmembers: 'Styrelse',
    ourliving_news: 'OurLiving nyheter',
    tickets_not_activated_title: 'Felanmälan ej aktiverad',
    tickets_not_activated: 'Felamnälan är ej aktiverad för denna bostad.',
    no_ticket_messages: 'Inga meddelanden',
    new: 'Ny',
    todo_reminder_due_at: 'Är planerad att utföras',
    todo_complete_success_reacuring: 'är nu avklarad och även tillagd för nästa tillfälle',
    todo_complete_success: 'är nu avklarad',
    complete_todo: 'Är du säker på att du vill klarmarkera ',
    push_notification_settings_todo: 'Att göra',
    push_notification_settings_tickets: 'Ärenden',
    push_notification_settings_warranty: 'Garanti',
    warranty_ending: 'Garantin går ut',
    board_information: 'Styrelseinformation',
    quantity: 'Antal',
    about: 'Om bostaden',
    addon_confirmation: 'Inredningsval bekräftelse',
    document_image: 'Bilder',
    plan_a: 'A-ritningar',
    plan_k: 'K-ritningar',
    plan_el: 'El-ritningar',
    plan_vs: 'VS-ritningar',
    plan_vent: 'Vent-ritningar',
    plan_other: 'Övriga ritningar',
    manual_white_goods: 'vitvaror',
    manual_layer: 'Ytskikt',
    manual_other: 'Övrigt',
    todo_remove: 'Ta bort',
    todo_remove_success: 'är nu borttagen',
    todo_remove_error: 'Kunde inte ta bort',
    commune: 'Kommun',
    active_booking_resource: 'Det finns inga aktiva bokingskalendrar',
    apartment_number: 'Lgh nr',
    your_number_of_participants: 'Du har anmält',
    event_not_found: 'Kunde inte hitta eventet',
    unsign_done: 'Avanmälan gjord',
    event_date: 'Eventdatum',
    last_sign_date: 'Sista anmälningsdag',
    last_sign_expired: 'Sista anmälningsdag passerad',
    solar_production: 'Solproduktion',
    solar_tile_description: 'Vår egenproducerade hållbara solel',
};
export type StringDataType = keyof typeof stringData;

export const L = (key: string, projectType?: ProjectType) => {
    let i18string = undefined;
    switch (projectType) {
        case 'housing_society':
            i18string = housingSociety[key];
            break;
        case 'apartment_block':
            i18string = apartmentBlock[key];
            break;
        case 'office':
            i18string = office[key];
            break;
        case 'small_house':
            i18string = smallHouse[key];
            break;
        case 'solar':
            i18string = solar[key];
            break;
        default:
            i18string = stringData[key] || key;
            break;
    }

    return i18string || stringData[key] || key;
};

// import { L } from '@app/shared/lib/i18n';

// let i18 = stringData;

// export const spreadNewStringDatas = (newStringDatas: Partial<typeof i18>) => {
//     i18 = { ...i18, ...newStringDatas };
// };

// export const L = (key: string) => {
//     return i18[key] || key;
// };
