import styled from 'lib/styled';
import React from 'react';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { style } from '../../components/Shared/Style';
import CreateNewTicketButton from './components/CreateNewTicketButton';
import { L } from '@app/shared/lib/i18n';
import { useIsTicketEnabledForProperty } from './hooks/useIsTicketEnabledForProperty';
import TicketsFeed from './components/TicketsFeed';
import P from '../../components/Texts/P';
import H1 from '../../components/Texts/H1';
import { useGetPropertyDetail } from '../binder/hooks';
import PropertyName from '../../components/Texts/PropertyName/PropertyName';

const GridWrapper = styled.div({
    marginBottom: '2rem',
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: 'auto min-content',
    gridTemplateRows: 'min-content',
    gridTemplateAreas: `
    "nav createnew"
    `,
});

const StyledH1 = styled(H1)({
    margin: '0px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        fontSize: '1.5rem',
        padding: '0px',
    },
});

const MainContent = styled.main({
    padding: style.margin.m,
    paddingBottom: '60px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
        paddingBottom: '0px',
    },
});

const StyledP = styled(P)({
    margin: '16px 0px 16px 0px',
});

const Tickets = () => {
    const { data: ticketEnabled } = useIsTicketEnabledForProperty();
    const { data } = useGetPropertyDetail();

    return (
        <MainContent>
            <GridWrapper>
                <div>
                    <StyledH1>{L('ticket')}</StyledH1>
                    <PropertyName>{data?.name}</PropertyName>
                </div>
                {ticketEnabled && <CreateNewTicketButton style={{ placeSelf: 'center' }} />}
            </GridWrapper>
            {ticketEnabled ? <TicketsFeed /> : <StyledP>{L('tickets_not_activated')}</StyledP>}
        </MainContent>
    );
};

export default Tickets;
