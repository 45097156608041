import lazyRetry from 'lib/lazyRetry';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { ConfirmPasswordPage } from './ConfirmPasswordPage';
import LoginProblemPage from './LoginProblemPage';

const ForgottenPasswordPage = lazy(() =>
    lazyRetry(() => import('./ForgottenPasswordPage').then((module) => ({ default: module.ForgottenPasswordPage })))
);

const SetResetPasswordPage = lazy(() =>
    lazyRetry(() =>
        import('../login/SetResetPasswordPage').then((module) => ({ default: module.SetResetPasswordPage }))
    )
);

export const routes = [
    <Route key="0" exact path="/user/invitation/accept" component={ConfirmPasswordPage} />,
    <Route key="1" exact path="/forgottenpassword" component={ForgottenPasswordPage} />,
    <Route key="2" exact path="/confirmaccount" component={ConfirmPasswordPage} />,
    <Route key="3" exact path="/resetpassword" component={SetResetPasswordPage} />,
    <Route key="4" exact path="/loginproblem" component={LoginProblemPage} />,
];
