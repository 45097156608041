import { L } from '@app/shared/lib/i18n';
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

export type Params = {
    spaceId?: string;
    productId?: string;
    propertyId?: string;
};

const CreateNewTicketLink = ({ ...rest }) => {
    const params = useParams<Params>();

    return (
        <NavLink
            to={{
                pathname: `/properties/${params.propertyId}/tickets/new`,
                state: params,
            }}
            {...rest}
        >
            {L('new_ticket')}
        </NavLink>
    );
};

export default CreateNewTicketLink;
