import React, { FC } from 'react';
import styled from 'lib/styled';

const StyledH2 = styled.h2(({ theme }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        lineHeight: '100%',
        marginTop: '0px',
        letterSpacing: '0.41px',
        color: theme.colors.textColor2,
    };
});

type Props = React.HTMLAttributes<HTMLHeadElement>;
const H2: FC<Props> = ({ children, ...rest }) => {
    return <StyledH2 {...rest}>{children}</StyledH2>;
};

export default H2;
