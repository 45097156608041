import styled from 'lib/styled';
import React, { FC } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';
import { style } from '../../Shared/Style';
import IconWrapper from '../../Icon/ThemedIcons/IconWrapper';
import NotificationCount from '../../NotificationCount/NotificationCount';

const Item = styled.li(({ theme }) => {
    return {};
});

const DisabledLink = styled.div(({ theme }) => {
    return {
        opacity: '0.2',
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme?.colors?.black50,
    };
});

const Link = styled(NavLink)(({ theme }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme?.colors.tabInactive,
        margin: `${style.margin.xs}px ${style.margin.xs}px ${style.margin.xs}px ${style.margin.xs}px`,
        '&:hover': {
            span: {
                color: theme?.colors?.tabActive,
            },
            cursor: 'pointer',
            '& path': {
                stroke: theme?.colors?.tabActive,
            },
            '& line': {
                stroke: theme?.colors?.tabActive,
            },
            '& g': {
                stroke: theme?.colors?.tabActive,
            },
            '& polyline': {
                stroke: theme?.colors?.tabActive,
            },
            '& rect': {
                stroke: theme?.colors?.tabActive,
            },
            '& circle': {
                stroke: theme?.colors?.tabActive,
            },
        },
        '&.active': {
            span: {
                color: theme?.colors?.tabActive,
            },
            textDecoration: 'none',
            '& path': {
                stroke: theme?.colors?.tabActive,
            },
            '& line': {
                stroke: theme?.colors?.tabActive,
            },
            '& g': {
                stroke: theme?.colors?.tabActive,
            },
            '& polyline': {
                stroke: theme?.colors?.tabActive,
            },
            '& rect': {
                stroke: theme?.colors?.tabActive,
            },
            '& circle': {
                stroke: theme?.colors?.tabActive,
            },
        },

        [`@media screen and (min-width: 375px)`]: {
            margin: `${style.margin.xs}px ${style.margin.m}px ${style.margin.xs}px ${style.margin.m}px`,
        },
    };
});

const Info = styled.span(({ theme }) => {
    return {
        fontSize: '12px',
        textAlign: 'center',
        padding: `0px 0px 0px 0px`,
        margin: '0px',
        color: theme?.colors?.black50,
    };
});

export type Props = {
    Icon: FC;
    description: string;
    to: LinkProps['to'];
    disabled?: boolean;
    notificationCount?: number;
};

const StyledNotificationCount = styled(NotificationCount)({
    top: '-2px',
    right: '-5px',
});

const StickyBarItem: FC<Props & React.LiHTMLAttributes<HTMLLIElement>> = ({
    to,
    Icon,
    description,
    children,
    notificationCount = 0,
    ...rest
}) => {
    const ItemIcon = styled(Icon)({});
    return (
        <Item {...rest}>
            <Link exact to={to}>
                <IconWrapper>
                    <ItemIcon />
                    <StyledNotificationCount counts={notificationCount} />
                </IconWrapper>
                <Info>{description}</Info>
                {children}
            </Link>
        </Item>
    );
};

export default StickyBarItem;
