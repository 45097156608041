import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'lib/styled';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import CreateNewTicketLink from '../../areas/tickets/components/CreateNewTicketLink';
import { colors } from '../Shared/Style/colors';
import MoreVertical from '../Icon/ThemedIcons/MoreVertical';
import EditArea from '../../areas/binder/components/EditArea/EditArea';
import useEditBinderIsReadonly from '../../areas/binder/hooks/useBinderIsReadonly';
import { useIsTicketEnabledForProperty } from '../../areas/tickets/hooks/useIsTicketEnabledForProperty';
import { Trigger, Content } from './Radix/StyledRadix';

const StyledCreateNewTicketLink = styled(CreateNewTicketLink)({
    alignSelf: 'start',
});

const StyledArrow = styled(DropdownMenu.Arrow)({
    fill: colors.white,
});
const ContextItem = styled.li(() => ({
    padding: '0.5rem',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '1rem',
    cursor: 'pointer',
}));

const StyledMoreVertical = styled(MoreVertical)({
    fill: colors.white,
    stroke: colors.white,
    '& path': {
        stroke: colors.white,
        fill: colors.white,
    },
});

const StyledEdit = styled(EditArea)({
    alignSelf: 'start',
});

const Wrapper = styled.div({
    display: 'flex',
    gap: '2rem',
});

export type Props = {
    editUrl?: string;
};

const RadixDropdownMenu: FC<Props> = ({ editUrl }) => {
    const isReadonly = useEditBinderIsReadonly();
    const { spaceId } = useParams<{
        spaceId: string;
    }>();

    const ticketEnabled = useIsTicketEnabledForProperty();
    return (
        <Wrapper>
            {ticketEnabled && <StyledCreateNewTicketLink />}
            {!isReadonly && editUrl && (
                <DropdownMenu.Root>
                    <Trigger
                        style={{
                            paddingBottom: '0.75rem',
                        }}
                    >
                        <StyledMoreVertical />
                    </Trigger>

                    <DropdownMenu.Portal>
                        <Content collisionPadding={10}>
                            <DropdownMenu.Item />

                            <DropdownMenu.Item>
                                <ContextItem>
                                    {spaceId ? (
                                        <StyledEdit url={editUrl} />
                                    ) : window.location.pathname.includes('security') ? (
                                        <StyledEdit url={editUrl} />
                                    ) : (
                                        <StyledEdit url={editUrl} />
                                    )}
                                </ContextItem>
                            </DropdownMenu.Item>

                            <StyledArrow />
                        </Content>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            )}
        </Wrapper>
    );
};

export default RadixDropdownMenu;
