import { ThemeType } from '@app/api/models/Theme';
import { keyframes } from '@emotion/core';
import styled from 'lib/styled';
import { useTheme } from 'emotion-theming';
import React, { useState, useEffect } from 'react';
import { FC } from 'react';
import { isCordovaBuild } from '../../lib/cordovaHelpers';
import { style } from '../Shared/Style';

const spinner = keyframes({
    '0%': {
        opacity: 0,
        WebkitTransform: 'scale(0)',
    },
    '100%': {
        opacity: 1,
        WebkitTransform: 'scale(1)',
    },
});

const Page = styled.div({
    minHeight: isCordovaBuild ? '-webkit-fill-available' : '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const Loader = styled.div((p: { theme: ThemeType }) => {
    return {
        width: '80px',
        height: '80px',
        backgroundColor: p.theme?.colors?.primary || style.colors.primary,
        borderRadius: '100%',
        webkitAnimation: `${spinner} 1.0s infinite ease-in-out`,
        MozAnimation: `${spinner} 1.0s infinite ease-in-out`,
        animation: `${spinner} 1.0s infinite ease-in-out`,
    };
});

export const Loading: FC = () => {
    const theme = useTheme<ThemeType>();
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const t = setTimeout(() => setVisible(true), 300);
        return () => clearTimeout(t);
    }, []);
    if (!visible) return null;
    return (
        <Page>
            <Loader theme={theme} />
        </Page>
    );
};
