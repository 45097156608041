import React from 'react';
import DocumentItem from './DocumentItem';
import { style } from '../../Shared/Style';
import toast from 'react-hot-toast';
import styled from '@emotion/styled';
import { L } from '@app/shared/lib/i18n';
import { colors } from '../../Shared/Style/colors';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import TrashCan from '../../Icon/ThemedIcons/TrashCan';
import Download from '../../Icon/ThemedIcons/Download';
import Copy from '../../Icon/ThemedIcons/Copy';

const DeleteDocButton = styled.button({
    border: 'none',
    margin: '0px',
    padding: '0px',
    cursor: 'pointer',
    backgroundColor: 'inherit',
});

const DropDownContent = styled(DropdownMenu.Content)({
    padding: '0.5rem',
    backgroundColor: colors.white,
    color: colors.textColor2,
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
});

const StyledArrow = styled(DropdownMenu.Arrow)({
    fill: colors.white,
});

const DeleteIcon = styled(TrashCan)({
    height: '1.3rem',
    width: '1.3rem',
});

const DownloadIcon = styled(Download)({
    height: '1.3rem',
    width: '1.3rem',
});

const CopyIcon = styled(Copy)({
    height: '1.3rem',
    width: '1.3rem',
});

const ContextItem = styled.li(() => ({
    padding: '1rem 2rem 1rem 1rem ',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '1rem',
    cursor: 'pointer',
}));

type Props = {
    deleteDocument?: () => void;
    url?: string;
    name: string;
    fileType: string;
};

const DocumentClick = ({ url, name, fileType, deleteDocument }: Props) => {
    const handleCopy = async () => {
        if (!url) return;
        await navigator?.clipboard?.writeText(url);
        toast(`url ${L('copied_to_clipboard')}`);
    };
    const handleDownload = () => {
        if (!url) return;
        window.open(url, '_blank');
    };

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger
                style={{
                    backgroundColor: 'inherit',
                    border: 'none',
                    padding: '0px',
                    margin: '0px',
                    display: 'block',
                }}
            >
                <DocumentItem name={name} fileType={fileType} />
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropDownContent sideOffset={1}>
                    {url && navigator?.clipboard && (
                        <>
                            <DropdownMenu.Item onClick={() => handleCopy()}>
                                <ContextItem>
                                    <CopyIcon />
                                    <span>{L('copy')}</span>
                                </ContextItem>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item onClick={handleDownload}>
                                <ContextItem>
                                    <DownloadIcon />
                                    <span>{L('download')}</span>
                                </ContextItem>
                            </DropdownMenu.Item>
                        </>
                    )}
                    {deleteDocument && (
                        <DropdownMenu.Item onClick={() => deleteDocument()}>
                            <ContextItem>
                                <DeleteDocButton>
                                    <DeleteIcon />
                                </DeleteDocButton>
                                <span>{L('remove')}</span>
                            </ContextItem>
                        </DropdownMenu.Item>
                    )}
                    <StyledArrow />
                </DropDownContent>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default DocumentClick;
