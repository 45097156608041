import React from 'react';
import { useParams } from 'react-router-dom';
import List from '../../components/Lists/List';
import { BoardContent } from '@app/api/models/BoardContentInformation';
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs/Breadcrumbs';
import HousingPostLink from '../housing/components/HosingPostLink';
import { L } from '@app/shared/lib/i18n';

const BoardPosts = (lists: { list: BoardContent[] }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const list = lists?.list;
    const Posts = list?.map((item) => {
        return (
            <HousingPostLink
                key={item.id}
                to={`/projects/${projectId}/board/${item.id}`}
                name={item.title}
                documentData={item.cover_image_data}
            />
        );
    });
    return (
        <div>
            <Breadcrumbs style={{ marginBottom: '2rem' }}>
                <Breadcrumbs.ItemActive>{L('board_information')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            {list && <List>{Posts}</List>}
        </div>
    );
};

export default BoardPosts;
