import React from 'react';
import _ from 'lodash';
import styled from 'lib/styled';
import { style } from '../../../components/Shared/Style';
import { DetailHeader } from '../../../components/Texts/DetailHeader';
import { L } from '@app/shared/lib/i18n';
import { ContentInformationDocumentDetail } from '@app/api/models/ContentInformation';
import { usePromise } from '../../../lib/react';
import useGetDocumentUrl from '../../../areas/documents/useGetDocumentUrl';
import { colors } from '../../Shared/Style/colors';
import DocumentItemContext from './DocumentItemContext';

const DocumentGroups = styled.div({
    width: '100%',
    marginTop: style.margin.m,
});

const Wrapper = styled.div({
    width: '100%',
});

function DocumentGroupBy({
    documents,
    ...rest
}: { documents: ContentInformationDocumentDetail[] } & React.HTMLAttributes<HTMLDivElement>) {
    const { getSignedDocuments } = useGetDocumentUrl();

    const [ready, data] = usePromise(async () => {
        return await getSignedDocuments(documents);
    }, [documents]);

    if (!ready) return null;

    const document_groups = _(data.sort((a, b) => b.document_group_sort - a.document_group_sort))
        .groupBy('document_type')
        .map((value, key) => ({ name: key, documents: value }))
        .value();

    const r = document_groups.map((r, index) => (
        <DocumentGroups key={index}>
            {r.name !== 'null' && <DetailHeader color={style.colors.black90} padding={'0px'} title={L(`${r.name}`)} />}
            {r.documents.map((doc) => (
                <DocumentItemContext
                    style={{
                        borderBottom: `1px solid ${colors.divider}`,
                    }}
                    key={doc.id}
                    doc={doc}
                />
            ))}
        </DocumentGroups>
    ));
    return <Wrapper {...rest}>{r}</Wrapper>;
}

export default DocumentGroupBy;
