import React, { FC, ReactNode, useState } from 'react';
import styled from 'lib/styled';
import { style } from '../Shared/Style';
import Close from '../Close';
import { useSpring, animated } from 'react-spring';
import { isCordovaBuild } from '../../lib/cordovaHelpers';

const MenuWrapper = styled.nav({
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: 'white',
    height: isCordovaBuild ? '-webkit-fill-available' : '100vh',
    '& a': {
        color: style.colors.black,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '90%',
        marginTop: style.margin.m,
        fontSize: style.fontSize.gamma,
        fontWeight: style.fontWeight.thin,
        'a:first-of-type': {
            padding: `71px 0px 0px 21px`,
        },
    },
});

const OpenMenuButton = styled.button({
    background: 'none',
    border: 'none',
    display: 'flex',
    margin: 0,
});

export const MoreHorizontalLinkStyle = {
    color: style.colors.black,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '90%',
    marginTop: style.margin.m,
    fontSize: style.fontSize.gamma,
    padding: `12px 0px 0px 21px`,
    fontWeight: style.fontWeight.thin,
    '&:first-of-type': {
        padding: `71px 0px 0px 21px`,
    },
};

const AnimatedMenu = animated(MenuWrapper);

export const AvatarMenu = ({ avatar, children }: { avatar: any; children: ReactNode }) => {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const expand = useSpring({
        config: { friction: 20, clamp: !isSettingsOpen },
        width: isSettingsOpen ? '320px' : '0px',
    });

    if (!children) return null;
    return (
        <>
            <OpenMenuButton onClick={() => setIsSettingsOpen((prev) => !prev)}>{avatar}</OpenMenuButton>
            <AnimatedMenu style={expand}>
                <Close
                    inverted
                    left={10}
                    top={10}
                    onClose={() => {
                        setIsSettingsOpen(false), console.log('click');
                    }}
                />
                <span onClick={() => setIsSettingsOpen((prev) => !prev)}>{children}</span>
            </AnimatedMenu>
        </>
    );
};
