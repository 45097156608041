import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import TasksApi from '../../../../api/public/TasksApi';
import { getTodosQueryKeys } from './useGetTaskList';

const getTask = async (propertyId?: string, id?: string) => {
    if (!propertyId || !id) return;

    return await TasksApi.byId(propertyId, id);
};

const useGetTask = () => {
    const { propertyId, todoId } = useParams<{ propertyId: string; todoId: string }>();

    return useQuery(getTodosQueryKeys.singel({ propertyId, id: todoId }), () => getTask(propertyId, todoId), {
        enabled: !!propertyId && !!todoId,
    });
};

export default useGetTask;
