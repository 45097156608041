import styled from 'lib/styled';
import React, { FC } from 'react';
import { Ticket } from '@app/api/models/Tickets';
import H2 from '../../../components/Texts/H2';
import { L } from '@app/shared/lib/i18n';
import TicketEmailInfo from './TicketEmailInfo';
import ClosePage from '../../../components/Buttons/ClosePage/ClosePageLink';
import TicketOLInfo from './TicketOLInfo';
import { useParams } from 'react-router';
import DocumentItem from '../../../components/Document/DocumentItem';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import { useGetTicketAttachments } from '../hooks/UseGetTicketAttachments';
import TicketStatus from '../components/TicketStatus';
import H1 from '../../../components/Texts/H1';
import P from '../../../components/Texts/P';
import useGetProjectDetailByPropertyId from 'hooks/useGetProjectDetailByPropertyId';

const DescriptionTitle = styled(H2)({
    textTransform: 'capitalize',
    fontSize: '1rem',
    fontWeight: 'bolder',
    padding: '2rem 0rem 0rem 0rem',
    margin: '0px',
});

const Header = styled.div({
    display: 'flex',
    paddingBottom: '1rem',
    width: '100%',
    justifyContent: 'end',
});

const FlexSameRow = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});
const Content = styled.main({
    padding: '1rem',

    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
    },
});

const DescriptionParagraph = styled(P)({
    fontSize: '1rem',
    marginBottom: '1.5rem',
    marginTop: '16px',
    padding: '0 1rem',
});

const Title = styled(H1)({
    textTransform: 'capitalize',
    fontSize: '1.2rem',
    fontWeight: 'bolder',
});

const TicketInfo = styled.div({
    paddingBottom: '2rem',
});

export type Props = {
    ticket: Ticket;
    serviceEmail?: string;
};

const SentTicketPage: FC<Props> = ({ serviceEmail, ticket }) => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const { data: documentDetails } = useGetTicketAttachments(ticket.id);
    const { data: projectDetail } = useGetProjectDetailByPropertyId(propertyId);
    const documents = documentDetails?.flatMap((doc) => (doc ? [doc] : [])) || [];
    return (
        <Content>
            <Header>
                <ClosePage to={`/properties/${propertyId}/tickets`} />
            </Header>
            <Title>{L('ticket_sent_title')}</Title>
            <TicketInfo>
                {serviceEmail ? (
                    <TicketEmailInfo serviceEmail={serviceEmail} responseText={projectDetail?.service_response} />
                ) : (
                    <TicketOLInfo responseText={projectDetail?.service_response} />
                )}
            </TicketInfo>
            <FlexSameRow>
                <Title>{ticket.title}</Title>
                <TicketStatus {...ticket} />
            </FlexSameRow>
            {ticket.space_name && (
                <>
                    <DescriptionTitle>Rum</DescriptionTitle>
                    <DescriptionParagraph>{ticket.space_name}</DescriptionParagraph>
                </>
            )}

            {ticket.product_name && (
                <>
                    <DescriptionTitle>Produkt</DescriptionTitle>
                    <DescriptionParagraph>{ticket.product_name}</DescriptionParagraph>
                </>
            )}

            <DescriptionTitle>Beskrivning</DescriptionTitle>
            <DescriptionParagraph>{ticket.description}</DescriptionParagraph>

            {documents?.map((doc) => (
                <DocumentItem key={doc.id} document={doc} />
            ))}
        </Content>
    );
};

export default SentTicketPage;
