import React, { HTMLAttributes } from 'react';
import styled from 'lib/styled';
import { Switch, SwitchProps } from '@rebass/forms';

const SSwitch = styled(Switch)(({ theme }) => {
    return {
        borderColor: theme.colors.black90,
        cursor: 'pointer',
        '&[aria-checked=true]': {
            backgroundColor: theme.colors.primary,
        },
        '& div': {
            backgroundColor: theme.colors.background,
            borderColor: theme.colors.black90,
            borderWidth: '1px',
        },
        ':focus': {
            boxShadow: 'none',
        },
    };
});

export const StyledSwitch: React.FC<SwitchProps & { style?: HTMLAttributes<HTMLDivElement>['style'] }> = ({
    ...rest
}) => {
    return <SSwitch {...rest} />;
};

export default StyledSwitch;
