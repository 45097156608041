import styled from 'lib/styled';
import React, { FC } from 'react';
import { desktop } from '../MediaQuery/breakpoints';
import { PageBackground } from '../Layout/PageBackground';
import AreaNavDesktop from '../../areas/residence/ListPage/Components/AreaNavDesktop';
import SmallTab from '../Tabs/SmallTab';
import TabList from '../Tabs/TabList';
import { useParams } from 'react-router-dom';
import { L } from '@app/shared/lib/i18n';
import {
    useIsBoardMember,
    useHasInfoPages,
    useHasBookingsResources,
} from '../../areas/residence/hooks/areaCardQueries';
import { useGetProjectDetail } from 'hooks/projectQueries';

const StyledDesktopNav = styled.nav({
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

const Card = styled.div({
    width: '100%',
    margin: '0px',
    padding: '0px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        paddingTop: '2rem',
        display: 'grid',
        gridTemplateAreas: `
        "nav main"
        `,
        gridTemplateColumns: 'max-content 1fr',
        columnGap: '2rem',
    },
});

const StyledSmallTab = styled(SmallTab)({
    padding: '0px 0px 8px 0px',
    margin: '0px',
});

const StyledTabList = styled(TabList)({
    rowGap: '1rem',
    display: 'flex',
    flexDirection: 'column',
});

const ProjectsLayout: FC = ({ children }) => {
    const { projectId } = useParams<{
        projectId: string;
    }>();

    const { data: board } = useIsBoardMember(+projectId);

    const { data: hasInfoPages } = useHasInfoPages(+projectId);

    const { data: hasBookings } = useHasBookingsResources(+projectId);

    const { data: projectDetail } = useGetProjectDetail(projectId);
    return (
        <PageBackground style={{ marginBottom: '60px' }}>
            <Card>
                <StyledDesktopNav>
                    <StyledTabList>
                        <StyledSmallTab to={`/projects/${projectId}/housing`}>
                            {L('housing_about', projectDetail?.project_type)}
                        </StyledSmallTab>
                        {board && hasInfoPages && projectDetail?.project_type !== 'solar' && (
                            <StyledSmallTab to={`/projects/${projectId}/board`}>
                                {L('board', projectDetail?.project_type)}
                            </StyledSmallTab>
                        )}
                        {hasBookings && projectDetail?.project_type !== 'solar' && (
                            <StyledSmallTab to={`/projects/${projectId}/bookings`}>{L('bookings')}</StyledSmallTab>
                        )}
                    </StyledTabList>
                </StyledDesktopNav>
                <main>{children}</main>
            </Card>
        </PageBackground>
    );
};

export default ProjectsLayout;
