import React, { FC } from 'react';
import styled from 'lib/styled';
import { style } from '../../Shared/Style';
import { Icon } from '../../Icon/Icon';
import { Colors } from '../../Shared/Style/colors';
import { ThemeType } from '@app/api/models/Theme';
import { useTheme } from 'emotion-theming';

export const SendReportButton = styled.button(() => {
    return {
        background: 'transparent',
        border: 'none',
        padding: '0.15rem',
        height: '60px',
        width: '60px',

        '&:hover': {
            cursor: 'pointer',
        },
    };
});

export const SendReportLable = styled.p(({ textcolor, theme }: props & { theme: ThemeType }) => {
    return {
        color: textcolor
            ? theme?.colors?.[textcolor] || style.colors[textcolor]
            : theme?.colors?.secondary || style.colors.secondary,
        margin: 0,
        marginTop: '0.5rem',
    };
});

export const SendReportIcon = styled(Icon.CirleRightArrow)(
    ({ arrowcolor, circlecolor, theme }: props & { theme: ThemeType }) => {
        return {
            display: 'block',

            '& svg': {
                width: '100%',
            },

            '& .nc-icon-wrapper': {
                'path:first-of-type': {
                    // Circle
                    fill: circlecolor
                        ? theme?.colors?.[circlecolor] || style.colors[circlecolor]
                        : theme?.colors?.tabActive || style.colors.tabActive,
                },

                'path:last-of-type': {
                    // Arrow
                    fill: arrowcolor
                        ? theme?.colors?.[arrowcolor] || style.colors[arrowcolor]
                        : theme?.colors?.white || style.colors.white,
                },
            },
            'button:disabled &': {
                'path:first-of-type': {
                    // Circle
                    fill: circlecolor
                        ? theme?.colors?.[circlecolor] || style.colors[circlecolor]
                        : theme?.colors?.primary || style.colors.primary,
                },

                'path:last-of-type': {
                    // Arrow
                    fill: arrowcolor
                        ? theme?.colors?.[arrowcolor] || style.colors[arrowcolor]
                        : theme?.colors?.white || style.colors.white,
                },
            },
        };
    }
);

export type props = {
    label?: string;
    className?: string;
    onClick?: () => void;
    circlecolor?: Colors;
    arrowcolor?: Colors;
    textcolor?: Colors;
    type?: 'button' | 'submit' | 'reset' | undefined;
    disabled?: true | false;
};
const SendButton: FC<props> = ({
    textcolor,
    arrowcolor,
    circlecolor,
    className,
    label,
    type = 'submit',
    onClick,
    ...rest
}) => {
    return (
        <SendReportButton onClick={onClick} className={className} type={type} {...rest}>
            <SendReportIcon arrowcolor={arrowcolor} circlecolor={circlecolor} />
            {label && <SendReportLable textcolor={textcolor}>{label}</SendReportLable>}
        </SendReportButton>
    );
};

export default SendButton;
