import { L } from '@app/shared/lib/i18n';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

export type Props = {
    url: string;
};

const EditArea: FC<Props> = ({ url, ...rest }) => {
    return (
        <NavLink to={url} {...rest}>
            {L('binder_edit')}
        </NavLink>
    );
};

export default EditArea;
