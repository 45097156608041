import React, { FC } from 'react';
import styled from 'lib/styled';
import { style } from '../../Shared/Style';
import BackArrow from '../../Breadcrumb/BackArrow/BackArrow';
import { Colors } from '../../Shared/Style/colors';

const NavlinkContainer = styled.div({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '1rem',
});

const HeaderNav = styled.div({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: `0px ${style.margin.s}px ${style.margin.m}px 0px`,
    width: '100%',
});

export type Props = {
    to?: string;
    className?: string;
    color?: Colors;
};

const HeroNav: FC<Props> = ({ className, to, color = 'white', ...rest }) => {
    return (
        <HeaderNav className={className}>
            <NavlinkContainer {...rest}>
                <BackArrow to={to} color={color} />
            </NavlinkContainer>
        </HeaderNav>
    );
};

export default HeroNav;
