import React, { forwardRef } from 'react';
import styled from 'lib/styled';
import { style } from '../Shared/Style';
import ClipLoader from 'react-spinners/ClipLoader';

const padding = 9;

const StyledButton = styled.button(({ theme }) => ({
    position: 'relative',
    color: theme.colors.primary,
    background: 'inherit',
    border: 'none',
    cursor: 'pointer',
    fontSize: style.fontSize.seta,
    fontWeight: style.fontWeight.bold,
    borderRadius: style.roundCornerSize.small,
    textDecoration: 'none',
    boxSizing: 'border-box',
    outline: 'none',
    padding: `${padding}px`,
    width: 'max-content',
    minWidth: '94px',

    '&.primary': {
        color: theme.colors.primary,
        padding: `${padding - 1}px `,
        border: `1px solid ${theme.colors.primary}`,
        '&:hover': {
            padding: `${padding - 2}px `,
            border: `2px solid ${theme.colors.primary}`,
        },
        '&:disabled, &:disabled &:hover': {
            padding: `${padding - 1}px `,
            border: `1px solid ${theme.colors.secondary}`,
            cursor: 'default',
        },
    },
    '&.secondary': {
        color: theme.colors.secondary,
        padding: `${padding - 1}px `,
        border: `1px solid ${theme.colors.secondary}`,

        '&:hover': {
            padding: `${padding - 2}px `,
            border: `2px solid ${theme.colors.secondary}`,
        },
        '&:disabled, &:disabled &:hover': {
            padding: `${padding - 1}px `,
            border: `1px solid ${theme.colors.secondary}`,
            cursor: 'default',
        },
    },
    '&.menu': {
        color: theme.colors.primary,
        '&:disabled, &:disabled:hover': {
            border: 'none',
            padding: `${padding}px `,
            color: theme.colors.black80,
            backgroundColor: 'inherit',
        },
    },
    '&.filledPrimary': {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        '&:hover, &:active': { backgroundColor: theme.colors.primary50 },
    },
    '&.selectedMenu': {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        '&:hover, &:active': { backgroundColor: theme.colors.primary50 },
    },
    '&.remove': {
        backgroundColor: theme.colors.black80,
        color: theme.colors.black,
        '&:hover, &:active': { backgroundColor: theme.colors.black50 },
    },
    '&.delete': {
        backgroundColor: theme.colors.deleteButton,
        color: 'white',
        '&:hover, &:active': { backgroundColor: theme.colors.deleteButtonHover },
    },
    '&.removeConfirm': {
        backgroundColor: theme.colors.black80,
        color: 'white',
        '&:hover, &:active': { backgroundColor: theme.colors.black50 },
    },

    '&:disabled, &:disabled:hover': {
        color: theme.colors.black80,
        border: '1px solid',
        backgroundColor: 'inherit',
        borderColor: theme.colors.black80,
        cursor: 'default',
    },
}));

type newProps = {
    variant?:
        | 'primary'
        | 'secondary'
        | 'menu'
        | 'filledPrimary'
        | 'selectedMenu'
        | 'remove'
        | 'removeConfirm'
        | 'delete';
    children: React.ReactNode;
    isLoading?: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'ref'>;

const Button = forwardRef<HTMLButtonElement, newProps>(
    ({ variant = 'primary', className = '', children, isLoading, disabled, ...props }, ref) => {
        if (isLoading) {
            return (
                <StyledButton ref={ref} disabled={disabled ?? true} className={`${variant} ${className}`} {...props}>
                    <div
                        style={{
                            display: 'grid',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            placeItems: 'center',
                        }}
                    >
                        <ClipLoader size={16} speedMultiplier={0.8} color={style.colors.black80} />
                    </div>
                    <span style={{ visibility: 'hidden' }}>{children}</span>
                </StyledButton>
            );
        }
        return (
            <StyledButton ref={ref} disabled={disabled} className={`${variant} ${className}`} {...props}>
                {children}
            </StyledButton>
        );
    }
);

export { Button };
