import React from 'react';
import { Extend } from '../../types';
import { flexbox, layout, space } from 'styled-system';
import styled from 'lib/styled';

const Div = styled.div({
    display: 'flex',
});

const Flex: React.FC<Extend<HTMLDivElement>> = ({ children, ...props }) => {
    return <Div {...props}>{children}</Div>;
};

export default styled(Flex)(flexbox, layout, space);
