import ThemeApi from '@app/api/public/ThemeApi';
import { useEffect } from 'react';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import { ThemeType } from '../../../../api/models/Theme';
import { LoginStore } from '../../../areas/login/LoginStore';
import { getIosTheme } from '../../../cordovaIos/IosTheme';
import { isCordovaBuild } from '../../../lib/cordovaHelpers';

export const setQueryThemeCache = (queryClient: QueryClient, theme: ThemeType) => {
    queryClient.setQueryData('theme', theme);
};

const getTheme = (queryClient: QueryClient) => {
    if (isCordovaBuild) {
        getIosTheme((theme) => setQueryThemeCache(queryClient, theme.data));
        return queryClient.getQueryData('theme') as ThemeType;
    }

    return ThemeApi.getThemeBySuburl();
};

const useGetTheme = () => {
    const queryClient = useQueryClient();
    useEffect(() => {
        queryClient.refetchQueries('theme', { active: true });

        if (isCordovaBuild) {
            getIosTheme((theme) => setQueryThemeCache(queryClient, theme.data));
        }
    }, [LoginStore.user]);
    const { data, isLoading } = useQuery('theme', () => getTheme(queryClient));

    return { data, isLoading };
};

export default useGetTheme;
