import React, { FC } from 'react';
import { QueryStatus } from 'react-query';
import SendButton from '../../../components/Forms/SendButton';
import { L } from '@app/shared/lib/i18n';

type Props = {
    status: QueryStatus;
};

const SendButtonHandler: FC<Props> = ({ status, ...rest }) => {
    let label = L('save');
    switch (status) {
        case 'loading':
            label = L('contact_form_status_loading');
            break;
        case 'success':
            label = L('contact_form_status_success');
            break;
        case 'error':
            label = L('contact_form_status_error');
            break;
        default:
            label = L('save');
    }
    return <SendButton disabled={status === 'loading'} label={label} {...rest} />;
};

export default SendButtonHandler;
