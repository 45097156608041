import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { useLocation, Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import useGetHelpListItem from '../../areas/help/hooks/useGetHelpListItem';
import Close from '../Close';
import { desktop } from '../MediaQuery/breakpoints';
import { useOnClickOutside } from '../Functions/ClickOutsideArea';
import { colors } from '../Shared/Style/colors';
import HelpIconSvg from '@app/web/components/Icon/helpicon.svg';

type Props = {
    isShowingIcon: boolean;
    marginTop: string;
    padding?: string;
};

const IconWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '1px 6px',
});

const ClickableIcon = styled.h1(({ isOpenModal, marginTop }: { isOpenModal?: boolean; marginTop?: string }) => {
    return {
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundImage: `url(${HelpIconSvg})`,
        backgroundRepeat: 'no-repeat',
        width: '24px',
        height: '24px',
        marginTop: marginTop,
        cursor: 'pointer',
    };
});

const ClickableText = styled.h1(({ isOpenModal }: { isOpenModal?: boolean }) => {
    return {
        marginTop: style.margin.s,
        fontWeight: style.fontWeight.thin,
        textAlign: 'right',
        color: style.colors.primary,
        fontSize: style.fontSize.seta,
        cursor: 'pointer',
        [`@media screen and (min-width:${desktop}px)`]: {
            fontSize: style.fontSize.delta,
        },
    };
});

const ModalContainer = animated(
    styled.div({
        position: 'fixed',
        top: 0,
        right: 0,
        width: '500px',
        maxWidth: '100%',
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: '#F7F8FC',
        color: 'black',
        boxShadow: '0 0 35px gray',
        zIndex: 99,
        '& img': {
            maxWidth: '100%',
            objectFit: 'cover',
            backgroundPosition: 'center',
        },
        '& iframe': {
            width: '100%',
            maxWidth: '100%',
        },
        '& p': {
            span: {
                fontStyle: 'normal',
                fontWeight: 300,
                fontSize: '1rem',
                lineHeight: '150%',
                color: '#9f9a9a',
            },
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '1rem',
            lineHeight: '150%',
            color: '#9f9a9a',
            fontFamily: 'DM sans',
        },
        '& span *': {
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '1rem',
            lineHeight: '150%',
            fontFamily: 'DM sans',
            color: '#9f9a9a',
        },
        '& span': {
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '1rem',
            lineHeight: '150%',
            color: '#9f9a9a',
            fontFamily: 'DM sans',
        },
        '& h1': {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '2rem',
            lineHeight: '100%',
            marginTop: '0px',
            letterSpacing: '0.41px',
            color: colors.textColor2,
            fontFamily: 'DM sans',
        },
        '& h2': {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            lineHeight: '100%',
            marginTop: '0px',
            letterSpacing: '0.41px',
            color: colors.textColor2,
            fontFamily: 'DM sans',
        },
        '& h3': {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1rem',
            lineHeight: '19px',
            color: colors.textColor2,
            fontFamily: 'DM sans',
        },
        '& h4': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '1rem',
            lineHeight: '19px',
            color: colors.textColor2,
            fontFamily: 'DM sans',
        },
        '& li': {
            listStyleType: 'auto',
        },
    })
);

const ModalWrapper = styled.div({
    width: '85%',
    margin: '10px auto',
});

const Title = styled.h1({
    marginTop: '50px',
    fontSize: '1.5rem',
});

const Description = styled.span({
    fontSize: '1rem',
});

const NoHelpText = styled.p({
    fontSize: '1rem',
    marginTop: '50px',
});

const StyledClose = styled(Close)({
    fontWeight: 100,
    position: 'relative',
    '& span': {
        fontSize: '2.25rem',
        color: 'black',
    },
});

const HelpIcon = ({ isShowingIcon, marginTop, padding }: Props) => {
    var { pathname } = useLocation();

    pathname.split('/').forEach((value) => {
        if (+value) {
            pathname = pathname.replace(value, ':id');
        }
    });

    const { data: helpListItem } = useGetHelpListItem(pathname);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isPinned, setIsPinned] = useState(false);

    const slideIn = useSpring({
        transform: isOpenModal ? 'translateX(0px)' : 'translateX(400px)',
        config: { friction: 30 },
    });
    const clickRef = useRef(null);

    useOnClickOutside(clickRef, () => {
        !isPinned && setIsOpenModal(false);
    });

    return (
        <>
            <IconWrapper style={{ padding: padding }}>
                <div>
                    {isShowingIcon ? (
                        <ClickableIcon
                            marginTop={marginTop}
                            isOpenModal={isOpenModal}
                            onClick={() => setIsOpenModal(!isOpenModal)}
                        />
                    ) : (
                        <ClickableText isOpenModal={isOpenModal} onClick={() => setIsOpenModal(!isOpenModal)}>
                            {helpListItem?.title}
                        </ClickableText>
                    )}
                </div>
            </IconWrapper>
            {isOpenModal && (
                <ModalContainer ref={clickRef} style={slideIn}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <StyledClose inverted onClose={() => setIsOpenModal(false)} />
                    </div>
                    <ModalWrapper>
                        {helpListItem ? (
                            <>
                                <Title>{helpListItem.title}</Title>
                                <Description dangerouslySetInnerHTML={{ __html: helpListItem.description || '' }} />
                            </>
                        ) : (
                            <NoHelpText>
                                Det finns tyvärr ingen hjälp för denna sidan, <Link to="/help">klicka här</Link> så
                                kommer du till våran hjälpsida!
                            </NoHelpText>
                        )}
                    </ModalWrapper>
                </ModalContainer>
            )}
        </>
    );
};

export default HelpIcon;
