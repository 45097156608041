import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import * as Projects from '../models/Projects';
import { getProjectContext } from '../models/Users';
import { Property } from '../models/Property';
import { ProjectType } from '../models/Projects';
export { ProjectType };

class ProjectsApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async id(projectId: number) {
        const context = await getProjectContext(this.user.id, projectId);
        if (!context) return null;
        return await Projects.id(context.project_id);
    }

    @rpc({ httpMethod: 'GET' })
    async getProjectDetailByPropertyId(propertyId: Property['id']) {
        return await Projects.getProjectDetailByPropertyId(propertyId);
    }
}
export default new ProjectsApi();
