import * as Model from '../models/BoardMembers';
import { BaseApi } from '../lib/BaseApi';
import { rpc } from 'koa-rpc-call';
export { IPageOf } from '../lib/db';

class BoardMembersApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async listBoardMembers(projectName: string) {
        return await Model.listBoardMembers(projectName);
    }

    @rpc({ httpMethod: 'GET' })
    async getBoardMemberById(id: string) {
        return await Model.getBoardMemberById(id);
    }
}
export default new BoardMembersApi();
