import styled from 'lib/styled';
import React, { FC } from 'react';
import { NotificationType } from '../../../../api/models/Notifications';
import AlertNotice from '../AlertNotice/AlertNotice';
import BookingNotice from '../BookingNotice/BookingNotice';
import CommunityCalendarNotice from '../CommunityCalendarNotice/CommunityCalendarNotice';
import CommunityNewsNotice from '../CommunityNewsNotice/CommunityNewsNotice';
import InformationNotice from '../InformationNotice/InformationNotice';
import TicketNotice from '../TicketNotice/TicketNotice';
import TodoNotice from '../TodoNotice/TodoNotice';
import WarrantyNotice from '../WarrantyNotice/WarrantyNotice';

type Props = {
    notification: NotificationType;
    isCollapsed: boolean;
};

// lägg till Rätt Notification components här
const componentMapper = {
    alert: AlertNotice,
    booking: BookingNotice,
    new_ticket: TicketNotice,
    todo: TodoNotice,
    warranty: WarrantyNotice,
    content_news: CommunityNewsNotice,
    content_calendar: CommunityCalendarNotice,
    content_information: InformationNotice,
};

const NotificationMapper: FC<Props & React.HTMLAttributes<HTMLElement>> = ({ notification, isCollapsed, ...rest }) => {
    const NotificationComponent = componentMapper[notification.notifiable_type];
    return <NotificationComponent {...notification} isCollapsed={isCollapsed} {...rest} />;
};

export default NotificationMapper;
