import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import TicketsApi from '@app/api/public/TicketsApi';

export const getTicketInfo = async (ticketId: number) => {
    try {
        const res = await TicketsApi.getbyid(ticketId);
        if (!res) return null;

        return {
            ...res,
        };
    } catch (err) {}
};

export const useFetchTicketInfo = () => {
    const { ticketId } = useParams<{ ticketId: string }>();

    return useQuery(['tickets', ticketId, 'info'], () => getTicketInfo(+ticketId), {
        enabled: !!ticketId,
    });
};
