import React, { FC } from 'react';
import styled from 'lib/styled';
import { Link } from 'react-router-dom';
import NoticeIcon from '../components/NoticeIcon';
import NoticeTitle from '../components/NoticeTitle';
import NoticeDate from '../components/NoticeDate';
import NoticeLabel from '../components/NoticeLabel';
import { CalendarNotification, TodoNotification } from '@app/api/models/Notifications';
import { daysJsDateFromNow } from '../../../lib/time';
import NoticeDescription from '../components/NoticeDescription';
import { ReactComponent as Calendar } from '../../Icon/calendar-date-2.svg';

const Border = styled.div(({ theme }) => {
    return {
        backgroundColor: theme.colors.alertBackground,
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        display: 'grid',
        gridTemplateColumns: 'min-content auto min-content',
        color: theme.colors.textColor2,
        textDecoration: 'none',
        whiteSpace: 'pre-line',
        gridTemplateAreas: `
    "icon title label"
    "icon description label"
    "icon date label"
    `,
        columnGap: '1rem',
        rowGap: '0.5rem',
    };
});

const CommunityCalendarNotice: FC<CalendarNotification & { isCollapsed: boolean }> = ({
    body,
    title,
    isCollapsed,
    created_at,
    link,
    ...rest
}) => {
    const when = created_at && daysJsDateFromNow(created_at);

    return (
        <Link to={link ?? `/`}>
            <Border {...rest}>
                <NoticeIcon>
                    <Calendar />
                </NoticeIcon>
                <NoticeTitle isCollapsed={isCollapsed} description={title} />
                <NoticeDescription isCollapsed={isCollapsed} description={body} />

                <NoticeDate when={when} />
                <NoticeLabel type={'Event'} />
            </Border>
        </Link>
    );
};

export default CommunityCalendarNotice;
