import styled from 'lib/styled';
import React, { FC } from 'react';

const Content = styled.ul({
    display: 'flex',
    listStyle: 'none',
    padding: '0px',
    margin: '0px',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    // flexWrap: 'wrap',
});

const Nav = styled.nav({
    width: '100%',
});

const StickyBarList: FC = ({ children }) => {
    return (
        <Nav>
            <Content>{children}</Content>
        </Nav>
    );
};

export default StickyBarList;
