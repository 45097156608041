import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import * as PushNotificationModel from '../models/PushNotification';
import { PushNotificationSetting } from '../models/PushNotification';
import * as FeatureModel from '../models/Features';
import * as TicketModel from '../models/tickets/Tickets';
export { PushNotificationSetting };
import * as UserModel from '../models/Users';
import * as ResidenceModel from '../models/Residence';

class PushNotificationApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async saveSetting(push_notification_setting_id: string, value: boolean) {
        return PushNotificationModel.saveSettings(this.user.id, push_notification_setting_id, value);
    }

    @rpc({ httpMethod: 'GET' })
    async listSettings() {
        const residenceList = await ResidenceModel.list(this.user.id);
        let isTicketEnabled = false;
        let isAddonEnabled = false;
        let isAlertEnabled = false;
        await Promise.all(
            residenceList.map(async (property) => {
                const ticket = await TicketModel.isTicketEnabled(property.projectId);
                ticket && (isTicketEnabled = true);
                const addon = await FeatureModel.hasFeature('addon', property.projectId);
                addon && (isAddonEnabled = true);
                const alert = await FeatureModel.hasFeature('alert', property.projectId);
                alert && (isAlertEnabled = true);
            })
        );
        let pushNotificationSettings = await PushNotificationModel.listSettings(this.user.id);
        if (!isTicketEnabled)
            pushNotificationSettings = pushNotificationSettings.filter((obj) => obj.type !== 'new_ticket');
        if (!isAddonEnabled) pushNotificationSettings = pushNotificationSettings.filter((obj) => obj.type !== 'addon');
        if (!isAlertEnabled) pushNotificationSettings = pushNotificationSettings.filter((obj) => obj.type !== 'alert');
        return pushNotificationSettings;
    }

    @rpc({ httpMethod: 'GET' })
    async saveToken(token: string, client: string) {
        const user = await UserModel.getById(+this.user.id);
        const locale = user && user.locale ? user.locale : 'en';
        return PushNotificationModel.saveToken({
            user_id: this.user.id,
            token: token,
            locale: locale,
            environment: process.env.NODE_ENV || 'development',
        });
    }

    @rpc({ httpMethod: 'GET' })
    async saveEmailSetting(push_notification_setting_id: string, value: boolean) {
        return PushNotificationModel.saveEmailSettings(this.user.id, push_notification_setting_id, value);
    }

    @rpc({ httpMethod: 'GET' })
    async listEmailSettings() {
        return PushNotificationModel.listEmailSettings(this.user.id);
    }
}

export default new PushNotificationApi();
