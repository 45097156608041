import UsersApi from '@app/api/public/UsersApi';
import { Preferences } from '@capacitor/preferences';
import { isCordovaBuild } from 'lib/cordovaHelpers';
import { useMutation } from 'react-query';

const useLogout = () => {
    return useMutation(
        async () => {
            const response = await UsersApi.logout();
            if (isCordovaBuild) {
                await Preferences.remove({
                    key: 'TOKEN',
                });
            }
            return response;
        },
        {
            onSettled: () => {
                location.assign('/');
            },
        }
    );
};

export default useLogout;
