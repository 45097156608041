import { useQuery } from 'react-query';

import BindersAPI from '@app/api/public/BindersApi';
import { useParams } from 'react-router-dom';

export const binderQueryKeys = {
    all: ['binder'] as const,
    list: () => [...binderQueryKeys.all, 'list'] as const,
    getSpaceDetail: (propertyId: number, spaceId: number) =>
        [...binderQueryKeys.all, 'spaceDetail', propertyId, spaceId] as const,
    getProductDetail: (propertyId: number, productId: number) =>
        [...binderQueryKeys.all, 'productDetail', propertyId, productId] as const,
    listEntities: (propertyId: number, entityType: number, spaceId?: number) =>
        [...binderQueryKeys.all, 'listEntities', propertyId, entityType, spaceId] as const,

    hasSecurity: (propertyId: number) => [...binderQueryKeys.all, 'hasSecurity', propertyId] as const,
    hasConstructionParts: (propertyId: number) => [...binderQueryKeys.all, 'hasConstructionParts', propertyId] as const,
};

const getPropertyDetailById = async (propertyId: string) => {
    return await BindersAPI.getPropertyDetailById(+propertyId);
};

export const useGetPropertyDetail = (pId?: string) => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return useQuery(['getPropertyDetail', pId || propertyId], () => getPropertyDetailById(pId || propertyId), {
        enabled: !!pId || !!propertyId,
    });
};

export const useGetSpaceDetail = (id?: string) => {
    const { propertyId, spaceId } = useParams<{ propertyId: string; spaceId: string }>();

    return useQuery(binderQueryKeys.getSpaceDetail(+propertyId, +(id || spaceId)), () =>
        BindersAPI.getSpaceDetail(+propertyId, +(id || spaceId))
    );
};

type EntityType = 0 | 2 | 3;

export const useListSpaces = (entityType: EntityType = 0) => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const { data, ...rest } = useQuery(binderQueryKeys.listEntities(+propertyId, entityType), () =>
        listEntities(propertyId, entityType)
    );
    if (!data) return { data, ...rest };
    return { data, ...rest };
};

const listEntities = async (propertyId: string, entityType: EntityType = 0) => {
    try {
        if (entityType === 0) return BindersAPI.listSpaces(+propertyId);
        if (entityType === 2) return BindersAPI.listComponent(+propertyId);
        if (entityType === 3) return BindersAPI.listSecurity(+propertyId);
    } catch (err) {
        return Promise.reject('Could not fetch entities');
    }
};

export const useGetProductDetail = () => {
    const { propertyId, productId } = useParams<{ propertyId: string; productId: string }>();
    return useQuery(binderQueryKeys.getProductDetail(+propertyId, +productId), () =>
        BindersAPI.getProductDetail(+propertyId, +productId)
    );
};

export const useListProducts = (spaceId?: string | number | null) => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const { data, ...rest } = useQuery(
        spaceId ? binderQueryKeys.listEntities(+propertyId, 1, +spaceId) : binderQueryKeys.listEntities(+propertyId, 1),
        () => BindersAPI.listProducts(+propertyId, spaceId),
        {
            enabled: !!spaceId && !!propertyId,
        }
    );
    if (!data) return { data, ...rest };
    return { data, ...rest };
};

export const useGetHasSecurity = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return useQuery(binderQueryKeys.hasSecurity(+propertyId), () => BindersAPI.hasSecurity(+propertyId));
};

export const useGetHasConstructionParts = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return useQuery(binderQueryKeys.hasConstructionParts(+propertyId), () =>
        BindersAPI.hasConstructionParts(+propertyId)
    );
};
