import React, { FC } from 'react';
import styled from 'lib/styled';
import { Colors, colors } from '../../Shared/Style/colors';
import { ThemeType } from '@app/api/models/Theme';
import { HTMLAttributes } from 'react';

const StyledP = styled.p((props: { color?: Colors | string; theme: ThemeType }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '1rem',
        lineHeight: '150%',
        margin: '0px',
        color: props.color ? colors[props.color] : props.theme?.colors?.textColor2 || colors.textColor2,
    };
});

type Props = {
    className?: string;
    color?: Colors | string;
    onClick?: () => void;
} & HTMLAttributes<HTMLParagraphElement>;
const P: FC<Props> = ({ children, ...rest }) => {
    return <StyledP {...rest}>{children}</StyledP>;
};

export default P;
