import React, { ChangeEvent, useRef } from 'react';
import SelectOptions from './SelectOptions';
import { TicketAction } from './CreateTicket';
import { CreateTicket } from '@app/api/models/Tickets';
import Select from '../../../components/Forms/Select/Select';
import { useListSpaces } from '../../binder/hooks';
import Label from '../../../components/Forms/Label';

type Props = {
    state: CreateTicket;
    dispatch: React.Dispatch<TicketAction>;
};

const SpaceSelect = ({ state, dispatch }: Props) => {
    const selectRef = useRef<HTMLSelectElement>(null);

    const spacesQueary = useListSpaces();
    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        if (!selectRef.current) return;

        dispatch({ type: 'space', value: +selectRef.current.value });
    };

    return (
        <>
            <Label title={'Välj vart felet är'} name={'where'} />
            <Select
                ref={selectRef}
                onChange={handleSelectChange}
                name={'spaces'}
                id={'where'}
                value={state.space_id?.toString() || ''}
                required={true}
            >
                <option value="" disabled>
                    Välj rum
                </option>
                {spacesQueary.data &&
                    spacesQueary.data.map((option) => (
                        <SelectOptions key={option.id} name={option.name} value={option.id} />
                    ))}
            </Select>
        </>
    );
};

export default SpaceSelect;
