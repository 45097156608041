import styled from 'lib/styled';
import React from 'react';
import { useListSettings } from '../hooks';
import { desktop } from '@app/web/components/MediaQuery/breakpoints';
import { L } from '@app/shared/lib/i18n';
import PushNotificationSwitch from './PushNotificationSwitch';

const Nav = styled.nav({
    justifySelf: 'center',
    width: '100%',
});

const P = styled.p(({ theme }) => ({
    color: theme.colors.textColor2,
    paddingBottom: '0.5rem',
    marginBottom: '0',
}));

const Ul = styled.ul({
    padding: 0,
    margin: 0,
});

const Li = styled.li((p) => {
    return {
        listStyleType: 'none',
        padding: '0',
        cursor: 'pointer',
        borderBottom: `1px solid ${p.theme?.colors?.listBorderColor}`,

        '&:first-of-type': {
            borderTop: `1px solid ${p.theme?.colors?.listBorderColor}`,
        },
    };
});
const Wrapper = styled.div({
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const RecurseStyled = styled.p({
    margin: '1rem 0',
    fontSize: '1.3rem',
});

const IconDivWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const WrapperDiv = styled.div({
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media screen and (min-width: ${desktop}px)`]: {
        width: '15%',
    },
});

const PushNotificationsSettings = () => {
    const { data: listSettings } = useListSettings();

    return (
        <Nav>
            <P>{L('notification_for')}</P>

            <Ul>
                {listSettings &&
                    listSettings.map((res) => {
                        return (
                            <Li key={res.setting_id}>
                                <Wrapper>
                                    <IconDivWrapper>
                                        <RecurseStyled>{L(`push_notification_settings_${res.type}`)}</RecurseStyled>
                                    </IconDivWrapper>
                                    <WrapperDiv>
                                        <PushNotificationSwitch id={res.setting_id} value={res.value} />
                                    </WrapperDiv>
                                </Wrapper>
                            </Li>
                        );
                    })}
            </Ul>
        </Nav>
    );
};

export default PushNotificationsSettings;
