import lazyRetry from 'lib/lazyRetry';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const importAddonsPageHandler = () => import('./AddonsPageHandler').then((module) => module);
const AddonsPageHandler = lazy(() => lazyRetry(importAddonsPageHandler));

export const routes = [
    <Route key="0" path="/properties/:propertyId/addons">
        <AddonsPageHandler />
    </Route>,
];

export const navigation = [{ name: 'Inredningsval', link: '/' }];
