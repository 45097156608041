import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { ReactComponent as Icon } from '../arrow-right.svg';

const ThemedArrowRight = styled(Icon)(({ theme }) => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: theme.colors.icons,
        },
    };
});
const ArrowRight: FC = ({ ...rest }) => {
    return <ThemedArrowRight viewBox="0 0 16 16" {...rest} />;
};

export default ArrowRight;
