import * as UserModel from '../models/Users';
import { getLoginContext, User } from '../models/Users';
import { BaseApi } from '../lib/BaseApi';
import { SESSION_COOKIE_NAME, SESSION_COOKIE_DOMAIN } from '../lib/permissions';
import { rpc } from 'koa-rpc-call';
import { Project } from '../models/Projects';
import dayjs from 'dayjs';
import loglevel from 'loglevel';
import { getLoglevelFormEnv } from '../utils/getLogLevelFromEnv';
export { User as IUser };

loglevel.setLevel(getLoglevelFormEnv());

class UsersApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async get_list() {
        return await UserModel.list();
    }

    @rpc({ anonymous: true })
    async verifyLogin(): Promise<UserModel.User | null> {
        loglevel.debug('verifyLogin cookie', this.httpContext.cookies.get(SESSION_COOKIE_NAME));
        loglevel.debug('verifyLogin authorization', this.httpContext.request.headers?.['authorization']);
        const loginToken =
            this.httpContext.cookies.get(SESSION_COOKIE_NAME) || this.httpContext.request.headers?.['authorization'];

        if (loginToken) {
            const userId = await UserModel.getIdFromLoginToken(loginToken);

            const found = userId !== null ? await UserModel.getById(userId) : null;

            const isImpersonated = this.httpContext.cookies.get('impersonation');

            if (found && found !== null) found.isImpersonated = isImpersonated ? true : false;

            if (!isImpersonated)
                this.httpContext.cookies.set(SESSION_COOKIE_NAME, loginToken, {
                    domain: SESSION_COOKIE_DOMAIN,
                    expires: dayjs().add(400, 'day').toDate(),
                });
            return found;
        }

        return null;
    }

    @rpc()
    async setCookie(propertyId: string) {
        this.httpContext.cookies.set('propertyId', propertyId, {
            domain: SESSION_COOKIE_DOMAIN,
            expires: dayjs().add(400, 'day').toDate(),
        });
        this.httpContext.property = {
            id: +propertyId || +this.httpContext.request.headers?.['x-property-id'],
            name: '',
            project_id: '',
        };
    }

    @rpc({ anonymous: true })
    async login(username: string, password: string): Promise<UserModel.User | null> {
        const found = await UserModel.getUsersByLogin(username, password);
        if (found) {
            const newToken = await UserModel.createLoginToken(found.id.toString());
            if (!newToken) return null;
            this.httpContext.cookies.set(SESSION_COOKIE_NAME, newToken, {
                domain: SESSION_COOKIE_DOMAIN,
                expires: dayjs().add(400, 'day').toDate(),
            });
            const cookieString = newToken;
            return { ...found, cookieString };
        }
        return null;
    }

    @rpc({ anonymous: true })
    async logout() {
        this.httpContext.cookies.set(SESSION_COOKIE_NAME, '', { domain: SESSION_COOKIE_DOMAIN });
        this.httpContext.cookies.set('propertyId', '', { domain: SESSION_COOKIE_DOMAIN });
        return true;
    }

    @rpc({ anonymous: true })
    async logoutImpersonatePropertyUser() {
        const imp: UserModel.Impersonation = JSON.parse(this.httpContext.cookies.get('impersonation') || '{}');
        const oldToken = imp.impersonate.oldToken;
        this.httpContext.cookies.set('impersonation', '', { domain: SESSION_COOKIE_DOMAIN });
        this.httpContext.cookies.set(SESSION_COOKIE_NAME, oldToken, {
            domain: SESSION_COOKIE_DOMAIN,
        });

        this.httpContext.cookies.set('propertyId', '', { domain: SESSION_COOKIE_DOMAIN });
        await this.logout();
        return true;
    }

    @rpc()
    async sendPasswordResetInstructions(email: string) {
        console.log(email);
    }

    @rpc({ httpMethod: 'GET' })
    async getProperties() {
        return await UserModel.getProperties(this.user.email);
    }

    @rpc()
    async updateSignInAt(userId: number) {
        return await UserModel.updateSignInAt(userId);
    }

    @rpc({ anonymous: true })
    async resetPasswordRequest(email: string) {
        // validate request interval
        const result_validate = await UserModel.validateRequestInterval(email);
        if (result_validate === false) return { status: 'error', message: 'not_valid_interval' };

        const result_send = await UserModel.resetPasswordRequest(email);

        if (result_send.status === 'ok') return { status: 'success' };
        else return { isvalid: 'error', message: 'error' };
    }

    @rpc({ anonymous: true })
    async validateResetPasswordToken(randomToken: string) {
        const r = await UserModel.validateResetPasswordToken(randomToken);
        return r;
    }

    @rpc({ anonymous: true })
    async resetPassword(newpassword: string, token: string) {
        const r = await UserModel.resetPassword(newpassword, token);
        return r;
    }

    @rpc({ anonymous: true })
    async setPasswordConfirm(newpassword: string, token: string) {
        const r = await UserModel.setPasswordConfirm(newpassword, token);
        return r;
    }

    @rpc({ httpMethod: 'GET' })
    async listUsers(propertyId: string) {
        const context = await getLoginContext(this.user.id, +propertyId);
        if (context?.property_id) {
            return await UserModel.listUsers(propertyId);
        }
        return null;
    }

    @rpc({ anonymous: true })
    async validateConfirmAccountToken(randomToken: string) {
        const r = await UserModel.validateConfirmAccountToken(randomToken);
        return r;
    }

    @rpc({ anonymous: true })
    async requestConfirmAccount(email: string) {
        return await UserModel.requestConfirmAccountRequest(email);
    }

    @rpc({ httpMethod: 'GET' })
    async getById() {
        return await UserModel.getById(+this.user.id);
    }

    @rpc({ httpMethod: 'GET' })
    async isMemberInAnyBoard() {
        return await UserModel.isMemberInAnyBoard(this.user.id);
    }

    @rpc({ httpMethod: 'GET' })
    async getIsBoardMember(projectId: Project['id']) {
        return await UserModel.getIsBoardMember(this.user.id, projectId);
    }

    @rpc()
    async changePassword(oldPassword: string, newPassword1: string, newPassword2: string) {
        // validate oldpassword

        const r = await UserModel.getUserByUserPassword(+this.user.id, oldPassword);
        if (!r) {
            return { status: 'error', message: 'wrong_password' };
        }

        if (newPassword1 !== newPassword2) {
            return { status: 'error', message: 'password_mismatch' };
        }

        return {
            status: 'success',
            data: await UserModel.changePassword(+this.user.id, newPassword1),
        };
    }
}

export default new UsersApi();
