import UsersApi from '@app/api/public/UsersApi';

const validateToken = async (token: string | (string | null)[] | null, pathname: string) => {
    if (!token) {
        return { isValid: false, message: 'no_valid_token' };
    }

    // check interval
    // const i = await UsersApi.checkRequestInterval();

    if (typeof token === 'object') return { isValid: false, message: 'no_valid_token' };

    const r =
        pathname === '/resetpassword'
            ? await UsersApi.validateResetPasswordToken(token)
            : await UsersApi.validateConfirmAccountToken(token);
    if (r && r.expired) return { isValid: false, message: 'expired' };
    if (r && !r.valid) {
        return { isValid: false, message: 'no_exists' };
    } else if (r && r.valid && !r.expired) {
        return {
            isValid: true,
            token,
        };
    } else return { isValid: false, message: 'error' };
};

export default validateToken;
