import * as ResidenceModel from '../models/Residence';
import * as BookingModel from '../models/Bookings/BookingsModel';
import * as TicketModel from '../models/tickets/Tickets';
import * as AddonsModel from '../models/Addons';
import * as ContentInformationModel from '../models/ContentInformation';
import * as ProjectModel from '../models/Projects';

import { FeatureCard, ResidenceDetail, ResidenceType } from '../models/Residence';
import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import { getBoardLoginContext, getLoginContext } from '../models/Users';
import { Property } from '../models/Property';
export { ResidenceDetail };

class ResidenceApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async id(propertyId: number) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            const r = await ResidenceModel.id(propertyId);
            if (r) r.type = ResidenceType[r.type];
            return r;
        }
        return null;
    }
    @rpc({ httpMethod: 'GET' })
    async list() {
        const r = await ResidenceModel.list(this.user.id);
        return r;
    }

    @rpc({ httpMethod: 'GET' })
    async getById(properyId: Property['id']) {
        const r = await ResidenceModel.getById(properyId);
        return r;
    }

    @rpc({ httpMethod: 'GET' })
    async listDocuments(propertyId: number) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await ResidenceModel.listDocuments(propertyId);
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async isTicketEnabled(propertyID: number) {
        const property = await ResidenceModel.getById(propertyID);
        const isTicketEnabled = await TicketModel.isTicketEnabled(property.project_id);
        if (isTicketEnabled) return true;
        return false;
    }

    @rpc({ httpMethod: 'GET' })
    async listFeatureCardsProperty(propertyId: number) {
        // board
        const board = await getBoardLoginContext(this.user.id, propertyId);

        const property = await ResidenceModel.getById(propertyId);

        // bookings
        const hasBookings = await BookingModel.hasBookingsResources(property.project_id);

        // ticket
        const isTicketEnabled = await TicketModel.isTicketEnabled(property.project_id);

        // addon
        const addon = await AddonsModel.getStatus(this.user.id, propertyId);

        // housing
        const housing = await ContentInformationModel.list(property.project_id);

        // housing
        const project = await ProjectModel.id(property.project_id);

        const cards: FeatureCard[] = [];
        if (housing && housing.length > 0) cards.push({ name: 'housing' });
        if (board) cards.push({ name: 'board' });
        cards.push({ name: 'binder' });
        cards.push({ name: 'about' });
        cards.push({ name: 'document' });
        cards.push({ name: 'todo' });
        if (project.solar_site) cards.push({ name: 'solar-production' });

        if (hasBookings) cards.push({ name: 'booking' });
        if (addon !== 'not_enabled' && addon !== 'not_valid') cards.push({ name: 'addon' });
        if (isTicketEnabled) cards.push({ name: 'ticket' });

        return cards;
    }

    @rpc({ httpMethod: 'GET' })
    async listsharedWith(propertyId: number) {
        const context = await getLoginContext(this.user.id, propertyId);

        return await ResidenceModel.listSharedWith(context.property_id);
    }

    @rpc({ httpMethod: 'GET' })
    async sharedWith(propertyId: number) {
        console.log('sharedWith', propertyId);
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            const r = await ResidenceModel.listSharedWith(propertyId);
            return r;
        }
        return null;
    }
}
export default new ResidenceApi();
