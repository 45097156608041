import { DocumentDetail, DocumentList } from '@app/api/public/DocumentsApi';
import getInboundsPosition from 'lib/getInboundsPosition';
import { L } from '@app/shared/lib/i18n';
import styled from 'lib/styled';
import React, { FC, useEffect, useRef, useState } from 'react';
import DocumentItem from '../../../components/Document/DocumentItem/DocumentItem';
import ThemedIcons from '../../../components/Icon/ThemedIcons';
import { style } from '../../../components/Shared/Style';
import toast from 'react-hot-toast';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import { useDownloadDocument } from '../../../areas/documents/hooks/useDownloadDocument';
import Spinner from '../../Spinner/Spinner';

const DockWrapper = styled.div({
    position: 'relative',
});

const OnHoldWrapper = styled.ul<{ clickPosition?: { x: number; y: number } }>(({ theme, clickPosition }) => ({
    position: 'absolute',
    bottom: clickPosition?.y ? `${clickPosition.y}px` : 0,
    left: clickPosition?.x ? `${clickPosition.x}px` : 0,
    display: 'block',
    visibility: 'hidden',
    padding: '0.5rem',
    backgroundColor: theme.colors.white,
    zIndex: 99,
    color: theme.colors.textColor2,
    borderRadius: style.roundCornerSize.small,
    ['&[aria-expanded="true"]']: {
        visibility: 'visible',
    },
}));

const DownloadIcon = styled(ThemedIcons.Download)({
    height: '1.3rem',
    width: '1.3rem',
});

const CopyIcon = styled(ThemedIcons.Copy)({
    height: '1.3rem',
    width: '1.3rem',
});

const ContextItem = styled.li(({ theme }) => ({
    padding: '1rem 2rem 1rem 1rem ',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '1rem',
    cursor: 'pointer',
}));

type Props<T extends DocumentDetail> = { doc: T } & React.HTMLAttributes<HTMLDivElement>;
const DocumentItemContext = <T extends DocumentDetail>({ doc, ...rest }: Props<T>) => {
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState<{ x: number; y: number }>();
    const contextRef = useRef<HTMLUListElement>(null);
    const { mutate: downloadDocument, status } = useDownloadDocument();

    const { data: url } = useGetSignedUrl(doc.document_data);

    const handleContext = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(true);
        const { x, y } = getInboundsPosition(e, contextRef);
        setPosition({ x, y });
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;
            if (target && contextRef.current && !contextRef.current.contains(target)) {
                setIsOpen(false);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [contextRef]);

    const handleCopy = async () => {
        if (!url) return;
        await navigator?.clipboard?.writeText(url);
        toast(`url ${L('copied_to_clipboard')}`);
        setIsOpen(false);
    };
    const handleDownload = () => {
        if (!url) return;
        downloadDocument(
            { url, name: doc.document_data.original.metadata.filename },
            {
                onSuccess: () => {
                    setIsOpen(false);
                },
                onError: () => {
                    toast(`${L('could_not_download_document')}`);
                },
            }
        );
    };

    return (
        <DockWrapper onContextMenu={handleContext} {...rest}>
            <OnHoldWrapper ref={contextRef} clickPosition={position} aria-expanded={isOpen}>
                {navigator?.clipboard && (
                    <ContextItem onClick={() => handleCopy()}>
                        <CopyIcon />
                        <span>{L('copy')}</span>
                    </ContextItem>
                )}
                <ContextItem onClick={handleDownload}>
                    {status === 'loading' ? <Spinner /> : <DownloadIcon />}
                    <span>{L('download')}</span>
                </ContextItem>
            </OnHoldWrapper>
            <DocumentItem document={doc} />
        </DockWrapper>
    );
};

export default DocumentItemContext;
