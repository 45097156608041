import React from 'react';
import styled from 'lib/styled';
import { style } from '../Shared/Style';
import { useTheme } from 'emotion-theming';
import { ThemeType } from '@app/api/models/Theme';

interface Props {
    error?: string;
    errorInfo?: string;
}

const Error = styled.div((props: { theme: ThemeType }) => {
    return {
        width: '80%',
        margin: '0 auto',
        color: props.theme?.colors?.errors || 'red',
        fontSize: style.fontSize.centi,
        lineHeight: style.lineHeight.normal,
        textAlign: 'center',
    };
});

const Info = styled.p((props: { theme: ThemeType }) => {
    return {
        width: '80%',
        margin: '0 auto',
        color: props.theme?.colors?.errors || 'red',
        fontSize: style.fontSize.centi,
        lineHeight: style.lineHeight.normal,
        textAlign: 'center',
    };
});

export const ErrorText = (p: Props) => {
    const theme = useTheme<ThemeType>();
    return (
        <Error theme={theme}>
            {p.error && <Info theme={theme}>{p.errorInfo}</Info>}
            {p.error}
        </Error>
    );
};
