import styled from 'lib/styled';
import React from 'react';
import Label from '../../components/Forms/Label';
import { PageBackground } from '../../components/Layout/PageBackground';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { style } from '../../components/Shared/Style';
import { colors } from '../../components/Shared/Style/colors';
import H1 from '../../components/Texts/H1';
import H2 from '../../components/Texts/H2';
import CleanHeader from '../../components/Header/HeaderOnlyBackArrow';
import { L } from '@app/shared/lib/i18n';
import { Loading } from '../../components/Loading/Loading';
import { useGetBoardMemberContact } from './hooks/boardMemberQueries';
import { small } from 'lib/imageSize';
import useGetSignedUrlId from 'hooks/useGetSignedUrlId';
import Avatar from '../../components/thread/avatar/Avatar';
import Input from '../../components/Forms/Input';

const StyledLable = styled(Label)({
    marginTop: '18px',
});

const Main = styled.main({
    width: '100%',
    padding: `0px ${style.margin.m}px 4rem ${style.margin.m}px`,
});

const StyledH1 = styled(H1)({
    margin: '0px',
    marginLeft: style.margin.s,
});

const SubHeading = styled(H2)(() => {
    return {
        margin: '0px',
        fontSize: '18px',
        color: colors.textColor1,
        fontWeight: 'normal',
        [`@media screen and (min-width: ${desktop}px)`]: {
            display: 'none',
        },
    };
});

const StyledLink = styled.a(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 300,
    color: theme.colors.tabActive,
}));

const GridAvatar = styled(Avatar)({
    gridArea: 'avatar',
    justifySelf: 'start',
    alignSelf: 'start',
    width: '60px',
    height: '60px',
});

const StyledUserBox = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const ContactsBoardDetails = () => {
    const { data: BoardMemberContact } = useGetBoardMemberContact();
    const { data: url } = useGetSignedUrlId(BoardMemberContact?.profile_image_data?.original?.id, small);

    if (!BoardMemberContact) return <Loading />;
    return (
        <PageBackground>
            <CleanHeader />
            <Main>
                <SubHeading>{L('contacts')}</SubHeading>
                <StyledUserBox>
                    {url ? (
                        <GridAvatar url={url} />
                    ) : (
                        BoardMemberContact.name && <GridAvatar name={BoardMemberContact.name} />
                    )}
                    <StyledH1>{BoardMemberContact.name}</StyledH1>
                </StyledUserBox>

                <StyledLable name="name" title={L('name')} />
                <Input disabled={true} id="name" value={BoardMemberContact.name || ''} />

                <StyledLable name="phone_number" title={L('contact_number')} />

                <StyledLink href={`tel:${BoardMemberContact.phone_number}`}>
                    <Input
                        style={{ color: colors.primary }}
                        disabled={true}
                        id="name"
                        value={BoardMemberContact.phone_number || ''}
                    />
                </StyledLink>

                <StyledLable name="email" title={L('contact_email')} />

                <StyledLink href={`mailto:${BoardMemberContact.email}`}>
                    <Input
                        style={{ color: colors.primary }}
                        disabled={true}
                        id="name"
                        value={BoardMemberContact.email || ''}
                    />
                </StyledLink>

                <StyledLable name="city" title={L('profile_city')} />
                <Input disabled={true} id="name" value={BoardMemberContact.address.city || ''} />

                <StyledLable name="postalcode" title={L('postal_code')} />

                <Input disabled={true} id="name" value={BoardMemberContact.address.postal_code || ''} />

                <StyledLable name="street" title={L('street')} />

                <Input disabled={true} id="name" value={BoardMemberContact.address.addressline1 || ''} />

                <StyledLable name="field" title={L('role')} />

                <Input disabled={true} id="name" value={BoardMemberContact.role || ''} />
            </Main>
        </PageBackground>
    );
};

export default ContactsBoardDetails;
