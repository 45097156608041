import React from 'react';
import { useParams } from 'react-router-dom';
import H2 from '../../components/Texts/H2';
import { useBoardInformationList, useDocumentList } from './hooks';
import DocumentGroupBy from '../../components/Document/DocumentItem/DocumentGroupBy';
import styled from 'lib/styled';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import FeedImage from '../feed/components/FeedImage/FeedImage';
import { RawHtml } from '../../components/Layout/RawHtml';
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs/Breadcrumbs';
import { L } from '@app/shared/lib/i18n';
import { medium } from 'lib/imageSize';

const ContentMain = styled.div({
    width: '100%',
    padding: '0px 0px 0px 0px',
    maxWidth: '60ch',
    marginBottom: '2rem',
    '& img': {
        maxWidth: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
});
const StyledH2 = styled(H2)({
    marginTop: '0px',
});

const Wrapper = styled.div({
    paddingBottom: '3rem',
});

const StyledFeedImage = styled(FeedImage)({
    marginBottom: '1rem',
});

const BoardPagePost = ({ ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
    const { boardId, projectId } = useParams<{
        boardId: string;
        projectId: string;
    }>();

    const { data: info } = useBoardInformationList();
    const { data: documents } = useDocumentList();

    const informationItem = info?.find((item) => boardId && item.id === +boardId);
    const { data: FeedImgUrl } = useGetSignedUrl(informationItem?.cover_image_data, medium);

    if (!info || !documents || !boardId) return null;
    const title = info.find((item) => item.id === +boardId)?.title || '';
    return (
        <Wrapper {...rest}>
            <Breadcrumbs style={{ marginBottom: '2rem' }}>
                <Breadcrumbs.Item to={`/projects/${projectId}/board`}>{L('board_information')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{title}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <StyledH2>{title}</StyledH2>
            <ContentMain>
                {FeedImgUrl && <StyledFeedImage url={FeedImgUrl} />}
                {informationItem?.description && <RawHtml content={informationItem.description} />}
            </ContentMain>

            <DocumentGroupBy documents={documents} />
        </Wrapper>
    );
};

export default BoardPagePost;
