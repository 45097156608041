import { ContentInformationDocumentDetail, DocumentData } from '../models/ContentInformation';
import * as ContentInformationModel from '../models/ContentInformation';
import { BaseApi } from '../lib/BaseApi';
import { rpc } from 'koa-rpc-call';
import { getLoginContext, getProjectContext, getProjectLoginContext } from '../models/Users';
import { formatInputDateTimeDayjs } from '@app/shared/lib/formatting';
import dayjs from 'dayjs';
export { ContentInformationDocumentDetail, DocumentData };
export { IPageOf } from '../lib/db';

class ContentInformationApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async list(projectId: number) {
        const context = await getProjectContext(this.user.id, projectId);
        if (!context) return null;
        const projectItems = await ContentInformationModel.list(context.project_id);
        const accountItems = await ContentInformationModel.listAccount(context.project_id);
        return [...accountItems, ...projectItems];
    }

    @rpc({ httpMethod: 'GET' })
    async get(propertyId: number, id: number) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.project_id) {
            return await ContentInformationModel.get(context.project_id, id);
        }
        return null;
    }

    @rpc()
    async listDocuments(id: string, type?: ContentInformationModel.FeedDocType) {
        return await ContentInformationModel.listDocuments(id, type);
    }

    @rpc()
    async listDocumentGroups(propertyId: number) {
        const context = await getLoginContext(this.user.id, +propertyId);
        if (context?.property_id) {
            return await ContentInformationModel.listDocumentGroups(context.property_id);
        }
        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async saveCalendarParticipation(
        participation: Partial<ContentInformationModel.CalendarParticipation>,
        projectId: number
    ) {
        const context = await getProjectLoginContext(this.user.id, projectId);

        if (context?.project_id && participation.content_calendar_item_id) {
            const calendar = await ContentInformationModel.getCalendar(
                projectId,
                participation.content_calendar_item_id
            );
            if (
                (calendar?.last_sign_date &&
                    formatInputDateTimeDayjs(dayjs()) < formatInputDateTimeDayjs(calendar?.last_sign_date)) ||
                (!calendar?.last_sign_date &&
                    formatInputDateTimeDayjs(dayjs()) < formatInputDateTimeDayjs(calendar?.event_date))
            )
                return await ContentInformationModel.saveCalendarParticipation(participation);
        }
        throw new Error(`Invalid dates `);
    }

    @rpc({ httpMethod: 'GET' })
    async removeCalendarParticipation(
        participation: Partial<ContentInformationModel.CalendarParticipation>,
        projectId: number
    ) {
        if (!participation.id) throw new Error('Wrong data');
        const context = await getProjectLoginContext(this.user.id, projectId);
        if (context?.project_id && participation?.content_calendar_item_id) {
            const calendar = await ContentInformationModel.getCalendar(
                projectId,
                participation?.content_calendar_item_id
            );

            if (
                (calendar?.last_sign_date &&
                    formatInputDateTimeDayjs(dayjs()) < formatInputDateTimeDayjs(calendar?.last_sign_date)) ||
                (!calendar?.last_sign_date &&
                    formatInputDateTimeDayjs(dayjs()) < formatInputDateTimeDayjs(calendar?.event_date))
            )
                return await ContentInformationModel.removeCalendarParticipation(participation.id, this.user.id);
        }
        return null;
    }
}

export default new ContentInformationApi();
