import React, { FC } from 'react';
import { style } from '../../Shared/Style';
import styled from 'lib/styled';

type Props = {
    title?: string;
    TitleArea?: React.ReactNode;
    error?: string;
    name?: string;
    width?: string;
};

const Field = styled.label((props: { width?: string }) => ({
    display: 'block',
    width: props.width,
}));

const Text = styled.div((props: { theme?: any }) => ({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: props.theme?.colors?.secondary || style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
}));

const Title = styled.span({});

const Errors = styled.span({
    color: 'red',
    fontSize: style.fontSize.mini,
    position: 'absolute',
    top: '11px',
    right: '0px',
});

type LabelProps = React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

export const Label: FC<LabelProps & Props> = (p) => {
    const errorText = p.error;
    const hasError = errorText !== undefined;
    return (
        <Field width={p.width} htmlFor={p.htmlFor || p.name} {...p}>
            <Text className={'label_text'}>
                <Title>{p.title || p.TitleArea}</Title>
                {hasError && <Errors>{errorText}</Errors>}
            </Text>
            {p.children}
        </Field>
    );
};

export default Label;
