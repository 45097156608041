import { useQuery } from 'react-query';
import ResidenceApi from '@app/api/public/ResidenceApi';
import { useParams } from 'react-router-dom';

const useGetResidenceSharedWith = () => {
    const { propertyId } = useParams<{ propertyId: string }>();

    return useQuery('residenceSharedWith', () => ResidenceApi.sharedWith(+propertyId));
};
export default useGetResidenceSharedWith;
