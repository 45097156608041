import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../search.svg';

const ThemedSearch = styled(Icon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',

        '& g': {
            stroke: theme?.colors?.formPlaceholder || colors.formPlaceholder,
        },
        '& path': {
            stroke: theme?.colors?.formPlaceholder || colors.formPlaceholder,
        },
        '& polyline': {
            stroke: theme?.colors?.formPlaceholder || colors.formPlaceholder,
        },
        '& circle': {
            stroke: theme?.colors?.formPlaceholder || colors.formPlaceholder,
        },
    };
});
const Search: FC = ({ ...rest }) => {
    return <ThemedSearch {...rest} />;
};

export default Search;
