import React from 'react';
import styled from 'lib/styled';
import { ThemeType } from '@app/api/models/Theme';
import { colors } from '../Shared/Style/colors';

const HeroImage = styled.div((props: { url?: string | undefined | null; gradient?: boolean; theme: ThemeType }) => ({
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    backgroundPosition: 'center',
    color: props?.theme?.colors?.white || colors.white,
    padding: '1rem',
    aspectRatio: '4/3',
    width: '100%',
    display: 'flex',
    marginBottom: '1.5rem',
    backgroundColor: props?.theme?.colors?.black90 || colors?.black90,
    backgroundImage: props.url
        ? props.gradient
            ? `linear-gradient(0deg, rgba(0,0,0,0.48783263305322133) 0%, rgba(0,0,0,0.0984768907563025) 37%, rgba(255,255,255,0) 55%, rgba(255,255,255,0) 64%, rgba(0,0,0,0.1741071428571429) 81%, rgba(0,0,0,0.41220238095238093) 100%), url(${props.url})`
            : `url(${props.url})`
        : ``,
}));

export type Props = {
    url?: string | undefined | null;
    gradient?: boolean;
    className?: string;
};
const Hero: React.FC<Props> = ({ url, gradient, children, className }) => {
    return (
        <HeroImage className={className} gradient={gradient} url={url}>
            {children}
        </HeroImage>
    );
};

export default Hero;
