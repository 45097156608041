import styled from 'lib/styled';
import React from 'react';
import { desktop } from '@app/web/components/MediaQuery/breakpoints';
import { useRef } from 'react';
import { useOnUIEvent } from '@app/web/lib/hooks';
import context from '@app/web/lib/context';
import { observer } from 'mobx-react';
import { style } from './Shared/Style';
import { useDocumentSize } from './MediaQuery/use-document-size';
import { ThemeType } from '@app/api/models/Theme';
import { useTheme } from 'emotion-theming';

const Div = styled.div<{ minHeight: number; theme: ThemeType }>((p) => ({
    backgroundColor: p.theme?.colors?.pageBackground || style.colors.pageBackground,
    padding: `0px`,
    borderRadius: style.margin.s,
    // boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1)',
    margin: '0 auto',
    minHeight: p.minHeight,
    // overflow: 'hidden',

    [`@media screen and (min-width: ${desktop}px)`]: {
        width: desktop,
    },
}));

const ContentArea: React.FC = ({ children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [, height] = useDocumentSize();

    const defineViewport = (em: HTMLDivElement) => {
        const { left, width } = em.getBoundingClientRect();
        context.define(left, width);
    };

    useOnUIEvent(
        window,
        'resize',
        () => {
            defineViewport(ref.current!);
        },
        true,
        []
    );

    return (
        <Div ref={ref} minHeight={height}>
            {children}
        </Div>
    );
};

export default observer(ContentArea);
