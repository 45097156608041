import React from 'react';
import styled from 'lib/styled';
import { ThemeType } from '@app/api/models/Theme';
import H1 from '../../../components/Texts/H1';
import Status from '../../../components/thread/status/Status';
import SmallTab from '../../../components/Tabs/SmallTab';
import { colors } from '../../../components/Shared/Style/colors';
import { margin } from '../../../components/Shared/Style/margin';
import { useParams } from 'react-router-dom';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import { L } from '@app/shared/lib/i18n';
import { Ticket } from '@app/api/public/TicketsApi';

const GridContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
    gridTemplateAreas: `
    "title status"
    "link ."
    `,
    padding: `0px ${margin.m}px`,

    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
    },
});

const GridH1 = styled(H1)({
    fontSize: '1.5rem',
    maxWidth: '100%',
    padding: 0,
    margin: 0,
    lineHeight: 'normal',
    gridArea: 'title',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
const GridStatus = styled(Status)({
    gridArea: 'status',
});
const GridSmaltab = styled(SmallTab)(({ theme }: { theme: ThemeType }) => {
    return {
        gridArea: 'link',
        color: theme?.colors?.tabActive || colors.tabActive,
    };
});

const TicketSummary: React.FC<Ticket> = ({ id, title, status }) => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return (
        <GridContainer>
            <GridH1>{title}</GridH1>
            <GridStatus status={status} />
            <GridSmaltab to={`/properties/${propertyId}/tickets/${id}/status`}>{L('ticket_view_more')}</GridSmaltab>
        </GridContainer>
    );
};

export default TicketSummary;
