import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../button-record.svg';

const ThemedOptionsNotChecked = styled(Icon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: theme?.colors?.primary || colors.primary,
        },
        '& circle': {
            stroke: theme?.colors?.primary || colors.primary,
        },
    };
});
const OptionsNotChecked: FC = ({ ...rest }) => {
    return <ThemedOptionsNotChecked {...rest} />;
};

export default OptionsNotChecked;
