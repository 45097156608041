import styled from 'lib/styled';

type Props = {
    active: boolean;
};

const Wrapper = styled.div<Props>(
    ({ active }) =>
        active && {
            height: 0,
            overflow: 'hidden',
        }
);

export default Wrapper;
