import React, { FC } from 'react';
import { L } from '@app/shared/lib/i18n';
import StickyBarList from './StickyBarList';
import StickyBarItem from './StickyBarItem';
import ThemeIcons from '../Icon/ThemedIcons';
import styled from 'lib/styled';
import { ThemeType } from '../../../api/models/Theme';

const StyledThemedTile = styled(ThemeIcons.Tile)(({ theme }: { theme: ThemeType }) => {
    return {
        '& g': {
            stroke: theme?.colors?.black80,
        },
        '& path': {
            stroke: theme?.colors?.black80,
            strokeWidth: '3px',
        },
        '& line': {
            stroke: theme?.colors?.black80,
        },
    };
});

const StyledThemedProperty = styled(ThemeIcons.Home)(({ theme }: { theme: ThemeType }) => {
    return {
        '& g': {
            stroke: theme?.colors?.black80,
        },
        '& path': {
            stroke: theme?.colors?.black80,
        },
        '& polyline': {
            stroke: theme?.colors?.black80,
        },
    };
});

const StyledThemedContact = styled(ThemeIcons.Contacts)(({ theme }: { theme: ThemeType }) => {
    return {
        '& g': {
            stroke: theme?.colors?.black80,
        },
        '& path': {
            stroke: theme?.colors?.black80,
        },
        '& polyline': {
            stroke: theme?.colors?.black80,
        },
        '& line': {
            stroke: theme?.colors?.black80,
        },
        '& rect': {
            stroke: theme?.colors?.black80,
        },
        '& circle': {
            stroke: theme?.colors?.black80,
        },
    };
});

const StickyBarContent: FC = () => {
    return (
        <StickyBarList>
            <StickyBarItem to="/" Icon={StyledThemedProperty} description={L('start')} />
            <StickyBarItem
                style={{ position: 'relative' }}
                to="/properties"
                Icon={StyledThemedTile}
                description={L('my_ourliving')}
            />

            <StickyBarItem to="/contacts" Icon={StyledThemedContact} description={L('contacts')} />
        </StickyBarList>
    );
};

export default StickyBarContent;
