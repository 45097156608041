import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import * as BookingsModel from '../models/Bookings/BookingsModel';
import { BookingResource, BookingSlot, BookedSlot } from '../models/Bookings/BookingsModel';
import * as DocumentModel from '../models/Documents';
import dayjs from 'dayjs';
import isBlocked from '../lib/bookings/isBlocked';
import isInBookingWindow from '../lib/bookings/isInBookingWindow';
import { Project } from '../models/Projects';
import * as CommunityBookingNotificationModel from '../models/Bookings/CommunityBookingNotification';
import { L } from '@app/shared/lib/i18n';
export { BookingResource, BookingSlot, BookedSlot };

class BookingsApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async getBookingResource(resourceId: BookingResource['id']) {
        return await BookingsModel.getBookingsResource(resourceId);
    }
    @rpc({ httpMethod: 'GET' })
    async getBookingResources(projectId: Project['id']) {
        try {
            return await BookingsModel.getBookingResources(this.user.id, projectId);
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    @rpc({ httpMethod: 'GET' })
    async getUserResourceBookings(bookingResourceId?: number) {
        try {
            const br: number | undefined = bookingResourceId === undefined ? undefined : +bookingResourceId;
            return await BookingsModel.getUserResourceBookings(this.user, br);
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    @rpc({ httpMethod: 'GET' })
    async getBookings(bookingResourceId: number) {
        try {
            return await BookingsModel.getBookings(bookingResourceId);
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    @rpc({ httpMethod: 'GET' })
    async saveBooking(booking: BookingsModel.SaveBooking) {
        try {
            // Check not exceed max number of bookings
            if (booking.booking_resource_item_id) {
                const rules = await BookingsModel.getBookingsRules(+booking.booking_resource_item_id);
                const userBookings = await BookingsModel.getUserResourceBookings(this.user, rules.id);

                const date = dayjs(booking.booking_date);

                const block = isBlocked({
                    userBookings: userBookings,
                    maxBookingInterval: rules.max_booked_interval,
                    maxBookings: rules.max_booked_slots,
                    date,
                    max_days_booked: rules.max_booked_days,
                    start_date: rules.start_date,
                    end_date: rules.end_date,
                });
                // validate rules
                if (!isInBookingWindow(booking.booking_date || dayjs(), rules.booking_window))
                    return { status: 'blocked', message: L('booking_window_out_of_scope') };
                if (block) return { status: 'blocked', message: L('booking_exceeded') };
            } else return;

            // check if already booked
            if (booking && booking.booking_date && booking.booking_slot_id && booking.booking_resource_item_id) {
                const r = await BookingsModel.doBookingExist(
                    +booking.booking_resource_item_id,
                    +booking.booking_slot_id,
                    booking.booking_date
                );

                if (r) return { status: 'error', message: L('booking_exists') };
                else {
                    const r = await BookingsModel.saveBooking(booking, this.user);
                    const bookingInfo = await BookingsModel.getBookingInfo({ ...r });
                    if (r && bookingInfo) {
                        await CommunityBookingNotificationModel.sendNewBookingNotification(bookingInfo);
                        return { status: 'ok', value: r };
                    } else return { status: 'error', message: r };
                }
            } else throw new Error('Parameters are missing');
        } catch (err) {
            console.log(err);
            this.httpContext.status = 403;
            return err.message;
        }
    }

    @rpc({ httpMethod: 'GET' })
    async removeBooking(booking: BookingsModel.RemoveBooking) {
        const bookingInfo = await BookingsModel.getBookingInfo({ user_id: this.user.id, ...booking });
        const r = await BookingsModel.removeBooking(booking, this.user);

        if (r && bookingInfo) {
            return await CommunityBookingNotificationModel.sendNewBookingNotification(bookingInfo, true);
        }
        throw new Error('Could not remove booking');
    }

    @rpc({ httpMethod: 'GET' })
    async resourceDocuments(bookingResourceId: string) {
        return await DocumentModel.listByDocumentable(+bookingResourceId, 'BookingResource');
    }

    @rpc({ httpMethod: 'GET' })
    async getBookingSlots(bookingResourceId: BookingResource['id']) {
        return await BookingsModel.getBookingSlots(bookingResourceId);
    }

    @rpc({ httpMethod: 'GET' })
    async hasBookingsResources(projectId: Project['id']) {
        return await BookingsModel.hasBookingsResources(projectId);
    }
}

export default new BookingsApi();
