import * as React from 'react';
import H1 from '../../components/Texts/H1/H1';
import { L } from '@app/shared/lib/i18n';
import styled from 'lib/styled';
import Search from '../../components/Inputs/Search/Search';
import NotificationHandler from '../../components/Notice/notificationHandler/NotificationHandler';
import { useNotificationsList } from '../residence/hooks';
import TabList from '../../components/Tabs/TabList';
import SmallTab from '../../components/Tabs/SmallTab';
import HeaderOnlyBackArrow from '../../components/Header/HeaderOnlyBackArrow';
import { PageBackground } from '../../components/Layout/PageBackground';
import { FixedBar } from '../../components/StickyBar/FixedBar';
import StickyBarContent from '../../components/StickyBar/StickyBarContent';
import { MediaQuery } from '../../components/MediaQuery/MediaQuery';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { style } from '../../components/Shared/Style';

const StyledMain = styled.main({
    padding: ` 0px ${style.margin.m}px`,
    width: '100%',
    [`@media screen and (min-width: ${desktop}px`]: {
        padding: '0px',
    },
});

const Title = styled(H1)({
    margin: '0px',
});

const StyledTabList = styled(TabList)({
    marginBottom: '24px',
    marginTop: '12px',
});

const NotificationsCenter: React.FC = () => {
    //Input value
    const [value, setValue] = React.useState('');
    const notificationList = useNotificationsList();

    //Notification list
    const NoticationTypes = notificationList.data?.map((notification) => notification.notifiable_type) || [];

    // list of unique notifiable_type
    const uniqueNotifications = [...new Set(NoticationTypes)];

    const NotificationTabs = uniqueNotifications.map((notificationType) => {
        return (
            <SmallTab key={notificationType} to={`/notificationsCenter/${notificationType}`}>
                {L(notificationType)}
            </SmallTab>
        );
    });

    return (
        <PageBackground padding={'0px 0px 75px 0px'}>
            <MediaQuery off="desktop">
                <HeaderOnlyBackArrow to="/" />
            </MediaQuery>
            <StyledMain>
                <Title>{L('my_notifications')}</Title>
                <Search setValue={setValue} />
                <StyledTabList>
                    <SmallTab exact to={'/notificationsCenter'}>
                        {L('all')} {L('notifications')}
                    </SmallTab>
                    {NotificationTabs}
                </StyledTabList>
                <NotificationHandler filter={value} />
            </StyledMain>
            <MediaQuery off="desktop">
                <FixedBar>
                    <StickyBarContent />
                </FixedBar>
            </MediaQuery>
        </PageBackground>
    );
};

export default NotificationsCenter;
