import { L } from '@app/shared/lib/i18n';
import styled from 'lib/styled';
import { useObserver } from 'mobx-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Card from '../../components/Layout/Card';
import { Page } from '../../components/Layout/Page';
import InstallPwaButton from '../../components/Pwa/InstallPwaButton';
import { style } from '../../components/Shared/Style';
import useLogout from '../../hooks/useLogout';

const StyledNavLinkText = styled(NavLink)({
    color: style.colors.black,
    textDecoration: 'none',
    marginTop: style.margin.m,
    fontSize: style.fontSize.gamma,
    alignSelf: 'start',
    padding: '12px 0px 0px 0px',
});

const Title = styled.div({
    marginTop: style.margin.m,
    alignSelf: 'start',
    fontSize: style.fontSize.gamma,
    cursor: 'pointer',
    padding: '12px 0px 0px 0px',
});

const StyledCard = styled(Card)({
    paddingLeft: '18px',
    height: '100vh',
});

export const ProfilePage = () => {
    const { mutate: logout } = useLogout();

    return useObserver(() => {
        return (
            <Page>
                <StyledCard>
                    <StyledNavLinkText style={{ marginTop: '36px' }} to={'/profile/settings'}>
                        {L('my_profile')}
                    </StyledNavLinkText>
                    <StyledNavLinkText to={'/profile/notifications'}>{L('notifications')}</StyledNavLinkText>
                    <StyledNavLinkText to={'/profile/email-settings'}>{L('email_settings')}</StyledNavLinkText>
                    <InstallPwaButton />
                    <Title
                        onClick={(e) => {
                            e.stopPropagation();
                            logout();
                        }}
                    >
                        {L('log_out')}
                    </Title>
                </StyledCard>
            </Page>
        );
    });
};
