import React from 'react';
import styled from 'lib/styled';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)(({ theme }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '19px',
        color: theme?.colors?.menuTabInactive,

        boxSizing: 'border-box',
        display: 'inline-block',
        marginRight: '16px',
        paddingTop: '16px',
        textDecoration: 'none',
        paddingBottom: '8px',

        '&:hover': {
            color: theme?.colors?.menuTabActive,
            cursor: 'pointer',
            textDecoration: 'none',
        },
        '&.active': {
            color: theme?.colors?.menuTabActive,
            textDecoration: 'none',
            borderBottom: `1px solid ${theme?.colors?.menuTabActive}`,
        },
    };
});

const SmallTab: React.FC<NavLinkProps> = ({ children, ...rest }) => {
    return (
        <li>
            <StyledNavLink {...rest}>{children}</StyledNavLink>
        </li>
    );
};

export default SmallTab;
