import PushNotificationApi from '@app/api/public/PushNotificationApi';
import { IUser } from '@app/api/public/UsersApi';
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';

export const getTokenForIos = async (user: IUser) => {
    if (!user) return;
    saveIosToken();
};

const saveIosToken = async () => {
    const wasPermissionGiven = await PushNotifications.requestPermissions();
    await PushNotifications.register();
    if (wasPermissionGiven.receive === 'granted') {
        const token = await FCM.getToken();
        console.log('token', token.token);
        PushNotificationApi.saveToken(token.token, 'ios');
    }
};
