import styled from 'lib/styled';
import React from 'react';
import RightArrow from '../../Icon/ThemedIcons/RightArrow';

const StyledRightArrow = styled(RightArrow)({
    height: '24px',
    width: '24px',
});

const Arrow = ({ ...rest }) => {
    return <StyledRightArrow {...rest} />;
};

export default Arrow;
