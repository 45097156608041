import styled from 'lib/styled';
import React, { FC } from 'react';
import { desktop } from '../MediaQuery/breakpoints';
import { PageBackground } from '../Layout/PageBackground';
import AreaNavDesktop from '../../areas/residence/ListPage/Components/AreaNavDesktop';

const StyledDesktopNav = styled.nav({
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

const Card = styled.div({
    width: '100%',
    margin: '0px',
    padding: '0px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        paddingTop: '2rem',
        display: 'grid',
        gridTemplateAreas: `
        "nav main"
        `,
        gridTemplateColumns: 'max-content 1fr',
        columnGap: '2rem',
    },
});

const PropertiesLayout: FC = ({ children }) => {
    return (
        <PageBackground style={{ marginBottom: '60px' }}>
            <Card>
                <StyledDesktopNav>
                    <AreaNavDesktop />
                </StyledDesktopNav>
                <main>{children}</main>
            </Card>
        </PageBackground>
    );
};

export default PropertiesLayout;
