import React from 'react';
import styled from 'lib/styled';
import { style } from '../../../Shared/Style';
import HeaderStart from '../../HeaderStart';
import HeaderMenuList from '../components/HeaderMenuList';
import { NavLink, Route, Switch } from 'react-router-dom';

const HeaderContent = styled.header((props) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `${style.margin.m}px 0px`,
    width: '100%',
    backgroundColor: props.theme.colors.pageBackground,
}));

const MenuArea = styled.div({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2rem',
});
const Logo = styled.img({
    width: '100%',
    maxHeight: '70px',
    maxWidth: '350px',
});

const Link = styled(NavLink)({
    cursor: 'pointer',
});

const AvatarAndBell = styled(HeaderStart)({
    padding: '0rem',
    width: '15%',
});

function HeaderDesktopNew() {
    const matchedUrlName = window.location.origin.match(/\/{2}(\w+)\./i);

    const handleImgError = (e) => {
        e.currentTarget.src = 'https://s3-eu-west-1.amazonaws.com/public.ourliving.dse/images/ourliving.svg';
    };

    return (
        <HeaderContent>
            <MenuArea>
                {matchedUrlName && matchedUrlName?.length > 1 && matchedUrlName[1] !== 'residence' ? (
                    <Link to="/">
                        <Logo
                            onError={handleImgError}
                            src={`https://s3-eu-west-1.amazonaws.com/public.ourliving.se/images/${matchedUrlName[1]}.svg`}
                        />
                    </Link>
                ) : (
                    <Link to="/">
                        <Logo src={`https://s3-eu-west-1.amazonaws.com/public.ourliving.se/images/ourliving.svg`} />
                    </Link>
                )}
                <HeaderMenuList />
            </MenuArea>
            <AvatarAndBell />
        </HeaderContent>
    );
}

export default HeaderDesktopNew;
