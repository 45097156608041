import BoardContentInformationApi from '@app/api/public/BoardContentInformationApi';
import ResidenceApi from '@app/api/public/ResidenceApi';
import UsersApi from '@app/api/public/UsersApi';

import { useQuery } from 'react-query';
import BookingsApiv2 from '@app/api/public/BookingsApiv2';

const areaCardQuerykeys = {
    all: ['areaCard'],
    properties: () => [...areaCardQuerykeys.all, 'property'],
    property: (id?: number) => [...areaCardQuerykeys.all, 'property', id],
    boardMember: (id?: number) => [...areaCardQuerykeys.all, 'boardMember', id],
    infoPages: (id?: number) => [...areaCardQuerykeys.all, 'infoPages', id],
    hasBookingsResources: (id?: number) => [...areaCardQuerykeys.all, 'hasBookingsResources', id],
};

const getAreaCardList = async (propertyId?: number) => {
    if (!propertyId) return [];
    return (await ResidenceApi.listFeatureCardsProperty(propertyId)) || [];
};

export const useResidenceAreaCardList = (propertyId: number) => {
    return useQuery(areaCardQuerykeys.property(propertyId), () => getAreaCardList(propertyId), {
        enabled: !!propertyId,
    });
};

const isBoardMember = async (projectId: number) => {
    if (!projectId) return [];
    return await UsersApi.getIsBoardMember(projectId);
};

export const useIsBoardMember = (projectId: number) => {
    return useQuery(areaCardQuerykeys.boardMember(projectId), () => isBoardMember(projectId), {
        enabled: !!projectId,
    });
};

const hasInfoPages = async (projectId: number) => {
    if (!projectId) return false;
    const items = await BoardContentInformationApi.list(projectId);
    if (!items) return false;
    return items?.length > 0 ? true : false;
};

const hasBookingsResources = async (projectId: number) => {
    if (!projectId) return false;
    const hasResources = await BookingsApiv2.hasBookingsResources(projectId);
    return hasResources;
};

export const useHasInfoPages = (projectId: number) => {
    return useQuery(areaCardQuerykeys.infoPages(projectId), () => hasInfoPages(projectId), {
        enabled: !!projectId,
    });
};

export const useHasBookingsResources = (projectId: number) => {
    return useQuery(areaCardQuerykeys.hasBookingsResources(projectId), () => hasBookingsResources(projectId), {
        enabled: !!projectId,
    });
};
