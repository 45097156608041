import * as ProfilesModel from '../models/Profile';
import { ProfileDetail } from '../models/Profile';

import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import { User } from '../models/Users';
export { ProfileDetail };
import * as DocumentModel from '../models/Documents';

class ProfilesApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async id() {
        return await ProfilesModel.id(this.user.id);
    }

    @rpc({ httpMethod: 'GET' })
    async byId(id: User['id']) {
        return await ProfilesModel.id(id);
    }

    @rpc()
    async save(profile: Partial<ProfilesModel.ProfileDetail>) {
        return await ProfilesModel.save(profile, this.user.id);
    }

    @rpc()
    async saveProfileImage(file?: File) {
        let doc: DocumentModel.DocumentDetail;
        if (file) {
            try {
                const id = await DocumentModel.uploadToAwsStore(file);
                doc = await DocumentModel.fileToDocumentDetail(file, id, 'store');
                return await ProfilesModel.saveProfileImage(this.user.id, doc.document_data.original);
            } catch (err) {
                console.log(err?.message || err);
            }
        }
        return null;
    }

    @rpc()
    async removeProfileImage(id: string) {
        await DocumentModel.deleteProfileImageFromAWS('200x200/' + id);
        await DocumentModel.deleteProfileImageFromAWS(id);
        return await ProfilesModel.removeProfileImage(this.user.id);
    }
}
export default new ProfilesApi();
