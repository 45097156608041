import { Ticket } from '@app/api/models/Tickets';
import React, { FC } from 'react';
import useGetEmailFeatureInfo from '../hooks/useGetEmailFeatureInfo';
import SentTicketPage from '../SentTicketPage/SentTicketPage';

type Props = { ticket: Ticket };
const RenderSentTicket: FC<Props> = ({ ticket }) => {
    const { data } = useGetEmailFeatureInfo();

    return <SentTicketPage ticket={ticket} serviceEmail={data?.service_url} />;
};

export default RenderSentTicket;
