import { useMutation, useQueryClient } from 'react-query';
import ContactsApi from '@app/api/public/ContactsApi';
import * as ContactsModel from '@app/api/models/Contacts';
import { getContactQueryKeys } from './contactQueries';

const addContact = async (contactDetails: ContactsModel.SaveContactDetails) => {
    return await ContactsApi.save(contactDetails);
};

const useSaveContact = () => {
    const queryClient = useQueryClient();

    return useMutation(addContact, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(getContactQueryKeys.list());
            queryClient.invalidateQueries(getContactQueryKeys.id(data.id));
        },
    });
};

export default useSaveContact;
