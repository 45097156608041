import React from 'react';
import { useHistory, RouteComponentProps, NavLinkProps } from 'react-router-dom';
import styled from 'lib/styled';
import { Icon } from '../../Icon/Icon';
import BreadCrumbStore from '@app/web/components/Breadcrumb/BreadcrumbStore';
import BackArrow, { Props as BackArrowProps } from '../../Buttons/BackArrow/BackArrow';
const Button = styled.button({
    padding: '0',
    margin: '0',
    backgroundColor: 'transparent',
    border: 'none',
    display: 'grid',
    placeItems: 'center',
    '&:hover': {
        cursor: 'pointer',
    },
    '&:focus': {
        outline: 'none',
    },
});

export type Props = {
    to?: NavLinkProps['to'];
    background_arrow?: string;
} & BackArrowProps &
    React.ButtonHTMLAttributes<HTMLButtonElement>;

const BreadCrumbBackArrow: React.FC<Props> = ({ to, background_arrow, ...rest }) => {
    const history = useHistory();
    const navigateUrlTree = () => {
        to ? history.push(to) : history.goBack();
        BreadCrumbStore.removeLastBreadCrumb();
    };
    return (
        <Button aria-label="Go back" onClick={navigateUrlTree}>
            <BackArrow background_arrow={background_arrow} {...rest} />
        </Button>
    );
};

export default BreadCrumbBackArrow;
