import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { style } from '../../components/Shared/Style';
import styled from 'lib/styled';
import { ThemeType } from '@app/api/models/Theme';
import Logo from '../../components/Logo/Logo';
import { L } from '@app/shared/lib/i18n';
import { isCordovaBuild } from '../../lib/cordovaHelpers';
import useGetHelpListItem from '../help/hooks/useGetHelpListItem';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { RawHtml } from '../../components/Layout/RawHtml';

//#region css

const CenterGridDiv = styled.div({
    placeSelf: 'start center',
    width: '100%',
    [`@media screen and (min-width: 378px)`]: {
        width: '378px',
    },
    [`@media screen and (min-width: 500px)`]: {
        placeSelf: 'center',
    },
    [`@media screen and (min-width: ${desktop}px)`]: {
        width: '756px',
    },
});

const Card = styled.div<{ theme: ThemeType }>(({ theme }) => {
    return {
        display: 'grid',
        padding: '40px 16px 40px 16px',
        gridAutoRows: 'min-content auto',
        boxSizing: 'border-box',
        borderRadius: style.roundCornerSize.medium,
        flexDirection: 'column',
        width: '100%',
        [`@media screen and (min-width: 500px)`]: {
            backgroundColor: theme?.colors?.background,
        },
    };
});

const Background = styled.div(({ theme }: { theme: ThemeType }) => {
    return {
        minHeight: isCordovaBuild ? '-webkit-fill-available' : '100vh',
        display: 'grid',
        backgroundColor: theme?.colors?.pageBackground,
    };
});

const LoginCard = styled.div({
    alignSelf: 'end',
    position: 'relative',
    justifySelf: 'center',
    marginLeft: '10px',
});

const StyledLogo = styled(Logo)({
    maxWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    placeSelf: 'left',
    backgroundColor: 'inherit',
});
const Title = styled.h1({
    marginTop: '50px',
    fontSize: '1.5rem',
});

const Description = styled.span({
    fontSize: '1rem',
});

const NoHelpText = styled.p({
    fontSize: '1rem',
    marginTop: '50px',
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    paddingTop: style.margin.ml,
});

const StyledNavLink = styled(NavLink)(({ theme }: { theme: ThemeType }) => {
    return {
        textDecoration: 'none',
        color: theme?.colors?.primary || style.colors.primary,
        lineHeight: '32px',
        fontSize: style.fontSize.seta,
        fontWeight: style.fontWeight.bold,
    };
});

//#endregion

const LoginProblemPage = () => {
    const { data: helpListItem } = useGetHelpListItem('/loginproblem');

    return (
        <Background>
            <CenterGridDiv>
                <Card>
                    <StyledLogo />
                    <LoginCard>
                        {helpListItem ? (
                            <>
                                <Title>{L('login_problems')}</Title>
                                {helpListItem?.description && <RawHtml content={helpListItem.description} />}
                            </>
                        ) : (
                            <NoHelpText>
                                Det finns tyvärr ingen hjälp för denna sidan, <Link to="/help">klicka här</Link> så
                                kommer du till våran hjälpsida!
                            </NoHelpText>
                        )}
                        <StyledNavLink style={{ textAlign: 'center', display: 'block' }} to="/">
                            {L('back')}
                        </StyledNavLink>
                    </LoginCard>
                </Card>
            </CenterGridDiv>
        </Background>
    );
};

export default LoginProblemPage;
