import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import * as NotificationModel from '../models/Notifications';

class NotificationsApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async list() {
        return NotificationModel.list(this.user.id);
    }

    @rpc({ httpMethod: 'GET' })
    async getNotificationCount() {
        return NotificationModel.getNotificationCount(this.user.id);
    }

    @rpc({ httpMethod: 'GET' })
    async resetNotificationCount() {
        return NotificationModel.resetNotificationCount(this.user.id);
    }
}

export default new NotificationsApi();
