import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import TasksApi, { TaskFilter } from '../../../../api/public/TasksApi';

export const getTodosQueryKeys = {
    all: ['todos'],
    lists: () => [...getTodosQueryKeys.all, 'list'],
    list: (propertyId?: string, filter?: TaskFilter) => [...getTodosQueryKeys.all, 'list', { propertyId, filter }],
    singel: ({ propertyId, id }: { propertyId?: string; id?: string }) => [
        ...getTodosQueryKeys.all,
        'singel',
        propertyId,
        id,
    ],
};

const getTodoList = async (propertyId?: string, filter?: TaskFilter) => {
    if (!propertyId) return;

    const tasks = await TasksApi.list(propertyId, filter);

    if (filter?.completed) {
        return tasks?.sort((a, b) => {
            const aDueAt = dayjs(a.completed_at).valueOf();
            const bDueAt = dayjs(b.completed_at).valueOf();
            if (aDueAt > bDueAt) return -1;
            if (aDueAt < bDueAt) return 1;
            return 0;
        });
    }
    return tasks?.sort((a, b) => {
        const aDueAt = dayjs(a.due_at).valueOf();
        const bDueAt = dayjs(b.due_at).valueOf();
        if (aDueAt > bDueAt) return 1;
        if (aDueAt < bDueAt) return -1;
        return 0;
    });
};

const useGetTaskList = (propertyId: string, filter?: TaskFilter) => {
    return useQuery(getTodosQueryKeys.list(propertyId, filter), () => getTodoList(propertyId ?? propertyId, filter), {
        enabled: !!propertyId,
    });
};

export default useGetTaskList;
