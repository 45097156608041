import { QueryClientConfig } from 'react-query';

const config: QueryClientConfig = {
    defaultOptions: {
        queries: {
            // query options
            refetchOnWindowFocus: true,
        },
        mutations: {
            // mutation options
        },
    },
};

export default config;
