import styled from 'lib/styled';
import React from 'react';
import BackArrow from '../Breadcrumb/BackArrow';
import HelpIcon from '../Help/HelpIcon';
import { desktop } from '../MediaQuery/breakpoints';
import { style } from '../Shared/Style';

interface Props {
    path?: string;
    name?: string;
    to?: string;
}

const HeaderItem = styled.header({
    display: 'flex',
    justifyContent: 'flex-start',
    minHeight: '35px',
    maxHeight: '35px',
    flexDirection: 'column',
});

const StyledHeader = styled.div({
    display: 'flex',
    gridArea: 'header',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingBottom: style.margin.s,
    padding: `${style.margin.m}px ${style.margin.m}px ${style.margin.s}px ${style.margin.m}px`,

    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const Row = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
});

const HeaderClean: React.FC<Props> = ({ to, children, ...rest }) => {
    return (
        <StyledHeader {...rest}>
            <Row>
                <HeaderItem style={{ alignItems: 'flex-start' }}>
                    <BackArrow to={to} />
                </HeaderItem>
                <HeaderItem style={{ alignItems: 'flex-start' }}>
                    <HelpIcon marginTop="0px" isShowingIcon={true} />
                </HeaderItem>
                {children}
            </Row>
        </StyledHeader>
    );
};

export default HeaderClean;
