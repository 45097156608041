import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import * as ContactsModel from '../models/Contacts';
import * as BoardMemberModel from '../models/BoardMembers';

import { SaveContactDetails } from '../models/Contacts';
import { promises } from 'nodemailer/lib/xoauth2';

export { SaveContactDetails };

class ContactsApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async list() {
        return await ContactsModel.list(this.user.id);
    }

    @rpc({ httpMethod: 'GET' })
    async save(contactDetails: ContactsModel.SaveContactDetails) {
        return await ContactsModel.save(this.user.id, contactDetails);
    }

    @rpc({ httpMethod: 'GET' })
    async remove(id: ContactsModel.Contact['id'], property_id: ContactsModel.Contact['property_id']) {
        return await ContactsModel.remove(this.user.id, id, property_id);
    }

    @rpc({ httpMethod: 'GET' })
    async getContactById(id: number) {
        return await ContactsModel.getContactById(id);
    }

    @rpc({ httpMethod: 'GET' })
    async listContactsWithBoardMembers() {
        const contacts = await ContactsModel.list(this.user.id);

        const projects = await ContactsModel.getProjectsByUserId(this.user.id);
        const pcontat: ProjectContacts = { projects: [], myContacts: [] };
        const projectContacts = await Promise.all(
            projects.map(async (project) => {
                const boardMembers = await BoardMemberModel.listBoardMembersById(project.id);
                return {
                    name: project.name,
                    id: project.id,
                    contacts: contacts.filter(
                        (contact) => contact.contact_group !== 'my_personal' && contact.project_id === project.id
                    ),
                    boardMembers: boardMembers,
                };
            })
        );

        const myContacts = contacts.filter((contact) => contact.contact_group === 'my_personal');

        pcontat.myContacts = myContacts;
        pcontat.projects = projectContacts;
        return pcontat;
    }
}

export type ProjectContacts = {
    projects: {
        name: string;
        id: number;
        contacts: ContactsModel.Contact[];
        boardMembers: BoardMemberModel.BoardListContent[];
    }[];
    myContacts: ContactsModel.Contact[];
};

export default new ContactsApi();
