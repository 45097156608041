import store from './store';
import { Rect } from './types';

export const DURATION = 750;

const ease = (x: number): number => {
    const c1 = 1.10158;
    const c2 = c1 * 1.425;

    return x < 0.5
        ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
        : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
};

export const animate = (head: Rect, modalHead: Rect) => {
    const startTime = performance.now();

    const animation = (time: number) => {
        const { reverse, state, setState } = store;
        if (time - startTime > DURATION) {
            return setState(reverse ? null : 'opened', null);
        }

        const scale = modalHead.width / head.width;
        const height = scale * head.height;
        const top2 = Math.max(modalHead.top, -height);

        const p1 = Math.max(0.0001, (time - startTime) / DURATION);
        const pass2 = p1 > 0.5;

        const u = head.top - top2;
        const v = head.left - modalHead.left;

        const p = reverse ? 1 - p1 : p1;
        const b = ease(p);
        const q = 1 - b;
        const t = 1 + b * (scale - 1);

        setState(pass2 ? (reverse ? 'closing' : 'opening') : state, {
            top: top2 + u * q,
            left: v * q,
            width: head.width * t,
            height: head.height * t,
            borderRadius: 16 * (1 - p),
            opacity: (2 * (pass2 ? p1 : 1 - p1)) % 1,
        });

        requestAnimationFrame(animation);
    };

    return animation;
};

export const sumOffsets = (em: HTMLElement, top = 0, left = 0) => {
    top += em.offsetTop;
    left += em.offsetLeft;
    return em.offsetParent ? sumOffsets(em.offsetParent as HTMLElement, top, left) : { top, left };
};
