import React, { FC } from 'react';
import parse, { attributesToProps, HTMLReactParserOptions, Element, domToReact } from 'html-react-parser';
import P from '../Texts/P/P';
import H1 from '../Texts/H1/H1';
import H2 from '../Texts/H2/H2';
import H3 from '../Texts/H3/H3';
import styled from 'lib/styled';

const OL = styled.ol(({ theme }) => ({
    //
    color: theme.colors.textColor2,
    listStyleType: 'decimal',
    paddingLeft: '2rem',
}));
const UL = styled.ul(({ theme }) => ({
    color: theme.colors.textColor2,
    listStyleType: 'disc',
    paddingLeft: '2rem',
    //
}));

const LI = styled.li(({ theme }) => ({
    color: theme.colors.textColor2,
    listStyleType: 'inherit',
    //
}));

const A = styled.a(({ theme }) => ({
    color: 'blue',
    textDecoration: 'underline',
}));

const Img = styled.img(({ theme }) => ({
    maxWidth: '100%',
}));

const StyledP = styled(P)({
    margin: '13px 0',
    '&:first-of-type': {
        margin: '0 0 13px 0',
    },
});

const options: HTMLReactParserOptions = {
    replace: (domNode) => {
        if (domNode instanceof Element && domNode.attribs) {
            const props = attributesToProps(domNode.attribs);
            const jsx = domToReact(domNode.children, options);
            if (domNode.name === 'h1') return <H1 {...props}>{jsx}</H1>;
            if (domNode.name === 'h2') return <H2 {...props}>{jsx}</H2>;
            if (domNode.name === 'h3') return <H3 {...props}>{jsx}</H3>;
            if (domNode.name === 'p') {
                return <StyledP {...props}>{jsx}</StyledP>;
            }
            if (domNode.name === 'ol') return <OL {...props}>{jsx}</OL>;
            if (domNode.name === 'ul') return <UL {...props}>{jsx}</UL>;
            if (domNode.name === 'li') return <LI {...props}>{jsx}</LI>;
            if (domNode.name === 'img') {
                return <Img {...props} />;
            }
            if (domNode.name === 'span') return <>{jsx}</>;
            if (domNode.name === 'a') return <A {...props}>{jsx}</A>;
        }
    },
};

export const RawHtml = ({ content }: { content: string }) => {
    return <>{parse(content, options)}</>;
};
