import { action, observable } from 'mobx';

class Store {
    @observable
    left: number;

    @observable
    width: number;

    @action
    define(left: number, width: number) {
        this.left = left;
        this.width = width;
    }
}

export default new Store();
