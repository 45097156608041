import { L } from '@app/shared/lib/i18n';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentInformationListItem } from '../../../api/models/ContentInformation';
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs/Breadcrumbs';
import List from '../../components/Lists/List';
import HousingPostLink from './components/HosingPostLink';
import { useGetProjectDetail } from 'hooks/projectQueries';

const HousingPosts = (lists: { list: ContentInformationListItem[] }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const { data: project } = useGetProjectDetail(projectId);
    const list = lists?.list;
    const Posts = list?.map((item) => {
        return (
            <HousingPostLink
                key={item.id}
                to={`/projects/${projectId}/housing/${item.id}`}
                name={item.title}
                documentData={item.cover_image_data}
            />
        );
    });
    return (
        <div>
            <Breadcrumbs style={{ marginBottom: '2rem' }}>
                <Breadcrumbs.ItemActive>{L('housing_about', project?.project_type)}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            {list && <List>{Posts}</List>}
        </div>
    );
};

export default HousingPosts;
