import useGetTicketType from './useGetPropertyticketFeature';

const useIsOLTicketEnabledForPropery = () => {
    const { data: feature } = useGetTicketType();

    if (feature?.enabled && feature.ticket_type === 'ticket_default') {
        return true;
    }

    return false;
};

export default useIsOLTicketEnabledForPropery;
