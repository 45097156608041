import { Task } from '@app/api/models/Tasks';
import TasksApi from '@app/api/public/TasksApi';
import { fromNow } from '@app/shared/lib/formatting';
import { L } from '@app/shared/lib/i18n';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getTodosQueryKeys } from './useGetTaskList';

type Variables = {
    id: number;
    name: string;
    setTodoState: React.Dispatch<React.SetStateAction<'completed' | 'todo' | 'expired' | undefined>>;
};
const completeTask = async (task: Pick<Task, 'id'>, propertyId: string) => {
    return TasksApi.markAsCompleted(task.id, +propertyId);
};

const useCompleteTask = () => {
    const queryClient = useQueryClient();
    const { propertyId } = useParams<{ propertyId: string }>();
    return useMutation((task: Variables) => completeTask(task, propertyId), {
        onMutate: (variables) => {
            variables.setTodoState('completed');
            queryClient.cancelQueries(getTodosQueryKeys.list(propertyId));
        },
        onSuccess: (newTask, task) => {
            if (newTask && newTask.recurrency) {
                toast.success(`${task.name} ${L('todo_complete_success_reacuring')} ${fromNow(newTask.due_at)}`, {
                    id: `${newTask.id}`,
                });
                setTimeout(() => {
                    toast.dismiss(newTask.id.toString());
                }, 5000);
            } else {
                toast.success(`${task.name} ${L('todo_complete_success')}`, { id: `${task.id}` });
                setTimeout(() => {
                    toast.dismiss(newTask?.id.toString());
                }, 3000);
            }
        },
        onError: (_error, variables) => {
            toast.error(`${L('error')}`);
            variables.setTodoState('todo');
        },
    });
};

export default useCompleteTask;
