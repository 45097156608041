import React from 'react';
import styled from 'lib/styled';
import dayjs from 'dayjs';
import { colors } from '@app/web/components/Shared/Style/colors';
import formatChatDate from '../../../utils/formatChatDate';

const Time = styled.time({
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.black80,
});

type Props = {
    date: dayjs.ConfigType;
};

const Timestamp: React.FC<Props> = ({ date, ...rest }) => {
    if (!date) return null;
    return <Time {...rest}>{formatChatDate(date)}</Time>;
};

export default Timestamp;
