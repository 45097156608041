import * as React from 'react';
import styled from 'lib/styled';
import Avatar from '../../../components/thread/avatar/Avatar';
import { Link } from 'react-router-dom';
import { colors } from '../../Shared/Style/colors';
import Title from '../../thread/title/Title';
import Arrow from '../../thread/arrow/Arrow';
import Divider from '../../Divider/Divider';
import { Contact } from '@app/api/models/Contacts';
import useGetSignedUrlId from 'hooks/useGetSignedUrlId';
import { small } from 'lib/imageSize';

export interface ItemProps {
    id: number;
    name: string;
    profile_image_data?: Contact['profile_image_data'];
    company: string;
    phone: string;
    object: string;
}

const ContactContainer = styled(Link)({
    display: 'grid',
    gridTemplateAreas: `
    "avatar title arrow"
    "avatar company arrow"
    "divider divider divider"
    `,
    gridTemplateColumns: 'min-content auto min-content',
    columnGap: '1rem',
});

const GridAvatar = styled(Avatar)({
    gridArea: 'avatar',
    justifySelf: 'start',
    alignSelf: 'start',
});

const GridName = styled(Title)({
    gridArea: 'title',
    placeSelf: 'stretch',
});

const GridCompany = styled.span({
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.black80,
    gridArea: 'company',
    placeSelf: 'stretch',
});

const GridArrow = styled(Arrow)({
    gridArea: 'arrow',
    justifySelf: 'end',
    alignSelf: 'center',
});

const StyledDivider = styled(Divider)({
    gridArea: 'divider',
});

const Item: React.FC<ItemProps> = ({ id, name, company, object, profile_image_data }) => {
    const { data: url } = useGetSignedUrlId(profile_image_data?.original?.id, small);
    return (
        <ContactContainer to={`/${object}/${id}`}>
            {url ? <GridAvatar url={url} /> : <GridAvatar name={name} />}
            <GridName>{name}</GridName>
            <GridCompany>{company}</GridCompany>
            <GridArrow />
            <StyledDivider width={100} />
        </ContactContainer>
    );
};

export default Item;
