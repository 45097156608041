import { L } from '@app/shared/lib/i18n';
import React, { FC } from 'react';
import { HTMLAttributes } from 'react';
import Item from '../../components/ContactList/Item/Item';
import H2 from '../../components/Texts/H2/H2';
import { useGetContactList } from './hooks/contactQueries';

type Props = {
    searchValue: string;
} & HTMLAttributes<HTMLDivElement>;

const ContactListHandler: FC<Props> = ({ searchValue, ...rest }) => {
    const contactsList = useGetContactList();

    if (!contactsList.data) return <div>Här var det tomt</div>;

    const searchRegexp = new RegExp(searchValue, 'i');

    const groupedContactListObject = contactsList.data.projects;

    const groupedContactList = groupedContactListObject?.map((project) => {
        return (
            <div key={project.id}>
                <H2 style={{ margin: '16px 0px' }}>{project.name}</H2>
                {project.boardMembers.length > 0 && <p>Styrelse</p>}
                {project.boardMembers
                    .filter(
                        (boardMember) =>
                            boardMember?.name?.match(searchRegexp) ||
                            L(`${boardMember.role}`).match(searchRegexp) ||
                            'Styrelse'.match(searchRegexp) ||
                            project?.name?.match(searchRegexp)
                    )
                    .map((boardMember, index) => {
                        return (
                            <Item
                                key={index}
                                object={'contacts/board'}
                                name={boardMember.name}
                                id={+boardMember.id}
                                phone={boardMember.phone_number}
                                company={L(`${boardMember.role}`)}
                            />
                        );
                    })}
                <p>{L('all_contacts')}</p>

                {project?.contacts
                    .filter((contact) => {
                        return (
                            contact.name?.match(searchRegexp) ||
                            contact.company?.match(searchRegexp) ||
                            project?.name?.match(searchRegexp)
                        );
                    })
                    .map((contactDetail) => {
                        return (
                            <Item
                                key={contactDetail.id}
                                id={contactDetail.id}
                                name={contactDetail.name || ''}
                                profile_image_data={contactDetail.profile_image_data}
                                company={contactDetail.company || ''}
                                phone={contactDetail.phone_number || ''}
                                object={'contacts'}
                            />
                        );
                    })}
            </div>
        );
    });
    const myContacts = contactsList?.data?.myContacts
        .filter((myContact) => {
            return (
                myContact.name?.match(searchRegexp) ||
                myContact.company?.match(searchRegexp) ||
                'Mina kontakter'.match(searchRegexp)
            );
        })
        .map((contact) => {
            return (
                <Item
                    key={contact.id}
                    id={contact.id}
                    name={contact.name || ''}
                    profile_image_data={contact.profile_image_data}
                    company={contact.company || ''}
                    phone={contact.phone_number || ''}
                    object={'contacts'}
                />
            );
        });

    return (
        <div {...rest}>
            {groupedContactList}

            {myContacts && (
                <div>
                    <H2 style={{ margin: '16px 0px' }}>{L('my_personal')}</H2>
                    {myContacts}
                </div>
            )}
        </div>
    );
};

export default ContactListHandler;
