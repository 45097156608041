import styled from '@emotion/styled';
import { useGetSignedUrls } from 'hooks/useGetSignedUrl';
import { useScrollToTop } from 'hooks/useScrollToTop';
import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import CarouselHero from '../../components/Hero/CarouselHero';
import Hero from '../../components/Hero/WithTitle';
import { PageBackground } from '../../components/Layout/PageBackground';
import PropertiesLayout from '../../components/Layouts/PropertiesLayout';
import { desktop } from '../../components/MediaQuery/breakpoints';
import H2 from '../../components/Texts/H2';
import BinderTabs from '../binder/BinderTabs';
import ContentArea from '../binder/components/ContentArea';
import { useGetHasConstructionParts, useGetHasSecurity, useGetPropertyDetail } from '../binder/hooks';
import CreateNewTicketLink from '../tickets/components/CreateNewTicketLink';
import ResidenceAboutPage from './AboutPage/ResidenceAboutPage';
import ResidenceSharedWithPageMobile from './SharedWithPage/ResidenceSharedWithPage';

const StyledHero = styled(Hero)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});
const StyledCarouselHero = styled(CarouselHero)({
    'div:first-of-type': {
        gap: '2rem',
    },
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const StyledH2 = styled(H2)({
    width: '100%',
    textAlign: 'left',
    padding: '0px',
    margin: '0px',
});
const FlexContainer = styled.div({
    justifyContent: 'space-between',
    padding: '0px',
    paddingBottom: '16px',
    display: 'none',
    gap: '2rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'flex',
    },
});

const AboutPageHandler = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const { data: propertyDetail } = useGetPropertyDetail(propertyId);
    const { data: signedUrls } = useGetSignedUrls(propertyDetail?.documents);
    const { data: hasSecurity } = useGetHasSecurity();
    const { data: hasConstructionParts } = useGetHasConstructionParts();
    useScrollToTop();
    return (
        <PropertiesLayout>
            <PageBackground>
                <Route exact path={'/properties/:propertyId/binder/about'}>
                    {signedUrls && signedUrls.length > 1 ? (
                        <StyledCarouselHero
                            urls={signedUrls && signedUrls}
                            title={propertyDetail?.name}
                            to={`/properties/${propertyId}/binder`}
                        />
                    ) : (
                        <StyledHero
                            url={signedUrls && signedUrls[0]}
                            title={propertyDetail?.name}
                            nav={true}
                            to={`/properties/${propertyId}/binder`}
                        />
                    )}
                    <ContentArea>
                        <FlexContainer>
                            <StyledH2>{propertyDetail?.project_name}</StyledH2>
                        </FlexContainer>
                        <BinderTabs
                            hasConstructionParts={hasConstructionParts || false}
                            hasSecurity={hasSecurity || false}
                        />
                    </ContentArea>
                    <ResidenceAboutPage />
                </Route>
                <Route exact path={'/properties/:propertyId/binder/shared-with'}>
                    {signedUrls && signedUrls.length > 1 ? (
                        <StyledCarouselHero
                            urls={signedUrls && signedUrls}
                            title={propertyDetail?.name}
                            to={`/properties/${propertyId}/binder`}
                        />
                    ) : (
                        <StyledHero
                            url={signedUrls && signedUrls[0]}
                            title={propertyDetail?.name}
                            nav={true}
                            to={`/properties/${propertyId}/binder`}
                        />
                    )}
                    <ContentArea>
                        <FlexContainer>
                            <StyledH2>{propertyDetail?.project_name}</StyledH2>
                        </FlexContainer>
                        <BinderTabs
                            hasConstructionParts={hasConstructionParts || false}
                            hasSecurity={hasSecurity || false}
                        />
                    </ContentArea>
                    <ResidenceSharedWithPageMobile />
                </Route>
            </PageBackground>
        </PropertiesLayout>
    );
};

export default AboutPageHandler;
