import React, { FC } from 'react';
import styled from 'lib/styled';

const StyledList = styled.ul({
    paddingInlineStart: '0px',
    listStyle: 'none',
    padding: '0px',
    margin: '0px',
});

export type Props = {
    className?: string;
};

const List: FC<Props> = ({ className, children }) => {
    return <StyledList className={className}>{children}</StyledList>;
};

export default List;
