import { analytics } from '../../firebase';

export const analyticsLoginEventEmail = () => {
    console.log('login evenet');
    analytics.logEvent('login', {
        login_method: 'email',
    });
};

export const analyticsExeptionEvent = (errorMsg: string, fatal?: boolean) => {
    analytics.logEvent('exception', {
        exception: errorMsg,
        fatal,
    });
};
