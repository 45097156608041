import lazyRetry from 'lib/lazyRetry';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EmailSettingsPage from './EmailSettingsPage';
import { Notifications } from './Notifications';
const ProfilePage = lazy(() =>
    lazyRetry(() => import('./ProfilePage').then((module) => ({ default: module.ProfilePage })))
);
const ProfileSettingsPage = lazy(() =>
    lazyRetry(() => import('./ProfileSettingsPage').then((module) => ({ default: module.ProfileSettingsPage })))
);

export const routes = [
    <Route key="0" exact path="/profile" component={ProfilePage} />,
    <Route key="1" exact path="/profile/settings" component={ProfileSettingsPage} />,
    <Route key="2" exact path="/profile/notifications" component={Notifications} />,
    <Route key="3" exact path="/profile/email-settings" component={EmailSettingsPage} />,
];

export const navigation = [{ name: 'Profile', link: '/Profile' }];
