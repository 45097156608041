import FileApi from '@app/api/public/FileApi';

export const downloadFile = async ({ url, name }: { url: string; name: string }) => {
    const blob = await FileApi.downloadFile(url);
    const fileUrl = window.URL.createObjectURL(new Blob([blob as BlobPart]));
    const link = document.createElement('a');
    link.href = fileUrl;

    link.setAttribute('download', `${name}`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
};
