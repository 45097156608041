import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../megaphone.svg';

const ThemedMegaPhone = styled(Icon)(({ theme }) => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: theme?.colors?.icons || colors.icons,
            strokeWidth: '3px',
        },
        '& path': {
            stroke: theme?.colors?.icons || colors.icons,
            strokeWidth: '3px',
        },
        '& polyline': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& line': {
            stroke: theme?.colors?.icons || colors.icons,
        },
    };
});
const TrashCan: FC = ({ ...rest }) => {
    return <ThemedMegaPhone viewBox="0 0 48 48" {...rest} />;
};

export default TrashCan;
