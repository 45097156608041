import { useQueryClient } from 'react-query';
import { useMutation } from 'react-query';
import NotificationsApi from '../../../../../api/public/NotificationsApi';
import { ResetIosBadgeCount } from '../../../../cordovaIos/resetBadge';
import { isCordovaBuild } from '../../../../lib/cordovaHelpers';

const useResetNotificationBell = () => {
    const queryClient = useQueryClient();
    return useMutation(() => NotificationsApi.resetNotificationCount(), {
        onMutate: () => {
            queryClient.setQueryData('notificationBellCount', { value: 0 });
        },
        onSuccess: () => {
            if (isCordovaBuild) {
                ResetIosBadgeCount();
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries('notificationBellCount');
        },
    });
};

export default useResetNotificationBell;
