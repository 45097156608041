import { action, observable, computed } from 'mobx';
import { LinkProps } from 'react-router-dom';
import { breadCrumbs, crumbItem } from './Nav/BreadcrumbNav';

class Store {
    @action
    pushBreadcrumb = (crumb: crumbItem) => {
        const filteredBreadCrumbFromSamename = this.breadCrumb.filter(
            (crumbFromArray) => crumbFromArray.name !== crumb.name
        );
        const filteredBreadCrumbFromSameType = filteredBreadCrumbFromSamename.filter((crumbFromArray) =>
            crumb.type ? crumbFromArray.type !== crumb.type : true
        );
        filteredBreadCrumbFromSameType.push(crumb);

        this.breadCrumb = filteredBreadCrumbFromSameType;
    };

    @action
    removeBreadcrumb = (index: number) => {
        //remove all items from index
        const newBreadCrumbs = [...this.breadCrumb];
        this.breadCrumb = newBreadCrumbs.splice(0, index);
    };
    @action
    removeFromBreadcrumbId = (id: string) => {
        //remove all items from index
        const newBreadCrumbs = [...this.breadCrumb];
        const findBreadcrumb = this.breadCrumb.find((crumb) => id === crumb.id);
        if (!findBreadcrumb) return;
        const breadCrumbIndex = this.breadCrumb.indexOf(findBreadcrumb);
        this.breadCrumb = newBreadCrumbs.splice(0, breadCrumbIndex);
    };
    @action
    removeAllBreadCrumbs = () => {
        this.breadCrumb = [];
    };
    @action
    removeLastBreadCrumb = () => {
        //remove all items from index
        const newBreadCrumbs = [...this.breadCrumb];
        this.breadCrumb = newBreadCrumbs.splice(0, newBreadCrumbs.length - 1);
    };

    @action
    RemoveBreadcrumbById = (id: string) => {
        this.breadCrumb = this.breadCrumb.filter((crumbItem) => id !== crumbItem.id);
    };
    @action
    RemoveBreadcrumbByName = (name: string) => {
        this.breadCrumb = this.breadCrumb.filter((crumbItem) => name !== crumbItem.name);
    };
    @action
    RemoveBreadcrumbByPath = (path: LinkProps['to']) => {
        this.breadCrumb = this.breadCrumb.filter((crumbItem) => path !== crumbItem.path);
    };

    @action
    removeAllBreadCrumbs = () => {
        this.breadCrumb = [];
    };

    @observable
    breadCrumb: breadCrumbs = [];

    @computed
    get navCrumbs() {
        // url example
        // /binder/residence/:residenceId/space/:spaceId/product/:productId
        return this.breadCrumb;
    }
}

export default new Store();
