import React, { FC, useEffect, useState } from 'react';
import styled from 'lib/styled';
import { style } from '../Shared/Style';

const PwaButton = styled.button({
    width: 'max-content',
    marginTop: '28px',
    alignSelf: 'start',
    fontSize: style.fontSize.gamma,
    background: 'none',
    fontWeight: 300,
    border: 'none',
    padding: '0px',
});

type props = {
    className?: string;
};

type UsersChoice = {
    outcome: 'dismissed' | 'accepted';
    platform: string;
};

const InstallPwaButton: FC<props> = ({ className }) => {
    const [pwaInstallable, setPwaInstallable] = useState(false);
    const [installPropmtEvent, setinstallPropmtEvent] = useState<any>(null);
    useEffect(() => {
        if (window.deferredPrompt) {
            setPwaInstallable(true);
            setinstallPropmtEvent(window.deferredPrompt);
            return;
        }
    }, []);

    const installPwaClick = (e) => {
        e.preventDefault();
        if (!installPropmtEvent) return;

        installPropmtEvent.prompt();

        installPropmtEvent.userChoice.then((result: UsersChoice) => {
            window.deferredPrompt = null;
            setPwaInstallable(false);
        });
    };

    return pwaInstallable ? (
        <PwaButton className={className} onClick={installPwaClick}>
            Installera appen
        </PwaButton>
    ) : null;
};

export default InstallPwaButton;
