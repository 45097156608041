import styled from 'lib/styled';
import React, { ChangeEvent, FC } from 'react';
import CameraSvg from '@app/web/components/Icon/camera.svg';

const Input = styled.input({
    display: 'none',
});

const InputLabel = styled.label({
    display: 'block',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundImage: `url(${CameraSvg})`,
    backgroundRepeat: 'no-repeat',
    width: '35px',
    height: '35px',
    cursor: 'pointer',
});

type Props = {
    className?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const CameraInput: FC<Props> = ({ className, onChange }) => {
    return (
        <>
            <InputLabel className={className} htmlFor={'CameraInput'} />
            <Input id={'CameraInput'} onChange={onChange} type="file" accept="image/jpeg, image/png" capture="camera" />
        </>
    );
};

export default CameraInput;
