import React from 'react';
import { Stylable } from '../../../types';
import styled from 'lib/styled';
import { space } from 'styled-system';
import { ListTicket } from '@app/api/models/Tickets';
import Avatar from '../avatar/Avatar';
import Title from '../title/Title';
import TimeStamp from '../timestamp/TimeStamp';
import LastMessage from '../lastMessage/LastMessage';
import Arrow from '../arrow/Arrow';
import Status from '../status/Status';
import { Link, useParams } from 'react-router-dom';
import { L } from '@app/shared/lib/i18n';

const ThreadContainer = styled(Link)({
    display: 'grid',
    gridTemplateAreas: `
    "avatar title status"
    "avatar timestamp arrow"
    "avatar lastMessage arrow"
    `,
    gridTemplateColumns: 'min-content auto min-content',
    columnGap: '1rem',
});

const GridAvatar = styled(Avatar)({
    gridArea: 'avatar',
    justifySelf: 'start',
    alignSelf: 'start',
});
const GridTitle = styled(Title)({
    gridArea: 'title',
    placeSelf: 'stretch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
});
const GridTimeStamp = styled(TimeStamp)({
    gridArea: 'timestamp',
    placeSelf: 'stretch',
});

const GridLastMessage = styled(LastMessage)({
    gridArea: 'lastMessage',
    paddingTop: '0.25rem',
    placeSelf: 'stretch',
});

const GridArrow = styled(Arrow)({
    gridArea: 'arrow',
    justifySelf: 'end',
    alignSelf: 'center',
});

const GridStatus = styled(Status)({
    gridArea: 'status',
    justifySelf: 'end',
    alignSelf: 'start',
    marginBottom: '8px',
});

const TicketThread: React.FC<Stylable & ListTicket & { avatar_url?: string }> = ({
    className,
    last_message_received_at,
    last_message,
    created_name,
    avatar_url,
    status,
    title,
    id,
    updated_at,
}) => {
    const { propertyId } = useParams<{ propertyId: string }>();

    return (
        <ThreadContainer to={`/properties/${propertyId}/tickets/${id}`} className={className}>
            {avatar_url ? <GridAvatar url={avatar_url} /> : <GridAvatar name={created_name} />}
            <GridTitle>{title}</GridTitle>
            <GridTimeStamp date={last_message_received_at || updated_at} />
            <GridLastMessage message={last_message?.text.plain_text ?? L('no_ticket_messages')} />
            <GridStatus status={status} />
            <GridArrow />
        </ThreadContainer>
    );
};

export default styled(TicketThread)(space);
