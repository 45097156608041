import React, { FC } from 'react';
import { style } from '../../Shared/Style';
import styled from 'lib/styled';

const InputField = styled.input(({ theme }) => ({
    background: theme?.colors?.inputBackground,
    border: `1px solid ${theme?.colors?.inputBorder}`,
    width: '100%',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: `10px ${style.margin.s}px`,
    fontSize: style.fontSize.seta,
    '&:read-only': {
        background: theme.colors.inputBackgroundDisabled,
        opacity: 1,
    },
}));

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Input: FC<InputProps> = ({ ...rest }) => {
    return <InputField type={rest.type || 'text'} id={rest.id || rest.name} {...rest} />;
};

export default Input;
