import lazyRetry from 'lib/lazyRetry';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const importBinderPage = () => import('./BinderPage').then((module) => ({ default: module.BinderPage }));

const BinderPage = lazy(() => lazyRetry(importBinderPage));

export const routes = [<Route key="1" path="/properties/:propertyId/binder" component={BinderPage} />];

export const navigation = [{ name: 'Binder', link: '/Binder' }];
