import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timeZones from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timeZones);
dayjs.tz.guess();

export const daysJsDateFromNow = (date: dayjs.ConfigType) => {
    return dayjs(date).utc(true).fromNow();
};
