import React, { useState } from 'react';
import H1 from '../../components/Texts/H1/H1';
import styled from 'lib/styled';
import { PageBackground } from '../../components/Layout/PageBackground';
import CleanHeader from '../../components/Header/HeaderOnlyBackArrow';
import { L } from '@app/shared/lib/i18n';
import { style } from '../../components/Shared/Style';
import Label from '../../components/Forms/Label';
import Input from '../../components/Forms/Input';
import useSaveContact from './hooks/useSaveContact';
import { Contact, SaveContactDetails } from '@app/api/models/Contacts';
import { Loading } from '../../components/Loading/Loading';
import H2 from '../../components/Texts/H2';
import { useEffect } from 'react';
import TextArea from '../../components/Forms/TextArea';
import { colors } from '../../components/Shared/Style/colors';
import SendButtonHandler from './components/SendButtonHandler';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { useGetContactById } from './hooks/contactQueries';
import useGetProfile from 'hooks/useGetProfile';
import Avatar from '../../components/thread/avatar/Avatar';
import { small } from 'lib/imageSize';
import useGetSignedUrlId from 'hooks/useGetSignedUrlId';
import useRemoveContact from './hooks/useRemoveContact';
import { Button } from '../../components/Buttons/Button';
import { User } from '@app/api/models/Users';

const Form = styled.form({
    display: 'grid',
});

const StyledLable = styled(Label)({
    marginTop: '18px',
    '& div': {
        marginTop: '0px',
    },
});

const StyledSendButton = styled(SendButtonHandler)({
    width: '60px',
    height: '60px',
    placeSelf: 'center',
    marginTop: '1.5rem',
});

const Main = styled.main({
    width: '100%',
    padding: `0px ${style.margin.m}px 4rem ${style.margin.m}px`,
});

const StyledH1 = styled(H1)({
    margin: '0px',
    marginLeft: style.margin.s,
});

const SubHeading = styled(H2)(() => {
    return {
        margin: '0px',
        fontSize: '18px',
        color: colors.textColor1,
        fontWeight: 'normal',
        [`@media screen and (min-width: ${desktop}px)`]: {
            display: 'none',
        },
    };
});

const GridAvatar = styled(Avatar)({
    gridArea: 'avatar',
    justifySelf: 'start',
    alignSelf: 'start',
    width: '60px',
    height: '60px',
});

const StyledAddress = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: '4rem',
});

const StyledUserBox = styled.div({
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
});

const StyledLink = styled.a(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 300,
}));

const StyledRemoveButton = styled(Button)({
    marginLeft: 'auto',
});

const isMyOwnContact = (contact?: Contact, userId?: User['id']) => {
    return contact?.user_id === userId;
};

const isPropertyContact = (contact?: Contact) => {
    return contact?.property_id !== null && contact?.user_id === null;
};

const canEdit = (contact?: Contact, userId?: number) => {
    return isMyOwnContact(contact, userId) || isPropertyContact(contact);
};

const ContactsDetails: React.FC = () => {
    const { data: contact, status: contactStatus } = useGetContactById();
    const { data: url } = useGetSignedUrlId(contact?.profile_image_data?.original?.id, small);

    const [contactDetails, setContactDetails] = useState<SaveContactDetails>();
    const { mutate: saveContact, status } = useSaveContact();
    const { mutate: removeContact } = useRemoveContact();
    const [confirmDelete, setConfirmDelete] = useState(false);

    const { data: userProfile } = useGetProfile();

    // Set contact details value
    useEffect(() => {
        if (!contact) return;

        setContactDetails(contact);
        return;
    }, [contact]);

    if (contactStatus === 'loading' || !contact) return <Loading />;

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContactDetails({ ...contactDetails, [e.target.id]: e.target.value });
    };
    const handleOnChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContactDetails({ ...contactDetails, [e.target.id]: e.target.value });
    };

    const isEditable = canEdit(contact, userProfile?.id);

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!contactDetails) return;
        if (!isEditable) return;
        saveContact(contactDetails);
    };

    const handleOnRemove = () => {
        if (!contactDetails?.id) return;
        if (!isEditable) return;
        removeContact({ id: contactDetails.id, property_id: contactDetails.property_id });
    };

    return (
        <PageBackground>
            <CleanHeader />
            <Main>
                {contactDetails ? (
                    <>
                        <SubHeading>{L('contacts')}</SubHeading>
                        <StyledUserBox>
                            {url ? (
                                <GridAvatar url={url} />
                            ) : (
                                contactDetails.name && <GridAvatar name={contactDetails.name} />
                            )}
                            <StyledH1>{contactDetails.name}</StyledH1>

                            {isEditable &&
                                (confirmDelete ? (
                                    <StyledRemoveButton variant="delete" onClick={() => handleOnRemove()}>
                                        {L('confirm')}
                                    </StyledRemoveButton>
                                ) : (
                                    <StyledRemoveButton
                                        variant="primary"
                                        onClick={() => {
                                            setConfirmDelete(true);
                                        }}
                                    >
                                        {L('remove')}
                                    </StyledRemoveButton>
                                ))}
                        </StyledUserBox>
                        <Form onSubmit={handleOnSubmit}>
                            <StyledLable name="name" title={L('name')} />
                            <Input
                                disabled={!isEditable}
                                id="name"
                                onChange={handleOnChange}
                                value={contactDetails.name || ''}
                                required
                            />
                            <StyledLable name="company" title={L('company')} />
                            <Input
                                disabled={!isEditable}
                                id="company"
                                onChange={handleOnChange}
                                value={contactDetails.company || ''}
                            />

                            <StyledLable name="title" title={L('role')} />
                            <Input
                                disabled={!isEditable}
                                id="title"
                                onChange={handleOnChange}
                                value={contactDetails.title || ''}
                            />

                            <StyledLable name="phone_number" title={L('contact_number')} />

                            {isEditable ? (
                                <Input
                                    id="phone_number"
                                    onChange={handleOnChange}
                                    value={contactDetails.phone_number || ''}
                                />
                            ) : (
                                <StyledLink href={`tel:${contact.phone_number}`}>
                                    <Input
                                        style={{ color: colors.primary, WebkitTextFillColor: colors.primary }}
                                        disabled={true}
                                        id="phone_number"
                                        onChange={handleOnChange}
                                        value={contactDetails.phone_number || ''}
                                        required
                                    />
                                </StyledLink>
                            )}

                            <StyledLable name="email" title={L('contact_email')} />
                            {isEditable ? (
                                <Input id="email" onChange={handleOnChange} value={contactDetails.email || ''} />
                            ) : (
                                <StyledLink href={`mailto:${contact.email}`}>
                                    <Input
                                        style={{ color: colors.primary, WebkitTextFillColor: colors.primary }}
                                        disabled={true}
                                        id="email"
                                        onChange={handleOnChange}
                                        value={contactDetails.email || ''}
                                    />
                                </StyledLink>
                            )}

                            <StyledLable name="street" title={L('street')} />
                            <Input
                                disabled={!isEditable}
                                id="street"
                                onChange={handleOnChange}
                                value={contactDetails.street || ''}
                            />
                            <StyledAddress>
                                <div>
                                    <StyledLable name="postalcode" title={L('postal_code')} />
                                    <Input
                                        disabled={!isEditable}
                                        id="postalcode"
                                        onChange={handleOnChange}
                                        value={contactDetails.postalcode || ''}
                                    />
                                </div>
                                <div>
                                    <StyledLable name="city" title={L('profile_city')} />
                                    <Input
                                        disabled={!isEditable}
                                        id="city"
                                        onChange={handleOnChange}
                                        value={contactDetails.city || ''}
                                    />
                                </div>
                            </StyledAddress>

                            <StyledLable name="info" title={L('extra_information')} />
                            <TextArea
                                readOnly={!isEditable}
                                id="info"
                                onChange={handleOnChangeTextArea}
                                value={contactDetails.info || ''}
                            />

                            {isEditable && <StyledSendButton status={status} />}
                        </Form>
                    </>
                ) : (
                    <H2>{L('empty_response')}</H2>
                )}
            </Main>
        </PageBackground>
    );
};

export default ContactsDetails;
