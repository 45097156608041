import ResidenceApi from '@app/api/public/ResidenceApi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const useGetResidenceDetails = () => {
    const { propertyId } = useParams<{ propertyId: string }>();

    return useQuery(['residenceDetailsAbout'], () => ResidenceApi.getById(+propertyId));
};

export default useGetResidenceDetails;
