import React, { FC } from 'react';
import styled from 'lib/styled';
import { Link } from 'react-router-dom';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import sv from 'dayjs/locale/sv';
import NoticeIcon from '../components/NoticeIcon';
import NoticeTitle from '../components/NoticeTitle';
import NoticeLabel from '../components/NoticeLabel';
import { TodoNotification } from '../../../../api/models/Notifications';
import NoticeDescription from '../components/NoticeDescription';
import ThemeIcons from '../../Icon/ThemedIcons';
import dayjs from 'dayjs';
import NoticeDate from '../components/NoticeDate';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
const Border = styled.div(({ theme }) => {
    return {
        backgroundColor: theme.colors.alertBackground,
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        display: 'grid',
        whiteSpace: 'pre-line',
        gridTemplateColumns: 'min-content auto min-content',
        gridTemplateAreas: `
    "icon title label"
    "icon description label"
    "icon date label"
    `,
        columnGap: '1rem',
        rowGap: '0.5 rem',
    };
});

const TodoNotice: FC<TodoNotification & { isCollapsed: boolean }> = ({
    notifiable_type,
    body,
    title,
    isCollapsed,
    created_at,
    ...rest
}) => {
    const notificationDate = created_at && dayjs(created_at).locale(sv).fromNow();
    return (
        <Link to={`/${notifiable_type}`}>
            <Border {...rest}>
                <NoticeIcon>
                    <ThemeIcons.List />
                </NoticeIcon>
                <NoticeTitle isCollapsed={isCollapsed} description={title} />
                <NoticeDescription description={body} isCollapsed={isCollapsed} />
                <NoticeLabel type={'todo'} />
                <NoticeDate when={notificationDate} />
            </Border>
        </Link>
    );
};

export default TodoNotice;
