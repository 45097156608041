import { action, observable, autorun, computed, toJS } from 'mobx';
import { Rect, State, Styles } from './types';

type Factory = (state: State) => React.ReactNode;

class Store {
    constructor() {}

    @action
    open = (head: Rect, modalHead: Rect, item: any) => {
        this.headBounds = head;
        this.modalHeadBounds = modalHead;
        this.scroll = window.pageYOffset;
        this.state = 'open';
        this.index = item;
    };

    @action
    close = () => {
        this.state = 'close';
    };

    @action
    setState = (state: State, styles: Styles | null) => {
        this.state = state;
        this.styles = styles;
    };

    @computed
    get reverse() {
        const { state } = this;
        return state === 'close' || state === 'closing';
    }

    @computed
    get start() {
        const { state } = this;
        return state === 'close' || state === 'open';
    }

    @computed
    get end() {
        const { state } = this;
        return !state || state === 'opened';
    }

    @computed
    get modalSide() {
        const { state } = this;
        return state === 'opening' || state === 'opened' || state === 'close';
    }

    @computed
    get isAnimate() {
        const { state, styles } = this;
        return state === 'open' || state === 'close' || styles;
    }

    @observable
    styles: Styles | null;

    @observable
    state: State;

    headEms = new Map<any, React.ReactNode | Factory>();
    bodyEms = new Map<any, React.ReactNode>();
    headBounds: Rect;
    modalHeadBounds: Rect;
    context: HTMLElement;
    index: any;
    scroll: number;
}

export default new Store();
