import { useQuery } from 'react-query';
import ProjectsApi from '@app/api/public/ProjectsApi';

export const projectQueryKeys = {
    all: ['project'],
    details: () => [...projectQueryKeys.all, 'detail'],
    detail: (projectId?: string) => [...projectQueryKeys.all, 'detail', projectId],
};

const getProjectDetail = async (projectId?: string) => {
    if (!projectId) return;
    return await ProjectsApi.id(+projectId);
};
const useGetProjectDetail = (projectId?: string) => {
    return useQuery(projectQueryKeys.detail(projectId), () => getProjectDetail(projectId), {
        enabled: !!projectId,
    });
};

export { useGetProjectDetail };
