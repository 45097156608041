import React from 'react';
import styled from '@emotion/styled';
import store from './store';
import Pane from './Pane';
import { observer } from 'mobx-react';
import { Rect } from './types';

const Hero = styled.header<Rect>(({ top, left, width, height }) => ({
    position: 'absolute',
    top,
    left,
    width,
    height,
    overflow: 'hidden',
}));

const Transition: React.FC = () => {
    const { state, styles, headEms, index } = store;
    const headEm = headEms.get(index);

    return <Pane>{<Hero {...styles!}>{typeof headEm === 'function' ? headEm(state) : headEm}</Hero>}</Pane>;
};

export default observer(Transition);
