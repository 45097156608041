import { useQuery } from 'react-query';
import ResidenceApi from '@app/api/public/ResidenceApi';
import ContentInformationApi from '@app/api/public/ContentInformationApi';
import NotificationsApi from '@app/api/public/NotificationsApi';
import { useParams } from 'react-router-dom';

const getNotificationList = async () => {
    return await NotificationsApi.list();
};

const getInformationList = async (id?: string) => {
    if (!id) return;
    return await ContentInformationApi.list(+id);
};

export const useInformationList = (projectId?: string) => {
    return useQuery(['informationList', projectId], () => getInformationList(projectId), {
        enabled: !!projectId,
    });
};

export const useNotificationsList = () => useQuery('notificationsList', getNotificationList);

export const useResidence = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return useQuery(['residence', propertyId], () => ResidenceApi.id(+propertyId));
};

const getDocumentList = async (propertyId?: string) => {
    if (!propertyId) return;
    return await ResidenceApi.listDocuments(+propertyId);
};

export const useDocumentList = () => {
    const { propertyId } = useParams<{ propertyId: string }>();

    return useQuery(['documentGroupList', propertyId], () => getDocumentList(propertyId), {
        enabled: !!propertyId,
    });
};
