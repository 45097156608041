import React from 'react';
import styled from 'lib/styled';
import { desktop } from '../MediaQuery/breakpoints';

interface Props {
    children?: any;
    align?: string;
}

const Sticky = styled.div(({ theme }) => ({
    padding: '8px 4px 12px 4px',
    position: 'fixed',
    backgroundColor: theme.colors.mobileStyckyNav,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 99,
    justifyContent: 'space-between',
}));

export const FixedBar = ({
    children,
    ...rest
}: Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    return <Sticky {...rest}>{children}</Sticky>;
};
