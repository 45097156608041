import { useEffect } from 'react';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import { UserCompanyTheme } from '../../../../api/models/Theme';
import { LoginStore } from '../../../areas/login/LoginStore';
import { getIosTheme } from '../../../cordovaIos/IosTheme';
import { isCordovaBuild } from '../../../lib/cordovaHelpers';

const getThemeInfo = (queryClient: QueryClient) => {
    if (isCordovaBuild) {
        getIosTheme((theme) => queryClient.setQueryData('CompanyTheme', theme));
        return queryClient.getQueryData('CompanyTheme') as UserCompanyTheme;
    }
    return null;
};

export const useGetIosCompanyTheme = () => {
    const queryClient = useQueryClient();
    const companyThemeQuery = useQuery('CompanyTheme', () => getThemeInfo(queryClient));

    useEffect(() => {
        companyThemeQuery.refetch();
    }, [LoginStore.user]);

    return companyThemeQuery;
};
