import * as React from 'react';
import styled from 'lib/styled';
import { style } from '../Shared/Style';
import { ThemeType } from '@app/api/models/Theme';
import { FC } from 'react';
import { Page } from './Page';

const PageContainer = styled(Page)((p: { theme: ThemeType; padding?: string }) => {
    return {
        background: p?.theme?.colors?.pageBackground || style.colors.pageBackground,
        padding: p.padding || 0,
    };
});

export const PageBackground: FC<{ padding?: string } & React.HTMLAttributes<HTMLDivElement>> = ({
    children,
    ...rest
}) => {
    return <PageContainer {...rest}>{children}</PageContainer>;
};
