import styled from 'lib/styled';
import { useTheme } from 'emotion-theming';
import React, { FC } from 'react';
import { ThemeType } from '../../../../api/models/Theme';
import { colors } from '../../Shared/Style/colors';

const Text = styled.p(({ colors: themeColors }: ThemeType) => {
    const LineHeight = 20;
    const MaxLines = 2;

    const MaxHeight = LineHeight * MaxLines + 2;

    return {
        margin: 0,
        padding: 0,
        lineHeight: `${LineHeight}px`,
        fontSize: '14px',
        maxHeight: `${MaxHeight}px`,
        overflow: 'hidden',
        color: themeColors ? themeColors.textColor2 : colors.textColor2,
    };
});

const LastMessage: FC<{ message: string }> = ({ message, ...rest }) => {
    const theme = useTheme<ThemeType>();
    const MessageEllipse = message.length > 120 ? message.substring(0, 120) + '...' : message;
    return (
        <Text theme={theme} {...rest}>
            {MessageEllipse}
        </Text>
    );
};

export default LastMessage;
