import React from 'react';
import styled from 'lib/styled';
import { ReactComponent as TodoCalendar } from '../../Icon/calendar-outline.svg';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import { ReactComponent as CCheck } from '../../Icon/c-check-outline.svg';
import { ReactComponent as cycle } from '../../Icon/cycle-outline.svg';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { L } from '@app/shared/lib/i18n';
import { Task } from '@app/api/models/Tasks';
import { display } from 'styled-system';
import { daysJsDateFromNow } from 'lib/time';

dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

dayjs.locale(sv);

const TodoDate = styled.div({
    display: 'flex',
    gap: '3px',
});
const DateSpan = styled.span(({ theme }) => {
    return {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        '&:first-letter': {
            textTransform: 'capitalize',
        },

        '&.expired': {
            color: theme.colors.alert_high,
        },
    };
});
const TodoCalendarIcon = styled(TodoCalendar)(({ theme }) => {
    return {
        height: '0.65rem',
        width: '0.65rem',
        alignSelf: 'center',
        '& g': {
            stroke: theme?.colors?.icons,
            strokeWidth: '3px',
        },
        '&.expired': {
            '& g': {
                // stroke: theme?.colors?.alert_high,
            },
        },
    };
});
const CycleIcon = styled(cycle)(({ theme }) => {
    return {
        height: '0.65rem',
        width: '0.65rem',
        alignSelf: 'center',
        '&.expired': {
            '& g': {
                stroke: theme?.colors?.alert_high,
            },
        },
    };
});
const TodoWrapper = styled.div({
    padding: '1rem 0px',
    cursor: 'pointer',
    display: 'grid',
    columnGap: '0.7rem',
    gridTemplateColumns: 'max-content auto',
    alignItems: 'center',
    gridTemplateAreas: `
        "check title"
        "check date"
    `,
});
const NameSpan = styled.span({
    gridArea: 'title',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&.completed': {
        textDecoration: 'line-through',
    },
});

const CheckClickArea = styled.div({
    gridArea: 'check',
    height: '30px',
    width: '30px',
    display: 'grid',
});
const Check = styled(CCheck)(({ theme }) => ({
    placeSelf: 'center',
    height: '1.2rem',
    width: '1.2rem',
    '[data-id="checkmark"]': {
        stroke: 'none',
        transform: 'translate(-20%, -20%)',
        strokeWidth: 2,
    },
    circle: {
        stroke: theme.colors.ourliving,
    },
    '&:hover': {
        '[data-id="checkmark"]': {
            stroke: theme.colors.ourliving,
            transition: 'all 0.2s ease',
            transform: 'translate(0%, 0%)',
        },
    },

    '&.completed': {
        '[data-id="checkmark"]': {
            stroke: theme.colors.white,
            transform: 'translate(0%, 0%)',
        },
        circle: {
            stroke: theme.colors.ourliving,
            fill: theme.colors.ourliving,
        },
    },
}));

export type Props = {
    name: Task['name'];
    date: Task['due_at'];
    repeat?: Task['recurrency'];
    state?: 'completed' | 'todo' | 'expired';
    onCheckClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    completed_at?: Task['completed_at'];
} & React.HTMLAttributes<HTMLDivElement>;

const TodoItem = ({ name, date, state = 'todo', repeat, onCheckClick, ...rest }: Props) => {
    return (
        <TodoWrapper {...rest}>
            <CheckClickArea onClick={onCheckClick}>
                <Check className={state} viewBox="0 0 32 32" />
            </CheckClickArea>
            <NameSpan className={state}>{name}</NameSpan>
            <TodoDate>
                <TodoCalendarIcon viewBox="0 0 64 64" className={state} />
                <DateSpan className={state}>
                    {rest.completed_at ? daysJsDateFromNow(rest.completed_at) : daysJsDateFromNow(date)}
                </DateSpan>
                {repeat && <CycleIcon viewBox="0 0 12 12" className={state} />}
            </TodoDate>
        </TodoWrapper>
    );
};

export default TodoItem;
