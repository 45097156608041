import { Task } from '@app/api/models/Tasks';
import TasksApi from '@app/api/public/TasksApi';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getTodosQueryKeys } from './useGetTaskList';

const saveTask = async (task: Partial<Task>, propertyId?: string) => {
    if (!propertyId) return;
    return TasksApi.saveTask(propertyId, task);
};

const useSaveTask = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const queryClient = useQueryClient();
    return useMutation((task: Partial<Task>) => saveTask(task, propertyId), {
        onSettled: () => {
            queryClient.invalidateQueries(getTodosQueryKeys.list(propertyId));
        },
    });
};

export default useSaveTask;
