import React, { useRef } from 'react';
import { default as ReactSelect } from 'react-select';
import { useObserver } from 'mobx-react';
import { ChangeFunction } from '../../lib/react';
import { style } from '../Shared/Style';
import styled from 'lib/styled';
import { Icon } from '../Icon/Icon';

interface Option {
    value: any;
    label: any;
}

interface Props {
    placeholder?: string;
    id?: string;
    label?: string;
    options: Array<Option>;
    name?: string;
    onChange?: ChangeFunction<any>;
    value?: any;
    isClearable?: boolean;
    disabled?: boolean;
    error?: boolean;
    isWithoutBorder?: boolean;
    isSearchable?: boolean;
}

const Option = styled.span({ color: style.colors.primary });

function formatOptionLabel(label, v, meta) {
    if (label && meta && meta.context === 'value') {
        return (
            <>
                {label}: <Option>{v.label}</Option>
            </>
        );
    }
    return v.label;
}
const styles = {
    container: (st) => ({ ...st, fontSize: '80%', height: 32 }),
    control: (st) => ({
        ...st,
        backgroundColor: 'transparent',
        borderRadius: 4,
        height: '32px',
        minHeight: '32px',
    }),
    indicatorsContainer: (st) => ({ ...st, padding: 0 }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (st) => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
        maxWidth: 'calc(100% - 8px)',
        cursor: 'pointer',
    }),
    singleValue: (st) => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
    }),
    option: (st) => ({
        ...st,
        cursor: 'pointer',
    }),
    menu: (st) => ({ ...st, minWidth: '12em' }),
    input: (st) => ({ ...st, caretColor: 'transparent', maxWidth: 0 }),
};

const components = {
    DropdownIndicator() {
        return <Icon.SortDown />;
    },
    ClearIndicator(props) {
        const click = (evt) => {
            props.clearValue();
            evt.stopPropagation();
        };
        return <Icon.TrashCan onClick={click} />;
    },
};

export function Dropdown(props: Props) {
    const ref = useRef(null as any);
    const err_styles = { ...styles, control: (st) => ({ ...st, borderColor: 'red' }) };
    const noBorder = { ...styles, control: (st) => ({ ...st, border: '0px' }) };

    function onChange(v) {
        props.onChange && props.onChange(v ? v.value : undefined, props.name || '');
    }

    function onMenuOpen() {
        setTimeout(() => {
            const menuList = ref.current.select.menuListRef as HTMLDivElement;
            if (!menuList) return;
            const menu = menuList.parentElement as HTMLDivElement;

            const menuRect = menu.getClientRects().item(0);
            if (!menuRect) return;

            const windowWidth = window.innerWidth;
            const overflow = menuRect.right - windowWidth + 4;
            if (overflow > 0) {
                menu.style.left = `${parseInt(menu.style.left || '0') - overflow}px`;
            }
        }, 0);
    }

    const selected = props.options.find((opt) => opt.value === props.value) || null;
    return useObserver(() => (
        <>
            <ReactSelect
                {...props}
                ref={ref}
                styles={props.isWithoutBorder ? noBorder : props.error ? err_styles : styles}
                onMenuOpen={onMenuOpen}
                components={components}
                formatOptionLabel={(v, meta) => formatOptionLabel(props.label, v, meta)}
                onChange={onChange}
                placeholder={props.placeholder || props.label}
                value={selected}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary50: style.colors.primary50,
                        primary25: style.colors.primary25,
                        primary: style.colors.primary,
                    },
                })}
            />
        </>
    ));
}
