import * as colors from './colors';
import * as box from './box';
import * as margin from './margin';
import * as typography from './typography';
import * as zIndex from './z-index';

export const style = {
    ...colors,
    ...box,
    ...margin,
    ...typography,
    ...zIndex,
};
