import { DocumentDetail } from '@app/api/models/Documents';
import { ThemeType } from '@app/api/models/Theme';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import { medium } from 'lib/imageSize';
import styled from 'lib/styled';
import React, { RefAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Icon } from '../../../components/Icon/Icon';
import { colors } from '../../../components/Shared/Style/colors';
import { fontWeight } from '../../../components/Shared/Style/typography';

const Wrapper = styled.div((p: { theme: ThemeType }) => {
    return {
        textDecoration: 'none',
        color: p.theme?.colors?.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '3px',
    };
});

const StyledLink = styled(Link)((p: { theme: ThemeType }) => {
    return {
        textDecoration: 'none',
        display: 'block',
        color: p?.theme?.colors?.black,
        fontSize: '1.25rem',
        cursor: 'pointer',
        alignSelf: 'center',
        borderBottom: `1px solid ${p.theme?.colors?.listBorderColor || colors.listBorderColor}`,

        '&:first-of-type': {
            borderTop: `1px solid ${p.theme?.colors?.listBorderColor || colors.listBorderColor}`,
        },
    };
});

const RecurseStyled = styled.p({
    margin: '0.2rem 0',
    fontSize: '1.3rem',
    fontWeight: fontWeight.thin,
});

const TextArea = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

const IconDivWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const ListImage = styled.img({
    width: '138px',
    height: '138px',
    borderRadius: '2px',
    objectFit: 'cover',
    objectPosition: 'center',
});

const WrapperDiv = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});
const FirstIconDiv = styled.div((p: { theme: ThemeType }) => {
    return {
        cursor: 'pointer',
        width: '138px',
        height: '138px',
        marginLeft: '3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '1rem 1rem 1rem 0',
        '& span': {
            height: '100%',
            width: '100%',
            color: p?.theme?.colors?.textColor2,
        },
    };
});

const IconDiv = styled.div((p: { theme: ThemeType }) => {
    return {
        cursor: 'pointer',
        width: '20px',
        height: '20px',
        marginLeft: '1rem',
        marginRight: '3px',
        display: 'flex',
        '& span': {
            height: '100%',
            width: '100%',
            color: p?.theme?.colors?.textColor2,
        },
    };
});

const fallbackUrl = 'https://s3.eu-west-1.amazonaws.com/public.ourliving.se/ourliving/ourliving-transparent-icon.png';

type Props = {
    documentData?: DocumentDetail['document_data'];
    name: string;
} & LinkProps<unknown> &
    RefAttributes<HTMLAnchorElement>;

const HosingPostLink = ({ documentData, name, ...rest }: Props) => {
    const { data: signedUrl } = useGetSignedUrl(documentData, medium);
    const handleError = (e) => {
        e.currentTarget.src = fallbackUrl;
    };
    return (
        <StyledLink {...rest}>
            <Wrapper>
                <IconDivWrapper>
                    <FirstIconDiv>
                        <ListImage src={signedUrl || fallbackUrl} onError={handleError} />
                    </FirstIconDiv>
                    <TextArea>
                        <RecurseStyled>{name}</RecurseStyled>
                    </TextArea>
                </IconDivWrapper>
                <WrapperDiv>
                    <IconDiv>
                        <Icon.RightArrowBlack />
                    </IconDiv>
                </WrapperDiv>
            </Wrapper>
        </StyledLink>
    );
};

export default HosingPostLink;
