import { useMutation, useQueryClient } from 'react-query';
import ContactsApi from '@app/api/public/ContactsApi';
import * as ContactsModel from '@app/api/models/Contacts';
import { getContactQueryKeys } from './contactQueries';
import { useHistory } from 'react-router';

const removeContact = async (contact: {
    id: ContactsModel.Contact['id'];
    property_id: ContactsModel.Contact['property_id'];
}) => {
    return await ContactsApi.remove(contact.id, contact.property_id);
};

const useRemoveContact = () => {
    const queryClient = useQueryClient();

    const history = useHistory();
    return useMutation(removeContact, {
        onSettled: () => {
            queryClient.invalidateQueries(getContactQueryKeys.list());
        },
        onSuccess: () => {
            history.push('/contacts');
        },
    });
};

export default useRemoveContact;
