import styled from 'lib/styled';
import React, { FC } from 'react';
import { ThemeType } from '../../../../api/models/Theme';

type AvatarWithInitials = {
    name: string;
    url?: never;
};

type AvatarWithImage = {
    url: string;
    name?: never;
};
type AvatarProps = AvatarWithImage | AvatarWithInitials;

const Image = styled.div((props: { url?: string; theme: ThemeType }) => ({
    display: 'grid',
    placeItems: 'center',
    width: '40px',
    height: '40px',
    backgroundColor: props.url ? 'transparent' : props.theme.colors?.avatarBackground,
    backgroundImage: `url(${props.url})`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
}));

const Initials = styled.span({
    textTransform: 'uppercase',
    lineHeight: 0,
    color: 'black',
});

const getInitials = (name: string) => {
    const splitedName = name.split(' ');

    if (splitedName.length < 2) {
        return splitedName[0].substring(0, 2);
    }

    const firstInitial = splitedName[0].substring(0, 1);
    const secondInitial = splitedName[splitedName.length - 1].substring(0, 1);
    const initials = firstInitial + secondInitial;

    return initials;
};
const Avatar: FC<AvatarProps> = ({ url, name, ...rest }) => {
    if (!url && !name) return <Image role="img" aria-label="avatar" {...rest} />;

    const initials = name ? getInitials(name) : '';
    return url ? (
        <Image role="img" aria-label="avatar" {...rest} url={url} />
    ) : (
        <Image role="img" aria-label="avatar" {...rest}>
            <Initials>{initials}</Initials>
        </Image>
    );
};

export default Avatar;
