import styled from 'lib/styled';
import React from 'react';
import { HTMLProps } from 'react';
import { ChevronDown } from 'react-feather';
import { style } from '../../Shared/Style';

const SelectField = styled.select(({ theme }) => ({
    background: theme.colors.inputBackground,
    padding: `10px ${style.margin.s}px`,
    borderRadius: style.roundCornerSize.small,
    border: `1px solid ${theme.colors.inputBorder}`,
    width: '100%',
    outline: 'none',
    fontSize: style.fontSize.seta,
}));

const SelectWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    position: 'relative',
}));

const DropdownArrow = styled(ChevronDown)(({}) => ({
    position: 'absolute',
    right: 8,
    top: '50%',
    transform: 'translateY(-50%)',
}));

type Props = {
    wrapperClassName?: string;
    arrowClassName?: string;
};

const Select = React.forwardRef<HTMLSelectElement, HTMLProps<HTMLSelectElement> & Props>(
    ({ children, wrapperClassName, arrowClassName, ...rest }, ref) => {
        return (
            <SelectWrapper className={wrapperClassName}>
                <SelectField {...rest} ref={ref}>
                    {children}
                </SelectField>
                <DropdownArrow className={arrowClassName} />
            </SelectWrapper>
        );
    }
);

Select.displayName = 'Select';

export default Select;
