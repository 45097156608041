import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'lib/styled';
import StickyBarContent from '../../components/StickyBar/StickyBarContent';
import { FixedBar } from '../../components/StickyBar/FixedBar';
import Header from '../../components/Header/HeaderStart';
import TicketChat from './components/TicketChat';
import TicketDetail from './components/TicketDetail';
import { desktop } from '../../components/MediaQuery/breakpoints';
import Tickets from './Tickets';
import PropertiesLayout from '../../components/Layouts/PropertiesLayout';
import CreateTicket from './components/CreateTicket';

const MobileOnly = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const TicketsPage = () => {
    return (
        <Switch>
            <Route exact path="/properties/:propertyId/tickets/new">
                <PropertiesLayout>
                    <CreateTicket />
                </PropertiesLayout>
            </Route>
            <Route path="/properties/:propertyId/tickets/:ticketId/status">
                <PropertiesLayout>
                    <TicketDetail />
                </PropertiesLayout>
            </Route>
            <Route path="/properties/:propertyId/tickets/:ticketId">
                <TicketChat />
            </Route>
            <Route path="/properties/:propertyId/tickets">
                <PropertiesLayout>
                    <MobileOnly>
                        <Header to={'/properties'} />
                    </MobileOnly>
                    <Tickets />
                    <MobileOnly>
                        <FixedBar>
                            <StickyBarContent />
                        </FixedBar>
                    </MobileOnly>
                </PropertiesLayout>
            </Route>
        </Switch>
    );
};

export default TicketsPage;
