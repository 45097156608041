import styled from 'lib/styled';
import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SmallTab from '../../../../components/Tabs/SmallTab';
import TabList from '../../../../components/Tabs/TabList';
import { L } from '@app/shared/lib/i18n';
import NotificationCount from '../../../../components/NotificationCount';
import { useResidenceAreaCardList } from '../../hooks/areaCardQueries';
import { useGetPropertyDetail } from '../../../binder/hooks';

const StyledSmallTab = styled(SmallTab)({
    padding: '0px 0px 8px 0px',
    margin: '0px',
});

const StyledTabList = styled(TabList)({
    rowGap: '1rem',
    display: 'flex',
    flexDirection: 'column',
});

const StyledNotificationCount = styled(NotificationCount)({
    top: '14px',
    right: '104%',
});

const AreaNavDesktop: FC = (...rest) => {
    const history = useHistory();

    const { propertyId } = useParams<{ propertyId: string }>();

    const { data: propertyDetail } = useGetPropertyDetail(propertyId);

    const { data } = useResidenceAreaCardList(+propertyId);

    const Cards = data?.map((card) => {
        if (card.name === 'binder') {
            return (
                <StyledSmallTab
                    key={card.name}
                    to={{
                        pathname: `/properties/${propertyId}/binder`,
                        state: history.location.pathname,
                    }}
                >
                    {L('my_property', propertyDetail?.project_type)}
                </StyledSmallTab>
            );
        }
        if (card.name === 'ticket') {
            return (
                <StyledSmallTab
                    key={card.name}
                    to={{
                        pathname: `/properties/${propertyId}/tickets`,
                        state: history.location.pathname,
                    }}
                >
                    {L(card.name)}
                    <StyledNotificationCount counts={0} />
                </StyledSmallTab>
            );
        }
        if (card.name === 'booking' && propertyDetail?.project_type !== 'solar') {
            return (
                <StyledSmallTab
                    key={card.name}
                    to={{
                        pathname: `/projects/${propertyDetail?.project_id}/bookings`,
                        state: history.location.pathname,
                    }}
                >
                    {L(card.name)}
                    <StyledNotificationCount counts={0} />
                </StyledSmallTab>
            );
        }
        if (card.name === 'housing') return null;
        if (card.name === 'board') return null;
        if (card.name === 'document') return null;
        if (card.name === 'about') return null;
        if (propertyDetail?.project_type !== 'solar') {
            return (
                <StyledSmallTab
                    key={card.name}
                    to={{
                        pathname: `/properties/${propertyId}/${card.name}`,
                        state: history.location.pathname,
                    }}
                >
                    {L(card.name)}
                    <StyledNotificationCount counts={0} />
                </StyledSmallTab>
            );
        }
    });

    return <StyledTabList {...rest}>{Cards}</StyledTabList>;
};

export default AreaNavDesktop;
