import React, { useState } from 'react';
import styled from 'lib/styled';
import SendButton from '../../components/Forms/SendButton';
import { PageBackground } from '../../components/Layout/PageBackground';
import H1 from '../../components/Texts/H1/H1';
import CleanHeader from '../../components/Header/HeaderOnlyBackArrow';
import { L } from '@app/shared/lib/i18n';
import { style } from '../../components/Shared/Style';
import Label from '../../components/Forms/Label';
import Input from '../../components/Forms/Input';
import TextArea from '../../components/Forms/TextArea';
import useSaveContact from './hooks/useSaveContact';
import { SaveContactDetails } from '@app/api/models/Contacts';
import H2 from '../../components/Texts/H2';
import { colors } from '../../components/Shared/Style/colors';
import SendButtonHandler from './components/SendButtonHandler';
import { useHistory } from 'react-router-dom';
import { desktop } from '../../components/MediaQuery/breakpoints';

const Form = styled.form({
    display: 'grid',
});

const StyledLable = styled(Label)({
    marginTop: '18px',
});

const StyledSendButton = styled(SendButtonHandler)({
    width: '60px',
    height: '60px',
    placeSelf: 'center',
    marginTop: '1.5rem',
});

const Main = styled.div({
    width: '100%',
    padding: `0px ${style.margin.m}px 4rem ${style.margin.m}px`,
});

const StyledH1 = styled(H1)({
    margin: '0px',
});

const SubHeading = styled(H2)(() => {
    return {
        margin: '0px',
        fontSize: '18px',
        color: colors.textColor1,
        fontWeight: 'normal',
        [`@media screen and (min-width: ${desktop}px)`]: {
            display: 'none',
        },
    };
});

const StyledAddress = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: '4rem',
});

const ContactAdd: React.FC = () => {
    const history = useHistory();
    const [contactDetails, setContactDetails] = useState<SaveContactDetails>({});

    const { mutate: saveContact, status } = useSaveContact();

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContactDetails({ ...contactDetails, [e.target.id]: e.target.value });
    };
    const handleOnChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContactDetails({ ...contactDetails, [e.target.id]: e.target.value });
    };

    const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            saveContact(contactDetails, {
                onSuccess: (data) => history.push(`/contacts/${data.id}`),
            });
        } catch (err) {}
    };

    return (
        <Main>
            <SubHeading>{L('contacts')}</SubHeading>
            <StyledH1>{L('createNewContact')}</StyledH1>
            <Form onSubmit={handleOnSubmit}>
                <StyledLable name="name" title={L('name')} />
                <Input id="name" onChange={handleOnChange} required />

                <StyledLable name="company" title={L('company')} />
                <Input id="company" onChange={handleOnChange} />

                <StyledLable name="title" title={L('role')} />
                <Input id="title" onChange={handleOnChange} />

                <StyledLable name="phone_number" title={L('contact_number')} />
                <Input id="phone_number" onChange={handleOnChange} />

                <StyledLable name="email" title={L('contact_email')} />
                <Input id="email" onChange={handleOnChange} />

                <StyledLable name="street" title={L('street')} />
                <Input id="street" onChange={handleOnChange} />

                <StyledAddress>
                    <div>
                        <StyledLable name="postalcode" title={L('postal_code')} />
                        <Input id="postalcode" onChange={handleOnChange} />
                    </div>
                    <div>
                        <StyledLable name="city" title={L('profile_city')} />
                        <Input id="city" onChange={handleOnChange} />
                    </div>
                </StyledAddress>

                <StyledLable name="info" title={L('extra_information')} />
                <TextArea id="info" onChange={handleOnChangeTextArea} />

                <StyledSendButton status={status} />
            </Form>
        </Main>
    );
};

export default ContactAdd;
