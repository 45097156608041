import * as React from 'react';
import styled from 'lib/styled';
import { FC } from 'react';
const PageContainer = styled.div(({ theme }) => {
    return {
        width: '100%',
        height: '100%',
        display: 'flex',
        background: theme.colors.pageBackground,
        flexDirection: 'column',
        alignItems: 'center',
    };
});

export const Page: FC = ({ children, ...rest }) => {
    return <PageContainer {...rest}>{children}</PageContainer>;
};
