import { SpaceListing } from '@app/api/models/Binders';
import React from 'react';

type props = {
    name: string;
    value: string | SpaceListing;
};

const SelectOptions: React.FC<props> = ({ name, value }) => {
    const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
    return value ? <option value={stringValue}>{name}</option> : null;
};

export default SelectOptions;
