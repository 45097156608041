import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as UserIcon } from '../user.svg';

const ThemedUser = styled(UserIcon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& path': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& polyline': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& line': {
            stroke: theme?.colors?.icons || colors.icons,
        },
    };
});
const User: FC = ({ ...rest }) => {
    return <ThemedUser {...rest} />;
};

export default User;
