import { ThemeType } from '@app/api/models/Theme';
import styled from 'lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import { ReactComponent as Icon } from '../solar-production.svg';

const ThemedSolarProduction = styled(Icon)(({ theme }: { theme: ThemeType }) => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: theme?.colors?.icons || colors.icons,
            strokeWidth: '3px',
        },
        '& circle': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& rect': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& line': {
            stroke: theme?.colors?.icons || colors.icons,
        },
        '& polyline': {
            stroke: theme?.colors?.icons || colors.icons,
        },
    };
});
const SolarProduction: FC = ({ ...rest }) => {
    return <ThemedSolarProduction {...rest} />;
};

export default SolarProduction;
