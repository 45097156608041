import React, { FC } from 'react';
import styled from 'lib/styled';
import { tablet } from '@app/web/components/MediaQuery/breakpoints';
import { style } from '../../Shared/Style';
import { ThemeType } from '@app/api/models/Theme';
import TextAreaAutosize from 'react-textarea-autosize';

const TextAreaField = styled(TextAreaAutosize)((p: { theme: ThemeType }) => {
    return {
        background: p?.theme?.colors?.inputBackground || style.colors.inputBackground,
        border: `1px solid ${p?.theme?.colors?.inputBorder || style.colors.inputBorder}`,
        width: '100%',
        borderRadius: style.roundCornerSize.small,
        outline: 'none',
        padding: style.margin.s,
        fontSize: style.fontSize.seta,
        maxWidth: '100%',
        resize: 'vertical',
        minHeight: '10vh',

        '&:read-only': {
            background: p.theme.colors.inputBackgroundDisabled,
        },

        [`@media screen and (min-width: ${tablet}px)`]: {
            minHeight: '150px',
        },
    };
});

type TextAreaProps = React.ComponentProps<typeof TextAreaAutosize>;

export const TextArea: FC<TextAreaProps> = ({ ...rest }) => {
    return <TextAreaField id={rest.name || rest.id} {...rest} />;
};

export default TextArea;
