import ResidenceApi from '@app/api/public/ResidenceApi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const ticketQueryKeys = {
    all: ['ticket'],
    isEnabled: (id?: number) => [...ticketQueryKeys.all, 'isEnabled', id],
};

export const useIsTicketEnabledForProperty = () => {
    const { propertyId } = useParams<{ propertyId: string }>();

    return useQuery(ticketQueryKeys.isEnabled(+propertyId), () => getTicketEnabled(+propertyId), {
        enabled: !!propertyId,
    });
};

const getTicketEnabled = async (propertyId?: number) => {
    if (!propertyId) return [];
    return await ResidenceApi.isTicketEnabled(propertyId);
};
