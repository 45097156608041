import styled from '@emotion/styled';
import React from 'react';
import ResidenceSharedWithListHandler from '../components/ResidenceSharedWithListHandler';
import { desktop } from '../../../components/MediaQuery/breakpoints';

const Main = styled.div({
    padding: '0px 16px',
    width: '100%',
    marginBottom: '60px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        padding: '0px',
    },
});

const ResidenceSharedWithPageMobile: React.FC = () => {
    return (
        <>
            <Main>
                <ResidenceSharedWithListHandler searchValue={''} />
            </Main>
        </>
    );
};

export default ResidenceSharedWithPageMobile;
