import { rpc } from 'koa-rpc-call';
import { BaseApi } from '../lib/BaseApi';
import { Property } from '../models/Messages';
import * as TasksModel from '../models/Tasks';
import { Task, RecurrencyType, TaskFilter } from '../models/Tasks';
import { getLoginContext } from '../models/Users';
export { RecurrencyType, TaskFilter };

class TasksApi extends BaseApi {
    @rpc({ httpMethod: 'GET' })
    async list(propertyId: string, filter?: TaskFilter) {
        const context = await getLoginContext(this.user.id, +propertyId);
        if (context?.property_id) {
            return await TasksModel.list(+context.property_id, filter);
        }

        return null;
    }
    @rpc({ httpMethod: 'GET' })
    async byId(propertyId: string, id: Task['id']) {
        console.log(propertyId, id);
        const context = await getLoginContext(this.user.id, +propertyId);
        if (context?.property_id) {
            const r = TasksModel.id(id);
            return r;
        }

        return null;
    }

    @rpc({ httpMethod: 'GET' })
    async listTemplates(propertyId: string) {
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            return await TasksModel.listTemplates(+context.property_id);
        }
    }

    @rpc({ httpMethod: 'GET' })
    async saveTask(propertyId: string, task: Partial<Task>) {
        // Set on property check if entity that entity exist on property
        const context = await getLoginContext(this.user.id, propertyId);
        if (context?.property_id) {
            task.taskable_id = '' + context.property_id;
            task.taskable_type = 'Property';
            return await TasksModel.save(task);
        }
    }

    @rpc({ httpMethod: 'GET' })
    async snooze(id: string) {
        return await TasksModel.snooze(id);
    }

    @rpc({ httpMethod: 'GET' })
    async markAsCompleted(id: number, propertyId: Property['id']) {
        return await TasksModel.markAsCompleted(id, propertyId);
    }

    @rpc({ httpMethod: 'GET' })
    async markAsNonCompleted(id: string) {
        return await TasksModel.markAsNonCompleted(id);
    }
    @rpc({ httpMethod: 'GET' })
    async removePropertyTask({ todoId, propertyId }: { todoId: number; propertyId: Property['id'] }) {
        const context = await getLoginContext(this.user.id, propertyId);

        const task = await TasksModel.id(todoId);
        let chk: { id: Task['id'] };
        if (context?.property_id && task?.taskable_type === 'Entity')
            chk = await TasksModel.getTaskEntityExistsOnProperty(todoId, propertyId);
        else if (context?.property_id && task?.taskable_type === 'Property')
            chk = await TasksModel.getTaskPropertyExistsOnProperty(todoId, propertyId);

        if (chk.id > 0) return await TasksModel.removeTask(todoId);
        return 0;
    }
}

export default new TasksApi();
