import React, { FC, Fragment } from 'react';
import Feed from '../../../components/Feed';
import Message from '../../../components/thread/message/Message';
import styled from 'lib/styled';
import { Stylable } from '../../../types';
import { useParams } from 'react-router-dom';
import { Message as MessageType } from '@app/api/models/Messages';
import Divider from '../../../components/Divider/Divider';
import { ITEMS_PER_PAGE, THRESHOLD } from '../lib/constants';
import TicketsApi from '@app/api/public/TicketsApi';
import { L } from '@app/shared/lib/i18n';

const StyledDivider = styled(Divider)({});
export const ticketMessageFeedQueryKey = (ticketId: number) => ['tickets', 'messages', ticketId];

export const TicketMessageFeed: FC<Stylable> = ({ className }) => {
    const { ticketId } = useParams<{ ticketId: string }>();

    return (
        <Feed
            className={className}
            threshold={THRESHOLD}
            cacheKey={ticketMessageFeedQueryKey(+ticketId)}
            reverse
            partial
            emptyResponse={L('no_ticket_messages')}
            asyncFn={async ({ pageParam }) => {
                const ticketMessages = await TicketsApi.listTicketMessages(+ticketId, pageParam, ITEMS_PER_PAGE);
                return ticketMessages ?? undefined;
            }}
        >
            {(msg: MessageType | undefined) =>
                msg && (
                    <Fragment key={msg.id}>
                        <Message {...msg} />
                        <StyledDivider width={100} />
                    </Fragment>
                )
            }
        </Feed>
    );
};

export default TicketMessageFeed;
