import styled from 'lib/styled';
import { observer } from 'mobx-react';
import React from 'react';
import store from './store';

const Div = styled.div<{ opacity: number }>(({ opacity }) => ({
    opacity: opacity,
}));

const Mask: React.FC = ({ children }) => {
    const { styles } = store;

    return <Div opacity={styles ? styles.opacity : 1}>{children}</Div>;
};

export default observer(Mask);
