import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import TicketsApi from '@app/api/public/TicketsApi';
import { CreateMessage } from '@app/api/models/Messages';
import { ticketMessageFeedQueryKey } from '../components/TicketMessageFeed';

type Attachments = {
    attachments: File[];
};
export type IntermediateMsg = Omit<CreateMessage, 'attachment_ids' | 'thread_id'> & Attachments & { ticketId: number };

export const useSendTicketMessage = () => {
    const queryClient = useQueryClient();
    const { ticketId } = useParams<{ ticketId: string }>();
    const key = ['tickets', ticketId];

    return useMutation(saveMessage, {
        onError: (_err, _var, saved) => {
            queryClient.setQueryData(key, saved);
        },

        onSuccess: () => {
            queryClient.invalidateQueries(key);
            queryClient.invalidateQueries(ticketMessageFeedQueryKey(+ticketId));
            queryClient.invalidateQueries('tickets');
        },
    });
};

const saveMessage = async ({ ticketId, text, attachments }: IntermediateMsg) => {
    return TicketsApi.saveMessage({ id: ticketId, message: { text }, attachments });
};
