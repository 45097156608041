import * as React from 'react';
import styled from 'lib/styled';
import { LinkProps, NavLink } from 'react-router-dom';
import { Colors } from '../../Shared/Style/colors';
import IconWrapper from '../../Icon/ThemedIcons/IconWrapper';
import NotificationBellIcon from '../../Icon/ThemedIcons/NotificationBellIcon';
import NotificationCount from '../../NotificationCount';
import useGetNotificationBellCount from './hooks/useGetNotificationCount';
import useResetNotificationBell from './hooks/useResetnotificationBell';

const StyledNavLink = styled(NavLink)(({ size }: { size?: string }) => ({
    textDecoration: 'none',
    display: 'block',
}));

const StyledNotificationCount = styled(NotificationCount)({
    top: '-7px',
    right: '-7px',
});

type Props = {
    to: LinkProps['to'];
    color?: Colors;
    size?: string;
};

export const NotificationBell: React.FC<Props> = ({ to, size }) => {
    const bellCount = useGetNotificationBellCount();
    const { mutate: resetBell } = useResetNotificationBell();
    return (
        <StyledNavLink onClick={() => resetBell()} aria-label="notification bell" size={size} to={to}>
            <IconWrapper>
                <NotificationBellIcon />
                <StyledNotificationCount counts={bellCount?.data?.value ?? 0} />
            </IconWrapper>
        </StyledNavLink>
    );
};
export default NotificationBell;
