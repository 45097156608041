import lazyRetry from 'lib/lazyRetry';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const EmailSettings = lazy(() => lazyRetry(() => import('./EmailSettings')));

export const routes = [
    <Route exact key={1} path="/emailsettings">
        <EmailSettings />
    </Route>,
];
